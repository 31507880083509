import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserService } from '@modules/auth/services';

import { environment } from 'environments/environment';
import type { ActivaODesactivaComments, Default, PostComments, PutComentario, ResponsePostActivaODesactivaComments, ResponsePostComments } from '../models';
import { AdministrationCierreDeMesService } from '@modules/administration/services';

@Injectable({
    providedIn:'root'
})
export class ReportsService {
    API_URL_COMMOMS = environment.api.commons;
    API_URL_COMMOMS2 = environment.api.commons2;
    API_URL_AK_REPORTS = environment.api.akReport;
    MEDICAL_TK_REPORTS = environment.api.medicalReportsTK;
    API_CIERRE_REPORTS = environment.api.admonReportCierre;
    API_URL_CIERRE_MES = environment.api.administracionCierreMes;
    constructor(
        private http: HttpClient,
        private userService: UserService,
        private cierreMesSvc: AdministrationCierreDeMesService) {}

    getReports$(): Observable<{}> {
        return of({});
    }

    async ListInstituciones(ActiveInstitucionID) {
        return new Promise((resolve, reject) => {
            this.http
                .post<Default>(`${this.API_URL_COMMOMS}/catalogs/listInstituciones`,  {InstitucionID: ActiveInstitucionID })
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    // console.info('RESPONSE: ', resp.data);
                    resolve(resp.data);
                });
        });
    }

    async ListUsuarios() {
        const requestBody = {
            bitOperaciones: 1
        }
        return new Promise((resolve, reject) => {
            this.http
                .post<Default>(`${this.API_URL_COMMOMS}/generales/ListaUsuarios`, requestBody)
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    // console.info('RESPONSE: ', resp.data);
                    resolve(resp.data);
                });
        });
    }

    async ListGrupoMedico() {
        return new Promise((resolve, reject) => {
            this.http
                .get<Default>(`${this.API_URL_COMMOMS}/generales/ListaGrupoMedico`)
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    // console.info('RESPONSE: ', resp.data);
                    resolve(resp.data);
                });
        });
    }

    async ListParametros() {
        return new Promise((resolve, reject) => {
            this.http
                .get<Default>(`${this.API_CIERRE_REPORTS}/-reportes-facturacion/get-params`)
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    // console.info('RESPONSE: ', resp.data);
                    resolve(resp.data);
                });
        });
    }

    async ListaTipoInst() {
        return new Promise((resolve, reject) => {
            this.http
                .get<Default>(`${this.API_URL_COMMOMS2}/generales/ListaTipoInst`)
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    // console.info('RESPONSE: ', resp.data);
                    resolve(resp.data);
                });
        });
    }

    async getPendientes() {
        return new Promise((resolve, reject) => {
            this.http
                .get<Default>(`${this.MEDICAL_TK_REPORTS}/tk/captura-val/pendientes`)
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    // console.info('RESPONSE: ', resp.data);
                    resolve(resp.data);
                });
        });
    }

    async getDatosReporteMedicoAkMensual(requestBody) {
        return new Promise((resolve, reject) => {
                const headers = new HttpHeaders({
                    Authorization: `Bearer ${this.userService.AccessToken}`,
                });
            this.http
                .post<Default>(`${this.API_URL_AK_REPORTS}/ak/ReportMedicoMensual`, requestBody,{headers})
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    // console.info('RESPONSE: ', resp.data);
                    resolve(resp.data);
                });
        });
    }

    async getDatosReporteMedicoAkMensualDetalle(requestBody) {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({
                Authorization: `Bearer ${this.userService.AccessToken}`,
            });
            this.http
                .post<Default>(`${this.API_URL_AK_REPORTS}/ak/ReportMedicoMensualDetalle`, requestBody, {headers})
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    // console.info('RESPONSE: ', resp.data);
                    resolve(resp.data);
                });
        });
    }
    async BusquedaMuestra(requestBody) {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({
                Authorization: `Bearer ${this.userService.AccessToken}`,
            });
            this.http
                .post<Default>(`${this.MEDICAL_TK_REPORTS}/tk/busquemuestra`, requestBody, {headers})
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    // console.info('RESPONSE: ', resp.data);
                    resolve(resp.data);
                });
        });
    }
    async GuardarMuestra(requestBody) {
        return new Promise((resolve, reject) => {
            this.http
                .post<Default>(`${this.MEDICAL_TK_REPORTS}/tk/savemuestra`, requestBody)
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    // console.info('RESPONSE: ', resp.data);
                    resolve(resp.data);
                });
        });
    }

    async RegresarFiltro(requestBody) {
        return new Promise((resolve, reject) => {
            this.http
                .post<Default>(`${this.MEDICAL_TK_REPORTS}/tk/liberarfiltro`, requestBody)
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    // console.info('RESPONSE: ', resp.data);
                    resolve(resp.data);
                });
        });
    }


    async BuscarIngresoAuxilirReport(requestBody) {
        return new Promise((resolve, reject) => {
            this.http
                .post<Default>(`${this.API_CIERRE_REPORTS}/-reportes-facturacion/getReporteAux`, requestBody)
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    // console.info('RESPONSE: ', resp.data);
                    resolve(resp.data);
                });
        });
    }
    async BuscarReporteMovimientos(requestBody) {
        let urlData = ""
        if(requestBody.tipomovid == this.cierreMesSvc.TipoMovimientoEnum.Ingresos)
        {
            urlData = "admon/ObtenerOtrosIngresos"
        } else if(requestBody.tipomovid == this.cierreMesSvc.TipoMovimientoEnum.Egresos){
            urlData = "admon/obtenerrevgastos"
        } else if(requestBody.tipomovid == this.cierreMesSvc.TipoMovimientoEnum.Provision_Egresos){
            urlData = "admon/get-provision-egresos"
        } else if(requestBody.tipomovid == this.cierreMesSvc.TipoMovimientoEnum.Polizas_Diario){
            urlData = "admon/get-polizas-diario"
        }

        return new Promise((resolve, reject) => {
            this.http
                .post<Default>(`${this.API_URL_CIERRE_MES}/${urlData}`, requestBody)
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    // console.info('RESPONSE: ', resp.data);
                    resolve(resp.data);
                });
        });
    }
    async BuscarFacturacionMensualReport(requestBody) {
        return new Promise((resolve, reject) => {
            this.http
            .post<Default>(`${this.API_URL_CIERRE_MES}/admon/obtenerfacturacion`, requestBody)
            .subscribe({
                next(resp) {
                    if (resp.status === 'fail') {
                        console.error('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error(err){
                    console.error('ERROR: ', err);
                    reject(err);
                }
            });                                
        });
    }

    async AccionComentario(requestBody) {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });
 
        return new Promise((resolve, reject) => {
            this.http
                .post<Default>(`${this.MEDICAL_TK_REPORTS}/tk/pf/comentario`, requestBody, {headers})
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                });
        });
    }

    async CambiaEstatus(requestBody) {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });
 
        return new Promise((resolve, reject) => {
            this.http
                .post<Default>(`${this.MEDICAL_TK_REPORTS}/tk/pf/comentario/estatus`, requestBody, {headers})
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                });
        });
    }

    async PfSinDatos(requestBody) {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });
 
        return new Promise((resolve, reject) => {
            this.http
                .post<Default>(`${this.MEDICAL_TK_REPORTS}/tk/pf/sindatos `, requestBody, {headers})
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                });
        });
    }

    async ValidarCortesiaDescuento(requestBody) {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });
 
        return new Promise((resolve, reject) => {
            this.http
                .post<Default>(`${this.MEDICAL_TK_REPORTS}/tk/pf/cortesia/descuento `, requestBody, {headers})
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                });
        });
    }

    saveCommentMedico(comentario: PutComentario):Observable<PostComments>{
        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.MEDICAL_TK_REPORTS}/tk/pf/comentario`;
        return this.http.post<ResponsePostComments>(url, comentario, { headers }).pipe(
            map( resp => resp.data )
        )
    }

    activaODesactivaComments(comentario): Observable<ActivaODesactivaComments>{
        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url = `${this.MEDICAL_TK_REPORTS}/tk/pf/comentario/estatus`
        return this.http.post<ResponsePostActivaODesactivaComments>(url, comentario, { headers }).pipe(
            map( resp => resp.data)
        )
    }

    eliminaComments(comentario): Observable<ActivaODesactivaComments> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url = `${this.MEDICAL_TK_REPORTS}/tk/pf/comentario/del`
        return this.http.post<ResponsePostActivaODesactivaComments>(url, comentario, { headers }).pipe(
            map( resp => resp.data)
        )
    } 
    

}
