import { Component, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ChildActivationEnd, Router } from '@angular/router';
//import { I18nService } from '@modules/i18n/services';
import { SBRouteData } from '@modules/navigation/models';
import { Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { environment } from '../environments/environment';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    public readonly:boolean=true
    title = 'GK-Admin';
    apiURL = environment.api;
    private destroy$ = new Subject<void>();
    constructor(public router: Router, private titleService: Title, 
        // private i18nService: I18nService
    ) {
        this.router.events
            .pipe(
                filter(event => event instanceof ChildActivationEnd),
                map(event => {
                    let snapshot = (event as ChildActivationEnd).snapshot;
                    while (snapshot.firstChild !== null) {
                        snapshot = snapshot.firstChild;
                    }
                    return snapshot;
                }))
            }}

                // switchMap(snapshot => {
                //     const titleOrTranslationKey = (snapshot.data as SBRouteData).title || 'GK Admin Angular';
                  //  return //this.i18nService.selectTranslate(titleOrTranslationKey);
              //  }),
            //     takeUntil(this.destroy$)
            // )
            // .subscribe(title => {
            //     this.titleService.setTitle(title);
            // });
   // }

    // ngOnDestroy() {
    //     this.destroy$.next();
    //     this.destroy$.complete();
    // }
//}
