<div class="container-gray">

    @for (param of parametrosFln; track $index) {
        <div class="card mb-5 mt-1">
            <div class="card-body">
                <div class="row mt-4">
                    <div class="col-5">
                        <fa-icon class="text-success btnTam" [icon]='["fas", "tint"]'></fa-icon>
                        <strong class="text-success ms-1 titleStrong">{{ param.prueba_desc }}  ({{ param.Prueba }})</strong>
                    </div>
                    <div class="col-2">
                        <a class="txtlinka" (click)="toggleDetalle($index)"><i class="fa btnTam" [ngClass]="ocultarDetalles[$index] ? 'fa-folder' : 'fa-folder-open'"></i> {{ ocultarDetalles[$index] ? 'Mostrar Detalle' : 'Ocultar Detalle' }}</a>
                    </div>
                    <div class="col-2">
                        <a class="txtlinka" (click)="agregarComentario(param)"><i class="fa fa-comments btnTam"></i> Agregar Comentario</a>
                    </div>
                </div>
                <mat-divider></mat-divider>
                @if(!ocultarDetalles[$index]){
                    <div class="row mt-4" @enter>
                        <div class="col-6">
                            <div class="row">
                                <div class="col-3">
                                    <label class="fw-bold ms-5">Resultado:</label>
                                </div>
                                <div class="col">
                                    {{ param.respf }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3">
                                    <label class="fw-bold ms-5">Comentarios:</label>
                                </div>
                                <div class="col">
                                    <label class="txtlinka" (click)="verComentarios(param.ComentariosMed, param, 2)">{{ param.ComentariosMed.length }}</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3">
                                    <label class="fw-bold ms-5">Deficiencia:</label>
                                </div>
                                <div class="col">
                                    <label>{{ param.NombreDeficiencia }}</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3">
                                    <label class="fw-bold ms-5">Calificación TNA:</label>
                                </div>
                                <div class="col">
                                    <label>{{ param.CalificacionTNA }}</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3">
                                    <label class="fw-bold ms-5">Seguimiento:</label>
                                </div>
                                <div class="col">
                                    <label>{{ param.txtSegumiento }}</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="col-12 text-center">
                                <label class="txtlinka" (click)="toggleHistorial($index)">
                                    {{ ocultarHistorial[$index] ? 'Mostrar' : 'Ocultar' }} Historial de calificaciones
                                </label>
                            </div>
                                <div class="col-12 text-center">
                                    <h3 class="mb-2 mt-4" *ngIf="!ocultarHistorial[$index]" @fadeInOut>Historial de Calificaciones</h3>
                                </div>
                                <div class="col-12">
                                    <table class="table table-striped table-bordered row-border hover nowrap" @fadeInOut *ngIf="!ocultarHistorial[$index]">
                                        <thead>
                                            <tr class="ttdblue">
                                                <th class="text-start">CALIFICACIÓN ANTERIOR</th>
                                                <th class="text-start">CALIFICACIÓN ACTUAL</th>
                                                <th class="text-end">FECHA</th>
                                                <th class="text-start">USUARIO</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            @for (calif of param.HistorialCalificaciones.datos; track $index) {
                                                <tr>
                                                    <td class="text-start">{{ calif.Ant }}</td>
                                                    <td class="text-start">{{ calif.Act }}</td>
                                                    <td class="text-end">{{ calif.fecha | date: 'dd-MMM-yyyy': "UTC" }}</td>
                                                    <td class="text-start">{{ calif.Usuario }}</td>
                                                </tr>
                                            }@empty {
                                                <tr >
                                                    <td class="text-center" colspan="4">
                                                        {{ param.HistorialCalificaciones.mensaje }}
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                        </div>
                    </div>
                }
                
            </div>
        </div>
    }@empty {
        <h2 class="text-center">No hay datos registrados!</h2>
    }

</div>
