import { ChangeDetectionStrategy, Component,ChangeDetectorRef, OnInit,Output, EventEmitter, Input, AfterContentInit, AfterViewInit, ViewChild, inject, Injectable } from '@angular/core';

import { UserService } from '@modules/auth/services';
import { FormBuilder, FormGroup, NgForm, Validators} from '@angular/forms';
import { AppCommonService } from '@modules/app-common/services';
import { ReportsService } from '@modules/reports/services';
import { AdministracionService } from '@modules/catalogs/containers/administracion/services';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { DisabledTimeFn, DisabledTimePartial } from 'ng-zorro-antd/date-picker';
import { Session } from 'inspector';
import { TamikService } from '@modules/operations-tk/services';
import { TamikService as TamikServiceTools} from '@modules/operations-menu/containers/tools-operations/containers/tamik/services/tamik.service';
import { ValidatorService } from '@modules/validators/validator.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { combosRazon } from '@modules/operations-menu/containers/tools-operations/containers/tamik/models';
import { ConfirmacionMComponent } from '@modules/customers/components';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'gk-sample-capture-filter',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './sample-capture-filter.component.html',
    styleUrls: ['sample-capture-filter.component.scss'],
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('500ms ease-in', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('500ms ease-out', style({ opacity: 0 }))
            ])
        ])
    ]
})
export class SampleCaptureFilterComponent implements OnInit {

    @Input() dataRecollecion: any=[];
    @Input() visor: any;
    @Input() visorSimple: any;
    @Input() modifPF: any;
    @Input() Language;
    @Input() comboRazon: combosRazon[]
    pfCorreo:number = 0
    
    formFiltros: FormGroup 
    formbuldierConfig = {}
    AppCommonService: any;
    DataPaises: any=[];
    DataEstados: any=[];
    DataCiudades: any=[];
    nameCiudad: any;
    idPais: any;
    PaisChecked: any;
    idEstado: any;
    DataCiudadesM: any=[];
    DataCiudadesMe: any=[];
    DataEstadosM: any=[];
    DataEstadosMe: any=[];
    DataPaisesM: any=[];
    DataPaisesMe: any=[];
    idPaisMe: any;
    idEstadoMe: any;
    idOption:any;
    clasePadding:any=''
    claseLabel:any=''
    
    @Output()
    propagar = new EventEmitter<boolean>();
    @ViewChild('f') testFormElement: NgForm;

    
    readonly searchType = [{"id": 0, "value": 'M', text:'Masculino'}, {"id": 1, "value": 'F', text:'Femenino'}];
    readonly searchLecheMaterna = [{"id": 0, "value": 'S', text:'Si'}, {"id": 1, "value": 'N', text:'No'}];
    readonly searchLactosa = [{"id": 0, "value": 'S', text:'Si'}, {"id": 1, "value": 'N', text:'No'}];
    readonly searchLipidos = [{"id": 0, "value": 'S', text:'Si'}, {"id": 1, "value": 'N', text:'No'}];
    readonly searchNova = [{"id": 0, "value": 1, text:'Si'}, {"id": 1, "value": 0, text:'No'}];
    readonly searchCarnitina = [{"id": 0, "value": 'S', text:'Si'}, {"id": 1, "value": 'N', text:'No'}];
    readonly searchNutriParental = [{"id": 0, "value": 'S', text:'Si'}, {"id": 1, "value": 'N', text:'No'}];
    readonly searchRaza = [{"value": 1, "text":'Blanca'}, {"value": 2, "text":'Negra'},{"value": 3, "text":'Asiatica'}, {"value": 4, "text":'Mestiza'},{"value": 5, "text":'Otra'}];
    validDate: any;
    datoSexo: number = 0;
    showfechaTrans: boolean = false
    option_idChecked: any;
    option_idChecked2: any;
    Filtro_Id: number | undefined;
    Paciente_id: any = '';
    today = new Date();
    timeDefaultValue = setHours(new Date(), 0);
    DatosVisor: any;
    bitVisor: any;
    user: any  = btoa('ngUsrGK')
    pass: any  = btoa('ngGenomi-k2023!.123')
    urlBase:any='https://sistema.genomi-k.com/';
    isCliente: any = 0;
    DetalleImagenFront: any;
    ImagenPF: any;
    URLImagen: any = '';
    Institucion_id: any;
    Kit_id: any;
    DataComentarios: any;
    sectiorPublicoShow: any;
    pacienteNovaShow: any;
    catalogoDoctores: any;
    Cortesia: any;
    validadoDescCort: number;
    vRazon1: any;
    ActiveNivelID: any;
    FormaTk: any;

    private vS = inject(ValidatorService);
    private tamikService = inject(TamikService);
    private tamikServiceTools = inject(TamikServiceTools);
    private dialog = inject(MatDialog);
    pacientenuevo: any;
    filtroinicial: any;
    cambioDespuesDeRes: any;
    
    range(start: number, end: number): number[] {
        const result: number[] = [];
        for (let i = start; i < end; i++) {
        result.push(i);
        }
        return result;
    }
    constructor(
        public appCommon: AppCommonService,
        private ref: ChangeDetectorRef,
        private reportsService:ReportsService,
        public formBuilder: FormBuilder,
        public userService: UserService,
        http: HttpClient,         
        private router: ActivatedRoute,
    ) {
        this.formFiltros = formBuilder.group({
            nombre: [''],
            Apellidos: [''],
            inpSexo: [''],
            semGestacion: [''],
            peso: [''],
            registroInst: [''],
            muestraProf: [''],
            fechaNac: [''],
            fechaNacHorario: [''],
            fechaMuestra: [''],
            fechaMuestraHorario: [''],
            muestra: [''],
            antes24horas: [''],
            raza: [''],
            chekSaludable: [''],
            chekEnfermo: [''],
            chekCuidadoInt: [''],
            chekVolPeque: [''],
            chekIntercambio: [''],
            Leche: [''],
            carnitina: [''],
            Lactosa: [''],
            nutriParental: [''],
            lipidos: [''],
            comentarios: [''],
            nmama: [''],
            apmama: [''],
            mamadir: [''],
            paismama: [''],
            estadomama: [''],
            ciudadmama: [''],
            telefonomama: [''],
            mamacorreo: [''],
            nmedico: [''],
            apmedico: [''],
            medicodir: [''],
            paismedicoid: [''],
            estadomedicoid: [''],
            ciudadmedicoid: [''],
            medicotel: [''],
            medicocorreo: [''],
            fechaTrans: [''],        
            novaconchita: [''],
            enviadapor: [''],
            sectorpublico : [''],
            EnviaCorreo: [''],
            TipoCorDes: [''],
            inpCorDesId: [''],
            guardarsolo: [''],
            show_error: [''],
            arrayDatosCompara: ['']
        });
    }
    
    formModifi: FormGroup = this.formBuilder.group({
        Comentarios: [ '', [Validators.required, this.vS.noWhitespaceValidator ] ],
        Razon: [ '', Validators.required ],
    });

    ngOnInit() {
        //console.log(this.visor)
        this.getNivelID();

        if(this.visor == true){
            this.dataRecollecion = this.dataRecollecion[0]
        }
        if(location.hostname.toLowerCase().indexOf("localhost") != -1) {
            this.urlBase = "https://demo.genomi-k.com/"
        }
        
        //datos pfmod
        this.pacientenuevo = this.dataRecollecion.pacientenuevo       
        this.filtroinicial = this.dataRecollecion.filtroinicial       
        this.cambioDespuesDeRes = this.dataRecollecion.cambioDespuesDeRes       


        this.DatosVisor = this.dataRecollecion.DatosVisor       
        this.bitVisor = this.dataRecollecion.bitVisor
        if(this.bitVisor == 1){
            this.clasePadding = 'no-padding'
        }      
        this.FormaTk = this.dataRecollecion.FormaTk
        console.log('this.FormaTk',this.FormaTk)
        this.Filtro_Id = this.dataRecollecion.resultFiltroQry[0].Filtro_id
        this.ImagenPF = this.dataRecollecion.resultFiltroQry[0].imagen_pf
        if(this.ImagenPF != '' && this.ImagenPF != null){
            this.URLImagen = 'https://sistema.genomi-k.com/imagen_pf/' + this.ImagenPF
        }
        this.Paciente_id = this.dataRecollecion.dataFilter.pacienteid         
        const comentarios = this.dataRecollecion.dataFilter.Comentarios    
        this.vRazon1 = this.dataRecollecion.dataFilter.vRazon1    
        this.DataComentarios = {
            Medicos: comentarios.Medicos,
            Operativos: comentarios.Operativos,
            Calificacion: comentarios.Calificacion,
            usuario: comentarios.usuario,
            Paciente_id: this.Paciente_id,
            Filtro_Id: this.Filtro_Id,
        }
        this.getIsCliente();
        
        if(this.DatosVisor.cartasSCtabla != 0){
            this.DatosVisor.cartaSCfile= this.urlBase + "ng-files/carta_"+this.DatosVisor.Codigo+".pdf"
            this.DatosVisor.cartaSCurl= this.urlBase + "ng_cartas_sc.cfm?isCliente="+this.isCliente+"&res_carta="+this.DatosVisor.filtro_id+"&user="+this.user+"&pass="+this.pass+"&nfile=carta_"+this.DatosVisor.Codigo
        }
        this.cargarPaises(this.dataRecollecion.arrayPaises);
        this.cargarEstados(this.dataRecollecion.arrayEstados);
        this.cargarCiudades(this.dataRecollecion.arrayCiudades);
        this.Institucion_id = this.dataRecollecion.resultqryEncabezado[0].institucion_id
        this.Kit_id = this.dataRecollecion.resultqryEncabezado[0].Kit_id
        this.pacienteNovaShow = this.dataRecollecion.dataFilter.pacienteNovaShow
        this.sectiorPublicoShow = this.dataRecollecion.dataFilter.sectiorPublicoShow
        this.catalogoDoctores = this.dataRecollecion.dataFilter.catalogoDoctores
        this.Cortesia = this.dataRecollecion.dataFilter.Cortesia
        this.pfCorreo = this.dataRecollecion.dataFilter.pf_correo

        if(this.bitVisor != 1){
            const ciudadInstitucion = this.dataRecollecion.resultqryEncabezado[0].ciudad_id
            const dirmedico = this.dataRecollecion.dataFilter.dirmedico
            const estadoInstitucion = this.DataCiudades.filter(ciudad => ciudad.Ciudad_id == ciudadInstitucion)[0].Estado_id
            const paisInstitucion = this.DataEstados.filter(estado => estado.Estado_id == estadoInstitucion)[0].Pais_id            
            //madre
            this.formFiltros.controls.paismama.setValue(paisInstitucion);
            this.formFiltros.controls.estadomama.setValue(estadoInstitucion);
            this.formFiltros.controls.ciudadmama.setValue(ciudadInstitucion);
            
            //dr
            this.formFiltros.controls.paismedicoid.setValue(paisInstitucion);
            this.formFiltros.controls.estadomedicoid.setValue(estadoInstitucion);
            this.formFiltros.controls.ciudadmedicoid.setValue(ciudadInstitucion);
            if(dirmedico != '' && dirmedico != undefined){
                this.formFiltros.controls.medicodir.setValue(dirmedico);
            }
        }
        /*console.log(this.dataRecollecion.resultDatosQuery[0].Fecha_hora_nac.replace(/T/, " ").replace(/:00.000Z/, "") .replace(/00:00/, "") )
        console.log(this.dataRecollecion.resultDatosQuery[0].PX_Hora_Naci.replace(/T/, " ").replace(/:00.000Z/, "").replace(/1900-01-01/, "").replace(/.000Z/, ""))
        */
        //let fechaNac = this.dataRecollecion.resultDatosQuery[0].Fecha_hora_nac.replace(/T/, " ").replace(/:00.000Z/, "").replace(/00:00/, "") + '' + this.dataRecollecion.resultDatosQuery[0].PX_Hora_Naci.replace(/T/, " ").replace(/:00.000Z/, "").replace(/1900-01-01/, "").replace(/.000Z/, "")
       // fechaNac = fechaNac.replace("  ", " ")
       console.log(this.dataRecollecion.resultDatosQuery[0].Fecha_hora_nac.replace(/T/, " ").replace(/:00.000Z/, "").replace(/.000Z/, ""))
        if(this.dataRecollecion.resultDatosQuery.length != 0){
            this.formFiltros.patchValue({
                nombre:this.dataRecollecion.resultDatosQuery[0].Nombre_bebe,
                Apellidos: this.dataRecollecion.resultDatosQuery[0].Apellido_bebe,
                inpSexo: this.dataRecollecion.resultDatosQuery[0].Sexo,
                semGestacion: this.dataRecollecion.resultDatosQuery[0].Semanas_g,
                peso: this.dataRecollecion.resultDatosQuery[0].Peso,
                registroInst: this.dataRecollecion.resultDatosQuery[0].Registro_institucion,
                muestraProf: this.dataRecollecion.resultDatosQuery[0].Tomada_por,
                fechaNac: this.dataRecollecion.resultDatosQuery[0].Fecha_hora_nac.replace(/T/, " ").replace(/:00.000Z/, "").replace(/.000Z/, ""),
                fechaNacHorario: this.dataRecollecion.dataFilter.nampm,
                fechaMuestra:this.dataRecollecion.resultDatosQuery[0].Fecha_hora_toma.replace(/T/, " ").replace(/:00.000Z/, "").replace(/.000Z/, ""),
                fechaMuestraHorario: this.dataRecollecion.dataFilter.tampm,
                //muestra: this.dataRecollecion.resultDatosQuery[0].Muestra_tomada,
                muestra: this.dataRecollecion.resultDatosQuery[0].Inicial,
                antes24horas: this.dataRecollecion.resultDatosQuery[0].Menos_24,
                raza: String(this.dataRecollecion.resultDatosQuery[0].Raza_id),
                chekSaludable: this.dataRecollecion.resultDatosQuery[0].Saludable,
                chekEnfermo: this.dataRecollecion.resultDatosQuery[0].Enfermo,
                chekCuidadoInt: this.dataRecollecion.resultDatosQuery[0].Cuidados,
                chekVolPeque: this.dataRecollecion.resultDatosQuery[0].Volumen,
                chekIntercambio: this.dataRecollecion.resultDatosQuery[0].Intercambio,
                Leche: this.dataRecollecion.resultDatosQuery[0].Leche_Materna,
                carnitina: this.dataRecollecion.resultDatosQuery[0].Carnitina,
                Lactosa: this.dataRecollecion.resultDatosQuery[0].Lactosa,
                nutriParental: this.dataRecollecion.resultDatosQuery[0].Nutricion_Parenteral,
                lipidos: this.dataRecollecion.resultDatosQuery[0].Lipidos,
                comentarios: this.dataRecollecion.resultDatosQuery[0].Observaciones,
                nmama: this.dataRecollecion.resultDatosQuery[0].Nombre_mama,
                apmama: this.dataRecollecion.resultDatosQuery[0].Apellido_mama,
                mamadir: this.dataRecollecion.resultDatosQuery[0].Direccion_mama,
                paismama: this.dataRecollecion.dataFilter.dataPaisMama[0].Pais_id,
                estadomama: this.dataRecollecion.dataFilter.dataPaisMama[0].Estado_id,
                ciudadmama: this.dataRecollecion.dataFilter.dataPaisMama[0].Ciudad_id,
                telefonomama: this.dataRecollecion.resultDatosQuery[0].Telefono_mama,
                mamacorreo: this.dataRecollecion.resultDatosQuery[0].Correo_mama,
                nmedico: this.dataRecollecion.resultDatosQuery[0].Nombre_medico,
                apmedico: this.dataRecollecion.resultDatosQuery[0].Apellido_medico,
                medicodir: this.dataRecollecion.resultDatosQuery[0].Direccion_medico,
                paismedicoid: this.dataRecollecion.dataFilter.dataPaisMedico.Pais_id,
                estadomedicoid: this.dataRecollecion.dataFilter.dataPaisMedico.Estado_id,
                ciudadmedicoid: this.dataRecollecion.dataFilter.dataPaisMedico.Ciudad_id,
                medicotel: this.dataRecollecion.resultDatosQuery[0].Telefono_medico,
                medicocorreo: this.dataRecollecion.resultDatosQuery[0].correo_medico,
                fechaTrans: this.dataRecollecion.resultDatosQuery[0].Fecha_hora_trans,
                novaconchita: this.dataRecollecion.resultDatosQuery[0].Px_Nova_Nac_Conchita,
                enviadapor: this.dataRecollecion.resultDatosQuery[0].Enviada_por,
                EnviaCorreo: '0',
                guardarsolo: '0',
                TipoCorDes: '0',
                arrayDatosCompara: this.dataRecollecion.dataFilter.arrayDatosCompara,
                sectorpublico: this.dataRecollecion.resultDatosQuery[0].Px_SectorPublico_Tam_Muguerza,
                show_error: this.dataRecollecion.show_error,
            });     
            this.showfechaTrans = this.dataRecollecion.resultDatosQuery[0].Transfundido
        }
    } 
    
    cargarPaises(array){
        this.DataPaises =  array;
        this.DataPaisesM =  array;
        this.DataPaisesMe =  array;  
    }

    cargarEstados(array){
        this.DataEstados =  array;
        this.DataEstadosM =  array;
        this.DataEstadosMe =  array;                   
    }

    cargarCiudades(array){
        this.DataCiudades = array; 
        this.DataCiudadesM =  array; 
        this.DataCiudadesMe =  array; 
    }

    async generarFiltro(form:any){
        //console.log("datos de formulario:",form)
        if(!this.isCliente){
            const emailReg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
            if(this.pfCorreo == 1 && !emailReg.test(form.value.mamacorreo)) {
                this.appCommon.Notify("Correo de la Madre no válido", 'warning');
                this.ref.detectChanges();
                return false;
            }
            if(this.pfCorreo == 1 && !emailReg.test(form.value.medicocorreo)) {
                this.appCommon.Notify("Correo del Médico no válido", 'warning');
                this.ref.detectChanges();
                return false;
            }
            let validadoSexo = 0
            if(form.value.inpSexo == ''){
                this.appCommon.openConfirmDialog({
                    type: 'confirm',
                    title: '',
                    message: 'No se ha seleccionado el sexo, está seguro de validar?',
                }).then(response => {
                    if (response.Confirmed) {
                        validadoSexo = 1
                    }
                })
                if(!validadoSexo){
                    return false;
                }
            }
    
        }
        if(form.value.nombre == ''){
            this.appCommon.Notify("Favor de introducir el nombre del bebé", 'warning');
            this.ref.detectChanges();
            return false;
        }


        if(form.value.nombre == ''){
            this.appCommon.Notify("Favor de introducir el nombre del bebé", 'warning');
            this.ref.detectChanges();
            return false;
        }
        if(form.value.Apellidos == ''){
            this.appCommon.Notify("Favor de introducir los apellidos del bebé", 'warning');
            this.ref.detectChanges();
            return false;
        } 
        if(form.value.semGestacion == ''){
            this.appCommon.Notify("Escriba las semanas de gestaciòn", 'warning');
            this.ref.detectChanges();
            return false;
        }
        if(form.value.peso == ''){
            this.appCommon.Notify("Escriba el peso del bebé", 'warning');
            this.ref.detectChanges();
            return false;
        }
        if(form.value.fechaNac == ''){
            this.appCommon.Notify("Ingrese fecha de nacimiento del bebé", 'warning');
            this.ref.detectChanges();
            return false;
        }          
        if(form.value.fechaNacHorario == ''){
            this.appCommon.Notify("Seleccione si el horario nacimiento es AM o PM", 'warning');
            this.ref.detectChanges();
            return false;
        }
        if(form.value.fechaMuestra == ''){
            this.appCommon.Notify("Ingrese la fecha en que fue tomada la muestra.", 'warning');
            this.ref.detectChanges();
            return false;
        }          
        //SECCION MARA MANIPULACION DE FECHAS
        const data= this.formFiltros.controls.fechaMuestra.value
        //console.log("data de fecha:",data.fechaMuestra)
        const formatFecha: any = { year: 'numeric', month: '2-digit', day: '2-digit'}
        let DateNow = data
        if(data !== null)
        {
            const today = new Date(data).toLocaleDateString('fr-CA', formatFecha);
            const todayTime = new Date(data).toLocaleTimeString('en-GB');
            DateNow = `'${today} ${todayTime}'`
        }
        //VALIDACIONES ENTRE RANGO DE FECHAS

        let hoy = new Date()
        let fechaT = new Date(this.formFiltros.controls.fechaMuestra.value)
        let fechaN = new Date(this.formFiltros.controls.fechaNac.value)
        let diferencia = fechaT.getTime() - fechaN.getTime() ;
        let diferencia2 = hoy.getTime() - fechaN.getTime() ;
        let horaNac = fechaN.toLocaleTimeString();
        let horaMuestra = fechaT.toLocaleTimeString();
        
        if(hoy < fechaT){
            this.appCommon.Notify("La fecha de la toma no puede ser mayor al día de hoy u hora actual", 'warning');
            this.ref.detectChanges();
            return false;
        }
        if(fechaT < fechaN){
            this.appCommon.Notify("Fecha de muestra debe ser posterior a la fecha de nacimiento.", 'warning');
            this.ref.detectChanges();
            return false;
        }
        if((diferencia < 86400000 && this.formFiltros.controls.antes24horas.value) ){
            this.appCommon.Notify("El bebé tiene menos de 24 horas favor de seleccionarlo.", 'warning');
            this.ref.detectChanges();
            return false;
        }  
        if(diferencia2 > 777600000 && diferencia2<=2505600000 ){
            this.appCommon.Notify("La muestra tiene más de 9 días de tomada.", 'warning');
            this.ref.detectChanges();
            return false;
        } 
        if(diferencia2>2505600000 && !this.modifPF ){
            this.appCommon.Notify("La muestra tiene más de 29 dias de tomada.", 'warning');
            this.ref.detectChanges();
            return false;
        }  
        if(form.value.fechaNacHorario == ''){
            this.appCommon.Notify("Seleccione si a fecha en que fue tomada la muestra es AM o PM", 'warning');
            this.ref.detectChanges();
            return false;
        }
        if(form.value.raza == ''){
            this.appCommon.Notify("Favor de seleccionar raza", 'warning');
            this.ref.detectChanges();
            return false;
        }
        if(form.value.nmama == ''){
            this.appCommon.Notify("Ingrese el nomre de la mama", 'warning');
            this.ref.detectChanges();
            return false;
        }
        if(form.value.apmama == ''){
            this.appCommon.Notify("Ingrese apellidos de la mama", 'warning');
            this.ref.detectChanges();
            return false;
        }
        if(this.isCliente){
            if(form.value.mamadir == ''){
                this.appCommon.Notify("Ingrese domicilio de la mama", 'warning');
                this.ref.detectChanges();
                return false;
            }
            if(form.value.telefonomama == ''){
                this.appCommon.Notify("Ingrese el telefono de la mama", 'warning');
                this.ref.detectChanges();
                return false;
            }
            if(form.value.paismama == ''){
                this.appCommon.Notify("El pais de la mamá no ha sido seleccionado", 'warning');
                this.ref.detectChanges();
                return false;
            }
            if(form.value.estadomama == ''){
                this.appCommon.Notify("El estado de la mamá no ha sido seleccionado", 'warning');
                this.ref.detectChanges();
                return false;
            }
            if(form.value.ciudadmama == ''){
                this.appCommon.Notify("La ciudad de la mamá no ha sido seleccionada", 'warning');
                this.ref.detectChanges();
                return false;
            }
            if(form.value.nmedico == ''){
                this.appCommon.Notify("Ingrese el nombre del medico", 'warning');
                this.ref.detectChanges();
                return false;
            }
            if(form.value.apmedico == ''){
                this.appCommon.Notify("Ingrese los apellidos del medico", 'warning');
                this.ref.detectChanges();
                return false;
            }
            if(form.value.medicodir == ''){
                this.appCommon.Notify("Ingrese la direccion del medico", 'warning');
                this.ref.detectChanges();
                return false;
            }
            if(form.value.medicotel == ''){
                this.appCommon.Notify("Ingrese telefono del medico", 'warning');
                this.ref.detectChanges();
                return false;
            }
            if(form.value.paismedicoid == ''){
                this.appCommon.Notify("El pais del medico no ha sido seleccionado", 'warning');
                this.ref.detectChanges();
                return false;
            }
            if(form.value.estadomedicoid == ''){
                this.appCommon.Notify("El estado del medico no ha sido seleccionado", 'warning');
                this.ref.detectChanges();
                return false;
            }
            if(form.value.ciudadmedicoid == ''){
                this.appCommon.Notify("La ciudad del medico no ha sido seleccionada", 'warning');
                this.ref.detectChanges();
                return false;
            }
        }
        if(this.Institucion_id == 127 && form.value.novaconchita == ''){
            this.appCommon.Notify("Seleccione si es paciente nova", 'warning');
            this.ref.detectChanges();
            return false;
        }

        const TipoCorDes = this.formFiltros.controls.TipoCorDes.value
        const CorDes = this.formFiltros.controls.inpCorDesId.value
        if(!this.isCliente){
            if(TipoCorDes != 0 && this.validadoDescCort != 1)
            {
                let txtMensaje = "";
                if(TipoCorDes == 1)
                {
                    txtMensaje = "Favor de ingresar un codigo de Descuento.";
                }
                else
                {
                    txtMensaje = "Favor de ingresar un codigo de Cortesia.";
                }
                if (CorDes == ""){
                    this.appCommon.Notify(txtMensaje, 'warning');
                    return false;
                }
                await this.ValidarCortesiaDescuento();
                return
            }

        }    

        const requestBody = {
            "form":form.value,
            "filtroid": this.Filtro_Id,
            "pacienteid": this.Paciente_id,
            "bitrepe": 0,
            "filtro": '',
            "filtroini": '',
            "filtrorep": '',
            "imagenaux": '',
            "imagenposteaux": '',
            "puedeguardarfoto": 0,
            "validacorreo": 0,
            "horaNac": horaNac,
            "horaMuestra": horaMuestra,
            "guardarsolo": 0,
            "vusuarioid": this.userService.vUsuarioID,
            "sessionCliente": this.isCliente
        }
         
        if(!this.modifPF){
            //console.log("request Body:",requestBody)
            this.appCommon.spinner(true);
            this.reportsService
            .GuardarMuestra(requestBody)
            .then(
                (Default:any) => {
                    this.appCommon.spinner(false);
                    this.appCommon.Notify("La muestra se guardó con éxito.", 'success'); 
                    this.ref.detectChanges();
                    this.propagar.emit(true);
                },
                error => {
                    console.log('fail callback', error);
                    this.appCommon.spinner(false);
                    this.appCommon.Notify(error.data.message, 'danger');
                    this.ref.detectChanges();
                }
            )
            .catch(e => {
                console.log('catch', e);
                this.appCommon.spinner(false);
                this.appCommon.Notify(e, 'danger');
                this.ref.detectChanges();
            });
        }else{
            this.appCommon.spinner(true);
            this.tamikServiceTools
            .saveModifyPf(requestBody)
            .then(
                (Default:any) => {
                    this.appCommon.spinner(false);
                    this.appCommon.Notify(Default.Mensaje, 'success'); 
                    this.ref.detectChanges();
                    this.propagar.emit(true);
                },
                error => {
                    console.log('fail callback', error);
                    this.appCommon.spinner(false);
                    this.appCommon.Notify(error.data.message, 'danger');
                    this.ref.detectChanges();
                }
            )
            .catch(e => {
                console.log('catch', e);
                this.appCommon.spinner(false);
                this.appCommon.Notify(e, 'danger');
                this.ref.detectChanges();
            });

        }
        
    }

    async RegresarFiltro(){
        const requestBody = {
            "filtroid": this.Filtro_Id
        }
        
        this.appCommon.spinner(true);
        this.reportsService
        .RegresarFiltro(requestBody)
            .then(
                (Default:any) => {
                    this.ref.detectChanges();
                    this.propagar.emit(true);
                    this.appCommon.spinner(false);
                },
                error => {
                    console.log('fail callback', error);
                    this.appCommon.spinner(false);
                    this.appCommon.Notify(error.data.message, 'danger');
                    this.ref.detectChanges();
                }
            )
            .catch(e => {
                console.log('catch', e);
                this.appCommon.spinner(false);
                this.appCommon.Notify(e, 'danger');
                this.ref.detectChanges();
            });
    }

    async getListPaises() {
        this.appCommon.spinner(true);
        this.ref.detectChanges();
        this.appCommon
            .getListPaises()
            .then(
                (Default: any) => {
                    this.DataPaises =  Default.dataresult;
                    this.DataPaisesM =  Default.dataresult;
                    this.DataPaisesMe =  Default.dataresult;                  
                    this.appCommon.spinner(false);
                    this.ref.detectChanges();
                },
                error => {
                    //console.log('fail callback', error);
                    this.appCommon.spinner(false);
                    this.appCommon.Notify(error.data.message, 'error');
                }
            )
            .catch(e => {
                //console.log('catch', e);
                this.appCommon.spinner(false);
            });
    }
    async ListEstados() {
        this.appCommon.spinner(true);
        this.ref.detectChanges();
        this.appCommon
            .ListEstados()
            .then(
                (Default: any) => {
                    this.DataEstados =  Default.dataresult;
                    this.DataEstadosM =  Default.dataresult;
                    this.DataEstadosMe =  Default.dataresult;                   
                    this.appCommon.spinner(false);
                    this.ref.detectChanges();
                },
                error => {
                    //console.log('fail callback', error);
                    this.appCommon.spinner(false);
                    this.appCommon.Notify(error.data.message, 'error');
                }
            )
            .catch(e => {
                //console.log('catch', e);
                this.appCommon.spinner(false);
            });
    }
    async ListCiudades() {
        this.appCommon.spinner(true);
        this.ref.detectChanges();
        this.appCommon
            .ListCiudades()
            .then(
                (Default: any) => {
                    this.DataCiudades = Default.dataresult;
                    this.DataCiudadesM =  Default.dataresult;
                    this.DataCiudadesMe =  Default.dataresult;
                    this.appCommon.spinner(false);
                    this.ref.detectChanges();
                },
                error => {
                    //console.log('fail callback', error);
                    this.appCommon.spinner(false);
                    this.appCommon.Notify(error.data.message, 'error');
                }
            )
            .catch(e => {
                //console.log('catch', e);
                this.appCommon.spinner(false);
            });
    }
    onCheckChangeBusqueda($event) {       
        this.idPais = $event
        this.DataEstadosM =  this.DataEstados.filter(estado => estado.Pais_id == this.idPais)
        this.formFiltros.controls.estadomama.setValue('');
    }
    onCheckChangeBusqueda2($event) {       
        this.idEstado = $event
        this.DataCiudadesM =  this.DataCiudades.filter(ciudad => ciudad.Estado_id == this.idEstado)
        this.formFiltros.controls.ciudadmama.setValue('');
    }
    onCheckChangeBusqueda3($event) {       
        this.idPaisMe = $event
        this.DataEstadosMe =  this.DataEstados.filter(estado => estado.Pais_id == this.idPaisMe)
        this.formFiltros.controls.estadomedicoid.setValue('');
    }
    onCheckChangeBusqueda4($event) {       
        this.idEstadoMe = $event
        this.DataCiudadesMe =  this.DataCiudades.filter(ciudad => ciudad.Estado_id == this.idEstadoMe)
        this.formFiltros.controls.ciudadmedicoid.setValue('');
    }

    onCheckChangeOption($event) {   
        this.option_idChecked = $event.target.checked    
        if(this.option_idChecked == true || this.option_idChecked2 == true){
            this.showfechaTrans = true
        }else if(this.option_idChecked == false && this.option_idChecked2 == false){
            this.showfechaTrans = false
        }
    }
    onCheckChangeOption1($event) {   
        this.option_idChecked2 = $event.target.checked    
        if(this.option_idChecked == true || this.option_idChecked2 == true){
            this.showfechaTrans = true
        }else if(this.option_idChecked == false && this.option_idChecked2 == false){
            this.showfechaTrans = false
        }
    }

    disabledDate = (current: Date): boolean =>
    // Can not select days before today and today
        differenceInCalendarDays(current, this.today) > 0;

    disabledDateTime: DisabledTimeFn = () => ({
        nzDisabledHours: () => this.range(0, 24).splice(4, 20),
        nzDisabledMinutes: () => this.range(30, 60),
        nzDisabledSeconds: () => [55, 56]
    });

    disabledRangeTime: DisabledTimeFn = (_value, type?: DisabledTimePartial) => {
        if (type === 'start') {
        return {
            nzDisabledHours: () => this.range(0, 60).splice(4, 20),
            nzDisabledMinutes: () => this.range(30, 60),
            nzDisabledSeconds: () => [55, 56]
        };
        }
        return {
        nzDisabledHours: () => this.range(0, 60).splice(20, 4),
        nzDisabledMinutes: () => this.range(0, 31),
        nzDisabledSeconds: () => [55, 56]
        };
    };    
    
    getIsCliente(){
        return new Promise((resolve, reject) => {
            this.userService.isCliente$.subscribe(data=>{
                //console.info("Is Cliente: ",data);
                if(data==null){
                    reject(0);
                }else{
                    this.isCliente = data
                    if(!this.isCliente){
                        this.claseLabel = 'redlabel'
                    }            
                }
            });
        });
    }

    async GetFile(name,type) {
        const requestBody = {
            name,
            type: 'imagen-pf',
        };
        this.appCommon.spinner(true);
        this.tamikService
            .GetFile(requestBody)
            .then(
                (Default: any) => {
                    if(type ==1)
                    {
                        this.DetalleImagenFront = Default.file
                    }
                    else
                    {
                        this.DetalleImagenFront = Default.file
                    }
                    this.appCommon.spinner(false);
                },
                error => {
                    console.log('fail callback', error);
                    this.appCommon.Notify(error.data.message, 'error');
                }
            )
            .catch(e => {
                console.log('catch', e);
                this.appCommon.spinner(false);
            });
    }

    async MostrarImgPF(){
        this.appCommon.UrlViewer(this.URLImagen,'urlimg')
    }

    async ActualizaDatosDoctor(id){
        
        let doctor = this.catalogoDoctores.filter(x => x.Doctor_Id == id);
        if(doctor.length > 0){
            this.formFiltros.patchValue({
                nmedico: doctor[0].Nombre,
                apmedico: doctor[0].Apellido,
                medicodir: doctor[0].Direccion,
                paismedicoid: doctor[0].pais_id,
                estadomedicoid: doctor[0].estado_id,
                ciudadmedicoid: doctor[0].Ciudad_Id,
                medicotel: doctor[0].Telefono,
                medicocorreo: doctor[0].correo,
            });     
        }

    }

    async validaguarda(){
        this.formFiltros.controls.guardarsolo.setValue(1)   

        let forma= this.formFiltros.controls
        if(forma.semGestacion.value == '' && isNaN(forma.semGestacion.value)){
            this.appCommon.Notify("El campo semanas de gestación debe de ser numérico", 'warning');
            this.ref.detectChanges();
            return false;
        }
        if(forma.fechaMuestra.value != ''){
            if(forma.fechaNacHorario.value == ''){
                this.appCommon.Notify("Seleccione si es AM o PM la fecha de nacimiento del bebé", 'warning');
                this.ref.detectChanges();
                return false;
            }
        }

        this.testFormElement.ngSubmit.emit();  
    }

    async sinDatos(){
        const requestBody = {
            Filtro_Id: this.Filtro_Id
        }
        this.appCommon.openConfirmDialog({
            type: 'confirm',
            title: '',
            message: 'Solo se darán permisos para poder dar de baja, no se guardara ningun dato',
        }).then(response => {
            if (response.Confirmed) {
                this.appCommon.spinner(true);
                this.reportsService
                    .PfSinDatos(requestBody)
                        .then(
                            (Default:any) => {
                                this.appCommon.spinner(false);
                                this.appCommon.Notify("La muestra se marcó Sin Datos con éxito.", 'success'); 
                                this.ref.detectChanges();
                                this.propagar.emit(true);
                            },
                            error => {
                                console.log('fail callback', error);
                                this.appCommon.spinner(false);
                                this.appCommon.Notify(error.data.message, 'danger');
                                this.ref.detectChanges();
                            }
                        )
                        .catch(e => {
                            console.log('catch', e);
                            this.appCommon.spinner(false);
                            this.appCommon.Notify(e, 'danger');
                            this.ref.detectChanges();
                        });
            }
        })
    }


    async ValidarCortesiaDescuento(){
        const requestBody = {
            Filtro_Id: this.Filtro_Id,
            inpCorDesId: this.formFiltros.controls.inpCorDesId.value,
            TipoCorDes: this.formFiltros.controls.TipoCorDes.value
        }
        this.appCommon.spinner(true);
        this.reportsService
            .ValidarCortesiaDescuento(requestBody)
                .then(
                    (Default:any) => {
                        this.validadoDescCort =1 

                        if(Default == 0){
                            var TipoCorDesTxt = "";
                            if(requestBody.TipoCorDes == 1)
                            {
                                TipoCorDesTxt = "El Descuento no se encuentra activo o ya alcanzo su limite de uso.";
                            }
                            else
                            {
                                TipoCorDesTxt = "La Cortesia no se encuentra activa o ya fue usada.";
                            }
                            this.appCommon.Notify(TipoCorDesTxt, 'warning'); 
                            this.appCommon.spinner(false);
                            this.validadoDescCort =0
                        }else{
                            this.appCommon.spinner(false);

                            //si se valida continua
                            this.testFormElement.ngSubmit.emit();  
                        }
                    },
                    error => {
                        console.log('fail callback', error);
                        this.appCommon.spinner(false);
                        this.appCommon.Notify(error.data.message, 'danger');
                        this.ref.detectChanges();
                    }
                )
                .catch(e => {
                    console.log('catch', e);
                    this.appCommon.spinner(false);
                    this.appCommon.Notify(e, 'danger');
                    this.ref.detectChanges();
                });
                
    }


    async getNivelID(){
        return new Promise((resolve, reject) => {
            this.userService.vNivelID$.subscribe(data=>{
                console.info("Nivel ID: ",data);
                if(data==null){
                    reject(0);
                }else{
                    resolve(data);
                   this.ActiveNivelID = data
                }
            });
        });
    }

    //? Algun elemento invalido el formulario
    isValidFieldModifi( field: string ): boolean | null{
        return this.formModifi.controls[field].errors 
        && this.formModifi.controls[field].touched;
    }

    procesar(esNuevo:boolean) {
        
        if(this.formModifi.invalid){
            this.appCommon.Notify("Formulario invalido. Favor de seleccionar una razon y un comentario", 'warning');
            this.formModifi.markAsUntouched();
            return;
        }
        
        const formValue = { ...this.formModifi.value }
        const formFil  = { ...this.formFiltros}
        formFil.value.RazonCambio = formValue.Razon
        formFil.value.ComentariosMod = formValue.Comentarios
        formFil.value.filtroinicial = this.filtroinicial
        formFil.value.cambioDespuesDeRes = this.cambioDespuesDeRes
        if(esNuevo){
            formFil.value.cambioDespuesDeRes = false;
            formFil.value.pacientenuevo = true;
            this.generarFiltro(formFil);
        }else{
            formFil.value.cambioDespuesDeRes = true;
            formFil.value.pacientenuevo = false;
            console.log(formFil);
            this.generarFiltro(formFil);
        }
        console.log(formValue);

    }
    
    showMessage(titulo:string, mensaje:string){
        const dialogRefM = this.dialog.open(ConfirmacionMComponent, {
            data: { titulo: titulo, 
                    mensaje: mensaje,
                    Noconfirm: true
            }
        })
        dialogRefM.afterOpened().subscribe(() => {
            setTimeout(() => {
                dialogRefM.close();
            }, 5000);
        });
    }


}