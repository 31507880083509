<div class="container-fluid">
    <form *ngIf="(formbuldierConfig | json) !== '{}' && MostrarFiltrosComponent" [formGroup]="formFiltros" #f="ngForm" (ngSubmit)="generarFiltro()">
        <div id="TipoBusquedaPeriodo" *ngIf="ConfigShow.TipoBusquedaPeriodo && ConfigShow.TipoBusquedaPeriodo.show === true">
            <div class="row mt-3">
                <div class="col-2">
                    <label for="soluciones"><fa-icon class="mr-1" [icon]='["fas", "filter"]'></fa-icon> &nbsp;<strong>{{Language('periodo')}}:</strong></label>
                </div>
                <div class="col-9">
                    <div class="ng-star-inserted" [hidden]="ConfigShow.TipoBusquedaPeriodoRadios">
                        <ng-container *ngFor="let periodo of searchPeriodo">
                            <input
                                class="icheckbox_square-blue"
                                type="radio"
                                name="TipoBusquedaPeriodo"
                                formControlName="TipoBusquedaPeriodo"
                                value="{{periodo.value}}"
                            />
                            &nbsp;&nbsp;{{Language('TiposPeriodos')[periodo.value]}}&nbsp;&nbsp;
                        </ng-container>
                    </div>
                    <div class="col-md-7 mt-2" *ngIf="formFiltros!.controls['TipoBusquedaPeriodo'].value !== '0'">
                        <div class="col-12 form-group" *ngIf="formFiltros!.controls['TipoBusquedaPeriodo'].value === '1' && !ConfigShow.TipoBusquedaPeriodo.especial">
                            <!---<div>
                                <label class="control-label">Seleccione Fecha </label>
                            </div><br>--->
                            <div nz-row [nzGutter]="16">
                                <div nz-col class="gutter-row" [nzSpan]="12">
                                    <nz-form-item>
                                        <nz-form-control>
                                            <nz-select nzSize="large" nzShowSearch nzPlaceHolder= "{{Language('Mes')}}" [nzMaxTagCount]="5" [nzAllowClear]="true" formControlName="mes">
                                                <nz-option *ngFor="let FechaMes of dataMeses" [nzLabel]="FechaMes.mes" [nzValue]="FechaMes.id"> </nz-option>
                                            </nz-select>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                                <div nz-col class="gutter-row" [nzSpan]="12">
                                    <nz-form-item>
                                        <nz-form-control>
                                            <nz-select nzSize="large" nzShowSearch nzPlaceHolder="{{Language('Ano')}}" [nzMaxTagCount]="5" [nzAllowClear]="true" formControlName="anio">
                                                <nz-option *ngFor="let anio of years" [nzLabel]="anio.year" [nzValue]="anio.year"> </nz-option>
                                            </nz-select>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 " *ngIf="formFiltros!.controls['TipoBusquedaPeriodo'].value === '2' && !ConfigShow.TipoBusquedaPeriodo.especial">
                            <!---<div>
                                <label class="control-label"> Intervalo de Fechas </label>
                            </div><br>--->
                            <div class="col-12" nz-row [nzGutter] = "16">
                                <div class="col-6" nz-col class="gutter-row" [nzSpan]="12">
                                    <nz-form-item>
                                        <nz-form-control>
                                            <input nz-input placeholder="Fecha inicial" nzSize="large" type="date" formControlName="fechaIni" />
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                                <div class="col-6" nz-col class="gutter-row" [nzSpan]="12">
                                    <nz-form-item>
                                        <nz-form-control>
                                            <input nz-input placeholder="Fecha final" nzSize="large" type="date" formControlName="fechaFin" />
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 form-group" *ngIf="formFiltros!.controls['TipoBusquedaPeriodo'].value === '1' && ConfigShow.TipoBusquedaPeriodo.especial">
                            <!---<div>
                                <label class="control-label">Seleccione Fecha </label>
                            </div><br>--->
                            <div nz-row [nzGutter]="16">
                                <div nz-col class="gutter-row" [nzSpan]="8">
                                    <nz-form-item>
                                        <nz-form-control>
                                            <nz-select nzSize="large" nzShowSearch nzPlaceHolder="{{Language('MesTo')}}" [nzMaxTagCount]="5" [nzAllowClear]="true" formControlName="mes">
                                                <nz-option *ngFor="let FechaMes of dataMeses" [nzLabel]="FechaMes.mes" [nzValue]="FechaMes.id"> </nz-option>
                                            </nz-select>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                                <div nz-col class="gutter-row" [nzSpan]="8">
                                    <nz-form-item>
                                        <nz-form-control>
                                            <nz-select nzSize="large" nzShowSearch nzPlaceHolder= "{{Language('MesFrom')}}"  [nzMaxTagCount]="5" [nzAllowClear]="true" formControlName="fechaMesFin">
                                                <nz-option *ngFor="let FechaMesFin of dataMeses" [nzLabel]="FechaMesFin.mes" [nzValue]="FechaMesFin.id"> </nz-option>
                                            </nz-select>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                                <div nz-col class="gutter-row" [nzSpan]="8">
                                    <nz-form-item>
                                        <nz-form-control>
                                            <nz-select nzSize="large" nzShowSearch nzPlaceHolder="{{Language('AnoEspecial')}}" [nzMaxTagCount]="5" [nzAllowClear]="true" formControlName="anio">
                                                <nz-option *ngFor="let anio of years" [nzLabel]="anio.year" [nzValue]="anio.year"> </nz-option>
                                            </nz-select>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="divEmpresa" *ngIf="ConfigShow.cbxEmpresa && ConfigShow.cbxEmpresa.show === true">
            <div class="row mt-3">
                <div class="col-2">
                    <label for="soluciones"><fa-icon class="mr-1" [icon]='["fas", "filter"]'></fa-icon> &nbsp;<strong>{{Language('Company')}}</strong></label>
                </div>
                <div class="col-9">
                    <div class="row">
                        <div class="col-md-7">
                            <div >
                                <div class="">
                                    <nz-form-item>
                                        <nz-form-control>
                                                <nz-select nzSize="large" nzShowSearch nzPlaceHolder="{{Language('selectCompany')}}" [nzMaxTagCount]="5" [nzAllowClear]="true" formControlName="cbxEmpresa">
                                                <nz-option nzLabel="{{Language('cbxTodas')}}"  [nzValue]= '0'></nz-option>
                                                <nz-option *ngFor="let dataEmpresa of dataEmpresas" [nzLabel]="dataEmpresa.Empresa" [nzValue]="dataEmpresa.Empresa_Id"> </nz-option>
                                            </nz-select>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="divCuentas" *ngIf="ConfigShow.cbxCuentas && ConfigShow.cbxCuentas.show === true">
            <div class="row mt-3">
                <div class="col-2">
                    <label for="soluciones"><fa-icon class="mr-1" [icon]='["fas", "filter"]'></fa-icon> &nbsp;<strong>{{Language('Acount')}}</strong></label>
                </div>
                <div class="col-9">
                    <div class="row">
                        <div class="col-md-7">
                            <div >
                                <div class="">
                                    <nz-form-item>
                                        <nz-form-control>
                                                <nz-select nzSize="large" nzShowSearch nzPlaceHolder="{{Language('selectAcount')}}" [nzMaxTagCount]="5" [nzAllowClear]="true" formControlName="cbxCuentas">
                                                <nz-option nzLabel="{{Language('selectAcount')}}"  [nzValue]= '0'></nz-option>
                                                <nz-option *ngFor="let dataCuenta of dataCuentas" [nzLabel]="dataCuenta.Cuenta" [nzValue]="dataCuenta.Cuenta_GK_id"> </nz-option>
                                            </nz-select>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 mt-3 text-center">
                <button (click)="OcultarFiltrosForm()" class="btn btn-warning  btn-lg m-1" >{{Language('btnCancelar')}}</button>
                <button type="submit" class="btn btn-primary  btn-lg m-1">{{Language('btnGenerar')}}</button>
            </div>
        </div>
    </form>
    <div class="row text-center">
        <div class="col-12">

            <a href={{urlExportar}} *ngIf="!MostrarFiltrosComponent" class="btn btn-{{btnExportatConfig.class}} btn-lg m-1" id="btnExportar">{{btnExportatConfig.txt}}</a>
            <button *ngIf="MostrarBotonValidar === 1 && ctrlbtnValidar" class="btn btn-primary btn-lg m-1" id="btnValidar" (click)="ModalValidar()">Validar Datos</button>
            <button *ngIf="(MostrarBotonValidar === 1 && ctrlbtnValidar) || showBtnRegresar === true" class="btn btn-primary btn-lg m-1" id="btnRegresarCierreMes" (click)="Regresar()">Regresar</button>
        </div>
    </div>
</div>


