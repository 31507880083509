<div class="tab-content">
    
    <button class="btn btn-success" type="button" (click)="enviarCorreo()">Realizar preliminar</button>

    @if(resultDatos.length > 0){
        <div class="row">
            <div class="col-2"></div>
            <div class="col-1">
                <fa-icon class="text-success btnTam" [icon]='["fas", "envelope"]'></fa-icon>
            </div>
            <div class="col">
                <label>La institucion ha sido informada</label>
            </div>
        </div>
    
    
    
        <div class="row">
            <div class="col-2"></div>
            <div class="col-1">
                <fa-icon class="text-warning btnTam" [icon]='["fas", "envelope"]'></fa-icon>
            </div>
            <div class="col">
                <label>El mensaje esta pendiente de enviar por el área médica</label>
            </div>
        </div>
    
        <div class="row">
            <div class="col-1"></div>
            <div class="col">
                <table class="table table-striped table-bordered row-border hover nowrap">
                    <thead>
                        <tr class="ttdblue">
                            <th class="text-center" width="5%">ID</th>
                            <th class="text-end"    width="10%">FECHA ALTA</th>
                            <th class="text-start"  width="15%">PRUEBA(S)</th>
                            <th class="text-start"  width="60%">MENSAJE</th>
                            <th class="text-center"  width="10%">ESTATUS</th>
                        </tr>
                    </thead>
                    <tbody>
                        @for (resultados of resultDatos; track $index) {
                            <tr>
                                <td class="text-center">{{ resultados.Preliminar_PP_id }}</td>
                                <td class="text-end">{{ resultados.Fecha_Oper | date: 'dd-MMM-yyyy': "UTC"  }}</td>
                                <td class="text-start">{{ resultados.vprdesc }}</td>
                                <td class="text-start" [innerHTML]="resultados.txtmsj_Oper"></td>
                                <td class="text-center">
                                    <fa-icon class="btnTam" [icon]='["fas", "envelope"]'
                                    [ngClass]="{
                                        'text-success': resultados.color === 'green',
                                        'text-warning': resultados.color === 'orange'
                                    }"
                                    ></fa-icon>
                                </td>
                            </tr>
                        }@empty {
                            <tr>
                                <td class="text-center" colspan="6">
                                    <h3>Por el momento no existen datos!!!</h3>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
            <div class="col-1"></div>
        </div>
    }



</div>