<label class="btn-primary" style="display:none;" ngbButtonLabel>
    <input #fCLick type="checkbox" ngbButton > ENE
</label>
<div class="row">
    <div class="col-md-12" align="center">
        <div class="row">
            <div class="col-md-12 text-center light-title">
                Papel Filtro {{ DatosGeneralesPF?.codigo }} &nbsp;({{DatosGeneralesPF?.Kit}})&nbsp;- {{ DatosGeneralesPF?.Nombre_Comercial }} &nbsp;&nbsp;
                <span class="fs-14">Caducidad: {{ DatosGeneralesPF?.Fecha_Caducidad | date: 'MMM/yyyy' }}</span>
            </div>
        </div>
    </div>
</div>
<div class="row pf-details">
    <div class="col-sm-4">
        <div class="line">
            <div class="row">
                <div class="col-sm-6 label-t">
                    <span class="field-caption">ID Paciente</span>
                </div>
                <div class="col-sm-6">
                    <span class="field-value">{{ DatosGeneralesPF?.paciente_Id }}</span>
                </div>
            </div>
        </div>
        <div class="line">
            <div class="row">
                <div class="col-sm-6 label-t">
                    <span class="field-caption">Código</span>
                </div>
                <div class="col-sm-6">
                    <span class="field-value">{{ DatosGeneralesPF?.codigo }}</span>
                </div>
            </div>
        </div>
        <div class="line">
            <div class="row">
                <div class="col-sm-6 label-t">
                    <span class="field-caption">Institución</span>
                </div>
                <div class="col-sm-6">
                    <span class="field-value">{{ DatosGeneralesPF?.Nombre_Comercial }}</span>
                </div>
            </div>
        </div>
        <div class="line">
            <div class="row">
                <div class="col-sm-6 label-t">
                    <span class="field-caption">Contrato</span>
                </div>
                <div class="col-sm-6">
                    <span class="field-value">{{ DatosFacturacionPF?.Contrato }}</span>
                </div>
            </div>
        </div>
        <div class="line">
            <div class="row">
                <div class="col-sm-6 label-t">
                    <span class="field-caption">Nombre bebé</span>
                </div>
                <div class="col-sm-6">
                    <span class="field-value">{{DatosGeneralesPF?.Nombre_bebe}} {{DatosGeneralesPF?.Apellido_bebe}}</span>
                </div>
            </div>
        </div>
        <div class="line">
            <div class="row">
                <div class="col-sm-6 label-t">
                    <span class="field-caption">Fecha de muestra</span>
                </div>
                <div class="col-sm-6">
                    <span class="field-value"> {{DatosGeneralesPF?.Fecha_hora_toma | date: 'dd/MMM/yyyy HH:mm' }}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-4">
        <div class="line">
            <div class="row">
                <div class="col-sm-6 label-t">
                    <span class="field-caption">ID Laboratorio</span>
                </div>
                <div class="col-sm-6">
                    <span class="field-value">PTE</span>
                </div>
            </div>
        </div>
        <div class="line">
            <div class="row">
                <div class="col-sm-6 label-t">
                    <span class="field-caption">RemisiónX</span>
                </div>
                <div class="col-sm-6">
                    <span class="field-value">{{DatosFacturacionPF?.nota_id}}</span>
                </div>
            </div>
        </div>
        <div class="line">
            <div class="row">
                <div class="col-sm-6 label-t">
                    <span class="field-caption">FacturaX</span>
                </div>
                <div class="col-sm-6">
                    <span class="field-value">{{ DatosFacturacionPF?.factura }}</span>
                </div>
            </div>
        </div>
        <div class="line">
            <div class="row">
                <div class="col-sm-6 label-t">
                    <span class="field-caption">SurtimientoX</span>
                </div>
                <div class="col-sm-6">
                    <span class="field-value">{{DatosFacturacionPF?.Orden_Surtimiento}}</span>
                </div>
            </div>
        </div>
        <div class="line">
            <div class="row">
                <div class="col-sm-6 label-t">
                    <span class="field-caption">Nombre mamá</span>
                </div>
                <div class="col-sm-6">
                    <span class="field-value">{{DatosGeneralesPF?.Nombre_mama}} {{DatosGeneralesPF?.Apellido_mama}}</span>
                </div>
            </div>
        </div>
        <div class="line">
            <div class="row">
            </div>
        </div>
    </div>
    <div class="col-sm-4 pf-preview">
        <img src="{{'data:image/jpg;base64,' + DatosGeneralesPF?.ImagenPF}}" width="100%" border="0" alt="" />
    </div>
</div>
<div class="row">
    <div class="col-sm-12">
        <div class="line">
            <div class="row">
                <div class="col-sm-6">
                    <span class="field-value"><strong style="color: #ee5037">{{ DatosGeneralesPF?.NecesitaRepeticion }}</strong></span>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-sm-12">
        <div class="line">
            <div class="row">
                <div class="col-sm-6">
                    <span class="field-value"></span>
                </div>
            </div>
        </div>
    </div>
</div>