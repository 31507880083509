import { ChangeDetectionStrategy, Component, ChangeDetectorRef, ElementRef, OnInit, ViewChild, EventEmitter, Input, Injectable, inject } from '@angular/core';
import { UntypedFormBuilder} from '@angular/forms';
import { AppCommonService } from '@common/services';
import { UserService } from '@modules/auth/services';
import { ReportsService } from '@modules/reports/services';
import type { ResultDatosEstudio } from '@modules/medical-records/models';
import { DetalleSolicitudMComponent } from '../Modales/detalle-solicitud-m/detalle-solicitud-m.component';
import { MatDialog } from '@angular/material/dialog';
import { MedicalRecordsService } from '@modules/medical-records/services';
@Injectable({ providedIn: 'root' })

@Component({
    selector: 'gk-expediente-estudios',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './expediente-estudios.component.html',
    styleUrls: ['expediente-estudios.component.scss'],
})

export class ExpedienteEstudiosComponent implements OnInit{
    constructor(
        private userService: UserService,
        public appCommon: AppCommonService,
        private ref: ChangeDetectorRef,
        public formBuilder: UntypedFormBuilder,
        private reportsService: ReportsService,
        private dialog: MatDialog, 
    ) {
    }

    @Input() datosEstudio: ResultDatosEstudio[];
    ActiveInstitucionID: any;
    isCliente: any;
    usuarioId: any;
    nivelId: any;
    datoRecollecion:any = []
    arrayPaises: any;
    arrayEstados: any;
    arrayCiudades: any;

    private medicalRecordServices = inject(MedicalRecordsService);

    async ngOnInit() {
        this.ActiveInstitucionID = await this.getInstitucionID();
        this.isCliente = await this.getIsCliente();
        this.nivelId = await this.getNivelID();
        this.usuarioId = await this.getUsuarioId();

    }
    
    getInstitucionID(){
        return new Promise((resolve, reject) => {
            this.userService.vInstitucionID$.subscribe(data=>{
                console.info("Institucion ID: ",data);
                if(data==null){
                    reject(0);
                }else{
                    resolve(data);
                    //this.ActiveInstitucionID = data
                }
            });
        });
    }

    getIsCliente(){
        return new Promise((resolve, reject) => {
            this.userService.isCliente$.subscribe(data=>{
                //console.info("Is Cliente: ",data);
                if(data==null){
                    reject(0);
                }else{
                    resolve(data);
                    this.isCliente = data
                }
            });
        });
    }

    getUsuarioId(){
        return new Promise((resolve, reject) => {
            this.userService.vUsuarioID$.subscribe(data=>{
                //console.info("Is Cliente: ",data);
                if(data==null){
                    reject(0);
                }else{
                    resolve(data);
                    this.usuarioId = data
                }
            });
        });
    }

    getNivelID(){
        return new Promise((resolve, reject) => {
            this.userService.vNivelID$.subscribe(data=>{
                //console.info("Is Cliente: ",data);
                if(data==null){
                    reject(0);
                }else{
                    resolve(data);
                    this.usuarioId = data
                }
            });
        });
    }


    cargarPF(filtro_id){
        const requestBody = {
            "ch":filtro_id,
            "chrep": 0,
            "bitrep": 0,
            "sessionInstitucion":this.ActiveInstitucionID,
            "sessionCliente":this.isCliente,
            "sessionPais": 1,
            "visor": true
        }
        this.appCommon.spinner(true);
        this.reportsService
        .BusquedaMuestra(requestBody)
            .then(
                    (Default:any) => {
                    this.datoRecollecion = Default
                    this.datoRecollecion.arrayPaises = this.arrayPaises
                    this.datoRecollecion.arrayEstados = this.arrayEstados
                    this.datoRecollecion.arrayCiudades = this.arrayCiudades
                    this.datoRecollecion.bitVisor = true
                    //console.log("datos a mostrar::", this.codigo, this.nameInst, this.numEnf)
                    this.appCommon.spinner(false);
                    //this.showMensaje('Recuerda utilizar formato de 24 hrs al ingresar las horas de nacimiento y toma de muestra.')
                    this.appCommon.ViewModalFilter(this.datoRecollecion,true)
                    this.ref.detectChanges();
                },
                error => {
                    console.log('fail callback', error);
                    this.appCommon.spinner(false);
                    this.appCommon.Notify(error.data.message, 'danger');
                    this.ref.detectChanges();
                }
            )
            .catch(e => {
                console.log('catch', e);
                this.appCommon.spinner(false);
                this.appCommon.Notify(e, 'danger');
                this.ref.detectChanges();
            });
    }


    // addregistro()
    // {
    //     this.arrayDataEstudios.push({
    //         "Filtro_id": 511735,
    //         "R": 511735,
    //         "RH": null,
    //         "RPH": null,
    //         "WNL": 0,
    //         "resultado_bloqueado": false,
    //         "Prueba_especial_id": 1,
    //         "resultado_orig": null,
    //         "fecha_resultado_gk": "2023-01-06T21:56:39.000Z",
    //         "Baja": null,
    //         "ID": null,
    //         "precio_propuesta": null,
    //         "Moneda": null,
    //         "Resultado_PE_Esp": null,
    //         "Factura_estatus_id": 0,
    //         "bit_bloquea_resul_dx": false,
    //         "bit_Cortesia": false,
    //         "bit_confirmatoria": false,
    //         "Pruebas_especiales": null,
    //         "kit": "Támi-k69",
    //         "Nombre_estudio": "Prueba agrega nueva",
    //         "fecha_captura": "2022-12-31T16:07:00.000Z",
    //         "codigo": 30767608
    //     })
    // }

    verDetalleSolicitud(id: string | number){
        this.appCommon.spinner(true);
        this.medicalRecordServices.getSolicitudAsesoriaD(id).subscribe({
            next: (resp) => {
                this.appCommon.spinner(false);
                const dialogData = {
                    DatosEstudios: resp ,
                    Id: id
                }
                const dialogRef = this.dialog.open(DetalleSolicitudMComponent, {
                    width: '75vw', //?    Usa el 65% del ancho de la ventana
                    maxWidth: '85vw', //? Limita el ancho máximo también al 75% de la ventana
                    position: {
                        top: '20px'
                    },
                    data: dialogData,
                    enterAnimationDuration: '250ms',  //? Cambia la duración de la animación de entrada
                    exitAnimationDuration: '250ms',    //? Cambia la duración de la animación de salida
                    autoFocus: false
                })
            },
            error: err => {
                this.appCommon.spinner(false);
                this.appCommon.showMsg(`Operacion fallida`, `A ocurrido un error al querer realizar la operacion`); 
                console.log(err);
            }
        })
    }
    
}
