import { ChangeDetectionStrategy, Component, EventEmitter, Inject, OnInit, Output, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CustomersService } from '@modules/customers/services';
import { ConfirmacionMComponent } from '../confirmacion-m/confirmacion-m.component';
import { AppCommonService } from '@common/services';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'gk-crear-contacto-m',
  templateUrl: './crear-contacto-m.component.html',
  styleUrls: ['./crear-contacto-m.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CrearContactoMComponent implements OnInit {

  //? combos recibidos
  combos: any;
  usuario:any;
  institucionid: any;
  @Output() contactoAdded = new EventEmitter<any>();

  tamik: boolean = false;
  audik: boolean = false;
  dx: boolean = false;
  cardik: boolean = false;
  esVisor:boolean = false;

  private fb = inject(FormBuilder);
  private customersService= inject(CustomersService);
  private dialog = inject(MatDialog);
  private dialogRef= inject(MatDialogRef<CrearContactoMComponent>);
  public appCommon= inject(AppCommonService);
  private router = inject(ActivatedRoute);

  public formContacto: FormGroup = this.fb.group({
    Activo: [],
    AccesoSistemaGk: [],
    ApellidoPaterno: ['', Validators.required],
    ApellidoMaterno: ['', Validators.required],
    Nombre: ['', Validators.required],
    Correo: ['', Validators.required],
    Telefono: ['', Validators.required],
    Fax: ['' ],
    Celular: [''],
    Nextel: [''],
    Otro: [''],
    Puesto: [''],
    Usuario: [{ value: '', disabled: true},  Validators.required],
    Contrasena: [{ value: '', disabled: true},  Validators.required],
    NivelId: [ { value: '', disabled: true},  Validators.required],
    RecordatorioCumpleanios: [],
    Comentarios: [''],
    AccesoTamik: [],
    AccesoAudik: [],
    AccesoDx: [],
    AccesoCardik: [],
    AlertasTamik: [],
    AlertasAudik: [],
    AlertasDx: [],
    AlertasCardik: []
  });

  //ToDos campos invalidos
  isValidFieldContacto( field: string ): boolean | null{
    return this.formContacto.controls[field].errors 
    && this.formContacto.controls[field].touched;
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, ){
    if( this.data && this.data.combos && this.data.usuario && this.data.institucionid ){
      this.combos = this.data.combos;
      this.usuario = this.data.usuario;
      this.institucionid = this.data.institucionid;
    }
    if (this.data && this.data.combos && this.data.institucionid  && !this.data.usuario) {
      this.combos = this.data.combos;
      this.institucionid = this.data.institucionid;
    }
  }

  ngOnInit(): void {
    this.router.queryParams.subscribe(params => {
      if (params['esVisor'] === 'true') {
          this.esVisor = true;
      }
    });
    
    this.formContacto.get('AccesoSistemaGk')?.valueChanges.subscribe((value) => {
      if (value === true) {
        // Si se selecciona "Si", habilitar los campos
        this.formContacto.get('Usuario')!.enable();
        this.formContacto.get('Contrasena')!.enable();
        this.formContacto.get('NivelId')!.enable();
      } else {
        // Si se selecciona "No", deshabilitar los campos
        this.formContacto.get('Usuario')!.disable();
        this.formContacto.get('Contrasena')!.disable();
        this.formContacto.get('NivelId')!.disable();
      }
    }); 

    if(this.usuario){
      console.log(this.usuario);
      const  [nombre, apellidoPaterno, apellidoMaterno] = this.separarNombre(); 
      this.formContacto.patchValue({ Activo: this.usuario.Activo , AccesoSistemaGk: true   , Nombre: nombre, Correo: this.usuario.Correo, 
        Telefono: this.usuario.Telefono,  Celular: this.usuario.Celular, NivelId: this.usuario.NivelId, Nextel: this.usuario.Nextel,  Usuario: this.usuario.Usuario,
        Puesto: this.usuario.Puesto,  ApellidoPaterno: apellidoPaterno, ApellidoMaterno: apellidoMaterno
      });
    }

    this.formContacto.get('AccesoTamik')?.valueChanges.subscribe((value) => {
      if (value == false) {
        if(this.formContacto.get('AlertasTamik')!.value && this.formContacto.get('AlertasTamik')!.value.length > 0){
          const dialogRef = this.dialog.open(ConfirmacionMComponent, {
            data: { titulo: `Eliminacion de acceso Tamik a la solucion`, 
                    mensaje: `¿Está seguro en quitar el acceso a la solución? 
                    ya que se van a eliminar las alertas del contacto para la solución seleccionada` }
          })

          dialogRef.afterClosed().subscribe(confirmo => {
            if (confirmo) {
              this.tamik = false;
              this.eliminarAlertas(1);
            } else{
              this.formContacto.patchValue({
                AccesoTamik: true
              })
              this.tamik = true;
            }
          });
        }else{
          this.tamik = false;
        }
        
      }else {
        this.tamik = true;
      }
    });

    this.formContacto.get('AccesoAudik')?.valueChanges.subscribe((value) => {
      if (value == false) {

        if(this.formContacto.get('AlertasAudik')!.value && this.formContacto.get('AlertasAudik')!.value.length > 0){
          const dialogRef = this.dialog.open(ConfirmacionMComponent, {
            data: { titulo: `Eliminacion de acceso Audik a la solucion`, 
                    mensaje: `¿Está seguro en quitar el acceso a la solución? 
                    ya que se van a eliminar las alertas del contacto para la solución seleccionada` }
          })

          dialogRef.afterClosed().subscribe(confirmo => {
            if (confirmo) {
              this.audik = false;
              this.eliminarAlertas(2);
            }else{
              this.formContacto.patchValue({
                AccesoAudik: true
              })
              this.audik = true;
            }
          });
        }else{
          this.audik = false;
        }

      }else{
        this.audik = true
      }
    });

    this.formContacto.get('AccesoDx')?.valueChanges.subscribe((value) => {
      if (value == false) {

        if(this.formContacto.get('AlertasDx')!.value && this.formContacto.get('AlertasDx')!.value.length > 0){
          const dialogRef = this.dialog.open(ConfirmacionMComponent, {
            data: { titulo: `Eliminacion de acceso Dx a la solucion`, 
                    mensaje: `¿Está seguro en quitar el acceso a la solución? 
                    ya que se van a eliminar las alertas del contacto para la solución seleccionada` }
          })

          dialogRef.afterClosed().subscribe(confirmo => {
            if (confirmo) {
              this.dx = false;
              this.eliminarAlertas(3);
            }else{
              this.formContacto.patchValue({
                AccesoDx: true
              })
              this.dx = true;
            }
          });
        }else{
          this.dx = false;
        }

      }else{
        this.dx = true;
      }
    });

    this.formContacto.get('AccesoCardik')?.valueChanges.subscribe((value) => {
      if (value == false) {

        if(this.formContacto.get('AlertasCardik')!.value && this.formContacto.get('AlertasCardik')!.value.length > 0){
          const dialogRef = this.dialog.open(ConfirmacionMComponent, {
            data: { titulo: `Eliminacion de acceso Cardik a la solucion`, 
                    mensaje: `¿Está seguro en quitar el acceso a la solución? 
                    ya que se van a eliminar las alertas del contacto para la solución seleccionada` }
          })

          dialogRef.afterClosed().subscribe(confirmo => {
            if (confirmo) {
              this.cardik = false;
              this.eliminarAlertas(4);
            }else{
              this.formContacto.patchValue({
                AccesoCardik: true
              })
              this.cardik = true;
            }
          });
        }else{
          this.cardik = false;
        }
        
      }else{
        this.cardik = true;
      }
    });
  }

  separarNombre(): [string, string, string]{
    const nombreCompleto = this.usuario.Nombre.trim();
    const partes = nombreCompleto.split(' ');


    const apellidoMaterno = partes.pop();
    const apellidoPaterno = partes.pop();
    const nombre = partes.join(' ');

    return [nombre, apellidoPaterno, apellidoMaterno]
  }

  eliminarAlertas(opcion: number){

    this.appCommon.spinner(true);

    const usuarioId = this.usuario.UsuarioId;

    let alertas;

    switch(opcion) {
      case 1:
        alertas = this.formContacto.value.AlertasTamik;
        break;
      case 2:
        alertas = this.formContacto.value.AlertasAudik;
        break;
      case 3:
        alertas = this.formContacto.value.AlertasDx;
        break;
      case 4:
      alertas = this.formContacto.value.AlertasCardik;
        break;
      default:
        alertas = null;
    }

    const objetoFinal = {
      UsuarioId: usuarioId,
      Alertas: alertas
    };

    console.log(objetoFinal);
    

    this.customersService.deleteAlert(objetoFinal).subscribe(
      {
        next: (resp:any) => {
            this.appCommon.spinner(false);
            this.dialog.open(ConfirmacionMComponent, {
                data: { titulo: `Eliminacion exitosa`, 
                        mensaje: `Se ha eliminado el acceso a la solucion`,
                        Noconfirm: true
                    }
            })
            console.log(resp)                
        }, 
        error: err => {
            this.appCommon.spinner(false);
            this.dialog.open(ConfirmacionMComponent, {
                data: { titulo: `No se ha podido eliminar`, 
                        mensaje: `A ocurrido un error al querer eliminar el acceso a la solucion`,
                        Noconfirm: true
                    }
            })
            console.log('error', err);
        }
    })

  }

  addUser(){

    if(this.formContacto.invalid){
      const dialogRefM = this.dialog.open(ConfirmacionMComponent, {
        data: { titulo: `formulario invalido`, 
                mensaje: `Favor de rellenar el formulario`,
                Noconfirm: true
            }
      })
      dialogRefM.afterOpened().subscribe(() => {
        setTimeout(() => {
          dialogRefM.close();
        }, 3000);
      });
      console.log(this.formContacto.value);
      this.formContacto.markAllAsTouched();
      return;
    }

    this.appCommon.spinner(true);

    //? Desestructuro el objeto 
    const formConValue = { ...this.formContacto.value }

    formConValue.institucionid = this.institucionid;

    if(this.usuario){
      formConValue.UsuarioId = this.usuario.UsuarioId;
    }

    console.log(formConValue);

    this.customersService.addCustomer(formConValue)
        .subscribe(
          {
            next: (resp: any) =>{
              this.appCommon.spinner(false);
              const dialogRefM = this.dialog.open(ConfirmacionMComponent, {
                data: { titulo: `Cliente agregado`, 
                        mensaje: `Se ha guardado el cliente`,
                        Noconfirm: true
                    }
              })
              dialogRefM.afterOpened().subscribe(() => {
                setTimeout(() => {
                  dialogRefM.close();
                }, 3000);
              });
              console.log(resp);
              this.contactoAdded.emit(resp.data.data);
              this.dialogRef.close();
            },
            error: err => {
              this.appCommon.spinner(false);
              const dialogRefM = this.dialog.open(ConfirmacionMComponent, {
                data: { titulo: `No se pudo guardar la informacion`, 
                        mensaje: `A ocurrido un error al querer guardar el contacto`,
                        Noconfirm: true
                    }
              })
              dialogRefM.afterOpened().subscribe(() => {
                setTimeout(() => {
                  dialogRefM.close();
                }, 3000);
              });
              console.log('error al enviar la informacion', err);
            }
          }
        )
  }

}
