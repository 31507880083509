import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GkBasicResponse } from '@common/models';
import { filterNil } from '@modules/utils/operators';

//import { Auth } from 'aws-amplify';
import { signIn, type SignInInput, signOut, getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
//import  Auth from 'aws-amplify/auth';


import { BehaviorSubject, ReplaySubject, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { User, UserResponse } from '../models';

@Injectable({ providedIn: 'root' })
export class UserService {
    API_URL = environment.api.auth;
    private RefreshToken = '';

    private subSubject = new BehaviorSubject<string>('');
    private accessTokenSubject = new BehaviorSubject<string>('');
    private userSubject = new ReplaySubject<User>(1);
    private vUsuarioIDSubject = new BehaviorSubject<number | null>(null);
    private vNivelIDSubject = new BehaviorSubject<number | null>(null);
    private vJefeOpe = new BehaviorSubject<number | null>(null);
    private menuItemsSubject = new BehaviorSubject<any>(null);
    private topMenuItemsSubject = new BehaviorSubject<any>(null);
    private sideMenuItemsSubject = new BehaviorSubject<any>(null);
    private isClienteSubject = new BehaviorSubject<boolean>(false);
    private vInstitucionIDSubject = new BehaviorSubject<number | null>(null);
    public usuarioNivelId:number | null;

    accessToken$ = this.accessTokenSubject.asObservable();
    sub$ = this.subSubject.asObservable();
    user$ = this.userSubject.asObservable();
    vUsuarioID$ = this.vUsuarioIDSubject.asObservable().pipe(filterNil());
    vNivelID$ = this.vNivelIDSubject.asObservable().pipe(filterNil());
    vInstitucionID$ = this.vInstitucionIDSubject.asObservable().pipe(filterNil());
    vJefeOperativo$ = this.vJefeOpe.asObservable().pipe(filterNil());
    menuItems$ = this.menuItemsSubject.asObservable().pipe(filterNil());
    topMenuItems$ = this.topMenuItemsSubject.asObservable().pipe(filterNil());
    sideMenuItems$ = this.sideMenuItemsSubject.asObservable().pipe(filterNil());
    isCliente$ = this.isClienteSubject.asObservable().pipe(filterNil());

    get AccessToken() {
        return this.accessTokenSubject.value;
    }

    get sub() {
        return this.subSubject.value;
    }

    get vUsuarioID() {
        //return 2207;//TODO: remove uSynertix
        return this.vUsuarioIDSubject.value;
    }

    get vNivelID() {
        //return 27;//TODO remove Contador Externo
        return this.vNivelIDSubject.value;
    }

    get isUsuarioAdministrador(){
        return this.vNivelIDSubject.value != 27;//si no es contador externo es admin
    }

    get vInstitucionID() {
        return this.vInstitucionIDSubject.value;
    }
    get vJefeOperativo() {
        return this.vJefeOpe.value;
    }

    get menuItems() {
        return this.menuItemsSubject.value;
    }

    get topMenuItems() {
        return this.topMenuItemsSubject.value;
    }

    get sideMenuItems() {
        return this.sideMenuItemsSubject.value;
    }

    get isCliente() {
            return this.isClienteSubject.value;
        }

    get dataService(){
       let item = this.menuItemsSubject.value;
       let instId = this.vInstitucionIDSubject.value;
       let userId = this.vUsuarioIDSubject.value;
       let nivelId = this.vNivelIDSubject.value;

       let data
       return data = {item, instId,userId,nivelId}

    }

    constructor(private router: Router, private http: HttpClient) {}

    setUser(user: User) {
        this.userSubject.next(user);
    }

    async validaSession() {
        const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
        const userData:any = idToken?.payload;
        console.info("idToken: ", idToken?.toString() );
        //console.info("AccessT: ", accessToken );
        //console.info("AAAA", await fetchAuthSession());

        this.subSubject.next(userData?.sub);
        this.accessTokenSubject.next(idToken?.toString() || '');
        //this.RefreshToken = profileResponse.getRefreshToken().getToken() || '';

        //? Guardar el token en localStorage
        if (idToken) {
            localStorage.setItem('idTokenGenomi', idToken.toString());
        }



        this.updateUserProfile();
        console.info('IdToken', this.accessTokenSubject.value);

        this.setUser({
            id: userData?.sub,
            name: userData?.name,
            firstName: userData?.name,
            lastName: userData?.family_name,
            email: userData?.email,
            tokenId: '',
            AccessToken: this.AccessToken,
            RefreshToken: this.RefreshToken,
            image_profile: '',
        });

        /*
        Auth.currentSession()
            .then(async profileResponse => {
                const idToken = profileResponse.getIdToken();

                this.subSubject.next(idToken.payload.sub);
                this.accessTokenSubject.next(idToken.getJwtToken() || '');
                this.RefreshToken = profileResponse.getRefreshToken().getToken() || '';
                this.updateUserProfile();

                console.info('IdToken', this.accessTokenSubject.value);

                this.setUser({
                    id: idToken.payload.sub,
                    name: idToken.payload.name,
                    firstName: idToken.payload.name,
                    lastName: idToken.payload.family_name,
                    email: idToken.payload.email,
                    tokenId: '',
                    AccessToken: this.AccessToken,
                    RefreshToken: this.RefreshToken,
                    image_profile: '',
                });

                return profileResponse;
            })
            .catch(error => {
                console.log('REFRESH ERROR: ', error);
                this.redirectToLogin();
            });
            */
    }

    /*
    Obtiene el Perfil de Usuario
    */
    getUserProfile(sub: string) {
        if (!sub) {
            return throwError({ data: { message: 'The user id is invalid' } });
        }
        return this.http.get<GkBasicResponse<UserResponse>>(`${this.API_URL}/users/${sub}`);
    }

    private updateUserProfile() {
        this.getUserProfile(this.sub).subscribe(resp => {
            this.setUser({
                id: resp.data.sub,
                name: resp.data.name,
                firstName: resp.data.name,
                lastName: resp.data.family_name,
                email: resp.data.email,
                tokenId: '',
                AccessToken: this.AccessToken,
                RefreshToken: this.RefreshToken,
                image_profile: resp.data.image_profile,
            });

            this.vUsuarioIDSubject.next(resp.data?.SQLProfile?.id ?? null);
            this.vNivelIDSubject.next(resp.data?.SQLProfile?.nivel_id ?? null);
            this.usuarioNivelId = resp.data?.SQLProfile?.nivel_id ?? null;
            this.vInstitucionIDSubject.next(resp.data?.SQLProfile?.InstitucionId ?? null);
            this.vJefeOpe.next(resp.data?.SQLProfile?.JefeOperativo ?? null);
            this.menuItemsSubject.next(resp.data?.permissions ?? null); // quitar una vez que esten al 100 los top y side menus
            //this.topMenuItemsSubject.next(resp.data?.menuPermissions?.topMenu ?? null); // se carga mediante archivo S3
            //this.sideMenuItemsSubject.next(resp.data?.menuPermissions?.sideMenu ?? null); // se carga mediante archivo S3
            this.isClienteSubject.next(resp.data?.SQLProfile?.Cliente ?? false);
        });
    }

    public getUserProfileData(){
        return this.getUserProfile(this.sub);
    }

    /*
    Login con API de Amplify
    */
    async loginWithCognito(credentials: { email: string; password: string }) {
        try {
            //const userResponse = await Auth.signIn(credentials.email, credentials.password);
            const username = credentials.email;
            const password = credentials.password;
            const { isSignedIn, nextStep } = await signIn({ username, password });


            if (isSignedIn){
                const { userId, signInDetails } = await getCurrentUser();
                console.info("DET: ", signInDetails );
                await this.validaSession();
                this.router.navigate(['home']);
            } else {
                await this.redirectToLogin();
            }



            console.log('Authentication performed for user:', credentials.email, 'login result:', isSignedIn);
            //const tokens = userResponse.signInUserSession;
            // const session = await Auth.currentSession();
            return false;
            /*
            if (userResponse.challengeName === 'NEW_PASSWORD_REQUIRED___') {
                console.info('NEW_PASSWORD_REQUIRED: ', userResponse.storage);
            }


            if (idToken != null) {
                await this.validaSession();
                this.router.navigate(['home']);
            }
            */
        } catch (error : any) {
            if(error && error.name && error.name.indexOf("UserAlreadyAuthenticatedException") > - 1){
                const { signInDetails } = await getCurrentUser();
                console.info("DET: ", signInDetails );
                await this.validaSession();
                this.router.navigate(['home']);
            }else{
                console.error('Error: ', error);
                alert('User Authentication failed');
            }
        }
    }

    async logoutCognito() {
        try {
            await signOut({ global: true });
            //await Auth.signOut({ global: true });
            await this.redirectToLogin();
        } catch (error) {
            console.error('Error: ', error);
            alert('User Logout failed');
        }
    }

    private redirectToLogin() {
        return this.router.navigate(['auth/login']);
    }

    /*
    Obtiene el listado de Niveles de usuario
    */
    getUserNiveles() {
        return this.http.get<GkBasicResponse<any>>(`${this.API_URL}/users/nivel`);
    }
    /*
    Obtiene el listado de usuarios del sistema en CF
    */
    getUsersCF() {
        return this.http.get<GkBasicResponse<any>>(`${this.API_URL}/users/cf`);
    }

}
