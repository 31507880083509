<ng-container *transloco="let dt; read: 'operaciones.ResultadosyBusquedasAK'">
    <div class="row">
        <div class="col-xl-12">
            <div class="form-group row">
                <div class="col-md-12">
                    <div ngbAccordion #a="ngbAccordion" activeIds="" [closeOthers]="true">
                        <div ngbAccordionItem id="custom-panel-1">
                            <h2 ngbAccordionHeader>
                                <div class="d-flex align-items-center justify-content-between">
                                    <button ngbAccordionButton class="btn container-fluid text-left pl-0 btn-accordion">
                                        <fa-icon [icon]='["fas", "angle-right"]' class="fa-icon"></fa-icon>{{dt('titulo')}} {{DatosPaciente.paciente_id}} : {{DatosPaciente.nombre}} {{DatosPaciente.apellidos}}
                                    </button>
                                </div>
                            </h2>
                            <div ngbAccordionCollapse>
                                <div ngbAccordionBody>
                                    <ng-template>
                                        <div class="row d-flex">
                                            <div class="col-md-6">
                                                <br>
                                                <strong>{{dt('NombrePx')}}:</strong>&nbsp; {{DatosPaciente.nombre}} {{DatosPaciente.apellidos}}
                                                <br />
                                                <strong>{{dt('Institucion')}}:</strong>&nbsp; {{DatosPaciente.nombre_comercial}}
                                                <br />
                                                <strong> {{dt('NoOrdenServicio')}}:</strong> &nbsp; {{DatosPaciente.Codigo}}{{DatosPaciente.folio}}
                                                <br>
                                                <strong>{{dt('NoFicha')}}:</strong>&nbsp; {{DatosPaciente.ficha}}
                                                <br />
                                                <strong>{{dt('Sexo')}}:</strong>&nbsp;<span *ngIf="DatosPaciente.sexo === false">Masculino</span><span *ngIf="DatosPaciente.sexo === true">Femenino</span>
                                                <br />
                                                <strong>{{dt('EdadGestacional')}}:</strong>&nbsp; {{DatosPaciente.semanas_g}}
                                                <br />
                                                <strong>{{dt('MedicoSolicita')}}:</strong>&nbsp; {{DatosPaciente.medico_solicita}}
                                                <br />
                                                <strong>{{dt('NombreMadre')}}:</strong>&nbsp; {{DatosPaciente.nombre_mama}} {{DatosPaciente.apellido_mama}}
                                                <br />
                                                <strong>{{dt('Direccion')}}:</strong>&nbsp; {{DatosPaciente.calle}} {{DatosPaciente.colonia}} {{DatosPaciente.ciudad}} {{DatosPaciente.estado}}
                                                <br />
                                                <strong>{{dt('Comentarios')}}:</strong>&nbsp; {{DatosPaciente.comentarios}}
                                            </div>
                                            <div class="col-md-6">
                                                <br>
                                                <strong>{{dt('FechaNacimiento')}}:</strong>&nbsp; {{DatosPaciente.fecha_nacimiento | date: "dd-MMM-yyyy hh:mm a": "UTC"}}
                                                <br />
                                                <strong>{{dt('TipoNacimiento')}}:</strong>&nbsp; {{DatosPaciente.tipo_parto}}
                    
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row" *ngIf="DatosPaciente.Tipo_Institucion === 5">
                                            <div class="col-md-12">
                                                <div class="col-xs-4">
                                                    <strong>{{dt('Remission')}}:</strong>&nbsp; {{DatosPaciente.remision_id}}
                                                </div>
                                                <div class="col-xs-4">
                                                    <strong>{{dt('Invoice')}}:</strong>&nbsp; {{DatosPaciente.factura}}
                                                </div>
                                                <div class="col-xs-4">
                                                    <strong>{{dt('OrdenSurtimiento')}}:</strong> {{DatosPaciente.Orden_Surtimiento}}
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row text-center mt-4" *ngIf="TipoDetalle >= 1 && DatosPaciente.bitAK !== 0">
        <div class="row text-center">
            <h4><strong>{{dt('Audik')}}</strong></h4>
            <div class="col-md-12 text-center">
                <h5><strong>{{dt('Calificacion')}}:</strong>&nbsp;{{DatosPaciente.Calificacion_final}}</h5>
            </div>
        </div>

        <div class="col-md-12 table-responsive">
            <table class="table-striped">
                <!--Aqui va una validacion con el usuario session.cliente eq 0 -->
                <tr *ngIf="DatosPaciente.bitPP" style="border-collapse: separate; border-spacing:  5px;">
                    <td class="ttdblue text-left" align="left">{{dt('CalificacionPresuntosPositivos')}}: &nbsp;</td>
                    <td class="text-left mr-3" colspan="3">
                        <div align="left">
                            <font face="Arial, Helvetica, sans-serif" size="2"> &nbsp;&nbsp;{{DatosPaciente.Calif_pp}}</font>
                        </div>
                    </td>
                </tr>

                <tr *ngIf="DatosPaciente.bitSoundgene">
                    <td height="26" align="left" class="ttdblue text-left">{{dt('Soundgene')}}:</td>
                    <td class="text-left" colspan="3">
                        <div align="left">
                            <font face="Arial, Helvetica, sans-serif" size="2"><a href="/soundgene/DatosPaciente.soundgene" [ngClass]="{'txtlinka': !isCliente, 'txtlinkGreen': isCliente}" target="_new">{{DatosPaciente.soundgene}}</a></font>
                        </div>
                    </td>
                </tr>
            </table>
            <table width="100%" class="table table-striped table-border border" *ngIf="DatosResultadosAK.length > 0">
                <thead>
                    <tr class="ttdblue">
                        <td class="text-center" colspan="4">{{dt('ProtocoloTamizAuditivoGenomik')}} (35 dBs)</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="text-center"><strong>{{dt('Estudio')}}</strong></td>
                        <td class="text-center"><strong>{{dt('Fecha')}}</strong></td>
                        <td class="text-center" *ngIf="DatosPaciente.Version_pdf_id >= 11" class="text-center "><strong>{{dt('Resultado')}}</strong></td>
                        <td class="text-center" *ngIf="DatosPaciente.Version_pdf_id < 11" class="text-center "><strong>{{dt('Izquierdo')}}</strong></td>
                        <td class="text-center" *ngIf="DatosPaciente.Version_pdf_id < 11" class="text-center "><strong>{{dt('Derecho')}}</strong></td>
                    </tr>
                    <tr *ngFor="let data of DatosResultadosAK">
                        <td class="text-center">
                            <gk-cf-pdf-viewer [url]="DataResumen.url" [file]="DataResumen.file" [display]="data.etapa" [clase]="{'txtlinka': !isCliente, 'txtlinkGreen': isCliente}"></gk-cf-pdf-viewer>
                        </td>
                        <td class="text-center ">{{ data.Fecha_estudio | date: "dd-MMM-yyyy": "UTC"}}</td>
                        <td class="text-center " *ngIf="DatosPaciente.Version_pdf_id >= 11">{{data.varresunificado}}</td>
                        <td class="text-center " *ngIf="DatosPaciente.Version_pdf_id < 11">{{data.res_izq}}</td>
                        <td class="text-center " *ngIf="DatosPaciente.Version_pdf_id < 11">{{data.res_der}}</td>
                    </tr>
                    <ng-container *ngIf="DatosResultadosAK.length>0">
                        <tr>
                            <td colspan="4" class="text-left">
                                <gk-cf-pdf-viewer [url]="DataResumen.url" [file]="DataResumen.file" [display]="DataResumen.display" [clase]="{'txtlinka': !isCliente, 'txtlinkGreen': isCliente}"></gk-cf-pdf-viewer>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
            <table width="100%" class="table table-striped table-border border mt-3" *ngIf="DatosResultadosPostAK.length > 0">
                <thead>
                    <tr class="ttdblue">
                        <td class="text-center" colspan="4">{{dt('PruebasPosteriores')}}</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="text-center"><strong>{{dt('Estudio')}}</strong></td>
                        <td class="text-center"><strong>{{dt('Fecha')}}</strong></td>
                        <td class="text-center" class="text-center "><strong>{{dt('Izquierdo')}}</strong></td>
                        <td class="text-center" class="text-center "><strong>{{dt('Derecho')}}</strong></td>
                    </tr>
                    <tr *ngFor="let data of DatosEnlacesPostAK">
                        <td class="text-center txtlinka">
                            <gk-cf-pdf-viewer [url]="data.url" [file]="data.file" [display]="data.tipo_estudio" [clase]="clase"></gk-cf-pdf-viewer>
                        </td>
                        <td class="text-center ">{{ data.Fecha_estudio | date: "dd-MMM-yyyy": "UTC"}}</td>
                        <td class="text-center ">{{data.resizquierdo}}</td>
                        <td class="text-center ">{{data.resderecho}}</td>
                    </tr>
                </tbody>
            </table>
            <table width="100%" class="table table-striped table-border border mt-3" *ngIf="DatosComentariosAK.length > 0 && isCliente && TipoDetalle >= 1 && DatosPaciente.bitAK !== 0">
                <thead>
                    <tr class="ttdblue">
                        <td class="text-center"><strong>{{dt('TipoComentario')}}</strong></td>
                        <td class="text-center"><strong>{{dt('Fecha')}}</strong></td>
                        <td class="text-center"><strong>{{dt('Comentario')}}</strong></td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of DatosComentariosAK">
                        <td class="text-center">
                            {{data.TipoComentario}}
                        </td>
                        <td class="text-center ">{{ data.FechaComentario | date: "dd-MMM-yyyy": "UTC"}}</td>
                        <td class="text-left ">{{data.Comentario}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row text-center mt-4" *ngIf="TipoDetalle === 2 && DatosPaciente.PacienteCK_id !== 0">
        <div class="row text-center">
            <h4><strong>{{dt('Cardik')}}</strong></h4>
            <div class="col-md-12 text-center">
                <h4>{{dt('ProtocoloCk')}}</h4>
            </div>
            <div class="col-md-12 text-center">
                <h5><strong>{{dt('Calificacion')}}:</strong>&nbsp;{{DatosPaciente.txtCalifCK}}</h5>
            </div>
        </div>
        <div class="col-md-12 table-responsive">
            <table width="100%" class=" table table-striped table-border border" *ngIf="DatosResultadosCK.length > 0">
                <thead>
                    <tr class="ttdblue">
                        <td class="text-center" colspan="5">{{dt('ProtocoloTamizAuditivoGenomik')}}</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="text-center"><strong>{{dt('Estudio')}}</strong></td>
                        <td class="text-center"><strong>{{dt('Fecha')}}</strong></td>
                        <td class="text-center"><strong>% SpO2 {{dt('Mano')}}</strong></td>
                        <td class="text-center"><strong>% SpO2 {{dt('Pie')}}</strong></td>
                        <td class="text-center"><strong>{{dt('Resultado')}}</strong></td>
                    </tr>
                    <tr *ngFor="let data of DatosEnlacesCK">
                        <td class="text-center">
                            <gk-cf-pdf-viewer [url]="data.url" [file]="data.file" [display]="data.num_estudio" [clase]="{'txtlinka': !isCliente, 'txtlinkGreen': isCliente}"></gk-cf-pdf-viewer>
                        </td>
                        <td class="text-center">{{ data.fecha_estudio | date: "dd-MMM-yyyy": "UTC"}}</td>
                        <td class="text-center">{{data.Avg_mano_oxi}}</td>
                        <td class="text-center">{{data.Avg_pie_oxi}}</td>
                        <td class="text-center">{{data.tipo_resultado}}</td>
                    </tr>
                    <tr *ngIf="DatosPaciente.bit_referidock === true || DatosPaciente.bit_eco === true">
                        <td colspan="5" *ngIf="DatosPaciente.bit_eco === true">
                            <gk-cf-pdf-viewer [url]="urlEco" [file]="fileEco" display="<i class=' fa fa-file-text '></i>&nbsp;Resultado Eco" [clase]="{'txtlinka': !isCliente, 'txtlinkGreen': isCliente}"></gk-cf-pdf-viewer>
                        </td>
                        <td colspan="5" *ngIf="DatosPaciente.bit_eco === false">
                            <strong>{{dt('Nota')}}: </strong><label>{{dt('NoEco')}}</label>
                        </td>
                    </tr>
                </tbody>
            </table>

            <table width="100%" class="table table-striped table-border border mt-3" *ngIf="DatosComentariosCK.length > 0 && isCliente && TipoDetalle === 2 && DatosPaciente.PacienteCK_id !== 0">
                <thead>
                    <tr class="ttdblue">
                        <td class="text-center"><strong>{{dt('TipoComentario')}}</strong></td>
                        <td class="text-center"><strong>{{dt('Fecha')}}</strong></td>
                        <td class="text-center"><strong>{{dt('Comentario')}}</strong></td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of DatosComentariosCK">
                        <td class="text-center">
                            {{data.TipoComentario}}
                        </td>
                        <td class="text-center ">{{ data.FechaComentario | date: "dd-MMM-yyyy": "UTC"}}</td>
                        <td class="text-left " [innerHTML]="data.Comentario"> </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</ng-container>
