import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DataComentariosTK, MedicoTK, OperativoTK } from '@modules/medical-records/models';
import { AgregarComentMedicoComponent } from '../Modales/agregar-coment-medico/agregar-coment-medico.component';
import { ViewCommentsGComponent } from '../Modales/view-comments-g/view-comments-g.component';

@Component({
  selector: 'gk-add-comments-g',
  templateUrl: './add-comments-g.component.html',
  styleUrl: './add-comments-g.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddCommentsGComponent implements OnInit{

  @Input() comentarios: DataComentariosTK;
  @Input() esVisor: boolean = false;
  @Output() comentarioAgregado = new EventEmitter<void>();
  
  //? Variables
  id:number;

  //? Servicios
  private dialog = inject(MatDialog);
  private route = inject(ActivatedRoute);

  ngOnInit(): void {
    console.log(this.comentarios);
    this.route.params.subscribe( params => {
      this.id = params['pacienteId']
    })
  }

  agregarComentario(tipo:number) {
    
    const prueba  = tipo === 1 ? 'Medico General' : 'Operativo';
    const gral    = tipo === 1 ? 1 : 0; //? op es 0 medicG es 1    
    const filtro  = tipo === 1 ? 0 : 90296;  //? op es 90296 medicG es 0 

    const dialogData = {
        Prueba:  prueba,
        Pacienteid: this.id,
        Tipocomentario: 1,
        Filtroid: filtro,
        Gral: gral,  
        CalifFinal: 0,
    };

    const dialogRef = this.dialog.open(AgregarComentMedicoComponent, {
        width: '1120px', //? Ancho deseado
        data: dialogData,
        position: {
          top: '50px' 
        },
        autoFocus: false
    })

    const instance = dialogRef.componentInstance;
    instance.actualizarDatosEmitter.subscribe(() => {
      this.comentarioAgregado.emit();
    });

  }

  verComentarios(comentarios:  MedicoTK[] | OperativoTK[], tipo:number){

    const titulo = tipo === 1 ? 'Medicos' : 'Operativos';
    const tipoComentario = tipo === 1 ? 2 : 1;
    const subtitulo = tipo === 1 ? comentarios[0].prueba_desc : null;
    const canDelete: boolean = tipo === 1 ? true : false;  

    const dialogData = {
      Titulo:      titulo,
      Comentarios: comentarios,
      TipoComentario: tipoComentario,
      Usuario: this.comentarios.usuario,
      SubTitulo: subtitulo,
      CanDelete: canDelete
    };

    const dialogRef = this.dialog.open(ViewCommentsGComponent, {
      width: '1120px', //? Ancho deseado
      data: dialogData,
      position: {
        top: '50px' 
      },
      autoFocus: false
    })

    const instance = dialogRef.componentInstance;
    instance.actualizarDatosEmitter.subscribe(() => {
      this.comentarioAgregado.emit();
    });

  }


}
