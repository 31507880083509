<mat-dialog-content class="mat-typography">

    <form [formGroup]="formTamizaje" (ngSubmit)="saveTamizaje()">
        <div class="row">
            <div class="col-4"></div>
            <h2 mat-dialog-title class="col-4">Nuevo Tamizaje</h2>
            <div class="col-4"></div>
        </div>
        <div class="row">

            <label class="control-label col-4 mb-2" >Tamizaje:</label>
            <label class="control-label col-4 " >Costo Aproximado:</label>
            <label class="control-label col-4 " >Incluido en paquete maternidad:</label>
            <div class="col-md-4 mb-3" >
                <span class="form-text text-danger my-1" *ngIf="isValidFieldTamiz('Tamiz')">
                    <b>Este campo es requerido</b>
                </span>
                <select  class="form-control"  formControlName="Tamiz" >
                    <option value="">Seleccione un tipo</option>
                    <option
                            *ngFor="let tamizaje of combos.Tamizajes"
                            [value]="tamizaje.value"
                            >
                            {{ tamizaje.display }}
                    </option>
                </select>
            </div>
            <div class="col-md-4" >
                <span class="form-text text-danger my-1" *ngIf="isValidFieldTamiz('Costo_Aproximado')">
                    <b>Este campo es requerido</b>
                </span>
                <input type="number" formControlName="Costo_Aproximado"  class="form-control" min="0" >
            </div>
            <div class="col-md-4">
                <input type="radio" [value]="1" class="form-check-input" formControlName="IncluidoPaquete" > SI 
                <input type="radio" [value]="2" class="form-check-input ms-5"  formControlName="IncluidoPaquete"> NO
            </div>
            <label class="control-label col-12 mb-3"  >Comentarios:</label>
            <div class="col-10">
                <input type="text" formControlName="Comentarios"  class="form-control ms-1">
            </div>
            <div class="col-2"></div>
        </div>
    
        <div class="full-width-element full-height-element">
            <mat-divider class="mt-4"></mat-divider>
            <mat-dialog-actions align="end" class="gris py-4">
                <button cdkFocusInitial class="btn btn-success mx-2 mt-3" type="submit">
                    <mat-icon class="tamIconB">save</mat-icon>
                    Guardar
                </button>
                <button [mat-dialog-close] class="btn btn-secondary mx-2 mt-3">
                    <mat-icon class="tamIconB">keyboard_arrow_left</mat-icon>
                    Regresar
                </button>
            </mat-dialog-actions>
        </div>
    </form>
</mat-dialog-content>