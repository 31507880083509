import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'gk-confirmacion-m',
  templateUrl: './confirmacion-m.component.html',
  styleUrl: './confirmacion-m.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmacionMComponent {

  //? recibe
  titulo!: string;
  mensaje!: string;
  confirm: boolean = true;

  
  constructor(
    public dialogRef: MatDialogRef<ConfirmacionMComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ){
    if(this.data && this.data.titulo && this.data.mensaje && this.data.Noconfirm ){
      this.titulo = this.data.titulo;
      this.mensaje = this.data.mensaje;
      this.confirm = false
    }else if(this.data && this.data.titulo && this.data.mensaje && !this.data.Noconfirm){
      this.titulo = this.data.titulo;
      this.mensaje = this.data.mensaje;
    }
  }

  confirmacion() {
    this.dialogRef.close(true);
  }



}
