import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'gk-modal-view-filter',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './modal-view-filter.component.html',
    styleUrls: ['modal-view-filter.component.scss'],
})


export class ModalViewFilterComponent {
    @Input() datoRecollecion: any=[];
    @Input() visor: any;


    constructor() {}

}
