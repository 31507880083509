import { GkBasicResponse } from '@common/models';
import { MonoTypeOperatorFunction, of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';

/**
 * Throws an error if the request replied with `null` or `status: 'fail'`
 */
export function checkResponseFailStatus<T extends GkBasicResponse>(): MonoTypeOperatorFunction<T> {
    return switchMap(response => {
        if (response == null || response?.status === 'fail') {
            console.info('RESPONSE ERROR: ', response);
            return throwError(() => response);
        }

        return of(response);
    });
}
