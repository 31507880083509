<ngb-toast
    *ngFor="let toast of toasts$ | async"
    [class]="toast.classname"
    [autohide]="true"
    [delay]="toast.delay || 5000"
    (hidden)="removeToast($event)"
>
    <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="templateByType">
        <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
    </ng-template>

    <ng-template #templateByType>
        <ng-container [ngSwitch]="toast.type">
            <ng-container *ngSwitchCase="'error'">
                <fa-icon size="2x" [icon]="['fas', 'times']"></fa-icon>
                <span class="toast-message">{{ toast.textOrTpl }}</span>
            </ng-container>

            <ng-container *ngSwitchCase="'danger'">
                <fa-icon size="2x" [icon]="['fas', 'times']"></fa-icon>
                <span class="toast-message">{{ toast.textOrTpl }}</span>
            </ng-container>

            <ng-container *ngSwitchCase="'warning'">
                <fa-icon size="2x" [icon]="['fas', 'times']"></fa-icon>
                <span class="toast-message">{{ toast.textOrTpl }}</span>
            </ng-container>

            <ng-container *ngSwitchCase="'success'">
                <fa-icon size="2x" [icon]="['fas', 'check']"></fa-icon>
                <span class="toast-message">{{ toast.textOrTpl }}</span>
            </ng-container>

            <ng-container *ngSwitchCase="'info'">
                <fa-icon size="2x" [icon]="['fas', 'info']"></fa-icon>
                <span class="toast-message">{{ toast.textOrTpl }}</span>
            </ng-container>

            <ng-container *ngSwitchDefault>
                <span class="toast-message">{{ toast.textOrTpl }}</span>
            </ng-container>
        </ng-container>
    </ng-template>
</ngb-toast>
