/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectionStrategy, Component,ChangeDetectorRef, OnInit,Output, EventEmitter, Input, AfterContentInit, AfterViewInit } from '@angular/core';
import { UserService } from '@modules/auth/services';
import { FormBuilder, FormGroup} from '@angular/forms';
import { AppCommonService } from '@modules/app-common/services';
import { ReportsService } from '@modules/reports/services';

@Component({
    selector: 'gk-search-filter-dinamic',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './search-filter-dinamic.component.html',
    styleUrls: ['search-filter-dinamic.component.scss'],
})

export class SearchFilterDinamicComponent implements AfterViewInit {
    @Output("generarConsultaFiltros") GenerarDatosReporte: EventEmitter<any> = new EventEmitter();
    @Output("OcultarFiltros") MostrarFiltros: EventEmitter<boolean> = new EventEmitter();
    @Input() Config
    @Input() Language
    @Input() TipoFormulario
    @Input() CargarInit
    @Input() OcultarTodoFormulario
    @Input() ListoBusqueda
    TipoFormularioComponent: any
    ConfigShow: any
    initTamiz:any
    readonly searchType = [{"value": '2', "text":'Privado'}, {"value": '1',"text":'Gobierno'}, {"value": '3',"text":'Gobierno y Privado'}];
    readonly tipoBaja = [{"value": '1', "text":'GK'}, {"value": '2',"text":'Cliente'}, {"value": '0',"text":'Ambos'}];
    readonly searchPeriodo = [{"value": '0', "text":'Todas las fechas disponibles'}, {"value": '1',"text":'Por mes/año'}, {"value": '2',"text":'Por intervalo'}];
    readonly searchTipoServ = [{"value": '1', "text":'RNs con protocolo en proceso', "tiposol":1}, {"value": '3',"text":'RNs Inconclusos',"tiposol":2}, {"value": '4',"text":'RNs Referidos', "tiposol":2},{"value": '2',"text":'Todos los servicios',"tiposol":1}];
    formFiltros: FormGroup | undefined
    mes_ini=""
    mes_Fin =""
    year_Fin =""
    inpDesglose = true
    tipobusqueda= 0
    tipoinstitucion= 0
    calif= 0
    requestBody: any
    cbxTipoInst = []
    formbuldierConfig = {}

    periodoFiltrado:any
    arrayFiltrado: any
    arrayFiltradoInstituciones:any;
    dataInstituciones: any;
    years: any = [];
    dataMeses = [
        {
            id: '1',
            mes: 'Enero',
        },
        {
            id: '2',
            mes: 'Febrero',
        },
        {
            id: '3',
            mes: 'Marzo',
        },
        {
            id: '4',
            mes: 'Abril',
        },
        {
            id: '5',
            mes: 'Mayo',
        },
        {
            id: '6',
            mes: 'Junio',
        },
        {
            id: '7',
            mes: 'Julio',
        },
        {
            id: '8',
            mes: 'Agosto',
        },
        {
            id: '9',
            mes: 'Septiembre',
        },
        {
            id: '10',
            mes: 'Octubre',
        },
        {
            id: '11',
            mes: 'Noviembre',
        },
        {
            id: '12',
            mes: 'Diciembre',
        },
      ];
    txtTipoBusqueda = "";
    inpIncTamiz: unknown;
    TipoBusquedaPeriodo: any;
    dataUsuarios: any;
    dataGrupoMedico: any;
    dataTipoInstituciones: any
    cbxUsuarioID: any;
    TipoBajaID: any;
    cbxGrupoMedicoID: any
    inpSexo: any;
    TipoBusquedaServicio: any
    //isCliente: boolean = false;
    isCliente: any;
    ActiveInstitucionID: any = 0;
    ActiveNivelID: any = 0;
    OcultarRadiosInstNivel = false
    varaux: any;
    usuarioid: any;

    constructor(
        public appCommon: AppCommonService,
        private ref: ChangeDetectorRef,
        private reportsService:ReportsService,
        public formBuilder: FormBuilder,
        public userService: UserService
    ) { }


    /*
    Este metodo se ejecuta antes de que la pagina este lista
    */


    /*
    Este metodo se ejecuta cuando la pagina ya cargo
    */
    async ngAfterViewInit(){
        this.ConfigShow = {}
        this.years = [];
        this.initTamiz = 0
        for (let i: any = 2014; i <= new Date().getFullYear(); i++) {
            this.years.push({ year: i });
        }
        //Aquie se manda llamar el service en el componente  para obtener las variables que requiero
        this.ActiveNivelID = await this.getNivelID();
        this.ActiveInstitucionID = await this.getInstitucionID();
        this.isCliente = await this.getIsCliente();
        this.usuarioid = await this.getUsuarioId();

        if(this.isCliente) {
            this.OcultarRadiosInstNivel = true
        }

        //se imprimen las variables para ver si ya las tengo disponibles ya que lo que sigue abajo bvoy a meter condiciones de logica para armar el formulario que requiero
        //console.log("dato comparativo",this.ActiveNivelID)
        //console.log("datos tipo inst1",[3,4].includes(Number(this.ActiveNivelID)))
        //console.log("datos tipo inst2",[5,6].includes(Number(this.ActiveNivelID)))

        //console.log("datos de configuracion: ",this.Config)

        this.TipoFormularioComponent = this.TipoFormulario || 1
        this.Config.forEach(elementconfig => {
            this.ConfigShow[elementconfig.name] = {
                "show": elementconfig.show,
                "especial":elementconfig.especial,
                "vistaadmin":elementconfig.vistaadmin,
                "tiposol":elementconfig.tiposol}

            //Se empieza a armar el form para los datos a enviar
            if  (elementconfig.show == true || elementconfig.vistaadmin)
            {

                    let InitCampos = elementconfig.init
                    if  (elementconfig.name == 'TipoBusqueda')
                    {
                       if([3,4].includes(this.ActiveNivelID))
                       {
                        InitCampos = '1'
                       }
                       else if([5,6].includes(this.ActiveNivelID))
                       {
                        InitCampos = '2'
                       }

                       if  (elementconfig.name == 'inpIncTamiz')
                       {
                        this.initTamiz
                       }


                    }
                    //console.log("datos para iniciar",InitCampos)

                    if  (elementconfig.type == 'radio')
                    {
                        this.ConfigShow[elementconfig.name+'Radios'] = elementconfig.hiddenRadios
                        this.formbuldierConfig[elementconfig.name] = InitCampos
                        if  (elementconfig.name == 'TipoBusqueda')
                        {
                            this.formbuldierConfig['Institucion_Id']=[0]
                        }
                        if  (elementconfig.name == 'TipoBusquedaPeriodo')
                        {
                            this.formbuldierConfig['fechaIni']=['']
                            this.formbuldierConfig['fechaFin']=['']
                            this.formbuldierConfig['mes']=[null]
                            this.formbuldierConfig['anio']=[null]
                            if (this.ConfigShow['TipoBusquedaPeriodo'].especial)
                            {
                                this.formbuldierConfig['fechaMesFin']=[null]
                            }
                        }

                    }
                    else if(elementconfig.type == 'input')
                    {
                        this.formbuldierConfig[elementconfig.name] = ['']
                    }
                    else if(elementconfig.type == 'cbx')
                    {
                        this.formbuldierConfig[elementconfig.name] = [elementconfig.init]
                    }
                    this.formbuldierConfig['NivelActivo']=['']
                    this.formbuldierConfig['InstActiva']=['']
                    this.formbuldierConfig['usuarioid']=['']
            }
        });



        //console.log("struct form",this.formbuldierConfig)

        this.formFiltros = this.formBuilder.group(this.formbuldierConfig);

        await this.verInstituciones()  // this.actionForm = 'createCatalog'
        if (this.isCliente){
            this.formFiltros?.controls.inpIncTamiz.setValue(this.Config.find(o => o.name === 'inpIncTamiz').initCliente);
        }
        await this.verUsuarios()
        await this.verGrupoMedico()
        await this.verTipoInst()

        this.arrayFiltrado = [
            {
            TipoBusqueda :  [false]
            }
        ]

        this.periodoFiltrado = [
            {
                TipoBusquedaPeriodo :  [false]
            }
        ]


    }

    async onCheckChangeBusqueda() {
        let Gobierno_bit = 0
            this.txtTipoBusqueda="Hospital"
            const TipoInstitucionID = this.formFiltros?.get('TipoBusqueda')?.value
            this.formFiltros?.controls.Institucion_Id.setValue(0);

            if(TipoInstitucionID == 1)
            {
                this.txtTipoBusqueda="Unidad Medica"
                Gobierno_bit = 1
            }

            if(TipoInstitucionID == 3)
            {
                Gobierno_bit = 3
            }

        this.arrayFiltrado[0].TipoBusqueda = Gobierno_bit
        this.varaux = await this.FiltrarInstituciones()
        if(this.CargarInit)
        {
            await this.generarFiltro()
            this.CargarInit = false
        }
        //console.info("VAR AUX: ", this.varaux);

      }

    async FiltrarInstituciones(){
        return new Promise((resolve, reject) => {
            let varaux;
            if ( this.arrayFiltrado[0].TipoBusqueda == 3) {
              this.arrayFiltradoInstituciones = this.dataInstituciones
              varaux = ""
              //console.log("Data Gobierno y privado", this.arrayFiltradoInstituciones )
            }
            else if([3,6].includes(this.ActiveNivelID))
            {
                //console.log(`entro admin: ${this.arrayFiltrado[0].TipoBusqueda} uduario = ${this.ActiveInstitucionID}`)
                this.arrayFiltradoInstituciones =  this.dataInstituciones.filter(institucion => institucion.Gobierno_bit == this.arrayFiltrado[0].TipoBusqueda && institucion.isgroup == this.ActiveInstitucionID)
                varaux =  this.arrayFiltradoInstituciones.map(institucion => institucion.Institucion_Id).join(", ");
                if(this.isCliente && !this.Config.find(o => o.name === 'TipoBusqueda').vistaadmin)
                {
                    this.formFiltros?.controls.Institucion_Id.setValue(this.ActiveInstitucionID)
                }
            }
            else if([4,5].includes(this.ActiveNivelID))
            {
                //console.log("entro user")
                this.arrayFiltradoInstituciones =  this.dataInstituciones.filter(institucion => institucion.Gobierno_bit == this.arrayFiltrado[0].TipoBusqueda && institucion.Institucion_Id == this.ActiveInstitucionID)
                this.formFiltros?.controls.Institucion_Id.setValue(this.ActiveInstitucionID)
                varaux =  this.arrayFiltradoInstituciones.map(institucion => institucion.Institucion_Id).join(", ");

            }
            else
            {
                //console.log("entro todos")
                this.arrayFiltradoInstituciones =  this.dataInstituciones.filter(institucion => institucion.Gobierno_bit == this.arrayFiltrado[0].TipoBusqueda)
                varaux =  this.arrayFiltradoInstituciones.map(institucion => institucion.Institucion_Id).join(", ");
            }

            if (varaux == null) {
                reject(0);
            }else{
                resolve(varaux);
            }
        });

      }




    async generarFiltro(){
        if (this.ListoBusqueda)
        {
        this.requestBody = {}
        if(this.ConfigShow.TipoBusqueda && this.ConfigShow.TipoBusqueda.show == true){
            this.tipoinstitucion = this.formFiltros?.controls.TipoBusqueda.value
            this.requestBody['tipoinstitucion'] = this.tipoinstitucion
            this.requestBody['IntAux'] = this.varaux
            this.requestBody['institucionid'] = this.formFiltros?.controls.Institucion_Id.value
            if(this.requestBody['tipoinstitucion'] == 1 || this.requestBody['tipoinstitucion'] == 2){
                if( this.requestBody['institucionid'] === ''){
                    this.appCommon.Notify("Seleccione una institucion", 'warning');
                        this.ref.detectChanges();
                        return false;
                }
            }
        }
        if(this.ConfigShow.cbxGrupoMedicoID && this.ConfigShow.cbxGrupoMedicoID.show == true){
            this.cbxGrupoMedicoID = this.formFiltros?.controls.cbxGrupoMedicoID.value
            this.requestBody['cbxGrupoMedicoID'] = this.cbxGrupoMedicoID
            // if( this.requestBody['cbxGrupoMedicoID'] === 0){
            //     this.appCommon.Notify("Seleccione un grupo medico", 'warning');
            //         this.ref.detectChanges();
            //         return false;
            // }
        }

        if(this.ConfigShow.cbxTipoInst && this.ConfigShow.cbxTipoInst.show == true){
            this.cbxTipoInst = this.formFiltros?.controls.cbxTipoInst.value
            this.requestBody['cbxTipoInst'] = this.cbxTipoInst
        }

        if(this.ConfigShow.TipoBusquedaPeriodo && this.ConfigShow.TipoBusquedaPeriodo.show == true){

            this.tipobusqueda = this.formFiltros?.controls.TipoBusquedaPeriodo.value
            this.requestBody['tipobusqueda'] = this.tipobusqueda

            if(this.tipobusqueda == 1 ){
                this.mes_ini = this.formFiltros?.controls.mes.value
                this.year_Fin = this.formFiltros?.controls.anio.value
                if(this.ConfigShow.TipoBusquedaPeriodo.especial)
                {
                    this.mes_Fin = this.formFiltros?.controls.fechaMesFin.value
                    console.log("mes fin",this.mes_Fin)
                    if( this.mes_Fin === null)
                    {
                        this.appCommon.Notify("Ingrese mes final", 'warning');
                        this.ref.detectChanges();
                        return false;
                    }


                    if( (this.mes_Fin !== null &&  this.mes_ini !== null) && Number(this.mes_Fin) < Number(this.mes_ini))
                    {
                        this.appCommon.Notify("El mes inicial no puede ser mayor al final", 'warning');
                        this.ref.detectChanges();
                        return false;
                    }

                }

                if( this.mes_ini === null || this.year_Fin === null){
                    this.appCommon.Notify("Ingrese mes y año", 'warning');
                        this.ref.detectChanges();
                        return false;
                }
            }
            else if (this.tipobusqueda == 2){
                this.mes_ini = this.formFiltros?.controls.fechaIni.value
                this.year_Fin = this.formFiltros?.controls.fechaFin.value

                if( this.mes_ini === ''){
                    this.appCommon.Notify("Ingrese Fecha de inicio y fin", 'warning');
                        this.ref.detectChanges();
                        return false;
                }
            }
            this.requestBody['f_mes_ini'] = this.mes_ini
            this.requestBody['f_mes_fin'] = this.mes_Fin
            this.requestBody['f_year_fin'] = this.year_Fin
            //console.log("valores de formulario::", this.mes_ini, this.year_Fin )
        }
        if(this.ConfigShow.inpIncTamiz && this.ConfigShow.inpIncTamiz.show == true){
            this.inpIncTamiz = this.formFiltros?.controls.inpIncTamiz.value
            this.requestBody['inpIncTamiz'] = this.inpIncTamiz
        }

        if(this.ConfigShow.inpSexo && this.ConfigShow.inpSexo.show == true){
            this.inpSexo = this.formFiltros?.controls.inpSexo.value
            this.requestBody['inpSexo'] = this.inpSexo
        }
        if(this.ConfigShow.cbxUsuarioID && this.ConfigShow.cbxUsuarioID.show == true){
            this.cbxUsuarioID = this.formFiltros?.controls.cbxUsuarioID.value
            this.requestBody['cbxUsuarioID'] = this.cbxUsuarioID
            if( this.requestBody['cbxUsuarioID'] === 0){
                this.appCommon.Notify("Seleccione un Usuario", 'warning');
                    this.ref.detectChanges();
                    return false;
            }
        }
        if(this.ConfigShow.TipoBajaID && this.ConfigShow.TipoBajaID.show == true){
            this.TipoBajaID = this.formFiltros?.controls.TipoBajaID.value
            this.requestBody['TipoBajaID'] = this.TipoBajaID
        }
        if(this.ConfigShow.TipoBusquedaServicio && this.ConfigShow.TipoBusquedaServicio.show == true){
            this.TipoBusquedaServicio = this.formFiltros?.controls.TipoBusquedaServicio.value
            this.requestBody['TipoBusquedaServicio'] = this.TipoBusquedaServicio
        }




        if(this.ConfigShow.inpDesglose && this.ConfigShow.inpDesglose.show == true){
            this.inpDesglose = this.formFiltros?.controls.inpDesglose.value
            this.requestBody['inpDesglose'] = this.inpDesglose
        }
        this.requestBody['NivelActivo']= this.ActiveNivelID;
        this.requestBody['InstActiva'] = this.ActiveInstitucionID;
        this.requestBody['Cliente']= this.isCliente;
        this.requestBody['usuarioid']= this.usuarioid;
        console.log("ver los datos que se van a enviar",this.requestBody)

       this.GenerarDatosReporte.emit(this.requestBody)
    }
    }

    async verInstituciones(){
        this.appCommon.spinner(true);
        this.ref.detectChanges();
        this.reportsService
            .ListInstituciones(this.ActiveInstitucionID)
            .then(
                async(Default: any) => {
                 this.dataInstituciones = Default.dataresult                 ;
                  //console.log("data de instituciones:::", this.dataInstituciones);

                    await this.onCheckChangeBusqueda()
                    this.appCommon.spinner(false);
                    this.ref.detectChanges();
                            },
                error => {
                    //console.log('fail callback', error);
                    this.appCommon.spinner(false);
                    this.appCommon.Notify(error.data.message, 'error');
                    this.ref.detectChanges();
                }
            )
            .catch(e => {
                //console.log('catch', e);
                this.appCommon.spinner(false);
            });
      }

    async verUsuarios(){
    this.appCommon.spinner(true);
    this.ref.detectChanges();
    this.reportsService
        .ListUsuarios()
        .then(
            async(Default: any) => {
                this.dataUsuarios = Default;
                //console.log("data de usuarios:::", this.dataUsuarios);

                this.appCommon.spinner(false);
                this.ref.detectChanges();
                        },
            error => {
                //console.log('fail callback', error);
                this.appCommon.spinner(false);
                this.appCommon.Notify(error.data.message, 'error');
                this.ref.detectChanges();
            }
        )
        .catch(e => {
            //console.log('catch', e);
            this.appCommon.spinner(false);
        });
    }

    async verGrupoMedico(){
        this.appCommon.spinner(true);
        this.ref.detectChanges();
        this.reportsService
            .ListGrupoMedico()
            .then(
                async(Default: any) => {
                    this.dataGrupoMedico = Default;
                    //console.log("data de usuarios:::", this.dataUsuarios);

                    this.appCommon.spinner(false);
                    this.ref.detectChanges();
                            },
                error => {
                    //console.log('fail callback', error);
                    this.appCommon.spinner(false);
                    this.appCommon.Notify(error.data.message, 'error');
                    this.ref.detectChanges();
                }
            )
            .catch(e => {
                //console.log('catch', e);
                this.appCommon.spinner(false);
            });
        }

    async verTipoInst(){
        this.appCommon.spinner(true);
        this.ref.detectChanges();
        this.reportsService
            .ListaTipoInst()
            .then(
                async(Default: any) => {
                    this.dataTipoInstituciones = Default;
                    //console.log("data de usuarios:::", this.dataUsuarios);

                    this.appCommon.spinner(false);
                    this.ref.detectChanges();
                            },
                error => {
                    //console.log('fail callback', error);
                    this.appCommon.spinner(false);
                    this.appCommon.Notify(error.data.message, 'error');
                    this.ref.detectChanges();
                }
            )
            .catch(e => {
                //console.log('catch', e);
                this.appCommon.spinner(false);
            });
    }

    async OcultarFiltrosForm(){
        this.MostrarFiltros.emit(false)
    }

    /*
    Estos metodos los meti aqui para que se entienda mejor como hacer este tipo de llamadas pero lo correcto es q esten en un service para q se puedan consumir desde cualquier otro componente
    */
    getNivelID(){
        return new Promise((resolve, reject) => {
            this.userService.vNivelID$.subscribe(data=>{
                //console.info("Nivel ID: ",data);
                if(data==null){
                    reject(0);
                }else{
                    resolve(data);
                    //this.ActiveNivelID = data
                }
            });
        });
    }

    getInstitucionID(){
        return new Promise((resolve, reject) => {
            this.userService.vInstitucionID$.subscribe(data=>{
                //console.info("Institucion ID: ",data);
                if(data==null){
                    reject(0);
                }else{
                    resolve(data);
                    //this.ActiveInstitucionID = data
                }
            });
        });
    }

    getIsCliente(){
        return new Promise((resolve, reject) => {
            this.userService.isCliente$.subscribe(data=>{
                //console.info("Is Cliente: ",data);
                if(data==null){
                    reject(0);
                }else{
                    resolve(data);
                    //this.isCliente = data
                }
            });
        });
    }
    getUsuarioId(){
        return new Promise((resolve, reject) => {
            this.userService.vUsuarioID$.subscribe(data=>{
                //console.info("Is Cliente: ",data);
                if(data==null){
                    reject(0);
                }else{
                    resolve(data);
                    //this.usuarioId = data

                }
            });
        });
    }


}
