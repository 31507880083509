import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { UserService } from '@modules/auth/services';
import { environment } from 'environments/environment';
import { Default } from '../models';

@Injectable({ providedIn: 'root' })
export class SearchService {
    API_URL_COMMOMS = environment.api.commons;
    API_URL_OPERATIONS = environment.api.operations;

    constructor(private http: HttpClient, private userService: UserService) {}

    getSearch$(): Observable<{}> {
        return of({});
    }

    async RealizaBusqueda(requestBody: any) {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        return new Promise((resolve, reject) => {
            this.http
                .post<Default>(
                    `${this.API_URL_OPERATIONS}/operations/busqueda`,
                    {
                        ...requestBody,
                        UsuarioSesionId: this.userService.vUsuarioID,
                    },
                    { headers }
                )
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    // console.info('RESPONSE: ', resp.data);
                    resolve(resp.data);
                });
        });
    }
    async downloadTestPdf(requestBody) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_COMMOMS}/getfile`, requestBody).subscribe(
                resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error => {
                    const resp = {
                        status: 'fail',
                        data: {
                            message: error.message,
                        },
                    };
                    reject(resp);
                }
            );
        });
    }
}
