import { ChangeDetectionStrategy, Component, ElementRef, ViewChild, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CustomersService } from '@modules/customers/services';

@Component({
    selector: 'sb-pickup-tab',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './pickup-tab.component.html',
    styleUrls: ['pickup-tab.component.scss'],
})
export class PickupTabComponent {

    
    @Input() combos:any;
    @Input() radios:any;
    @Input() checkboxes:any;
    @Input() tabs:any;

    horarios: string[] = ['11:00 AM', '1:00 PM'];
    dataDelCliente: any;

    
    message = 'Conexión a tab pickup!';

    @ViewChild('pickupCLick')
    pickupCLick!: ElementRef<HTMLElement>;

    //TodOs Formulario
    @Input() public formRec!: FormGroup;

    //ToDos campos invalidos
    isValidFieldRecoleccion( field: string ): boolean | null{
        return this.formRec.controls[field].errors 
        && this.formRec.controls[field].touched;
    }


    constructor() {}
    triggerFalseClick() {
        const el: HTMLElement = this.pickupCLick.nativeElement;
        el.click();
    }
    
}
