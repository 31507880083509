import { ChangeDetectionStrategy, Component,ChangeDetectorRef, ElementRef, OnInit,Output, ViewChild, EventEmitter, OnDestroy, AfterViewInit, Input } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AppCommonService } from '@modules/app-common/services';
import { ReportsService } from '@modules/reports/services';
@Component({
    selector: 'gk-search-filter-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './search-filter-component.component.html',
    styleUrls: ['search-filter-component.component.scss'],
})
export class SearchFilterComponentComponent implements OnInit {
    @Output("generarConsultaFiltros") GenerarDatosReporte: EventEmitter<any> = new EventEmitter();
    @Input() TipoFiltros = 0
    readonly searchType = [{"value": '2', "text":'Privado'}, {"value": '1',"text":'Gobierno'}, {"value": '3',"text":'Gobierno y Privado'}];
    readonly searchPeriodo = [{"value": '0', "text":'Todas las fechas disponibles'}, {"value": '1',"text":'Por mes/año'}, {"value": '2',"text":'Por intervalo'}];
    formFiltros: FormGroup
    mes_ini:String=""
    year_Fin:String =""
    tipobusqueda:number= 0
    tipoinstitucion:number= 0
    calif:number= 0
    requestBody: any

    periodoFiltrado:any
    arrayFiltrado: any
    arrayFiltradoInstituciones:any;
    dataInstituciones: any;
    years: any = [];
    dataMeses = [
        {
            id: '1',
            mes: 'Enero',
        },
        {
            id: '2',
            mes: 'Febrero',
        },
        {
            id: '3',
            mes: 'Marzo',
        },
        {
            id: '4',
            mes: 'Abril',
        },
        {
            id: '5',
            mes: 'Mayo',
        },
        {
            id: '6',
            mes: 'Junio',
        },
        {
            id: '7',
            mes: 'Julio',
        },
        {
            id: '8',
            mes: 'Agosto',
        },
        {
            id: '9',
            mes: 'Septiembre',
        },
        {
            id: '10',
            mes: 'Octubre',
        },
        {
            id: '11',
            mes: 'Noviembre',
        },
        {
            id: '12',
            mes: 'Diciembre',
        },
      ];
    txtTipoBusqueda: string = "";
    inpIncTamiz: any;
    constructor(
        public appCommon: AppCommonService,
        private ref: ChangeDetectorRef,
        private reportsService:ReportsService,
        public formBuilder: FormBuilder,
    ) {
        this.formFiltros = formBuilder.group({
            Institucion_Id:[''],
            TipoBusqueda: this.searchType[0].value,
            TipoBusquedaPeriodo:this.searchPeriodo[0],
            inpIncTamiz: '1',
            fechaIni:[''],
            fechaFin:[''],
            mes: [''],
            anio: ['']
          });

    }
    ngOnInit() {
        this.years = [];
        for (let i: any = 2014; i <= new Date().getFullYear(); i++) {
            this.years.push({ year: i });

            console.log(this.TipoFiltros)
    }

    this.verInstituciones()  // this.actionForm = 'createCatalog'

    this.arrayFiltrado = [
        {
          TipoBusqueda :  [false]
            }
        ]

        this.periodoFiltrado = [
          {
            TipoBusquedaPeriodo :  [false]
              }
          ]
    }

    onCheckChangeBusqueda() {
        let Gobierno_bit = 0
        this.txtTipoBusqueda="Hospital"
        const NombreBusqueda = this.formFiltros.get('TipoBusqueda')?.value

        if(NombreBusqueda == "Gobierno")
        {
            this.txtTipoBusqueda="Unidad Medica"
            Gobierno_bit = 1
        }

        if(NombreBusqueda == "Gobierno y Privado")
        {
          Gobierno_bit = 3
        }

        //console.log("Buesqueda", NombreBusqueda)

       this.arrayFiltrado[0].TipoBusqueda = Gobierno_bit
       this.FiltrarInstituciones()
      }

      FiltrarInstituciones(){
        //console.log("array completo",this.arrayFiltrado[0].TipoBusqueda )

        if ( this.arrayFiltrado[0].TipoBusqueda == 3) {
          this.arrayFiltradoInstituciones = this.dataInstituciones
          //console.log("Data Gobierno y privado", this.arrayFiltradoInstituciones )
        }
        this.arrayFiltradoInstituciones = this.dataInstituciones.filter(institucion => institucion.Gobierno_bit == this.arrayFiltrado[0].TipoBusqueda)

        //console.log("array arrayFiltradoInstituciones: ", this.arrayFiltradoInstituciones)

      }

      async generarFiltro(form:any){

        if(this.formFiltros.controls.TipoBusqueda.value == 'Privado' ){
            this.tipoinstitucion = 2
        }
        if(this.formFiltros.controls.TipoBusqueda.value == 'Gobierno' ){
            this.tipoinstitucion = 1
        }

        if(this.formFiltros.controls.TipoBusquedaPeriodo.value == 'Por mes/año' ){
            this.tipobusqueda = 1
            this.year_Fin = this.formFiltros.controls.anio.value

        }
        if(this.formFiltros.controls.TipoBusquedaPeriodo.value == 'Por intervalo' ){
            this.tipobusqueda = 2
            this.mes_ini = this.formFiltros.controls.fechaIni.value
            this.year_Fin = this.formFiltros.controls.fechaFin.value
        }
        this.inpIncTamiz = this.formFiltros.controls.inpIncTamiz.value

        this.requestBody = {
            institucionid: this.formFiltros.controls.Institucion_Id.value,
            tipoinstitucion: this.tipoinstitucion,
            tipobusqueda: this.tipobusqueda ,
            f_mes_ini: this.mes_ini,
            f_year_fin: this.year_Fin,
            inpIncTamiz: this.inpIncTamiz
        };
        console.log("datos a enviar",this.requestBody)

       this.GenerarDatosReporte.emit(this.requestBody)
    }

    async verInstituciones(){
        this.appCommon.spinner(true);
        this.ref.detectChanges();
        this.reportsService
            .ListInstituciones(600)
            .then(
                async(Default: any) => {
                 this.dataInstituciones = Default.dataresult                 ;
                  //console.log("data de instituciones:::", this.dataInstituciones);

                  this.onCheckChangeBusqueda()
                    this.appCommon.spinner(false);
                    this.ref.detectChanges();

                  ;
                            },
                error => {
                    //console.log('fail callback', error);
                    this.appCommon.spinner(false);
                    this.appCommon.Notify(error.data.message, 'error');
                    this.ref.detectChanges();
                }
            )
            .catch(e => {
                //console.log('catch', e);
                this.appCommon.spinner(false);
            });
      }

}
