<mat-dialog-content class="mat-typography">

    <form [formGroup]="formMaternidad" (ngSubmit)="saveMaternidad()">
        <div class="row">
            <div class="col-3"></div>
            @if(!paquete){
                <h2 mat-dialog-title class="col-6">Nuevo paquete maternidad</h2>
            }@else{
                <h2 mat-dialog-title class="col-6">Editar paquete maternidad</h2>
            }
            <div class="col-3"></div>
        </div>
        <div class="row">

            <label class="control-label col-4 mb-2" >Tipo:</label>
            <label class="control-label col-4 " >Dias:</label>
            <label class="control-label col-4 " >Costo:</label>
            <div class="col-md-4 mb-3" >
                <span class="form-text text-danger my-1" *ngIf="isValidFieldMaternidad('TipoPaqueteId')">
                    <b>Este campo es requerido</b>
                </span>
                <select  class="form-control"  formControlName="TipoPaqueteId" >
                    <option value="">Seleccione un tipo</option>
                    <option
                            *ngFor="let tipo of combos.TipoProgramaMaternidad"
                            [value]="tipo.Id"
                            >
                            {{ tipo.Tipo }}
                    </option>
                </select>
            </div>
            <div class="col-md-4" >
                <span class="form-text text-danger my-1" *ngIf="isValidFieldMaternidad('Dias')">
                    <b>Este campo es requerido</b>
                </span>
                <input type="number" class="form-control" min="0" formControlName="Dias" >
            </div>
            <div class="col-md-4">
                <span class="form-text text-danger my-1" *ngIf="isValidFieldMaternidad('Costo')">
                    <b>Este campo es requerido</b>
                </span>
                <input type="number"  class="form-control ms-1" formControlName="Costo">
            </div>
            <label class="control-label col-12 mb-3" >Comentarios:</label>
            <div class="col-10">
                <input type="text"  class="form-control ms-1" formControlName="Comentarios">
            </div>
            <div class="col-2"></div>
        </div>
    
        <div class="full-width-element full-height-element">
            <mat-divider class="mt-4"></mat-divider>
            <mat-dialog-actions align="end" class="gris py-4">
                <button cdkFocusInitial class="btn btn-success mx-2 mt-3 " type="submit">
                    <mat-icon class="tamIconB">save</mat-icon>
                    Guardar
                </button>
                <button [mat-dialog-close] class="btn btn-secondary mx-2 mt-3">
                    <mat-icon class="tamIconB">keyboard_arrow_left</mat-icon>
                    Regresar
                </button>
            </mat-dialog-actions>
        </div>
    </form>
</mat-dialog-content>