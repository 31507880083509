import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'gk-materiales-tab',
  templateUrl: './materiales-tab.component.html',
  styleUrls: ['./materiales-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MaterialesTabComponent {


  //ToDoS variables locales
  @Input() combos:any;
  @Input() radios:any;
  @Input() checkboxes:any;
  @Input() tabs:any;
  @Input() public formMat!: FormGroup;

  //ToDos campos invalidos
  isValidFieldMateriales( field: string ): boolean | null{
    return this.formMat.controls[field].errors 
    && this.formMat.controls[field].touched;
  }

  cargarDatos() {
    this.formMat.patchValue({
      EnvioNombre: this.tabs.EnvioMateriales.Valores.EnvioNombre,
      EnvioReferencia: this.tabs.EnvioMateriales.Valores.EnvioReferencia,
      EnvioDireccion: this.tabs.EnvioMateriales.Valores.EnvioDireccion,
      EnvioColonia: this.tabs.EnvioMateriales.Valores.EnvioColonia,
      EnvioCiudad: this.tabs.EnvioMateriales.Valores.EnvioCiudad,
      EnvioCodigoPostal: this.tabs.EnvioMateriales.Valores.EnvioCodigoPostal
    });
  }
}
