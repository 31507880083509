<div class="row mt-5 ms-3 me-3 mb-0">
    <div class="col-md-12">
        <div class="row">
            <div class="col-md-12 text-left">
                <mat-stepper orientation="vertical" [selectedIndex]="selectedIndex" #stepper
                    (click)="onStepChange(1)">
                    <mat-step>
                        <ng-template matStepLabel>
                            <span>Eventos ({{tabs.BitacoraEventos.Tablas.Eventos.length}})</span>
                        </ng-template>
                        <ng-template matStepContent>
                            <div class="row">
                                <div class="col-10"></div>
                                <div class="col-2">
                                    <input type="text" class="form-control mt-2" placeholder="Buscar..." (input)="onSearch($event.target.value)" (click)="stopEvent($event)" />
                                </div>
                            </div>  
                            <table  class="table table-striped table-bordered row-border hover nowrap w-100 mt-4" style="width:100%;  margin-left: 0;">
                                <thead class="ttdblue">
                                    <tr>
                                        <th class="text-left">EVENTO</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let evento of paginatedEvents">
                                        <td>
                                            {{evento.Fecha | date: 'dd-MMM-yyyy': "UTC"}} - {{evento.Usuario}} realizó el {{evento.Tipo_Relacion}}
                                            <b>{{evento.Descripcion}}</b>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <div class="d-flex justify-content-between align-items-center mt-3">
                                <div>
                                    Mostrando {{startIndex + 1}} a {{endIndex}} de {{filteredEvents.length}} registros
                                </div>
                                <mat-paginator [length]="filteredEvents.length"
                                            [pageSize]="selectedPageSize"
                                            [pageSizeOptions]="pageSizes"
                                            (page)="onPageChange($event)"
                                            (click)="stopEvent($event)">
                                </mat-paginator>
                            </div>

                        </ng-template>


                    </mat-step>
                </mat-stepper>

                
        
            </div>
        </div>
    </div>
</div>
