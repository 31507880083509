import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'gk-facturacion-tab',
  templateUrl: './facturacion-tab.component.html',
  styleUrls: ['./facturacion-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FacturacionTabComponent {
  
  //ToDoS variables locales
  @Input() combos:any;
  @Input() radios:any;
  @Input() checkboxes:any;
  @Input() tabs:any;
  @Input() public formFact!: FormGroup;

  //ToDos campos invalidos
  isValidFieldFacturacion( field: string ): boolean | null{
    return this.formFact.controls[field].errors 
    && this.formFact.controls[field].touched;
  }

  cargarDatos() {
    this.formFact.patchValue({
      FacturacionEmpresa: this.tabs.Facturacion.Valores.FacturacionEmpresa,
      FacturacionTipoClienteTC: this.tabs.Facturacion.Valores.FacturacionTipoClienteTC,
      FacturacionNombre: this.tabs.Facturacion.Valores.FacturacionNombre,
      FacturacionDireccion: this.tabs.Facturacion.Valores.FacturacionDireccion,
      FacturacionColonia: this.tabs.Facturacion.Valores.FacturacionColonia,
      FacturacionCiudad: this.tabs.Facturacion.Valores.FacturacionCiudad,
      FacturacionCodigoPostal: this.tabs.Facturacion.Valores.FacturacionCodigoPostal,
      FacturacionRFC: this.tabs.Facturacion.Valores.FacturacionRFC,
      FacturacionDiasPago: this.tabs.Facturacion.Valores.FacturacionDiasPago,
      FacturacionComentarios: this.tabs.Facturacion.Valores.FacturacionComentarios,
      FacturacionVersionCFDI: this.tabs.Facturacion.Valores.FacturacionVersionCFDI,
      FacturacionRegimenFiscal: this.tabs.Facturacion.Valores.FacturacionRegimenFiscal,
      FacturacionUsoCFDI: this.tabs.Facturacion.Valores.FacturacionUsoCFDI,
      FacturacionFormaPago: this.tabs.Facturacion.Valores.FacturacionFormaPago,
      FacturacionConceptoFactura: this.tabs.Facturacion.Valores.FacturacionConceptoFactura, 
      FacturacionMetodoPago: this.tabs.Facturacion.Valores.FacturacionMetodoPago,
      FacturacionNumeroCuenta: this.tabs.Facturacion.Valores.FacturacionNumeroCuenta,
      FacturacionRFCBanco: this.tabs.Facturacion.Valores.FacturacionRFCBanco,
      FacturacionNumeroCuentaBanco: this.tabs.Facturacion.Valores.FacturacionNumeroCuentaBanco
    });
  }

  

}
