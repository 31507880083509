import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Breadcrumb } from '@modules/navigation/models';

@Component({
    selector: 'sb-breadcrumbs',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent {
    @Input() breadcrumbs: Breadcrumb[] = [];
}
