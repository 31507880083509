import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@modules/auth/services';

@Component({
    selector: 'sb-top-nav-user',
    templateUrl: './top-nav-user.component.html',
    styleUrls: ['top-nav-user.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopNavUserComponent {
    user$ = this.userService.user$;

    constructor(public userService: UserService, private router: Router) {
        this.userService.validaSession();
    }

    logout() {
        // this.userService.logoutCognito();
        this.router.navigateByUrl('/auth/logout');
    }
}
