import { Injectable } from '@angular/core';
import { Observable, map, of, observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import type { Default, MedicalRecordsTk, Notifications, ResponseGetMedicalRecordsTk, ResponseGetNotifications } from '../models/medical-records.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserService } from '../../auth/services';
import { ResponseGetMedicalRecordsTKCliente } from '../models/medical-recordsTkClientes.model';
import type { AddProof, AsesoriaDetail, CorreoRequest, CorreosPre, GetResponseAsesoriaDetail, GetResponseCorreoRequest, GetResponseSpecialProof, PostResponseAddProof, postResponseProofRequest, postResponseSolicitudInfo, PreliminarSave, ProofRequest, putPreliminarSave, putSolicitudInfo, putTextoCorreo, ResponseGetCorreosPre, ResponsePostPreliminarSave, ResponsePostTextoCorreos, SolicitudInfo, SpecialProof } from '../models';

@Injectable({
    providedIn:'root'
})
export class MedicalRecordsService {

    API_URL_MEDICAL_COMPONENTS = environment.api.medicalRecordsComponents;

    constructor(
        private http: HttpClient,  private userService: UserService,
    ) {}

    getmedicalRecordsTK(pacienteId: number):Observable<MedicalRecordsTk> {
        const token = this.userService.AccessToken || localStorage.getItem('idTokenGenomi');
        const headers = new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });
        const url= `${this.API_URL_MEDICAL_COMPONENTS}/expediente/detallePXTK/${pacienteId}`;
        return this.http.get<ResponseGetMedicalRecordsTk>( url, { headers }).pipe(
            map( resp => resp.data )
        );
    }

    getmedicalRecordsTKCliente(requestBody) {
        const token = this.userService.AccessToken || localStorage.getItem('idTokenGenomi');
        const headers = new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });
        const url= `${this.API_URL_MEDICAL_COMPONENTS}/expediente/detallePXTKcliente`;
        return this.http.post<ResponseGetMedicalRecordsTKCliente>( url, requestBody ,{ headers }).pipe(
            map( resp => resp.data )
        );
    }


    async InsertCommentPx(requestBody) {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({
                Authorization: `Bearer ${this.userService.AccessToken}`,
            });
    
            this.http.post<Default>(`${this.API_URL_MEDICAL_COMPONENTS}/expediente/comentario/insertar`, requestBody, { headers }).subscribe(resp => {
                if (resp.status === 'fail') {
                    console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                resolve(resp.data);
            });
        });
    }

    clientNotification(id: number | string):Observable<Notifications>{
        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_MEDICAL_COMPONENTS}/expediente/notifica-cliente/${id}`;
        return this.http.get<ResponseGetNotifications>(url, { headers }).pipe(
            map( resp => resp.data)
        )
    }

    getCorreosPreliminar(id: number | string): Observable<CorreosPre>{
        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_MEDICAL_COMPONENTS}/expediente/detalledx/${id}`;
        return this.http.get<ResponseGetCorreosPre>(url, { headers }).pipe(
            map( resp => resp.data)
        )
    }

    postTextoCorreo(texto: putTextoCorreo){
        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_MEDICAL_COMPONENTS}/expediente/detalledx/textocorreo`;
        return this.http.post<ResponsePostTextoCorreos>(url, texto , { headers }).pipe(
            map( resp => resp.data)
        )
    }

    postPreliminarSave(texto: putPreliminarSave): Observable<PreliminarSave>{
        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_MEDICAL_COMPONENTS}/expediente/detalledx/prelim/save`;
        return this.http.post<ResponsePostPreliminarSave>(url, texto , { headers }).pipe(
            map( resp => resp.data )
        )
    }

    postSolicitudInfo(solicitud: putSolicitudInfo):Observable<SolicitudInfo> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_MEDICAL_COMPONENTS}/expediente/detalledx/solicitud/get`;
        return this.http.post<postResponseSolicitudInfo>(url, solicitud , { headers }).pipe(
            map( resp => resp.data)
        )
    }

    addProof(prueba): Observable<AddProof>{
        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_MEDICAL_COMPONENTS}/expediente/detalledx/solicitud/peasign/add`;
        return this.http.post<PostResponseAddProof>(url, prueba , { headers }).pipe(
            map( resp => resp.data)
        )
    }

    deleteProof(prueba): Observable<AddProof>{
        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_MEDICAL_COMPONENTS}/expediente/detalledx/solicitud/peasign/del`;
        return this.http.post<PostResponseAddProof>(url, prueba , { headers }).pipe(
            map( resp => resp.data)
        )
    }

    getCorreosRequest(id: number): Observable<CorreoRequest> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_MEDICAL_COMPONENTS}/expediente/detalledx/solicitud/correo/get/${id}`;
        return this.http.get<GetResponseCorreoRequest>(url, { headers })
        .pipe(
            map( resp => resp.data)
        )
    }

    postCorreoAsignados(correo):Observable<Notifications>{
        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_MEDICAL_COMPONENTS}/expediente/detalledx/solicitud/correo/send`;
        return this.http.post<ResponseGetNotifications>(url, correo , { headers })
        .pipe(
            map( resp => resp.data)
        )
    }

    getPruebaEspecial(id: number): Observable<SpecialProof> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_MEDICAL_COMPONENTS}/expediente/detalledx/pruebasesp/desc/${id}`;
        return this.http.get<GetResponseSpecialProof>(url, { headers })
        .pipe(
            map( resp => resp.data)
        )
    }

    postProofRequest(prueba):Observable<ProofRequest>{
        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_MEDICAL_COMPONENTS}/expediente/detalledx/pruebasesp/solicitar`;
        return this.http.post<postResponseProofRequest>(url, prueba , { headers })
        .pipe(
            map( resp => resp.data)
        )
    }

    getSolicitudAsesoriaD(id: number | string): Observable<AsesoriaDetail> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_MEDICAL_COMPONENTS}/expediente/detalledx/asesoria/det/${id}`;
        return this.http.get<GetResponseAsesoriaDetail>(url, { headers })
        .pipe(
            map( resp => resp.data)
        )
    }

}