<div class="tab-content">
    <div class="container">
        <div class="row">
            <div class="col text-center">
                <h2 class="mt-2">Estudios Realizados</h2>
            </div>
        </div>
    
        <div class="row">
            <table class="table table-striped table-bordered " style="width:100%">
                <thead class="ttdblue">
                    <tr>
                        <th class="text-start">INSTITUCION</th>
                        <th class="text-start">TIPO DE ESTUDIO</th>
                        <th class="text-center">Nº DE PAPEL FILTRO</th>
                        <th class="text-start">ESTATUS ACTUAL</th>
                        <th class="text-end">FECHA CAPTURA</th>
                        <th class="text-end">FECHA RESULTADO</th>
                        <th class="text-center" >RESULTADO</th>
                        <th class="text-center">DETALLE</th>
                    </tr>
                </thead>
                <tbody>
                    @for (data of datosEstudio; track $index) {
                        <tr>
                            <td class="text-start">{{data.Nombre_Comercial}}</td>
                            <td class="text-start">
                                {{data.Nombre_estudio}}
                                @if (data.IDTxt) {
                                    <br>
                                    <a class="txtlinka ms-1 my-1" (click)="verDetalleSolicitud(data.ID)">{{ data.IDTxt }}</a>
                                }
                                @if (data.ResultadoOrigTxt) {
                                    <br>
                                    <a class="txtlinka mA"> <fa-icon [icon]="['fas', 'file-alt']" class="iconTam" ></fa-icon> {{ data.ResultadoOrigTxt }}</a>
                                }
                            </td>
                            <td class="text-center"><label class="txtlinka">{{data.codigo}}</label></td>
                            <td class="text-start">{{data.Estatus}}</td>
                            <td class="text-end">{{data.fecha_captura | date: 'dd-MMM-yyyy': "UTC" }}</td>
                            <td class="text-end">{{data.fecha_resultado_gk | date: 'dd-MMM-yyyy': "UTC"}}</td>
                            <td class="text-center">{{data.Calificacion}}</td>
                            <td>
                                <button type="button" class="btn btn-outline-success" (click)="cargarPF(data.codigo)">Mostrar</button>
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    
    </div>
</div>

