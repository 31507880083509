<div class="container-fluid {{clasePadding}}">
    <!----visor pdf-->
    <ng-container *ngIf="bitVisor">
        <br>
        <div class="light-title text-center" *ngIf="!isCliente">
            {{Language('PapelFiltro')}} {{DatosVisor.Codigo}} ({{DatosVisor.Kit}}) - {{DatosVisor.nombre_comercial}}
            <img  *ngIf="URLImagen != ''" class="imgpf" [src]="URLImagen" width="5%" (click)="MostrarImgPF()" title="{{Language('verdetalle')}}">
            <small class="ant-input-lg">
                CADUCIDAD: {{DatosVisor.Fecha_Caducidad | date: "MMM-yyyy": "UTC"}}
            </small>
        </div>
        <div class="light-title text-center" *ngIf="isCliente">{{Language('PapelFiltro')}} {{DatosVisor.Codigo}} - {{DatosVisor.nombre_comercial}}</div>
        <br>

        <div class="row pf-details" *ngIf="!visorSimple">
            <div class="col-sm-4">
                <div class="line">
                    <div class="row">
                        <div class="col-sm-6 label-t">
                            <span class="field-caption">{{Language('PacienteID')}}</span>
                        </div>
                        <div class="col-sm-6">
                            <span class="field-value">{{DatosVisor.paciente_id}}</span>
                        </div>
                    </div>
                </div>

                <div class="line">
                    <div class="row">
                        <div class="col-sm-6 label-t">
                            <span class="field-caption">{{Language('codigo')}}</span>
                        </div>
                        <div class="col-sm-6">
                            <span class="field-value">{{DatosVisor.Codigo}}</span>
                        </div>
                    </div>
                </div>

                <div class="line">
                    <div class="row">
                        <div class="col-sm-6 label-t">
                            <span class="field-caption">{{Language('institucion')}}</span>
                        </div>
                        <div class="col-sm-6">
                            <span class="field-value">{{DatosVisor.nombre_comercial}}</span>
                        </div>
                    </div>
                </div>

                <div class="line">
                    <div class="row">
                        <div class="col-sm-6 label-t">
                            <span class="field-caption">{{Language('contrato')}}</span>
                        </div>
                        <div class="col-sm-6">
                            <span class="field-value">{{DatosVisor.contrato}}</span>
                        </div>
                    </div>
                </div>

                <div class="line">
                    <div class="row">
                        <div class="col-sm-6 label-t">
                            <span class="field-caption">{{Language('nombreBebe')}}</span>
                        </div>
                        <div class="col-sm-6">
                            <span class="field-value">{{DatosVisor.nombreBebe}}</span>
                        </div>
                    </div>
                </div>

                <div class="line">
                    <div class="row">
                        <div class="col-sm-6 label-t">
                            <span class="field-caption">{{Language('fechaMuestra')}}</span>
                        </div>
                        <div class="col-sm-6">
                            <span class="field-value"> {{DatosVisor.Fecha_hora_toma | date: "dd-MM-yyyy HH:mm": "UTC"}}</span>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-sm-4">

                <div class="line">
                    <div class="row">
                        <div class="col-sm-6 label-t">
                            <span class="field-caption">{{Language('idLaboratorio')}}</span>
                        </div>
                        <div class="col-sm-6">
                            <span class="field-value">{{DatosVisor.ps_id}}</span>
                        </div>
                    </div>
                </div>

                <div class="line">
                    <div class="row">
                        <div class="col-sm-6 label-t">
                            <span class="field-caption">{{Language('remision')}}</span>
                        </div>
                        <div class="col-sm-6">
                            <span class="field-value">{{DatosVisor.nota_id}}</span>
                        </div>
                    </div>
                </div>

                <div class="line">
                    <div class="row">
                        <div class="col-sm-6 label-t">
                            <span class="field-caption">{{Language('factura')}}</span>
                        </div>
                        <div class="col-sm-6">
                            <span class="field-value">{{DatosVisor.factura}}</span>
                        </div>
                    </div>
                </div>

                <div class="line">
                    <div class="row">
                        <div class="col-sm-6 label-t">
                            <span class="field-caption">{{Language('surtimiento')}}</span>
                        </div>
                        <div class="col-sm-6">
                            <span class="field-value">{{DatosVisor.orden_surtimiento}}</span>
                        </div>
                    </div>
                </div>

                <div class="line">
                    <div class="row">
                        <div class="col-sm-6 label-t">
                            <span class="field-caption">{{Language('nombreMadre')}}</span>
                        </div>
                        <div class="col-sm-6">
                            <span class="field-value">{{DatosVisor.nombreMadre}}</span>
                        </div>
                    </div>
                </div>
                <div class="line" *ngIf="DatosVisor.cartasSCtabla!==''">
                    <div class="row">
                        <div class="col-sm-6 label-t">
                            <span class="field-caption">{{Language('cartaSC')}}</span>
                        </div>
                        <div class="col-sm-6">
                            <span class="field-value" *ngIf="DatosVisor.cartasSCtabla === 0">En Proceso</span>
                            <gk-cf-pdf-viewer [url]="DatosVisor.cartaSCurl" [file]="DatosVisor.cartaSCfile" display="Ver" clase="txtlinka bold" *ngIf="DatosVisor.cartasSCtabla !== 0"></gk-cf-pdf-viewer>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-sm-4 pf-preview" *ngIf="URLImagen != ''">
                <img class="imgpf" [src]="URLImagen" width="75%" (click)="MostrarImgPF()" title="{{Language('verdetalle')}}">
            </div>

            <div class="line">
                <div class="row">
                    <div class="col-md-1"></div>
                    <div class="col-sm-5">
                        <span class="field-value">{{DatosVisor.texto1}}</span>
                    </div>
                </div>
            </div>

            <div class="line">
                <div class="row">
                    <div class="col-md-1"></div>
                    <div class="col-sm-5">
                        <span class="field-value">{{DatosVisor.texto2}}</span>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <div class="pf-table" *ngIf="!visorSimple">
            <div class="row">
                <div class="col-md-12 text-left">
                    <table width="100%" class="table table-striped table-border border mt-3" *ngIf="DatosVisor.ArrayEstatus?.length > 0">
                        <thead class="ttdblue text-uppercase">
                            <tr>
                                <th class="text-left">{{Language('estatus')}}</th>
                                <th class="text-center">{{Language('fecha')}}</th>
                                <th class="text-left">{{Language('comentarios')}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of DatosVisor.ArrayEstatus">
                                <td class="text-left">{{data.estatus}}</td>
                                <td class="text-center">{{ data.fecha | date: "dd-MMM-yyyy HH:mm": "UTC"}}</td>
                                <td class="text-left">{{data.comentarios}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </ng-container>
    <!---forma de captura-->
    <form [formGroup]="formFiltros" #f="ngForm" (ngSubmit)="generarFiltro(f)" class="pf-container">
        <div class="col-md-12">
            <div class="row" *ngIf="bitVisor">
                <div class="col-md-12 text-center">
                    <div style="margin-top: 5px;font-size: 30px;color:#000000d9"><br><br>Detalle del Papel Filtro<br><br></div>
                </div>
            </div>
            <div class="row" *ngIf="vRazon1!='' && !visor">
                <div class="col-md-12 text-center">
                    <div style="margin-top: 5px;font-size: 30px;color: red;"><br><b>{{vRazon1}}</b><br><br></div>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col">
                    <div class="row">
                        <div class="col-md-3 col-xs-12 mt-2">
                            <label class="{{claseLabel}}">{{Language('captura.nombreApellidoBebe')}}</label>
                            <div class="row">
                                <div nz-col class="gutter-row" [nzSpan]="24">
                                    <nz-form-item>
                                        <nz-form-control>
                                            <input nz-input placeholder="{{Language('captura.ingreseNombreRN')}}" formControlName="nombre" nzSize="large" type="text" />
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                                <div nz-col class="gutter-row mt-3" [nzSpan]="24">
                                    <nz-form-item>
                                        <nz-form-control>
                                            <input nz-input placeholder="{{Language('captura.ingreseApellidos')}}" formControlName="Apellidos" nzSize="large" type="text " />
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5 mt-2">
                            <div class="row">
                                <div class="col-sm">
                                    <label class="{{claseLabel}}">{{Language('captura.sexo')}}</label>
                                    <div class="row">
                                        <div nz-col class="gutter-row" [nzSpan]="24">
                                            <div class="d-flex mb-2" *ngFor="let type of searchType">
                                                <ng-container>
                                                    <div class="col-8">
                                                        <h5>{{Language('captura.sexoTipo')[type.id]}}</h5>
                                                    </div>
                                                    <div class="col-4">
                                                        <input class="icheckbox_square-blue" type="radio" name="inpSexo" formControlName="inpSexo" value={{type.value}} />
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm">
                                    <label class="{{claseLabel}}">{{Language('captura.semanasGest')}}</label>
                                    <div class="row">
                                        <div nz-col class="gutter-row" [nzSpan]="24">
                                            <nz-form-item>
                                                <nz-form-control>
                                                    <input nz-input placeholder="" formControlName="semGestacion" nzSize="large" type="number" />
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm">
                                    <label class="{{claseLabel}}">{{Language('captura.pesoKg')}}</label>
                                    <div class="row">
                                        <div nz-col class="gutter-row" [nzSpan]="24">
                                            <nz-form-item>
                                                <nz-form-control>
                                                    <input nz-input placeholder="" formControlName="peso" nzSize="large" type="text" />
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-xs-12 mt-2">
                            <div class="col-12">
                                <nz-form-label>{{Language('captura.registroInstitucion')}}</nz-form-label>
                                <div class="row">
                                    <div nz-col class="gutter-row" [nzSpan]="24">
                                        <nz-form-item>
                                            <nz-form-control>
                                                <input nz-input placeholder="" formControlName="registroInst" nzSize="large" type="text" />
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <nz-form-label>{{Language('captura.muestraTomadaProf')}}</nz-form-label>
                                <div class="row">
                                    <div nz-col class="gutter-row" [nzSpan]="24">
                                        <nz-form-item>
                                            <nz-form-control>
                                                <input nz-input placeholder="" formControlName="muestraProf" nzSize="large" type="text" />
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br><br><br><br>
            <div class="row">
                <div class="col-md-3">
                    <label class="{{claseLabel}}">{{Language('captura.fechaNacimiento')}}</label>
                    <div class="row">
                        <div class="col-md-9 col-sm">
                            <div class="row">
                                <div nz-col class="gutter-row" [nzSpan]="24">
                                    <nz-form-item>
                                        <nz-form-control>
                                            <nz-date-picker nzFormat="yyyy-MM-dd HH:mm:ss" [nzDisabledDate]="disabledDate" [nzShowTime]="{ nzDefaultOpenValue: timeDefaultValue }" formControlName="fechaNac"></nz-date-picker>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm">
                            <div class="row">
                                <div nz-col class="gutter-row" [nzSpan]="24">
                                    <div class="d-flex mb-2 mt-2">
                                        <ng-container>
                                            <div class="col-8">
                                                <h5>am</h5>
                                            </div>
                                            <div class="col-4">
                                                <input class="icheckbox_square-blue" type="radio" name="fechaNacHorario" formControlName="fechaNacHorario" value="am" />
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="d-flex mb-2">
                                        <ng-container>
                                            <div class="col-8">
                                                <h5>pm</h5>
                                            </div>
                                            <div class="col-4">
                                                <input class="icheckbox_square-blue" type="radio" name="fechaNacHorario" formControlName="fechaNacHorario" value="pm" />
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <label class="{{claseLabel}}">{{Language('captura.fechaMuestra')}}</label>
                    <div class="row">
                        <div class="col-md-9 col-sm">
                            <div class="row">
                                <div nz-col class="gutter-row" [nzSpan]="24">
                                    <nz-form-item>
                                        <nz-form-control>
                                            <!-- <input nz-input placeholder=""  formControlName="fechaMuestra" nzSize="large" type="date" /> -->
                                            <nz-date-picker nzFormat="yyyy-MM-dd HH:mm:ss" [nzDisabledDate]="disabledDate" [nzShowTime]="{ nzDefaultOpenValue: timeDefaultValue }" formControlName="fechaMuestra"></nz-date-picker>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm">
                            <div class="row">
                                <div nz-col class="gutter-row" [nzSpan]="24">
                                    <div class="d-flex mb-2 mt-2">
                                        <ng-container>
                                            <div class="col-8">
                                                <h5>am</h5>
                                            </div>
                                            <div class="col-4">
                                                <input class="icheckbox_square-blue" type="radio" name="fechaMuestraHorario" formControlName="fechaMuestraHorario" value="am" />
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="d-flex mb-2">
                                        <ng-container>
                                            <div class="col-8">
                                                <h5>pm</h5>
                                            </div>
                                            <div class="col-4">
                                                <input class="icheckbox_square-blue" type="radio" name="fechaMuestraHorario" formControlName="fechaMuestraHorario" value="pm" />
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="col-sm">
                        <nz-form-label>{{Language('captura.muestra')}}</nz-form-label>
                        <div class="row">
                            <div nz-col class="gutter-row" [nzSpan]="24">
                                <div class="d-flex mb-2">
                                    <ng-container>
                                        <div class="col-8">
                                            <h5>{{Language('captura.inicial')}}</h5>
                                        </div>
                                        <div class="col-4">
                                            <input class="icheckbox_square-blue" type="radio" name="muestra" formControlName="muestra" value="1" />
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="d-flex mb-2">
                                    <ng-container>
                                        <div class="col-8">
                                            <h5>{{Language('captura.repeticion')}}</h5>
                                        </div>
                                        <div class="col-4">
                                            <input class="icheckbox_square-blue" type="radio" name="muestra" formControlName="muestra" value="0" />
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="col-12">
                        <label class="{{claseLabel}}" *ngIf="!isCliente">Marcar si el bebé tiene menos de</label>
                        <label class="{{claseLabel}}" *ngIf="isCliente">{{Language('captura.muestraTomada')}}</label>
                        <div class="row">
                            <div nz-col class="gutter-row d-flex" [nzSpan]="24">
                                <div class="col-6 mt-2">
                                    <h5 *ngIf="!isCliente">24 horas</h5>
                                    <h5 *ngIf="isCliente">{{Language('captura.antes24horas')}}</h5>
                                </div>
                                <div class="col-2 mt-2">
                                    <ng-container>
                                        <input class="icheckbox_square-blue" type="checkbox" name="24horas" formControlName="antes24horas" value="1" />
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-2" align="left" *ngIf="!isCliente">
                    <label>Muestra enviada por</label>
                    <div class="row">
                        <div class="col-md-12">
                            <input class="form-control" name="enviadapor" formControlName="enviadapor" type="text">
                        </div>
                    </div>                        
                </div>                
            </div>
            <br><br><br><br>
            <div class="row">
                <div class="col-md-2 col-sm mt-2">
                    <label class="{{claseLabel}}">{{Language('captura.raza')}}</label>
                    <div class="row mt-2">
                        <div nz-col class="gutter-row" [nzSpan]="24">
                            <div class="d-flex mb-2" *ngFor="let type of searchRaza">
                                <ng-container>
                                    <div class="col-2">
                                        <input class="icheckbox_square-blue" type="radio" name="raza" formControlName="raza" value={{type.value}} />
                                    </div>
                                    <div class="col-8">
                                        <h5>{{Language('captura.razaTipo')[type.value-1]}}</h5>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-2 col-sm mt-2">
                    <nz-form-label>{{Language('captura.saludy')}} &nbsp;<span>{{Language('captura.transfusion')}}</span></nz-form-label>
                    <div class="row mt-2">
                        <div nz-col class="gutter-row" [nzSpan]="24">
                            <div class="d-flex mb-2">
                                <ng-container>
                                    <div class="col-10">
                                        <h5>{{Language('captura.saludable')}}</h5>
                                    </div>
                                    <div class="col-2">
                                        <input class="icheckbox_square-blue" type="checkbox" name="chekSaludable" formControlName="chekSaludable" value="S" />
                                    </div>
                                </ng-container>
                            </div>
                            <div class="d-flex mb-2">
                                <ng-container>
                                    <div class="col-10">
                                        <h5>{{Language('captura.enfermo')}}</h5>
                                    </div>
                                    <div class="col-2">
                                        <input class="icheckbox_square-blue" type="checkbox" name="chekEnfermo" formControlName="chekEnfermo" value="S" />
                                    </div>
                                </ng-container>
                            </div>
                            <div class="d-flex mb-2">
                                <ng-container>
                                    <div class="col-10">
                                        <h5>{{Language('captura.cuidadosInt')}}</h5>
                                    </div>
                                    <div class="col-2">
                                        <input class="icheckbox_square-blue" type="checkbox" name="chekCuidadoInt" formControlName="chekCuidadoInt" value="S" />
                                    </div>
                                </ng-container>
                            </div>
                            <div class="d-flex mb-2">
                                <ng-container>
                                    <div class="col-10">
                                        <h5>{{Language('captura.volPequenio')}}</h5>
                                    </div>
                                    <div class="col-2">
                                        <input class="icheckbox_square-blue" type="checkbox" name="chekVolPeque" formControlName="chekVolPeque" value="S" (change)="onCheckChangeOption($event)" />
                                    </div>
                                </ng-container>
                            </div>
                            <div class="d-flex mb-2">
                                <ng-container>
                                    <div class="col-10">
                                        <h5>{{Language('captura.intercambio')}}</h5>
                                    </div>
                                    <div class="col-2">
                                        <input class="icheckbox_square-blue" type="checkbox" name="chekIntercambio" formControlName="chekIntercambio" value="S" (change)="onCheckChangeOption1($event)" />
                                    </div>
                                </ng-container>
                            </div>
                            <div class="mb-2" *ngIf="showfechaTrans == true">
                                <ng-container>
                                    <div class="col-12">
                                        <h5>{{Language('captura.fechaHoraTransfundido')}}</h5>
                                    </div>
                                    <div class="col-12">
                                        <div class="row">
                                            <div nz-col class="gutter-row" [nzSpan]="24">
                                                <nz-form-item>
                                                    <nz-form-control>
                                                        <nz-date-picker nzFormat="yyyy-MM-dd" [nzDisabledDate]="disabledDate" [nzShowTime]="{ nzDefaultOpenValue: timeDefaultValue }" formControlName="fechaTrans"></nz-date-picker>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-8 col-sm mt-2">
                    <nz-form-label>{{Language('captura.alimentacion')}}</nz-form-label>
                    <div class="row">
                        <div class="col-md-3 col-sm ">
                            <div class="col-12 ">
                                <nz-form-label>{{Language('captura.lecheMaterna')}}</nz-form-label>
                                <div nz-col class="gutter-row" [nzSpan]="24">
                                    <div class="col-12 d-flex">
                                        <div class="col-6 d-flex" *ngFor="let type of searchLecheMaterna">
                                            <div class="col-8">
                                                <h5>{{Language('captura.siNoTipo')[type.id]}}</h5>
                                            </div>
                                            <div class="col-2">
                                                <input class="icheckbox_square-blue" type="radio" name="Leche" formControlName="Leche" value="{{type.value}}" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mt-2">
                                <nz-form-label>{{Language('captura.carnitina')}}</nz-form-label>
                                <div nz-col class="gutter-row" [nzSpan]="24">
                                    <div class="col-12 d-flex">
                                        <div class="col-6 d-flex" *ngFor="let type of searchCarnitina">
                                            <div class="col-8">
                                                <h5>{{Language('captura.siNoTipo')[type.id]}}</h5>
                                            </div>
                                            <div class="col-2">
                                                <input class="icheckbox_square-blue" type="radio" name="carnitina" formControlName="carnitina" value="{{type.value}}" />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mt-2">
                                <nz-form-label>{{Language('captura.lactosa')}}</nz-form-label>
                                <div nz-col class="gutter-row" [nzSpan]="24">
                                    <div class="col-12 d-flex">
                                        <div class="col-6 d-flex" *ngFor="let type of searchLactosa">
                                            <div class="col-8">
                                                <h5>{{Language('captura.siNoTipo')[type.id]}}</h5>
                                            </div>
                                            <div class="col-2">
                                                <input class="icheckbox_square-blue" type="radio" name="Lactosa" formControlName="Lactosa" value="{{type.value}}" />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm mt-2">
                            <div class="col-12 ">
                                <nz-form-label>{{Language('captura.nutricionParenteral')}}</nz-form-label>
                                <div nz-col class="gutter-row" [nzSpan]="24">
                                    <div class="col-12 d-flex">
                                        <div class="col-6 d-flex" *ngFor="let type of searchNutriParental">
                                            <div class="col-8 mt-1">
                                                <h5>{{Language('captura.siNoTipo')[type.id]}}</h5>
                                            </div>
                                            <div class="col-2">
                                                <input class="icheckbox_square-blue" type="radio" name="nutriParental" formControlName="nutriParental" value="{{type.value}}" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mt-2">
                                <nz-form-label>{{Language('captura.lipidos')}}</nz-form-label>
                                <div nz-col class="gutter-row" [nzSpan]="24">
                                    <div class="col-12 d-flex">
                                        <div class="col-6 d-flex" *ngFor="let type of searchLipidos">
                                            <div class="col-8 mt-1">
                                                <h5>{{Language('captura.siNoTipo')[type.id]}}</h5>
                                            </div>
                                            <div class="col-2">
                                                <input class="icheckbox_square-blue" type="radio" name="lipidos" formControlName="lipidos" value="{{type.value}}" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-md-6 col-sm mt-2">
                            <nz-form-label>{{Language('captura.observaciones')}}</nz-form-label>
                            <div class="row">
                                <div nz-col class="gutter-row" [nzSpan]="24">
                                    <div class="row mb-2">
                                        <div class="col-12">
                                            <textarea name="comentario" id="comentario" rows="5" class="form-control" formControlName="comentarios"></textarea>
                                            <br>
                                            <br>
                                            <label>Enviar por correo?</label>
                                            <br>
                                            <div nz-col class="gutter-row" [nzSpan]="24">
                                                <nz-form-item>
                                                    <nz-form-control>
                                                        <nz-select nzSize="large" formControlName="EnviaCorreo" nzShowSearch>
                                                            <nz-option nzLabel="NO" nzValue="0"></nz-option>
                                                            <nz-option nzLabel="SI" nzValue="1"></nz-option>
                                                        </nz-select>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br><br><br><br>
            <div class="row datosmadremedico">
                <div class="col-sm mt-3">
                    <nz-form-label>{{Language('captura.informacionMadre.titulo')}}</nz-form-label>
                    <div  *ngIf="catalogoDoctores.length > 0">
                        <br>
                        <br>
                        <br>
                    </div>
                    <div class="row mt-2">
                        <div class="col-sm-2">
                            <h5 class="{{claseLabel}}">{{Language('captura.informacionMadre.nombre')}}</h5>
                        </div>
                        <div class="col-sm-10">
                            <div nz-col class="gutter-row" [nzSpan]="24">
                                <nz-form-item>
                                    <nz-form-control>
                                        <input nz-input placeholder="" formControlName="nmama" nzSize="large" type="text" />
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-sm-2">
                            <h5 class="{{claseLabel}}">{{Language('captura.informacionMadre.apellidos')}}</h5>
                        </div>
                        <div class="col-sm-10">
                            <div nz-col class="gutter-row" [nzSpan]="24">
                                <nz-form-item>
                                    <nz-form-control>
                                        <input nz-input placeholder="" formControlName="apmama" nzSize="large" type="text" />
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-sm">
                            <h5>{{Language('captura.informacionMadre.direccion')}}</h5>
                        </div>
                        <div class="col-sm-10">
                            <div nz-col class="gutter-row" [nzSpan]="24">
                                <nz-form-item>
                                    <nz-form-control>
                                        <input nz-input placeholder="" formControlName="mamadir" nzSize="large" type="text" />
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-sm-2">
                            <h5>{{Language('captura.informacionMadre.pais')}}</h5>
                        </div>
                        <div class="col-sm-10">
                            <div nz-col class="gutter-row" [nzSpan]="24">
                                <nz-form-item>
                                    <nz-form-control>
                                        <nz-select nzSize="large" nzPlaceHolder="{{Language('captura.informacionMadre.paisSel')}}" formControlName="paismama" nzShowSearch (ngModelChange)="onCheckChangeBusqueda($event)">
                                            <nz-option *ngFor="let data of DataPaisesM" [nzLabel]="data.Pais" [nzValue]="data.Pais_id"></nz-option>
                                        </nz-select>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-sm-2">
                            <h5>{{Language('captura.informacionMadre.estado')}}</h5>
                        </div>
                        <div class="col-sm-10">
                            <div nz-col class="gutter-row" [nzSpan]="24">
                                <nz-form-item>
                                    <nz-form-control>
                                        <nz-select nzSize="large" nzPlaceHolder="{{Language('captura.informacionMadre.estadoSel')}}" formControlName="estadomama" nzShowSearch (ngModelChange)="onCheckChangeBusqueda2($event)">
                                            <nz-option value=''></nz-option>
                                            <nz-option *ngFor="let data of DataEstadosM" [nzLabel]="data.Estado" [nzValue]="data.Estado_id"></nz-option>
                                        </nz-select>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-sm-2">
                            <h5>{{Language('captura.informacionMadre.ciudad')}}</h5>
                        </div>
                        <div class="col-sm-10">
                            <div nz-col class="gutter-row" [nzSpan]="24">
                                <nz-form-item>
                                    <nz-form-control>
                                        <nz-select nzSize="large" nzPlaceHolder="{{Language('captura.informacionMadre.ciudadSel')}}" formControlName="ciudadmama" nzShowSearch>
                                            <nz-option value=''></nz-option>
                                            <nz-option *ngFor="let data of DataCiudadesM" [nzLabel]="data.Ciudad" [nzValue]="data.Ciudad_id"></nz-option>
                                        </nz-select>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-sm-2">
                            <h5>{{Language('captura.informacionMadre.telefono')}}</h5>
                        </div>
                        <div class="col-sm-10">
                            <div nz-col class="gutter-row" [nzSpan]="24">
                                <nz-form-item>
                                    <nz-form-control>
                                        <input nz-input placeholder="" formControlName="telefonomama" nzSize="large" type="text" />
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2" *ngIf="pfCorreo == 1">
                        <div class="col-sm-2">
                            <h5>{{Language('captura.informacionMadre.correo')}}</h5>
                        </div>
                        <div class="col-sm-10">
                            <div nz-col class="gutter-row" [nzSpan]="24">
                                <nz-form-item>
                                    <nz-form-control>
                                        <input nz-input placeholder="" formControlName="mamacorreo" nzSize="large" type="text" />
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm mt-3">
                    <nz-form-label>{{Language('captura.informacionMedico.titulo')}}</nz-form-label>
                    <div class="row" id="div_cat_dr" style="padding-bottom: 5px;" *ngIf="catalogoDoctores.length > 0">
                        <div class="col-md-4">
                            Catalógo de doctores
                        </div>
                        <div class="col-md-8">
                            <select  class="form-control" (change)="ActualizaDatosDoctor($event.target.value)" >
                                <option value="">Seleccione doctor</option>
                                <option *ngFor="let data of catalogoDoctores" [value]="data.Doctor_Id">
                                    {{ data.Doctor }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <br />
                    <div class="row mt-2">
                        <div class="col-sm-2">
                            <h5 class="{{claseLabel}}">{{Language('captura.informacionMedico.nombre')}}</h5>
                        </div>
                        <div class="col-sm">
                            <div class="row">
                                <div nz-col class="gutter-row" [nzSpan]="24">
                                    <nz-form-item>
                                        <nz-form-control>
                                            <input nz-input placeholder="" formControlName="nmedico" nzSize="large" type="text" />
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-sm-2">
                            <h5 class="{{claseLabel}}">{{Language('captura.informacionMedico.apellidos')}}</h5>
                        </div>
                        <div class="col-sm">
                            <div class="row">
                                <div nz-col class="gutter-row" [nzSpan]="24">
                                    <nz-form-item>
                                        <nz-form-control>
                                            <input nz-input placeholder="" formControlName="apmedico" nzSize="large" type="text" />
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-sm-2">
                            <h5>{{Language('captura.informacionMedico.direccion')}}</h5>
                        </div>
                        <div class="col-sm">
                            <div class="row">
                                <div nz-col class="gutter-row" [nzSpan]="24">
                                    <nz-form-item>
                                        <nz-form-control>
                                            <input nz-input placeholder="" formControlName="medicodir" nzSize="large" type="text" />
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-sm-2">
                            <h5>{{Language('captura.informacionMedico.pais')}}</h5>
                        </div>
                        <div class="col-sm">
                            <div class="row">
                                <div nz-col class="gutter-row" [nzSpan]="24">
                                    <nz-form-item>
                                        <nz-form-control>
                                            <nz-select nzSize="large" nzPlaceHolder="{{Language('captura.informacionMedico.paisSel')}}" formControlName="paismedicoid" nzShowSearch (ngModelChange)="onCheckChangeBusqueda3($event)">
                                                <nz-option *ngFor="let data of DataPaisesMe" [nzLabel]="data.Pais" [nzValue]="data.Pais_id"></nz-option>
                                            </nz-select>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-sm-2">
                            <h5>{{Language('captura.informacionMedico.estado')}}</h5>
                        </div>
                        <div class="col-sm">
                            <div class="row">
                                <div nz-col class="gutter-row" [nzSpan]="24">
                                    <nz-form-item>
                                        <nz-form-control>
                                            <nz-select nzSize="large" nzPlaceHolder="{{Language('captura.informacionMedico.estadoSel')}}" formControlName="estadomedicoid" nzShowSearch (ngModelChange)="onCheckChangeBusqueda4($event)">
                                                <nz-option value=''></nz-option>
                                                <nz-option *ngFor="let data of DataEstadosMe" [nzLabel]="data.Estado" [nzValue]="data.Estado_id"></nz-option>
                                            </nz-select>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-sm-2">
                            <h5>{{Language('captura.informacionMedico.ciudad')}}</h5>
                        </div>
                        <div class="col-sm-10">
                            <div nz-col class="gutter-row" [nzSpan]="24">
                                <nz-form-item>
                                    <nz-form-control>
                                        <nz-select nzSize="large" nzPlaceHolder="{{Language('captura.informacionMedico.ciudadSel')}}" formControlName="ciudadmedicoid" nzShowSearch>
                                            <nz-option *ngFor="let data of DataCiudadesMe" [nzLabel]="data.Ciudad" [nzValue]="data.Ciudad_id"></nz-option>
                                        </nz-select>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-sm-2">
                            <h5>{{Language('captura.informacionMedico.telefono')}}</h5>
                        </div>
                        <div class="col-sm-10">
                            <div nz-col class="gutter-row" [nzSpan]="24">
                                <nz-form-item>
                                    <nz-form-control>
                                        <input nz-input placeholder="" formControlName="medicotel" nzSize="large" type="text" />
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2" *ngIf="pfCorreo == 1">
                        <div class="col-sm-2">
                            <h5>{{Language('captura.informacionMedico.correo')}}</h5>
                        </div>
                        <div class="col-sm-10">
                            <div nz-col class="gutter-row" [nzSpan]="24">
                                <nz-form-item>
                                    <nz-form-control>
                                        <input nz-input placeholder="" formControlName="medicocorreo" nzSize="large" type="text" />
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-5" *ngIf="Cortesia.PfTieneCorDes == 0 && (Cortesia.TieneDescuento || Cortesia.TieneCortesia)">
                    <div class="col-md-12" align="center">
                        <h2>Cortesias y/o Descuentos</h2>
                    </div>
                </div>
                <ng-container *ngIf="Cortesia.PfTieneCorDes == 0 && (Cortesia.TieneDescuento || Cortesia.TieneCortesia)">
                    <div class="row">
                        <div class="col-md-5"></div>
                        <div class="col-md-4">
                            <label><input type="radio" class="radio TipoCorDes" formControlName="TipoCorDes" value="0" checked="checked" /> Sin Descuento o cortesia</label>
                        </div>
                    </div>
                    <div class="row" *ngIf="Cortesia.TieneDescuento">
                        <div class="col-md-5"></div>
                        <div class="col-md-4">
                            <label><input type="radio" class="radio TipoCorDes" formControlName="TipoCorDes" value="1" /> Descuento</label>
                        </div>
                    </div>
                    <div class="row" *ngIf="Cortesia.TieneCortesia">
                        <div class="col-md-5"></div>
                        <div class="col-md-4">
                            <label><input type="radio" class="radio TipoCorDes" formControlName="TipoCorDes" value="2" /> Cortesia</label>
                        </div>
                    </div>
                </ng-container>
                <div class="row" *ngIf="Cortesia.PfTieneCorDes == 0 && (Cortesia.TieneDescuento || Cortesia.TieneCortesia)">
                    <div class="col-md-5"></div>
                    <div class="col-md-3" *ngIf="formFiltros.controls.TipoCorDes.value != 0">
                        <input class="form-control" type="text" formControlName="inpCorDesId" id="inpCorDesId" onkeyup="this.value = this.value.toUpperCase();"/>
                    </div>
                </div>
                <div class="row mt-5" *ngIf="Cortesia.PfTieneCorDes != 0">
                    <div class="col-md-12">
                        <h2><br>Este PF ya cuenta con un codigo de {{Cortesia.TipoRegistroDet}} activo: <strong>{{Cortesia.PfTieneCorDes}}</strong></h2>
                    </div>
                </div>
                <ng-container *ngIf="sectiorPublicoShow || pacienteNovaShow">
                    <div class="row mt-3">
                        <div class="col-md-2"></div>
                        <div class="col-4 text-center mt-5" *ngIf="pacienteNovaShow">
                            <nz-form-label *ngIf="isCliente">Paciente NOVA</nz-form-label>
                            <nz-form-label *ngIf="!isCliente">Paciente NOVA nacido en Conchita</nz-form-label>
                            <div nz-col class="gutter-row" [nzSpan]="24">
                                <div class="col-12 d-flex">
                                    <div class="col-6 d-flex">
                                        <div class="col-8 mt-1" align="rigth">
                                            <h5>Si</h5>
                                        </div>
                                        <div class="col-2">
                                            <input class="icheckbox_square-blue" type="radio" name="novaconchita" formControlName="novaconchita" value="1" />
                                        </div>
                                    </div>
                                    <div class="col-6 d-flex">
                                        <div class="col-8 mt-1" align="rigth">
                                            <h5>No</h5>
                                        </div>
                                        <div class="col-2">
                                            <input class="icheckbox_square-blue" type="radio" name="novaconchita" formControlName="novaconchita" value="0" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 text-center mt-5" *ngIf="sectiorPublicoShow">
                            <nz-form-label>¿ES PACIENTE SECTOR PÚBLICO?</nz-form-label>
                            <div nz-col class="gutter-row" [nzSpan]="24">
                                <div class="col-12 d-flex">
                                    <div class="col-6 d-flex">
                                        <div class="col-8 mt-1" align="rigth">
                                            <h5>Si</h5>
                                        </div>
                                        <div class="col-2">
                                            <input class="icheckbox_square-blue" type="radio" name="sectorpublico" formControlName="sectorpublico" value="1" />
                                        </div>
                                    </div>
                                    <div class="col-6 d-flex">
                                        <div class="col-8 mt-1" align="rigth">
                                            <h5>No</h5>
                                        </div>
                                        <div class="col-2">
                                            <input class="icheckbox_square-blue" type="radio" name="sectorpublico" formControlName="sectorpublico" value="0" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="row mt-5" *ngIf="!isCliente && !bitVisor">
                <div class="col-md-3">
                    <gk-comments-filter [DataComentarios]="DataComentarios"></gk-comments-filter>
                </div>
            </div> 
        </div>
        <ng-container *ngIf="!bitVisor">
            <div class="row mt-5">
                <div class="col-md-12 text-center">
                    <button type="button" class="btn btn-default pad1" (click)="RegresarFiltro()">{{Language('captura.regresar')}}</button>
                    <button type="button" class="ml-2 btn btn-primary pad1" (click)="validaguarda()" title="Guarda y regresa a Captura y/o Validación" *ngIf="!isCliente">Guardar y regresar</button>
                    <button type="button" class="ml-2 btn btn-success pad1" (click)="validaguarda()" title="Validar" *ngIf="!isCliente">Validar</button>
                    <button type="submit" class="ml-2 btn btn-success pad1" *ngIf="isCliente">{{Language('captura.guardar')}}</button>
                </div>
                <div class="col-md-4"></div>
            </div>
            <div class="row mt-5" *ngIf="!isCliente && (ActiveNivelID == 1 || ActiveNivelID == 2 || ActiveNivelID == 12 || ActiveNivelID == 15)">
                <div class="col-md-12 text-center">
                    <button type="button" class="btn btn-danger pad1" (click)="sinDatos()">Sin Datos</button>
                </div>
            </div>
        </ng-container>    
        <ng-container *ngIf="modifPF">
            <div class="container">
                <div class="row mt-5">
                    <div class="card">
                        <form
                            autocomplete="off"
                            [formGroup]="formModifi">
    
                            
                            <div class="container">
                                <div class="row mt-5">
                                    <div class="col-1"></div>
                                    <div class="col-3">
                                        <label class="control-label fw-bold" >Razón</label>
                                        <span class="form-text text-danger ms-2" *ngIf="isValidFieldModifi('Razon')" @fadeInOut>
                                            <b>Este campo es requerido</b>
                                        </span>
                                        <select  class="form-control" formControlName="Razon">
                                            <option value="">Seleccione un tipo</option>
                                            @for(razon of comboRazon; track $index){
                                                <option [value]="razon.id">{{razon.txt}}</option>
                                            }
                                        </select>
                                    </div>
                                    <div class="col-1"></div>
                                </div>
                            </div>
    
                            <div class="container">
                                <div class="row mt-5">
                                    <div class="col-1"></div>
                                    <div class="col">
                                        <label class="control-label fw-bold" >Comentarios</label>
                                        <span class="form-text text-danger my-1 ms-2" *ngIf="isValidFieldModifi('Comentarios')" @fadeInOut>
                                            <b>Este campo es requerido</b>
                                        </span>
                                        <div class="input-group mb-3" >
                                            <textarea class="form-control" formControlName="Comentarios" rows="5"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-1"></div>
                                </div>
                            </div>
    
    
                            <div class="col-12 text-center mt-4 mb-4">
                                <button type="button" class="ml-2 btn btn-success pad1" (click)="procesar(false)">Procesar</button>
                                <button type="button" class="ml-2 btn btn-success pad1" (click)="procesar(true)" *ngIf="pacientenuevo">Paciente NUEVO</button>
                            </div>
                            <div class="col-4"></div>
                        </form>
                    </div>
                </div>
            </div>
        </ng-container>    
    </form>
</div>

