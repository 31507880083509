import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppCommonModule } from '@common/app-common.module';
import { TranslocoModule } from '@ngneat/transloco';
import { GkFormsModule } from '@shared/genomik-forms/genomik-forms.module';
import { DataTablesModule } from 'angular-datatables';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { BreadcrumbsComponent, DashboardHeadComponent, SideNavItemComponent, TopNavMenuComponent, TopNavUserComponent } from './components';
import * as navigationContainers from './containers';
import * as appCommonLayouts from './layouts';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';


const nzModules = [NzButtonModule, NzDropDownModule, NzIconModule, NzMenuModule, NzModalModule, NzDatePickerModule];
const components = [SideNavItemComponent, BreadcrumbsComponent, DashboardHeadComponent, TopNavUserComponent, TopNavMenuComponent];

@NgModule({
   // imports: [CommonModule, RouterModule, AppCommonModule, TranslocoModule, GkFormsModule, DataTablesModule, ...nzModules],
    imports: [CommonModule, RouterModule, AppCommonModule,  TranslocoModule, GkFormsModule, ...nzModules],
    declarations: [...navigationContainers.containers, ...components, ...appCommonLayouts.layouts],
    exports: [...navigationContainers.containers, ...components, ...appCommonLayouts.layouts],
})
export class NavigationModule {}
