<div *transloco="let t; read: 'navigation.search'">
    <button type="button" style="display:none;" class="btn white" data-bs-toggle="modal" data-bs-target="#ModalSearchNavigation" #BtnModalSearchNavigation></button>
    <button type="button" style="display:none;" class="btn white" data-bs-toggle="modal" data-bs-target="#ModalSearchNavigationPFDetail" #BtnModalSearchNavigationPFDetail></button>
    <label class="btn-primary" style="display:none;" ngbButtonLabel>
        <input #fCLick type="checkbox" ngbButton > ENE
    </label>
    <button type="button" (click)="showModal()" (mouseover)="showBusqueda = true" (mouseleave)="showBusqueda = false" class="btn white">
        <fa-icon class="mr-1" [icon]="['fas', 'search']"></fa-icon> <span *ngIf="showBusqueda == true">&nbsp;{{ t('Busqueda') }}</span> 
    </button>

    <nz-modal 
    [(nzVisible)]="isVisible" 
    [nzTitle]="tituloModal"
    [nzFooter]="modalFooter" 
    (nzOnCancel)="handleCancel()" 
    nzClassName="modalbusqueda modalzorro"
    [nzStyle]="{ width: '90%'}"
    nzClosable="false"
    >
        <ng-container *nzModalContent>            
            <form novalidate (ngSubmit)="validateFormSearchNavigation()" #f3="ngForm" [formGroup]="formSearchNavigation">
                <div class="modal-content modalSearchNavigationContent">
                    <div class="row mt-2">
                        <div class="col-md-3">
                            <label>{{ t('Solucion') }}</label>
                            <select class="form-control" (change)="OnChangeSolucion($event)" formControlName="Solucion">
                                <option value="">{{ t('Seleccione') }} {{ t('Solucion') }}</option>
                                <option *ngFor="let item of soluciones" value="{{item.Value}}">{{item.Display}}</option>
                            </select>
                            <div *ngIf="formSearchNavigation.controls['Solucion'].invalid && (formSearchNavigation.controls['Solucion'].dirty || formSearchNavigation.controls['Solucion'].touched)" class="error">
                                <div *ngIf="formSearchNavigation.controls['Solucion']?.errors?.required">{{ t('MesageValidate') }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-3" *ngIf="this.formSearchNavigation.controls.Solucion.value !== '' && this.formSearchNavigation.controls.BusquedaAvanzada.value == false">
                        <label>{{ t('TipoBusqueda') }}</label>
                    </div>
                    <div class="row mt-2" >       
                        <div class="col-md-3" *ngIf="this.formSearchNavigation.controls.Solucion.value !== '' && this.formSearchNavigation.controls.BusquedaAvanzada.value == false">                          
                            <select class="form-control" (change)="OnChangeTipoBusqueda($event)" formControlName="TipoBusqueda">
                            <option value="" *ngIf="this.formSearchNavigation.controls.Solucion.value !== ''">{{ t('Seleccione') }} {{ t('tipobusqueda') }}</option>
                            <ng-container *ngIf="this.formSearchNavigation.controls.Solucion.value == 1">
                                <option value="1">{{ t('Codigo') }}</option>
                                <option value="2">{{ t('NombrePaciente') }}</option>
                                <option value="3">{{ t('InstitutionId') }}</option>
                                <option value="4">{{ t('PatientId') }}</option>
                                <option value="5">{{ t('NombreMadre') }}</option>
                                <option value="6">{{ t('LaboratorioId') }}</option>
                            </ng-container>
                            <ng-container *ngIf="this.formSearchNavigation.controls.Solucion.value == 2">
                                <option value="1">{{ t('NoOrdenServicio') }}</option>
                                <option value="2">{{ t('NombrePaciente') }}</option>
                                <option value="3">{{ t('InstitutionId') }}</option>
                                <option value="4">{{ t('PatientId') }}</option>
                                <option value="5">{{ t('NombreMadre') }}</option>
                                <option value="6">{{ t('NumeroRemision') }}</option>
                            </ng-container>
                            </select>
                            <div *ngIf="formSearchNavigation.controls['TipoBusqueda'].invalid && (formSearchNavigation.controls['TipoBusqueda'].dirty || formSearchNavigation.controls['TipoBusqueda'].touched)" class="error">
                                <div *ngIf="formSearchNavigation.controls['TipoBusqueda']?.errors?.required">{{ t('MesageValidate') }}</div>
                            </div>
                        </div>
                        <div class="col-md-5" *ngIf="this.formSearchNavigation.controls.TipoBusqueda.value != '' && this.formSearchNavigation.controls.Solucion.value !== ''">
                            <!-- <label *ngIf="this.formSearchNavigation.controls.BusquedaAvanzada.value == false && this.formSearchNavigation.controls.TipoBusqueda.value != ''">{{LabelTipoBusqueda}}</label> -->
                            <input *ngIf="this.formSearchNavigation.controls.TipoBusqueda.value != ''" formControlName="Busqueda" (keydown.enter)="validateFormSearchNavigation()" type="text" class="form-control" placeholder="{{ t('Ingrese') }} {{LabelTipoBusqueda}}">
                            <div >
                                <div *ngIf="formSearchNavigation.controls['Busqueda'].invalid && (formSearchNavigation.controls['Busqueda'].dirty || formSearchNavigation.controls['Busqueda'].touched)" class="error">
                                    <div *ngIf="formSearchNavigation.controls['Busqueda']?.errors?.required">{{ t('MesageValidate') }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-3 text-center" *ngIf="!this.formSearchNavigation.controls.BusquedaAvanzada.value">                                
                                    <button type="submit" class="btn btn-success"><fa-icon class="mr-1" [icon]="['fas', 'search']"></fa-icon> Buscar</button>                            
                            </div>
                        </div>
                    </div>
                </div>                    

                <div class="row mt-4" *ngIf="this.formSearchNavigation.controls.BusquedaAvanzada.value == true">
                    <div class="col-md-3">
                        <label>{{ t('Periodo') }}</label>
                    </div>
                </div>
                <div class="row mb-2" *ngIf="this.formSearchNavigation.controls.BusquedaAvanzada.value == true">
                    <div class="row container mt-2 mb-4 d-flex align-content-center">
                        <div class="col-6">                    
                            <ng-container *ngFor="let periodo of searchPeriodo; let i = index">
                                <input
                                    class="icheckbox_square-blue"
                                    type="radio"
                                    name="tipobusqueda"
                                    formControlName="bAvanzada"
                                    value={{periodo.value}}
                                    (ngModelChange)="modelChanged($event)"                    
                                />
                                <!-- [(ngModel)]="periodoSeleccionado" -->
                                &nbsp;&nbsp;{{ periodo.text }}&nbsp;&nbsp;
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="this.formSearchNavigation.controls.Solucion.value !== '' && this.formSearchNavigation.controls.BusquedaAvanzada.value == true">
                    <div class=" col-6 mt-2  d-flex" *ngIf="periodoSeleccionado == 1">
                        <div class="col-4 m-1">
                                <nz-select formControlName="f_mes_ini" nzPlaceHolder="Selecciona Mes" required>
                                    <nz-option *ngFor="let mes of dataMeses" [nzValue]="mes.Value" [nzLabel]="mes.Display" ></nz-option>
                                    </nz-select>
                        </div>
                        <div class="col-4 m-1">
                                    <nz-select formControlName="f_year_fin"  nzPlaceHolder="Selecciona año" required>
                                    <nz-option *ngFor="let year of dataYears" [nzValue]="year.anio" [nzLabel]="year.anio" ></nz-option>
                                    </nz-select>
                        </div>
                    </div>
                    <div class="col-6 mt-2" *ngIf="periodoSeleccionado == 2">                                       
                        <nz-date-picker [nzFormat]="dateFormat" formControlName="f_mes_ini" nzPlaceHolder="Fecha Inicial" class="m-1 col-4" required></nz-date-picker>
                        <nz-date-picker [nzFormat]="dateFormat" formControlName="f_year_fin" nzPlaceHolder="Fecha Final" class="m-1 col-4" required></nz-date-picker>                
                    </div>
                </div>                                                                              
                <div class="row mb-2" *ngIf="this.formSearchNavigation.controls.BusquedaAvanzada.value == true">
                    <div class="col-md-12 text-center">
                        <button type="submit" class="btn btn-success"><fa-icon class="mr-1" [icon]="['fas', 'search']"></fa-icon> Buscar</button>
                    </div>
                </div>
            </form>
            <div class="row mt-3" *ngIf="showResultadosBusqueda">
                <div class="col-md-12">
                    <hr>
                    <h4 class="bold mb-2">{{ t('ResultadoBusqueda') }}</h4>
                </div>
            </div>
            <div class="col-md-12 table-responsive" *ngIf="showResultadosBusqueda && DataBusqueda?.ResultadoBusqueda.length > 0">
                <table datatable class="table table-striped table-bordered row-border hover">
                    <thead class="ttdblue">
                        <tr>
                            <th class="text-center" width="10%">{{ t('Codigo') }}</th>
                            <th class="text-center" width="10%">{{ t('PatientId') }}</th>
                            <th class="text-left" width="25%">{{ t('NombrePaciente') }}</th>
                            <th class="text-center" width="11%">{{ t('FechaNacimiento') }}</th>
                            <th class="text-center" width="12%">{{ t('FechaResultado') }}</th>
                            <th class="text-left" width="25%">{{ t('Institution') }}</th>
                            <th class="text-center" width="7%">{{ t('Detalle') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of DataBusqueda?.ResultadoBusqueda">
                            <td class="text-center">
                                <a class="txtlinka" (click)="Download(item.Codigo)" title="{{ t('VerResultado') }}">
                                    {{item.Codigo}}
                                </a>
                            </td>
                            <td class="text-center">
                                <a class="txtlinka" (click)="GoExpediente(item.PacienteID)" title="{{ t('VerExpediente') }}">
                                    {{item.PacienteID}}
                                </a>
                            </td>
                            <td class="text-left">
                                <a class="txtlinka" (click)="GoExpediente(item.PacienteID)" title="{{ t('VerExpediente') }}">
                                    {{item.NombrePX}}
                                </a>
                            </td>
                            <td class="text-center">{{item.FechaHoraNac | date: "dd-MMM-yyyy": "UTC"}}</td>
                            <td class="text-center">{{item.Fecha | date: "dd-MMM-yyyy": "UTC"}}</td>
                            <td class="text-left">{{item.NombreComercial}}</td>
                            <td class="text-center">
                                <a class="txtlinka" (click)="OpenModalDetailPF(item.Codigo)">
                                    <fa-icon class="mr-1" [icon]="['fas', 'file']"></fa-icon>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-md-12 text-center" *ngIf="showResultadosBusqueda && DataBusqueda?.ResultadoBusqueda.length == 0 && !DataBusqueda?.BitBusquedaEspecifica">
                <h5 class="bold mb-2">{{ t('NoExistenResultados') }}</h5>
            </div>
            <div class="col-md-12 text-center" *ngIf="showResultadosBusqueda && DataBusqueda?.BitBusquedaEspecifica">
                <h5 class="bold mb-2">{{ t('EspecificaBusqueda') }}</h5>
            </div>

        </ng-container>
    </nz-modal>

      <ng-template #tituloModal>
        <div class="row">
        <div class="col-md-10">
            <h4 class="modal-title bold white">
                <ng-container *ngIf="this.formSearchNavigation.controls.BusquedaAvanzada.value == false">{{ t('BusquedaRapida') }}</ng-container>
                <ng-container *ngIf="this.formSearchNavigation.controls.BusquedaAvanzada.value == true">{{ t('BusquedaAvanzada') }}</ng-container>
            </h4>
        </div>
        <div class="col-md-2 text-right">
            <button type="button" (mouseover)="showBusquedaAvanzada = true" (mouseleave)="showBusquedaAvanzada = false" class="btn btn-primary" (click)="showBusquedaAvanzada = false" *ngIf="this.formSearchNavigation.controls.BusquedaAvanzada.value == false" (click)="this.formSearchNavigation.controls.BusquedaAvanzada.value=true;" (click) = "CleanModalSearchNavigation">
                <fa-icon class="mr-1" [icon]="['fas', 'sync']"></fa-icon> <span *ngIf="showBusquedaAvanzada === true">&nbsp;{{ t('BusquedaAvanzada') }}</span> 
            </button>
            <button type="button" (mouseover)="showBusquedaAvanzada = true" (mouseleave)="showBusquedaAvanzada = false" class="btn btn-primary" (click)="showBusquedaAvanzada = false" *ngIf="this.formSearchNavigation.controls.BusquedaAvanzada.value == true" (click)="this.formSearchNavigation.controls.BusquedaAvanzada.value=false;" (click) = "CleanModalSearchNavigation">
                <fa-icon class="mr-1" [icon]="['fas', 'sync']"></fa-icon> <span *ngIf="showBusquedaAvanzada === true">&nbsp;{{ t('BusquedaRapida') }}</span> 
            </button>
            <button type="button" (mouseover)="nuevaBusqueda = true" (mouseleave)="nuevaBusqueda = false" class="btn btn-default" (click)="CleanModalSearchNavigation()" *ngIf="showResultadosBusqueda">
                <fa-icon class="mr-1" [icon]="['fas', 'search-plus']"></fa-icon> <span *ngIf="nuevaBusqueda === true">&nbsp;{{ t('NuevaBusqueda') }}</span> 
            </button>
        </div>
    </div>
      </ng-template>

      <ng-template #modalFooter>
        <button nz-button class="btn btn-default m-1" nzType="default" (click)="handleCancel()">{{ t('btnclose') }}</button>
      </ng-template>




    <div id="ModalSearchNavigationPFDetail" class="modal" role="dialog" aria-labelledby="" data-bs-keyboard="false" data-bs-backdrop="static">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header border-bottom-0">
                    <div class="row w-100">
                        <div class="col-md-9">
                            <h4 class="modal-title bold white">
                                Detail PDF
                            </h4>
                        </div>
                        <div class="col-md-3 text-right">
                        </div>
                    </div>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body">
                    <app-pf-tamik-detail></app-pf-tamik-detail>
                </div>
                <div class="modal-footer" style="display: none;">
                    <button type="button" class="btn btn-default" data-bs-dismiss="modal" #btnModalSearchNavigationX>{{ t('Cerrar') }}</button>
                </div>
            </div>
        </div>
    </div>


                        <!-- <ng-container *ngFor="let searchTB of searchTypeBusqueda; let i = index">
                            <input
                                class="icheckbox_square-blue"
                                type="radio"
                                name="searchTypeBusqueda"
                                formControlName="searchTypeBusqueda"
                                value={{searchTB.value}}
                                (ngModelChange)="TypeBusquedaChanged($event)"
                
                            /> -->
                            <!-- [(ngModel)]="periodoSeleccionado" -->
                            <!-- &nbsp;&nbsp;{{ searchTB.text }}&nbsp;&nbsp;
                        </ng-container>
                    </div>                    

                    <div class="row mt-4" *ngIf="this.formSearchNavigation.controls.Solucion.value !== '' this.formSearchNavigation.controls.BusquedaAvanzada.value == true">
                        <ng-container *ngFor="let searchTP of searchTypePeriodo; let i = index">
                            <input
                                class="icheckbox_square-blue"
                                type="radio"
                                name="searchTypePeriodo"
                                formControlName="searchTypePeriodo"
                                value={{searchTP.value}}
                                (ngModelChange)="PeriodoChanged($event)"
                
                            /> -->
                            <!-- [(ngModel)]="periodoSeleccionado" -->
                            <!-- &nbsp;&nbsp;{{ searchTP.text }}&nbsp;&nbsp;
                        </ng-container>
                    </div> -->

                        <!-- <div id="ModalSearchNavigation" class="modal" role="dialog" aria-labelledby="" data-bs-keyboard="false" data-bs-backdrop="static">
        <div class="modal-dialog modal-xl" style="max-width: 90%;">
            <form novalidate (ngSubmit)="validateFormSearchNavigation()" #f3="ngForm" [formGroup]="formSearchNavigation">
                <div class="modal-content modalSearchNavigationContent">
                    <div class="modal-header modal-header-search bg-primary-especial">
                        <div class="row w-100">      
                            <div class="col-md-9">
                                <h4 class="modal-title bold white">
                                    <ng-container *ngIf="this.formSearchNavigation.controls.BusquedaAvanzada.value == false">{{ t('BusquedaRapida') }}</ng-container>
                                    <ng-container *ngIf="this.formSearchNavigation.controls.BusquedaAvanzada.value == true">{{ t('BusquedaAvanzada') }}</ng-container>
                                </h4>
                            </div>
                            <div class="col-md-3 text-right">
                                <button type="button" (mouseover)="showBusquedaAvanzada = true" (mouseleave)="showBusquedaAvanzada = false" class="btn btn-primary" (click)="showBusquedaAvanzada = false" *ngIf="this.formSearchNavigation.controls.BusquedaAvanzada.value == false" (click)="this.formSearchNavigation.controls.BusquedaAvanzada.value=true;">
                                    <fa-icon class="mr-1" [icon]="['fas', 'sync']"></fa-icon> <span *ngIf="showBusquedaAvanzada == true">&nbsp;{{ t('BusquedaAvanzada') }}</span> 
                                </button>
                                <button type="button" (mouseover)="showBusquedaAvanzada = true" (mouseleave)="showBusquedaAvanzada = false" class="btn btn-primary" (click)="showBusquedaAvanzada = false" *ngIf="this.formSearchNavigation.controls.BusquedaAvanzada.value == true" (click)="this.formSearchNavigation.controls.BusquedaAvanzada.value=false;">
                                    <fa-icon class="mr-1" [icon]="['fas', 'sync']"></fa-icon> <span *ngIf="showBusquedaAvanzada == true">&nbsp;{{ t('BusquedaRapida') }}</span> 
                                </button>
                                <button type="button" (mouseover)="nuevaBusqueda = true" (mouseleave)="nuevaBusqueda = false" class="btn btn-default" (click)="CleanModalSearchNavigation()" *ngIf="showResultadosBusqueda">
                                    <fa-icon class="mr-1" [icon]="['fas', 'search-plus']"></fa-icon> <span *ngIf="nuevaBusqueda == true">&nbsp;{{ t('NuevaBusqueda') }}</span> 
                                </button>
                            </div>
                        </div>
                        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-body">
                            <div class="row mb-2">
                                <div class="col-md-12">
                                    <div class="row mt-2">
                                        <div class="col-md-3">
                                            <label *ngIf="this.formSearchNavigation.controls.BusquedaAvanzada.value == true">{{ t('Solucion') }}</label>
                                            <select class="form-control" (change)="OnChangeSolucion($event)" formControlName="Solucion">
                                                <option value="">{{ t('Seleccione') }} {{ t('Solucion') }}</option>
                                                <option *ngFor="let item of soluciones" value="{{item.Value}}">{{item.Display}}</option>
                                            </select>
                                            <div *ngIf="formSearchNavigation.controls['Solucion'].invalid && (formSearchNavigation.controls['Solucion'].dirty || formSearchNavigation.controls['Solucion'].touched)" class="error">
                                                <div *ngIf="formSearchNavigation.controls['Solucion']?.errors?.required">{{ t('MesageValidate') }}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <label *ngIf="this.formSearchNavigation.controls.BusquedaAvanzada.value == true">{{ t('TipoBusqueda') }}</label>
                                            <select class="form-control" (change)="OnChangeTipoBusqueda($event)" formControlName="TipoBusqueda">
                                            <option value="">{{ t('Seleccione') }} {{ t('tipobusqueda') }}</option>
                                            <ng-container *ngIf="this.formSearchNavigation.controls.Solucion.value == 1">
                                                <option value="1">{{ t('Codigo') }}</option>
                                                <option value="2">{{ t('NombrePaciente') }}</option>
                                                <option value="3">{{ t('InstitutionId') }}</option>
                                                <option value="4">{{ t('PatientId') }}</option>
                                                <option value="5">{{ t('NombreMadre') }}</option>
                                                <option value="6">{{ t('LaboratorioId') }}</option>
                                            </ng-container>
                                            <ng-container *ngIf="this.formSearchNavigation.controls.Solucion.value == 2">
                                                <option value="1">{{ t('NoOrdenServicio') }}</option>
                                                <option value="2">{{ t('NombrePaciente') }}</option>
                                                <option value="3">{{ t('InstitutionId') }}</option>
                                                <option value="4">{{ t('PatientId') }}</option>
                                                <option value="5">{{ t('NombreMadre') }}</option>
                                                <option value="6">{{ t('NumeroRemision') }}</option>
                                            </ng-container>
                                        </select>
                                            <div *ngIf="formSearchNavigation.controls['TipoBusqueda'].invalid && (formSearchNavigation.controls['TipoBusqueda'].dirty || formSearchNavigation.controls['TipoBusqueda'].touched)" class="error">
                                                <div *ngIf="formSearchNavigation.controls['TipoBusqueda']?.errors?.required">{{ t('MesageValidate') }}</div>
                                            </div>
                                        </div>
                                        <div [ngClass]="{'col-md-5' : !this.formSearchNavigation.controls.BusquedaAvanzada.value, 'col-md-6' : this.formSearchNavigation.controls.BusquedaAvanzada.value}" class="else-class">
                                            <label *ngIf="this.formSearchNavigation.controls.BusquedaAvanzada.value == true && this.formSearchNavigation.controls.TipoBusqueda.value != ''">{{LabelTipoBusqueda}}</label>
                                            <input formControlName="Busqueda" (keydown.enter)="validateFormSearchNavigation()" *ngIf="this.formSearchNavigation.controls.TipoBusqueda.value != ''" type="text" class="form-control" placeholder="{{ t('Ingrese') }} {{LabelTipoBusqueda}}">
                                            <div *ngIf="this.formSearchNavigation.controls.TipoBusqueda.value !=''">
                                                <div *ngIf="formSearchNavigation.controls['Busqueda'].invalid && (formSearchNavigation.controls['Busqueda'].dirty || formSearchNavigation.controls['Busqueda'].touched)" class="error">
                                                    <div *ngIf="formSearchNavigation.controls['Busqueda']?.errors?.required">{{ t('MesageValidate') }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-1 text-center" *ngIf="this.formSearchNavigation.controls.BusquedaAvanzada.value == false">
                                            <button type="submit" class="btn btn-success">
                                            <fa-icon [icon]="['fas', 'search']"></fa-icon>
                                        </button>
                                        </div>
                                    </div>
                                    <div class="row mt-4" *ngIf="this.formSearchNavigation.controls.BusquedaAvanzada.value == true">
                                        <div class="col-md-3">
                                            <label>{{ t('Periodo') }}</label>
                                        </div>
                                    </div>
                                    <div class="row mb-2" *ngIf="this.formSearchNavigation.controls.BusquedaAvanzada.value == true">
                                        <div class="col-md-12 text-center">
                                            <button type="submit" class="btn btn-success"><fa-icon class="mr-1" [icon]="['fas', 'search']"></fa-icon> Buscar</button>
                                        </div>
                                    </div>
                                    <div class="row form-group mt-4" *ngIf="showResultadosBusqueda">
                                        <div class="col-md-12">
                                            <hr>
                                            <h4 class="bold mb-2">{{ t('ResultadoBusqueda') }}</h4>
                                        </div>
                                    </div>
                                    <div class="row form-group" *ngIf="showResultadosBusqueda">
                                        <div class="col-md-12 table-responsive" *ngIf="showResultadosBusqueda && DataBusqueda?.ResultadoBusqueda.length > 0">
                                            <table datatable class="table table-striped table-bordered row-border hover">
                                                <thead class="ttdblue">
                                                    <tr>
                                                        <th class="text-center" width="10%">{{ t('Codigo') }}</th>
                                                        <th class="text-center" width="10%">{{ t('PatientId') }}</th>
                                                        <th class="text-left" width="25%">{{ t('NombrePaciente') }}</th>
                                                        <th class="text-center" width="11%">{{ t('FechaNacimiento') }}</th>
                                                        <th class="text-center" width="12%">{{ t('FechaResultado') }}</th>
                                                        <th class="text-left" width="25%">{{ t('Institution') }}</th>
                                                        <th class="text-center" width="7%">{{ t('Detalle') }}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of DataBusqueda?.ResultadoBusqueda">
                                                        <td class="text-center">
                                                            <a class="txtlinka" (click)="Download(item.Codigo)" title="{{ t('VerResultado') }}">
                                                                {{item.Codigo}}
                                                            </a>
                                                        </td>
                                                        <td class="text-center">
                                                            <a class="txtlinka" (click)="GoExpediente(item.PacienteID)" title="{{ t('VerExpediente') }}">
                                                                {{item.PacienteID}}
                                                            </a>
                                                        </td>
                                                        <td class="text-left">
                                                            <a class="txtlinka" (click)="GoExpediente(item.PacienteID)" title="{{ t('VerExpediente') }}">
                                                                {{item.NombrePX}}
                                                            </a>
                                                        </td>
                                                        <td class="text-center">{{item.FechaHoraNac | date: "dd-MMM-yyyy": "UTC"}}</td>
                                                        <td class="text-center">{{item.Fecha | date: "dd-MMM-yyyy": "UTC"}}</td>
                                                        <td class="text-left">{{item.NombreComercial}}</td>
                                                        <td class="text-center">
                                                            <a class="txtlinka" (click)="OpenModalDetailPF(item.Codigo)">
                                                                <fa-icon class="mr-1" [icon]="['fas', 'file']"></fa-icon>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="col-md-12 text-center" *ngIf="showResultadosBusqueda && DataBusqueda?.ResultadoBusqueda.length == 0 && !DataBusqueda?.BitBusquedaEspecifica">
                                            <h5 class="bold mb-2">{{ t('NoExistenResultados') }}</h5>
                                        </div>
                                        <div class="col-md-12 text-center" *ngIf="showResultadosBusqueda && DataBusqueda?.BitBusquedaEspecifica">
                                            <h5 class="bold mb-2">{{ t('EspecificaBusqueda') }}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer" style="display: none;">
                        <button type="button" class="btn btn-default" data-bs-dismiss="modal" #btnModalSearchNavigation>{{ t('Cerrar') }}</button>
                    </div>
                </div>
            </form>
        </div>
    </div> -->


                            
                        <!-- <div class="col-md-6" *ngIf="this.formSearchNavigation.controls.Solucion.value !== '' && searchTSeleccionado == 2">                        
                            <div class="row offset-2">
                                     
                                <ng-container *ngFor="let periodo of searchPeriodo; let i = index">
                                    <input
                                        class="icheckbox_square-blue"
                                        type="radio"
                                        name="tipobusqueda"
                                        formControlName="bAvanzada"
                                        value={{periodo.value}}
                                        (ngModelChange)="modelChanged($event)"
                        
                                    /> -->
                                    <!-- [(ngModel)]="periodoSeleccionado" -->
                                    <!-- &nbsp;&nbsp;{{ periodo.text }}&nbsp;&nbsp;
                                </ng-container>
                            </div>
                        </div> -->

                        <!-- 

                    <div class="row">
                        <div class="col-md-6 else-class">
                            <label *ngIf="this.formSearchNavigation.controls.TipoBusqueda.value != ''">{{LabelTipoBusqueda}}</label>
                            <input formControlName="Busqueda" (keydown.enter)="validateFormSearchNavigation()" *ngIf="this.formSearchNavigation.controls.TipoBusqueda.value != ''" type="text" class="form-control" placeholder="{{ t('Ingrese') }} {{LabelTipoBusqueda}}">
                            <div *ngIf="this.formSearchNavigation.controls.TipoBusqueda.value !=''">
                                <div *ngIf="formSearchNavigation.controls['Busqueda'].invalid && (formSearchNavigation.controls['Busqueda'].dirty || formSearchNavigation.controls['Busqueda'].touched)" class="error">
                                    <div *ngIf="formSearchNavigation.controls['Busqueda']?.errors?.required">{{ t('MesageValidate') }}</div>
                                </div>
                            </div>
                        </div>    
                    </div> -->

                                                                <!-- <div class=" col-6 mt-2  d-flex" *ngIf="periodoSeleccionado == 1">
                                                <div class="col-4 m-1">
                                                        <nz-select formControlName="f_mes_ini" nzPlaceHolder="Selecciona Mes" required>
                                                            <nz-option *ngFor="let mes of dataMeses" [nzValue]="mes.Value" [nzLabel]="mes.Display" ></nz-option>
                                                          </nz-select>
                                                </div>
                                                <div class="col-4 m-1">
                                                          <nz-select formControlName="f_year_fin"  nzPlaceHolder="Selecciona año" required>
                                                            <nz-option *ngFor="let year of dataYears" [nzValue]="year.anio" [nzLabel]="year.anio" ></nz-option>
                                                          </nz-select>
                                                </div>
                                            </div> -->

                                                                <!-- <div class="modal-footer" style="display: none;">
                        <button type="button" class="btn btn-default" data-bs-dismiss="modal" #btnModalSearchNavigation>{{ t('Cerrar') }}</button>
                    </div> -->