import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
    selector: 'sb-commons',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './commons.component.html',
    styleUrls: ['commons.component.scss'],
})
export class CommonsComponent implements OnInit {
    constructor() {}
    ngOnInit() {}

}
