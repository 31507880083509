import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'gk-show-image-m',
  templateUrl: './show-image-m.component.html',
  styleUrl: './show-image-m.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShowImageMComponent {

  //? recibe
  titulo!: string;
  imagenBase64: string | null = null;
  height!: string;
  width!: string; 

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,){
    if(this.data && this.data.imagen && this.data.titulo && this.data.height && this.data.width ){
      this.imagenBase64 = 'data:image/png;base64,' + this.data.imagen;
      this.titulo = this.data.titulo;
      this.height = this.data.height;
      this.width  = this.data.width;
    }else if(this.data && this.data.imagen && this.data.titulo){
      this.imagenBase64 = 'data:image/png;base64,' + this.data.imagen;
      this.titulo = this.data.titulo;
    }
  }

}
