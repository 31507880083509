<h3 mat-dialog-title class="text-center">Agregar Comentario {{nombreCuenta}}</h3>
<mat-dialog-content class="mat-typography">
    <form
        [formGroup]="formAsignarComentario"
        (ngSubmit)="guardarComentario()"
        autocomplete="off">

        <div class="row">
            <div class="col-1"></div>
            <div class="col-3"> 
                <label class="control-label fw-bold">Activado para cliente:</label>
                <span class="form-text text-danger validation-message ms-2" *ngIf="isValidFieldAsignarComentario('VerCliente')" @fadeInOut>
                    <b>*</b>
                </span>
            </div>
            <div class="col-2"> 
                <nz-select class="w-100"
                    formControlName="VerCliente"
                    nzAllowClear>
                    <nz-option
                        [nzValue]="1"
                        nzLabel="Si">
                    </nz-option>
                    <nz-option
                        [nzValue]="0"
                        nzLabel="No">
                    </nz-option>
                </nz-select>
            </div>
            <div class="col-3"> 
                <label class="control-label fw-bold">Enviar por correo:</label>
                <span class="form-text text-danger validation-message ms-2" *ngIf="isValidFieldAsignarComentario('correo')" @fadeInOut>
                    <b>*</b>
                </span>
            </div>
            <div class="col-2"> 
                <nz-select 
                formControlName="correo"
                    nzAllowClear>
                    <nz-option
                        [nzValue]="1"
                        nzLabel="Si">
                    </nz-option>
                    <nz-option
                        [nzValue]="0"
                        nzLabel="No">
                    </nz-option>
                </nz-select>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-1"></div>
            <div class="col-10">
                <span class="form-text text-danger validation-message ms-2" *ngIf="isValidFieldAsignarComentario('coment')" @fadeInOut>
                    <b>Favor de agregar un comentario</b>
                </span>
                <quill-editor formControlName="coment" [modules]="editorModules" style="height: 100px; width: 100%;"></quill-editor>
            </div>
        </div>
        @if(hayFecha){
            <div class="row mt-2">
                <div class="col-1"></div>
                <div class="col-4"> 
                    <label class="control-label fw-bold">Fecha de Recordatorio:</label>
                    <span class="form-text text-danger validation-message ms-2" *ngIf="isValidFieldAsignarComentario('fecharecordatorio')" @fadeInOut>
                        <b>*</b>
                    </span>
                </div>
                <div class="col-6">
                    <input type="date" class="form-control" formControlName="fecharecordatorio" >
                </div>
            </div>
        }

        <div class="full-width-element full-height-element">
            <mat-divider class="mt-4"></mat-divider>
            <mat-dialog-actions align="end" class="gris py-4">
                <button type="submit" class="btn btn-primary mx-2 mt-3">
                    Agregar Comentario
                </button>
                <button type="button" [mat-dialog-close] class="btn btn-secondary mx-2 mt-3">
                    Cerrar
                </button>
            </mat-dialog-actions>
        </div>
    
    </form>
</mat-dialog-content>