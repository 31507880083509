import { AfterContentInit, Directive, OnDestroy, Optional } from '@angular/core';
import { FormGroupDirective, NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
    selector: '[gkForm]',
})
export class GkFormErrorsDirective implements AfterContentInit, OnDestroy {
    private destroy$ = new Subject<void>();

    constructor(@Optional() public _parentForm: NgForm, @Optional() public _parentFormGroup: FormGroupDirective) {}

    ngAfterContentInit(): void {
        const parent = this._parentFormGroup || this._parentForm;

        parent.ngSubmit.pipe(takeUntil(this.destroy$)).subscribe(() => {
            for (const key of Object.keys(parent.control.controls)) {
                parent.control.controls[key].markAsDirty();
                parent.control.controls[key].updateValueAndValidity();
            }
        });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
