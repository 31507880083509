import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NavigationService } from '@modules/navigation/services';

@Component({
    selector: 'sb-dashboard-head',
    templateUrl: './dashboard-head.component.html',
    styleUrls: ['dashboard-head.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardHeadComponent {
    @Input() title!: string;
    @Input() hideBreadcrumbs = false;
    @Input() color?: string;
    @Input() size?: string;
    title$ = this.navigationService.title$;
    breadcrumbs$ = this.navigationService.breadcrumbs$;

    constructor(private navigationService: NavigationService) {}
}
