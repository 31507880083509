import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { UserService } from '@modules/auth/services';
import { Observable, of } from 'rxjs';
import { Administration } from '../models';

@Injectable()
export class InvoiceService {
    API_URL_ADMINISTRATION = environment.api.administration;
    constructor(private http: HttpClient, private userService: UserService) {}

    getInvoice$(): Observable<{}> {
        return of({});
    }

    async getRemisionesPorFacturar() {
        return new Promise((resolve, reject) => {
            this.http.get<Administration>(`${this.API_URL_ADMINISTRATION}/invoice/pending`).subscribe(resp => {
                if (resp.status === 'fail') {
                    // console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                // console.info('RESPONSE: ', resp.data);
                resolve(resp.data);
            });
        });
    }

    async getPreliminaryInvoice(requestBody) {
        return new Promise((resolve, reject) => {
            this.http
            .post<Administration>(`${this.API_URL_ADMINISTRATION}/invoice/preliminary`, {
                ...requestBody,
                    UsuarioSesionId: this.userService.vUsuarioID,
                })
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        // console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                });
        });
    }

    async proccessInvoice(requestBody) {
        return new Promise((resolve, reject) => {
            this.http
                .post<Administration>(`${this.API_URL_ADMINISTRATION}/invoice/process`, {
                    ...requestBody,
                    UsuarioSesionId: this.userService.vUsuarioID,
                })
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        // console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                });
        });
    }

    async GetFacturas(requestBody) {
        return new Promise((resolve, reject) => {
            this.http
                .post<Administration>(`${this.API_URL_ADMINISTRATION}/invoices/get/params`, {
                    ...requestBody,
                    UsuarioSesionId: this.userService.vUsuarioID,
                })
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        // console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                });
        });
    }
}
