<div class="container-fluid">
    <form *ngIf="(formbuldierConfig | json) !== '{}'" [formGroup]="formFiltros" #f="ngForm" (ngSubmit)="generarFiltro()">
        <div>
            <div id="TipoInstitucion"  *ngIf="ConfigShow.TipoBusqueda.show ===true && ([1].includes(this.ActiveNivelID) || ([3,6].includes(this.ActiveNivelID) && ConfigShow.TipoBusqueda.vistaadmin))">
                <div class="row mt-3">
                    <div class="col-2">
                        <label for="soluciones"><fa-icon class="mr-1" [icon]='["fas", "filter"]'></fa-icon> &nbsp;<strong>Tipo de Institución:</strong></label>
                    </div>
                    <div class="col-10">
                        <div class="ng-star-inserted" >
                            <ng-container *ngFor="let type of searchType">
                                <input
                                    class="icheckbox_square-blue"
                                    type="radio"
                                    name="TipoBusqueda"
                                    formControlName="TipoBusqueda"
                                    (change)="onCheckChangeBusqueda()"
                                    value={{type.value}}
                                />
                                &nbsp;&nbsp;{{Language('tiposInstituciones')[type.value-1]}}&nbsp;&nbsp;
                                <!---&nbsp;&nbsp;{{ type.text }}&nbsp;&nbsp;--->
                            </ng-container>
                        </div>

                        <div class="row mt-2">
                            <div class="col-md-12" *ngIf="(formFiltros!.controls['TipoBusqueda'].value !== '3' && formFiltros!.controls['TipoBusqueda'].value !== '4') && (ActiveNivelID === 1 || ActiveNivelID === 3 || ActiveNivelID === 6)">
                                <div >
                                    <!---<label class="control-label">{{txtTipoBusqueda}}</label>
                                    <br><br>--->
                                    <div class="">
                                        <nz-form-item>
                                            <nz-form-control>
                                                    <nz-select nzSize="large" nzShowSearch nzPlaceHolder="Selecciona Institucion" [nzMaxTagCount]="5" [nzAllowClear]="true" formControlName="Institucion_Id">
                                                    <nz-option nzLabel="{{Language('cbxTodas')}}" [nzValue]= '0' ></nz-option>
                                                    <nz-option *ngFor="let dataInstituciones of arrayFiltradoInstituciones" [nzLabel]="dataInstituciones.NombreInst" [nzValue]="dataInstituciones.Institucion_Id"> </nz-option>
                                                </nz-select>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="TipoBusquedaDatos">
                <div class="row mt-3">
                    <div class="col-md-12 text-center">
                        <div class="ng-star-inserted">
                            <ng-container *ngFor="let type of searchTypeData">
                                <input
                                    class="icheckbox_square-blue"
                                    type="radio"
                                    name="TipoBusquedaDatos"
                                    formControlName="TipoBusquedaDatos"
                                    value={{type.value}}
                                />
                                &nbsp;&nbsp;{{Language('searchtypedata')[type.value]}}&nbsp;&nbsp;
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div id="TipoBusquedaTexto">
                    <div class="row mt-3">
                        <div class="col-md-12 text-center">
                            <nz-form-item *ngIf="formFiltros!.controls['TipoBusquedaDatos'].value !== '6'">
                                <nz-form-control>
                                <nz-input-group>
                                    <input type="text" nz-input formControlName="txtBusqueda" placeholder="{{Language('lblIngrese')}} {{Language('searchtypedata')[formFiltros!.controls['TipoBusquedaDatos'].value]}}" />
                                </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </div>
                    <div id="TipoBusquedaPeriodo" *ngIf="formFiltros!.controls['TipoBusquedaDatos'].value === '6'">
                        <div class="row mt-3">
                            <!-- <div class="col-1">
                                <label for="soluciones"><fa-icon class="mr-1" [icon]='["fas", "filter"]'></fa-icon> &nbsp;<strong>Periodo:</strong></label>
                            </div> -->
                            <div class="col-md-12 text-center">
                                <div class="ng-star-inserted">
                                    <ng-container *ngFor="let periodo of searchPeriodo">
                                        <input
                                            class="icheckbox_square-blue"
                                            type="radio"
                                            name="TipoBusquedaPeriodo"
                                            formControlName="TipoBusquedaPeriodo"
                                            value="{{periodo.value}}"
                                        />
                                        &nbsp;&nbsp;{{Language('TiposPeriodos')[periodo.value]}}&nbsp;&nbsp;
                                    </ng-container>
                                </div>
                                <div class="col-md-12 mt-2" *ngIf="formFiltros!.controls['TipoBusquedaPeriodo'].value !== '0'">
                                    <div class="col-12 form-group"  *ngIf="formFiltros!.controls['TipoBusquedaPeriodo'].value === '1'">
                                        <!---<div>
                                            <label class="control-label">Seleccione Fecha </label>
                                        </div><br>--->
                                        <div nz-row [nzGutter]="16">
                                            <div nz-col class="gutter-row" [nzSpan]="12">
                                                <nz-form-item>
                                                    <nz-form-control>
                                                        <nz-select nzSize="large" nzShowSearch nzPlaceHolder="{{Language('Mes')}}" [nzMaxTagCount]="5" [nzAllowClear]="true" formControlName="mes">
                                                            <nz-option *ngFor="let FechaMes of dataMeses" [nzLabel]="FechaMes.mes" [nzValue]="FechaMes.id"> </nz-option>
                                                        </nz-select>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </div>
                                            <div nz-col class="gutter-row" [nzSpan]="12">
                                                <nz-form-item>
                                                    <nz-form-control>
                                                        <nz-select nzSize="large" nzShowSearch nzPlaceHolder="{{Language('Ano')}}" [nzMaxTagCount]="5" [nzAllowClear]="true" formControlName="anio">
                                                            <nz-option *ngFor="let anio of years" [nzLabel]="anio.year" [nzValue]="anio.year"> </nz-option>
                                                        </nz-select>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12" *ngIf="formFiltros!.controls['TipoBusquedaPeriodo'].value === '2'">
                                        <div class="col-12" nz-row [nzGutter] = "16">
                                            <div class="col-6" nz-col class="gutter-row" [nzSpan]="12">
                                                <nz-form-item>
                                                    <nz-form-control>
                                                        <input nz-input placeholder="Fecha inicial" nzSize="large" type="date" formControlName="fechaIni" />
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </div>
                                            <div class="col-6" nz-col class="gutter-row" [nzSpan]="12">
                                                <nz-form-item>
                                                    <nz-form-control>
                                                        <input nz-input placeholder="Fecha final" nzSize="large" type="date" formControlName="fechaFin" />
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <button type="submit" class="btn btn-primary  btn-lg " >{{Language('btnGenerar')}}</button>
                </div>
            </div>
        </div>
    </form>
</div>
