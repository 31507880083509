<div class="container-fluid {{clasePadding}}">
    <ng-container *ngIf="bitVisor"></ng-container>


    <form [formGroup]="formFiltros" #f="ngForm" (ngSubmit)="generarFiltro(f)" class="pf-container">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-12 text-center">
                    <div style="margin-top: 5px;font-size: 24px; color:black">Detalle del Papel Filtro<br></div>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-md-6">
                    <h2>Información del paciente<hr></h2>
                    
                    <div class="row mt-1">
                        <label class="col-md-4">Nombre</label>
                        <div class="col-md-8">
                            <div nz-col class="gutter-row" [nzSpan]="24">
                                <nz-form-item>
                                    <nz-form-control>
                                        <input nz-input formControlName="nombre" nzSize="large" type="text" />
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <label class="col-md-4">Apellidos</label>
                        <div class="col-md-8">
                            <div nz-col class="gutter-row" [nzSpan]="24">
                                <nz-form-item>
                                    <nz-form-control>
                                        <input nz-input formControlName="Apellidos" nzSize="large" type="text " />
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <label class="col-md-4 mt-2">Fecha y hora nacimiento</label>
                        <div class="col-md-4 mt-2">
                            <div nz-col class="gutter-row" [nzSpan]="24">
                                <nz-form-item>
                                    <nz-form-control>
                                        <nz-date-picker nzFormat="yyyy-MM-dd HH:mm:ss" [nzDisabledDate]="disabledDate" [nzShowTime]="{ nzDefaultOpenValue: timeDefaultValue }" formControlName="fechaNac"></nz-date-picker>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                        <div class="col-md-1" align="left">
                            <div nz-col class="gutter-row" [nzSpan]="24">
                                <div class="d-flex mb-2 mt-2">
                                    <ng-container>
                                        <div class="col-8" style="margin-top: 10px;">
                                            <h5>AM</h5>
                                        </div>
                                        <div class="col-4" style="margin-top: 10px;">
                                            <input type="radio" name="fechaNacHorario" formControlName="fechaNacHorario" value="am" />
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="d-flex mb-2">
                                    <ng-container>
                                        <div class="col-8" style="margin-top: -18px;">
                                            <h5>PM</h5>
                                        </div>
                                        <div class="col-4" style="margin-top: -18px;">
                                            <input type="radio" name="fechaNacHorario" formControlName="fechaNacHorario" value="pm" />
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <label class="col-md-4">Sexo</label>
                        <div class="col-md-8">
                            <div nz-col class="gutter-row" [nzSpan]="24">
                                <nz-form-item>
                                    <nz-form-control>
                                        <nz-select nzSize="large" formControlName="sexo" nzShowSearch>
                                            <nz-option nzLabel="{{type.text}}" nzValue="{{type.value}}" *ngFor="let type of searchType"></nz-option>
                                        </nz-select>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <label class="col-md-4">Grupo Étnico</label>
                        <div class="col-md-8">
                            <div nz-col class="gutter-row" [nzSpan]="24">
                                <nz-form-item>
                                    <nz-form-control>
                                        <nz-select nzSize="large" formControlName="raza" nzShowSearch>
                                            <nz-option nzLabel="{{type.text}}" nzValue="{{type.value}}" *ngFor="let type of searchRaza"></nz-option>
                                        </nz-select>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>

                        </div>
                    </div>
                    <div id="divdatosMadre" *ngIf="verDatosMadre">
                        <div class="row mt-1">
                            <label class="col-md-4 redlabel">Nombre Madre</label>
                            <div class="col-md-8">
                                <div nz-col class="gutter-row" [nzSpan]="24">
                                    <nz-form-item>
                                        <nz-form-control>
                                            <input nz-input formControlName="mamanombre" nzSize="large" type="text" />
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-1">
                            <label class="col-md-4 redlabel">Apellidos Madre</label>
                            <div class="col-md-8">
                                <div nz-col class="gutter-row" [nzSpan]="24">
                                    <nz-form-item>
                                        <nz-form-control>
                                            <input nz-input formControlName="mamaApellidos" nzSize="large" type="text " />
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-1">
                            <label class="col-md-4">Direccion</label>
                            <div class="col-md-8">
                                <div nz-col class="gutter-row" [nzSpan]="24">
                                    <nz-form-item>
                                        <nz-form-control>
                                            <input nz-input formControlName="mamadir" nzSize="large" type="text " />
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-1">
                            <label class="col-md-4">País</label>
                            <div class="col-md-8">
                                <div nz-col class="gutter-row" [nzSpan]="24">
                                    <nz-form-item>
                                        <nz-form-control><!----->
                                            <nz-select nzSize="large" nzPlaceHolder="Selecciona pais" formControlName="paismama" nzShowSearch (ngModelChange)="onCheckChangeBusqueda($event)">
                                                <nz-option *ngFor="let data of DataPaises" [nzLabel]="data.Pais" [nzValue]="data.Pais_id"></nz-option>
                                            </nz-select>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-1">
                            <label class="col-md-4">Estado</label>
                            <div class="col-md-8">
                                <div nz-col class="gutter-row" [nzSpan]="24">
                                    <nz-form-item>
                                        <nz-form-control>
                                            <nz-select nzSize="large" nzPlaceHolder="Selecciona estado" formControlName="estadomama" nzShowSearch (ngModelChange)="onCheckChangeBusqueda2($event)">
                                                <nz-option value=''></nz-option>
                                                <nz-option *ngFor="let data of DataEstadosM" [nzLabel]="data.Estado" [nzValue]="data.Estado_id"></nz-option>
                                            </nz-select>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                        </div>
                        </div>
                        <div class="row mt-1">
                            <label class="col-md-4">Ciudad</label>
                            <div class="col-md-8">
                                <div nz-col class="gutter-row" [nzSpan]="24">
                                    <nz-form-item>
                                        <nz-form-control>
                                            <nz-select nzSize="large" nzPlaceHolder="Selecciona ciudad" formControlName="ciudadmama" nzShowSearch>
                                                <nz-option *ngFor="let data of DataCiudadesM" [nzLabel]="data.Ciudad" [nzValue]="data.Ciudad_id"></nz-option>
                                            </nz-select>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-1">
                            <label class="col-md-4">Telefono</label>
                            <div class="col-md-8">
                                <div nz-col class="gutter-row" [nzSpan]="24">
                                    <nz-form-item>
                                        <nz-form-control>
                                            <input nz-input formControlName="telefonomama" nzSize="large" type="text " />
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-1">
                            <label class="col-md-4">Correo</label>
                            <div class="col-md-8">
                                <div nz-col class="gutter-row" [nzSpan]="24">
                                    <nz-form-item>
                                        <nz-form-control>
                                            <input nz-input formControlName="correomama" nzSize="large" type="text " />
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <h2>Información del médico<hr></h2>
                    
                    <div class="row mt-1">
                        <label class="col-md-4">Nombre</label>
                        <div class="col-md-8">
                            <div nz-col class="gutter-row" [nzSpan]="24">
                                <nz-form-item>
                                    <nz-form-control>
                                        <input nz-input formControlName="mediconombre" nzSize="large" type="text" />
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <label class="col-md-4">Apellidos</label>
                        <div class="col-md-8">
                            <div nz-col class="gutter-row" [nzSpan]="24">
                                <nz-form-item>
                                    <nz-form-control>
                                        <input nz-input formControlName="medicoApellidos" nzSize="large" type="text " />
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                    </div>
                    <br>
                    <br>
                    <br>
                    <br>
                    <div class="row">
                        <label class="col-md-4">Direccion</label>
                        <div class="col-md-8">
                            <div nz-col class="gutter-row" [nzSpan]="24">
                                <nz-form-item>
                                    <nz-form-control>
                                        <input nz-input formControlName="medicodir" nzSize="large" type="text " />
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <label class="col-md-4">País</label>
                        <div class="col-md-8">
                            <div nz-col class="gutter-row" [nzSpan]="24">
                                <nz-form-item>
                                    <nz-form-control><!----->
                                        <nz-select nzSize="large" nzPlaceHolder="Selecciona pais" formControlName="paismedico" nzShowSearch (ngModelChange)="onCheckChangeBusqueda3($event)">
                                            <nz-option *ngFor="let data of DataPaises" [nzLabel]="data.Pais" [nzValue]="data.Pais_id"></nz-option>
                                        </nz-select>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <label class="col-md-4">Estado</label>
                        <div class="col-md-8">
                            <div nz-col class="gutter-row" [nzSpan]="24">
                                <nz-form-item>
                                    <nz-form-control>
                                        <nz-select nzSize="large" nzPlaceHolder="Selecciona estado" formControlName="estadomedico" nzShowSearch (ngModelChange)="onCheckChangeBusqueda4($event)">
                                            <nz-option value=''></nz-option>
                                            <nz-option *ngFor="let data of DataEstadosMe" [nzLabel]="data.Estado" [nzValue]="data.Estado_id"></nz-option>
                                        </nz-select>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                    </div>
                    </div>
                    <div class="row mt-1">
                        <label class="col-md-4">Ciudad</label>
                        <div class="col-md-8">
                            <div nz-col class="gutter-row" [nzSpan]="24">
                                <nz-form-item>
                                    <nz-form-control>
                                        <nz-select nzSize="large" nzPlaceHolder="Selecciona ciudad" formControlName="ciudadmedico" nzShowSearch>
                                            <nz-option *ngFor="let data of DataCiudadesMe" [nzLabel]="data.Ciudad" [nzValue]="data.Ciudad_id"></nz-option>
                                        </nz-select>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <label class="col-md-4">Telefono</label>
                        <div class="col-md-8">
                            <div nz-col class="gutter-row" [nzSpan]="24">
                                <nz-form-item>
                                    <nz-form-control>
                                        <input nz-input formControlName="telefono" nzSize="large" type="text " />
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <label class="col-md-4">Correo</label>
                        <div class="col-md-8">
                            <div nz-col class="gutter-row" [nzSpan]="24">
                                <nz-form-item>
                                    <nz-form-control>
                                        <input nz-input formControlName="correo" nzSize="large" type="text " />
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <br>
            <br>
            <div class="row">
                <div class="col-md-12">
                    <h2>Descripción del Padecimiento<hr></h2>
                    <div class="row mt-1">
                        <div class="col-md-1"></div>
                        <div class="col-md-10">
                            <textarea formControlName="descripcion" class="form-control" rows="3" cols="110"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <br>
            <br>
            <div class="row">
                <div class="col-md-12">
                    <h2>Antecedente de Importancia para el Padecimiento<hr></h2>
                    <div class="row mt-1">
                        <div class="col-md-1"></div>
                        <div class="col-md-10">
                            <textarea formControlName="antecedente" class="form-control" rows="3" cols="110"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <br>
            <div class="row">
                <div class="col-md-12">
                    <h2>Estudios realizados<hr></h2>
                    <div class="row mt-1">
                        <div class="col-md-1"></div>
                        <div class="col-md-10">
                            <textarea formControlName="estudiosrealizados" class="form-control" rows="3" cols="110"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <br>
            <br>
            <div class="row">
                <div class="col-md-12">
                    <h2>Archivos<hr></h2>
                    <div class="row mt-1">
                        <div class="col-md-1"></div>
                        <div class="col-md-10">
                            <input type="file">
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-md-1"></div>
                        <div class="col-md-10">
                            <table datatable class="table table-striped table-bordered row-border hover">
                                <thead class="ttdblue">
                                    <tr>
                                        <td align="center" width="90%">Archivo</td>
                                        <td align="center" width="10%"></td>
                                    </tr>
                                </thead>
                                <tbody>Estudios_Realizados_Id, Solicitud_Asesoria_Id, Archivo
                                    <tr *ngFor="let item of archivosArray; index as i">
                                        <td align="center">
                                            <a href="\Soluciones_Estudios\{{item.Archivo}}" target="_blank">{{item.Archivo}}</a>
                                        </td>					
                                        <!---
                                        <td class="text-center">
                                            <a class="txtlinka me-2" (click)="MostrarAnexo(item.AnexoId, item.Anexo, i+1)" title="{{ t('Descargar') }}" style="font-size: 18px">
                                                <fa-icon [icon]="['fas', 'file-download']"></fa-icon>
                                            </a>
                                            <a class="txtlinka me-2" (click)="DelAnexo(item.AnexoId, i+1)" title="{{ t('Eliminar') }}" style="font-size: 18px" *ngIf="!FinalizadaBit">
                                                <fa-icon [icon]="['fas', 'trash-alt']"></fa-icon>
                                            </a>
                                        </td>
                                        -->
                                    </tr>
                                </tbody>
                            </table>

                            <!--- <table border="0" cellpadding="0" cellspacing="0" align="center" width="50%">				
                                <tr>
                                    <td colspan="7" class="style7" align="center">Archivo</td>
                                    <td colspan="7" class="style7" align="center"></td>
                                </tr>
                                <cfoutput query="qryEstu">						
                                    <tr>
                                        <td colspan="7" class="style7" align="center"><a href="\Soluciones_Estudios\#archivo#" target="_blank">#Archivo#</a></td>					
                                        <td colspan="7" width="10%" class="style7" align="center"><div align="center"><a href="javascript:eliminafile('estudiosrealizados_borrar.cfm',#estudios_realizados_id#, #soliid#);void(0);" class="style7" style="TEXT-DECORATION: NONE"><img border="0" src="images/icono_tacha.png" width="23" height="24" title="Borrar" /></a></div></td>
                                    </tr>			
                                </cfoutput>		
                            </table>---->		
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <br>
            <br>
            <div class="row">
                <div class="col-md-12">
                    <h2>Impresión Diagnóstica<hr></h2>
                    <div class="row mt-1">
                        <div class="col-md-1"></div>
                        <div class="col-md-10">
                            <textarea formControlName="impresiondiagnostica" class="form-control" rows="3" cols="110"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <br>
            <br>
            <div class="row">
                <div class="col-md-12">
                    <h2>Otra información<hr></h2>
                    <div class="row mt-1">
                        <label class="col-md-2">Moneda Factura:</label>
                        <div class="col-md-4">
                            <div nz-col class="gutter-row" [nzSpan]="24">
                                <nz-form-item>
                                    <nz-form-control>
                                        <nz-select nzSize="large" formControlName="monedafactura" nzShowSearch>
                                            <nz-option nzLabel="{{type.text}}" nzValue="{{type.value}}" *ngFor="let type of searchMoneda"></nz-option>
                                        </nz-select>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>

                        <label class="col-md-2">Medicamentos:</label>
                        <div class="col-md-4">
                            <table class="table-striped tablaDx" width="100%"> 
                                <tr>
                                    <td width="50%" class="black mt-2"><input type="checkbox" formControlName="medicamento1" value="1" />Anticonvulsivos</td>
                                    <td width="50%" class="black mt-2"><input type="checkbox" formControlName="medicamento2" value="1" />Carnitina</td>
                                </tr>
                                <tr>  
                                    <td class="black mt-2"><input type="checkbox" formControlName="medicamento3" value="1" />Antidepresivos</td>
                                    <td class="black mt-2"><input type="checkbox" formControlName="medicamento4" value="1" />Anticonceptivos Orales</td>
                                </tr>
                                <tr>
                                    <td class="black mt-2"><input type="checkbox" formControlName="medicamento5" value="1" />Antibióticos</td>
                                    <td class="black mt-2"><input type="checkbox" formControlName="medicamento6" value="1" />Otros</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <label class="col-md-2">Transfusiones:</label>
                        <div class="col-md-4" align="left">                                
                            <input formControlName="transfusion" type="radio" value="1"><strong class="black">Si</strong>
                            &nbsp;&nbsp;
                            <input formControlName="transfusion" type="radio" value="0" (ngModelChange)="limpiaFechaTrans()"><strong class="black">No</strong>
                        </div>
                    </div>
                    <div class="row" *ngIf="formFiltros.controls.transfusion.value == 1">
                        <label class="col-md-2 mt-2">Fecha de Transfusión:</label>
                        <div class="col-md-4 mt-2">
                            <div nz-col class="gutter-row" [nzSpan]="24">
                                <nz-form-item>
                                    <nz-form-control>
                                        <nz-date-picker nzFormat="yyyy-MM-dd HH:mm:ss" [nzDisabledDate]="disabledDate" [nzShowTime]="{ nzDefaultOpenValue: timeDefaultValue }" formControlName="fechaTrans"></nz-date-picker>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <br>
            <br>
            <div class="rows">
                <div class="col-md-12">
                    <h2>Información de la prueba<hr></h2>
                    <div class="row mt-1">
                        <div class="col-md-12" align="center">                        
                            <span id="cPrueba" title="Código de la Prueba" class="black" style="font-style:italic; font-weight:bold">{{informacionPruebaArray.cPrueba}}</span>
                            &nbsp;&nbsp;
                            <span id="nPrueba" title="Nombre de la Prueba" class="black" style="font-style:italic; font-weight:bold">{{informacionPruebaArray.nPrueba}}</span>
                        </div>
                    </div>  
                </div>
            </div>
            <div class="row">
                <div class="col-md-6"> 
                    <div class="row">
                        <div class="col-md-4" align="left">                        
                            <label>Número de Seguimiento:</label>
                        </div>
                        <div class="col-md-8 black" align="left" id="nSeguimiento">
                            {{informacionPruebaArray.nSeguimiento}}
                        </div>
                    </div>                        
                </div>
            </div>
            <div class="row">
                <label class="col-md-2 mt-2">Fecha de muestreo:</label>
                <div class="col-md-2 mt-2">
                    <div nz-col class="gutter-row" [nzSpan]="24">
                        <nz-form-item>
                            <nz-form-control>
                                <nz-date-picker nzFormat="yyyy-MM-dd HH:mm:ss" [nzDisabledDate]="disabledDate" [nzShowTime]="{ nzDefaultOpenValue: timeDefaultValue }" formControlName="fechaMues"></nz-date-picker>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
                <div class="col-md-1" align="left">
                    <div nz-col class="gutter-row" [nzSpan]="24">
                        <div class="d-flex mb-2 mt-2">
                            <ng-container>
                                <div class="col-8" style="margin-top: 24px;">
                                    <h5>AM</h5>
                                </div>
                                <div class="col-4" style="margin-top: 24px;">
                                    <input type="radio" name="fechaMuesHorario" formControlName="fechaMuesHorario" value="am" />
                                </div>
                            </ng-container>
                        </div>
                        <div class="d-flex mb-2">
                            <ng-container>
                                <div class="col-8" style="margin-top: -18px;">
                                    <h5>PM</h5>
                                </div>
                                <div class="col-4" style="margin-top: -18px;">
                                    <input type="radio" name="fechaMuesHorario" formControlName="fechaMuesHorario" value="pm" />
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <label class="col-md-2">Muestra para estudio prenatal:</label>
                <div class="col-md-4" align="left">                                
                    <input formControlName="mpre" type="radio" value="1"><strong class="black">Si</strong>
                    &nbsp;&nbsp;
                    <input formControlName="mpre" type="radio" value="0"><strong class="black">No</strong>
                </div>
                <label class="col-md-2">Tipo de Muestra:</label>
                <div class="col-md-4">
                    <table class="table-striped tablaDx" width="100%"> 
                        <tr>
                            <td width="33%" style="border:none" class="black">
                                <input type="checkbox" formControlName="chkTM1"/>
                                Sangre en EDTA
                            </td>
                            <td width="33%" style="border:none" class="black">
                                <input type="checkbox" formControlName="chkTM2"/>
                                Tejido
                            </td>
                            <td width="33%" style="border:none" class="black">
                                <input type="checkbox" formControlName="chkTM3"/>
                                Orina
                            </td>
                        </tr>
                        <tr>
                            <td style="border:none" class="black">
                                <input type="checkbox" formControlName="chkTM4"/>
                                Sangre en Heparina
                            </td>
                            <td style="border:none" class="black">
                                <input type="checkbox" formControlName="chkTM5"/>
                                Suero
                            </td>
                            <td style="border:none" class="black">
                                <input type="checkbox" formControlName="chkTM6"/>
                                Saliva
                            </td>
                        </tr>
                        <tr>
                            <td style="border:none" class="black">
                                <input type="checkbox" formControlName="chkTM7"/>
                                Plasma
                            </td>
                            <td style="border:none" class="black">
                                <input type="checkbox" formControlName="chkTM8"/>
                                ADN	
                            </td>
                            <td style="border:none" class="black">
                                <input type="checkbox" formControlName="chkTM9"/>
                                Cultivo Fibroblastos
                            </td>
                        </tr>
                        <tr>
                            <td style="border:none" class="black">
                                <input type="checkbox" formControlName="chkTM10"/>
                                Sangre en papel filtro
                            </td>
                            <td style="border:none" class="black">
                                <input type="checkbox" formControlName="chkTM11"/>
                                L&iacute;quido amni&oacute;tico
                            </td>
                            <td style="border:none" class="black">
                                <input type="checkbox" formControlName="chkTM12"/>
                                Vellosidades coriales
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="row mt-5" *ngIf="!bitVisor">
                <div class="col-md-3">
                    <gk-comments-filter [DataComentarios]="DataComentarios"></gk-comments-filter>
                </div>
            </div> 
        </div>
        <ng-container *ngIf="!bitVisor">
            <br><br>
            <br><br>
            <div class="row mt-5">
                <div class="col-md-12 text-center">
                    <button type="button" class="btn btn-default pad1" (click)="RegresarFiltro()">{{Language('captura.regresar')}}</button>
                    <button type="submit" class="ml-1 btn btn-success pad1">Guardar</button>
                </div>
                <div class="col-md-4"></div>
            </div>
        </ng-container>    
    </form>
</div>