import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CrearContactoMComponent } from '../Modales/crear-contacto-m/crear-contacto-m.component';
import { ActivatedRoute } from '@angular/router';
import { ConfirmacionMComponent } from '../Modales/confirmacion-m/confirmacion-m.component';
import { CustomersService } from '@modules/customers/services';
import { AppCommonService } from '@common/services';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'gk-contacto-tab',
  templateUrl: './contacto-tab.component.html',
  styleUrls: ['./contacto-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactoTabComponent implements ViewChild, AfterViewInit, OnInit {

  descendants: boolean;
  emitDistinctChangesOnly: boolean;
  first: boolean;
  read: any;
  isViewQuery: boolean;
  selector: any;
  static?: boolean | undefined;

  esVisor:boolean = false;

  private newData: any;
  private dialog = inject(MatDialog);
  private router = inject(ActivatedRoute);
  private customersServices = inject(CustomersService);
  public appCommon= inject(AppCommonService);


  @Input() combos:any;
  @Input() tabs:any;

  @Output() dataChangedContactos = new EventEmitter<any>();

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {
    this.router.queryParams.subscribe(params => {
      if (params['esVisor'] === 'true') {
          this.esVisor = true;
      }
    }); 
  }

  ngAfterViewInit(): void {
    this.updateFilteredUsers();
    this.updatePaginatedUsers();
  }

  selectedIndex: number = 0;
  mostrarInactivos: boolean = false;

  selectedPageSize: number = 10;
  pageSizes: number[] = [10, 20, 30];
  filteredUsers: any[] = [];
  paginatedUsers: any[] = [];
  startIndex: number = 0;
  endIndex: number = 0;
  searchTerm: string = '';

  toggleMostrarInactivos(event: Event) {
    this.stopEvent(event);
    this.mostrarInactivos = !this.mostrarInactivos;
    this.updateFilteredUsers();
  }

  stopEvent(event: Event) {
    event.stopPropagation();
  }

  showMsg(titulo: string, mensaje: string) {
    this.dialog.open(ConfirmacionMComponent, {
      data: { titulo: titulo, 
              mensaje: mensaje,
              Noconfirm: true
          }
    })
  }

  newUser(event: Event) {
    this.stopEvent(event);
    const institucionid:number  = parseFloat(this.router.snapshot.params.id);
    const dialogRef = this.dialog.open(CrearContactoMComponent, {
      width: '950px', //? Ancho deseado
      data: { combos: this.combos, institucionid: institucionid }
    })

    dialogRef.componentInstance.contactoAdded.subscribe((newData: any) => {
      this.newData = newData;
    });

    dialogRef.afterClosed().subscribe(() => {
      if (this.newData != undefined) {
        this.tabs.Contacto.Tablas.Usuarios = this.newData; // Actualizar los datos en el hijo
        this.dataChangedContactos.emit(this.newData);
      }
    });
  }

  editUser(usuario, event: Event) {
    this.stopEvent(event);
    const institucionid:number  = parseFloat(this.router.snapshot.params.id);
    const dialogRef = this.dialog.open(CrearContactoMComponent, {
      width: '950px', //? Ancho deseado
      height: '870px',
      data: { combos: this.combos , usuario: usuario, institucionid: institucionid }
    })

    dialogRef.componentInstance.contactoAdded.subscribe((newData: any) => {
      this.newData = newData;
    });

    dialogRef.afterClosed().subscribe(() => {
      if (this.newData != undefined) {
        this.tabs.Contacto.Tablas.Usuarios = this.newData;
        this.dataChangedContactos.emit(this.newData);
      }
    });
  }

  inactivaUser(usuario,  event: Event){
    this.stopEvent(event);
    if(this.esVisor){
      this.showMsg('No es posible realizar esta accion', 'Solo es posible visualizar el formulario');
      return;
    }
    const dialogRef = this.dialog.open(ConfirmacionMComponent, {
      data: { titulo: `Desactivar usuario: ${usuario.Nombre}`, 
              mensaje: `¿Esta seguro de querer desactivar este usuario?` }
    })

    dialogRef.afterClosed().subscribe(confirmo => {
      if (confirmo) {
        this.appCommon.spinner(true);
          this.customersServices.inactivaUser(usuario.UsuarioId).subscribe({
              next: (resp:any) => {
                  this.appCommon.spinner(false);
                  this.tabs.Contacto.Tablas.Usuarios = resp.data.data;
                  this.dataChangedContactos.emit(resp.data.data);
                  this.showMsg('Desactivacion exitosa', 'Se ha desactivado el usuario');           
              }, 
              error: err => {
                  this.appCommon.spinner(false);
                  this.showMsg('No se ha podido desactivar', 'A ocurrido un error al querer desactivar el usuario');
                  console.log('error', err);
              }
          })
      }
    });
  }
  

  onStepChange(stepper: number) {
    if(this.selectedIndex == 1){
      stepper = 0;   
    }
    this.selectedIndex = stepper;
  }

  onPageChange(event: PageEvent) {
    this.startIndex = event.pageIndex * event.pageSize;
    this.endIndex = Math.min(this.startIndex + event.pageSize, this.filteredUsers.length);
    this.updatePaginatedUsers();
  }

  updatePaginatedUsers() {
    this.paginatedUsers = this.filteredUsers.slice(this.startIndex, this.endIndex);
    this.endIndex = Math.min(this.startIndex + this.selectedPageSize, this.filteredUsers.length);
  }

  onSearch(searchTerm: string) {
    this.searchTerm = searchTerm;
    this.updateFilteredUsers();
  }

  updateFilteredUsers() {
    if (this.searchTerm.trim() == '' || this.searchTerm == '') {
      this.updatePaginatedUsers();
      this.filteredUsers = this.usuariosFiltrados;
    } else {
      this.filteredUsers = this.usuariosFiltrados.filter(usuario =>
        usuario.Nombre.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
        usuario.Usuario.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
        usuario.Puesto.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
        usuario.Correo.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
        usuario.Telefono.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    }
    this.paginator.pageIndex = 0;
    this.updatePaginatedUsers();
  }

  get usuariosFiltrados() {
    if (this.mostrarInactivos) {
      return this.tabs.Contacto.Tablas.Usuarios.filter(usuario => !usuario.Activo);
    }
    return this.tabs.Contacto.Tablas.Usuarios.filter(usuario => usuario.Activo);
  }

}
