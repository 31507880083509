import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppCommonService } from '@common/services';
import { MedicalRecordsService } from '@modules/medical-records/services';
import { SpecialTestMComponent } from '../Modales/special-test-m/special-test-m.component';
import { ActivatedRoute, Router } from '@angular/router';
import { PruebasEsp } from '@modules/medical-records/models';

@Component({
  selector: 'gk-special-test',
  templateUrl: './special-test.component.html',
  styleUrl: './special-test.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpecialTestComponent implements OnInit{

  // Variables
  pacienteId: string;
  @Input() pruebasEspeciales: PruebasEsp[];
  @Output() solicitudAgregada = new EventEmitter<void>();

  // Servicios
  private medicalRecordService = inject(MedicalRecordsService);
  private cdr = inject(ChangeDetectorRef);
  private appCommon = inject(AppCommonService);
  private dialog = inject(MatDialog);
  private router = inject(ActivatedRoute);

  ngOnInit(): void {
    this.router.params.subscribe( params =>{
      this.pacienteId = params['pacienteId']
    })
  }

  updateDataTableSearch(value: string): void {
    // Selecciona el input de búsqueda en el datatable
    const dataTableInput = document.querySelector('.dt-input[type="search"]') as HTMLInputElement;
    if (dataTableInput) {
      dataTableInput.value = value;
      dataTableInput.dispatchEvent(new Event('input')); // Activa el evento para que DataTables lo detecte
    }
  }


  verPrueba(id: number){
    
    this.appCommon.spinner(true);

    this.medicalRecordService.getPruebaEspecial(id).subscribe({
      next: (resp) => {
        this.appCommon.spinner(false);
        const dialogData = {
          SpecialProf: resp ,
          PacienteId: this.pacienteId
        }
        const dialogRef = this.dialog.open(SpecialTestMComponent, {
          width: '93vw', //?    Usa el 100% del ancho de la ventana
          maxWidth: '96vw', //? Limita el ancho máximo también al 100% de la ventana
          data: dialogData,
          autoFocus: false
        })

        const instance = dialogRef.componentInstance;
        instance.actualizarDatosEmitter.subscribe(() => {
          this.solicitudAgregada.emit();
        });
      },
      error: err => {
        this.appCommon.spinner(true);
        console.log('err', err);
        this.appCommon.showMsg(`Operacion fallida`, `A ocurrido un error al querer realizar la operacion`);
      }
    });
  }

}
