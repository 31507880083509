import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { TopNavMenuItem } from '@modules/navigation/models';

@Component({
    selector: 'gk-top-nav-menu',
    templateUrl: './top-nav-menu.component.html',
    styleUrls: ['./top-nav-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class TopNavMenuComponent {
    @Input() menuItems: TopNavMenuItem[] = [];


    ngOnInit() {
        //console.info("TOP2 Items From File Menu: ",this.menuItems);
        // get Menu Items from Profile
        /*
        this.userService.menuItems$.subscribe(data=>{
            console.info("TOP Items From File Menu: ",data);
            this.sideNavItems = data;
        });
        */
    }


}
