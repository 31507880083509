import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AppCommonService } from '@common/services';
import { DetailPxAkCkService } from '@common/services/detail-px-akck.component.service';
import { UserService } from '@modules/auth/services';

@Component({
    selector: 'gk-detail-px-akck',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './detail-px-akck.component.html',
    styleUrls: ['detail-px-akck.component.scss'],
})
export class DetailPxAkckComponent implements AfterViewInit {
    @Input() PacienteID
    @Input() TipoDetalle
    dataInfo = []
    DatosPaciente: any = []
    DatosResultadosAK = []
    DatosResultadosCK = []
    DatosResultadosPostAK = []
    DatosEnlacesPostAK:any = []
    DatosComentariosAK = []
    DatosComentariosCK = []
    DatosEnlacesCK:any = []
    DatosTipoEstudio:any = []
    isCliente: any = 0;
    urlBase:any='https://sistema.genomi-k.com/';
    user: any  = btoa('ngUsrGK')
    pass: any  = btoa('ngGenomi-k2023!.123')
    clase: any  = ""
    urlEco: any  = ""
    fileEco: any  = ""
    DataResumen: any  = []
    file: string;
    url: string;



constructor(
        public appCommon: AppCommonService,
        private ref: ChangeDetectorRef,
        private detailakckService:DetailPxAkCkService,
        public userService: UserService,
    )  {}
    async ngAfterViewInit() {
        this.isCliente = await this.getIsCliente();
        this.verDetalleAkCK(this.PacienteID)
        if(location.hostname.toLowerCase().indexOf("localhost") != -1) {
            this.urlBase = "https://demo.genomi-k.com/"
       }
    }
    async verDetalleAkCK(PacienteID){
        this.appCommon.spinner(true);
        this.ref.detectChanges();
        this.detailakckService
            .DetalleAKCK(PacienteID)
            .then(
                async(Default: any) => {
                    this.DatosPaciente = Default.DatosPaciente;
                    this.DatosResultadosAK = Default.DatosResultadosAK;
                    this.DatosResultadosCK = Default.DatosResultadosCK;
                    this.DatosResultadosPostAK = Default.DatosResultadosPostAK;
                    this.DatosComentariosAK = Default.DatosComentariosAK;
                    this.DatosComentariosCK = Default.DatosComentariosCK;
                    console.log(this.DatosComentariosAK)
                    this.cargaEnlaces()
                    this.appCommon.spinner(false);
                    this.ref.detectChanges();
                            },
                error => {
                    //console.log('fail callback', error);
                    this.appCommon.spinner(false);
                    this.appCommon.Notify(error.data.message, 'error');
                    this.ref.detectChanges();
                }
            )
            .catch(e => {
                //console.log('catch', e);
                this.appCommon.spinner(false);
            });
      }


    cargaEnlaces(){
        const user = this.user
        const pass = this.pass
        const clase = this.clase
        //posteriores
        this.DatosEnlacesPostAK = []
        this.DatosResultadosPostAK.forEach(item  => {
            this.DatosEnlacesPostAK.push({
                tipo_estudio: item['tipo_estudio'],
                Fecha_estudio: item['Fecha_estudio'],
                resizquierdo: item['resizquierdo'],
                resderecho: item['resderecho'],
                file: this.urlBase + "ng-files/Rep_posterior_"+this.DatosPaciente.paciente_id+".pdf",//+"_#Session.usuarioid#.pdf",
                url: this.urlBase + "ng_a_res_etapaspos.cfm?resid="+item['resultado_id']+"&isCliente="+this.isCliente+"&pacienteid="+this.DatosPaciente.paciente_id+"&user="+user+"&pass="+pass+"&nfile=Rep_posterior_"+this.DatosPaciente.paciente_id
            })
        })
        //cardi-k
        this.DatosEnlacesCK = []
        this.DatosResultadosCK.forEach(item  => {
            this.DatosEnlacesCK.push({
                num_estudio: "Etapa " + item['num_estudio'],
                fecha_estudio: item['fecha_estudio'],
                Avg_mano_oxi: item['Avg_mano_oxi'],
                Avg_pie_oxi: item['Avg_pie_oxi'],
                tipo_resultado: item['tipo_resultado'],
                file: this.urlBase + "responsivegk/ng-files/Rep_35dB_"+this.DatosPaciente.paciente_id+".pdf",//+"_#Session.usuarioid#.pdf",
                url: this.urlBase + "responsivegk/ng_ck_res_3etapas.cfm?isCliente="+this.isCliente+"&pacienteid="+this.DatosPaciente.paciente_id+"&user="+user+"&pass="+pass+"&nfile=Rep_35dB_"+this.DatosPaciente.paciente_id
            })
        })

        //eco

        this.fileEco = this.urlBase + "responsivegk/ng-files/reporteECO"+this.DatosPaciente.paciente_id+".pdf",//+"_#Session.usuarioid#.pdf",
        this.urlEco  = this.urlBase + "responsivegk/ng_resultadospdf_ECOCk.cfm?isCliente="+this.isCliente+"&pacienteid="+this.DatosPaciente.paciente_id+"&user="+user+"&pass="+pass+"&nfile=reporteECO"+this.DatosPaciente.paciente_id

        this.file = this.urlBase + "ng-files/Rep_protocolo_"+this.PacienteID+".pdf",
        this.url = this.urlBase + "ng_a_res_protocolo.cfm?isCliente="+this.isCliente+"&dato="+this.PacienteID+"&user="+user+"&pass="+pass+"&nfile=Rep_protocolo_"+this.PacienteID

        //audi-k
        this.DatosTipoEstudio=[
            {},
            {
                tipo_estudio: "1",
                clase: clase,
                file:this.urlBase + "ng-files/Rep_35dB_"+this.DatosPaciente.paciente_id+".pdf",//+"_#Session.usuarioid#.pdf",
                url:this.urlBase + "ng_a_res35_3etapas.cfm?isCliente="+this.isCliente+"&pacienteid="+this.DatosPaciente.paciente_id+"&user="+user+"&pass="+pass+"&nfile=Rep_35dB_"+this.DatosPaciente.paciente_id
            },
            {
                tipo_estudio: "2",
                clase: clase,
                file:this.urlBase + "ng-files/Rep_diff_deb_"+this.DatosPaciente.paciente_id+".pdf",//+"_#Session.usuarioid#.pdf",
                url:this.urlBase + "ng_a_res_40_70_90.cfm?isCliente="+this.isCliente+"&pacienteid="+this.DatosPaciente.paciente_id+"&user="+user+"&pass="+pass+"&nfile=Rep_diff_deb_"+this.DatosPaciente.paciente_id
            },
            {
                tipo_estudio: "3",
                clase: clase,
                file:this.urlBase + "ng-files/Rep_EOA_"+this.DatosPaciente.paciente_id+".pdf",//+"_#Session.usuarioid#.pdf",
                url:this.urlBase + "ng_a_res_eoa.cfm?isCliente="+this.isCliente+"&pacienteid="+this.DatosPaciente.paciente_id+"&user="+user+"&pass="+pass+"&nfile=Rep_EOA_"+this.DatosPaciente.paciente_id
            },
            {
                tipo_estudio: "4",
                clase: clase,
                file:this.urlBase + "ng-files/Rep_diff_deb_"+this.DatosPaciente.paciente_id+".pdf",//+"_#Session.usuarioid#.pdf",
                url:this.urlBase + "ng_a_res_40_70_90.cfm?isCliente="+this.isCliente+"&pacienteid="+this.DatosPaciente.paciente_id+"&user="+user+"&pass="+pass+"&nfile=Rep_diff_deb_"+this.DatosPaciente.paciente_id
            },
            {
                tipo_estudio: "5",
                clase: clase,
                file:this.urlBase + "ng-files/Rep_diff_deb_"+this.DatosPaciente.paciente_id+".pdf",//+"_#Session.usuarioid#.pdf",
                url:this.urlBase + "ng_a_res_40_70_90.cfm?isCliente="+this.isCliente+"&pacienteid="+this.DatosPaciente.paciente_id+"&user="+user+"&pass="+pass+"&nfile=Rep_diff_deb_"+this.DatosPaciente.paciente_id
            },
        ]

        //Resumen
        this.DataResumen={
            display: 'Resumen Protocolo',
            clase: clase,
            file:this.urlBase + "ng-files/Rep_protocolo_"+this.PacienteID+".pdf",
            url:this.urlBase + "ng_a_res_protocolo.cfm?isCliente="+this.isCliente+"&dato="+this.PacienteID+"&user="+user+"&pass="+pass+"&nfile=Rep_protocolo_"+this.PacienteID
        }
    }
    getIsCliente(){
        return new Promise((resolve, reject) => {
            this.userService.isCliente$.subscribe(data=>{
                //console.info("Is Cliente: ",data);
                if(data==null){
                    reject(0);
                }else{
                    resolve(data);
                    //this.isCliente = data
                }
            });
        });
    }
}

