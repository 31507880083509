import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import { NZ_I18N, en_US, es_ES } from 'ng-zorro-antd/i18n';
import es from '@angular/common/locales/es';

//import { Amplify } from 'aws-amplify'
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { Amplify } from 'aws-amplify';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './interceptors/auth-interceptor';
import { CommonModule, DatePipe, PercentPipe } from '@angular/common';
import { AppCommonModule } from '../modules/app-common/app-common.module';
import { NavigationModule } from '../modules/navigation/navigation.module';
import { TranslocoRootModule } from './transloco-root.module';
import {TranslocoModule} from '@ngneat/transloco'

import { QuillModule } from 'ngx-quill';

Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: 'us-east-1_1TYtOrndD', // us-east-1_R3oWyBtKK
        userPoolClientId: '3apbr0kh7t3lolsl4tbgnp0esj',
        signUpVerificationMethod: 'code', // 'code' | 'link'        
      }
    },
});


@NgModule({
    imports: [
        BrowserModule, 
        BrowserAnimationsModule, 
        HttpClientModule, 
        FormsModule, 
        AppRoutingModule, 
        CommonModule, 
        RouterModule, 
        AppCommonModule, 
        NavigationModule, 
        TranslocoModule, 
        TranslocoRootModule,
        AmplifyAuthenticatorModule,
        QuillModule.forRoot()
    ],
    declarations: [AppComponent],
    providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }, { provide: NZ_I18N, useValue: en_US }, DatePipe, PercentPipe],
    bootstrap: [AppComponent]
})
export class AppModule {}


