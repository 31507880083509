import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import type { ComentariosMed, ResultDatosFLN } from '@modules/medical-records/models';
import { trigger, style, animate, transition } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { AgregarComentMedicoComponent } from '../Modales/agregar-coment-medico/agregar-coment-medico.component';
import { ActivatedRoute } from '@angular/router';
import { ViewCommentsGComponent } from '../Modales/view-comments-g/view-comments-g.component';
import { ViewCommentsMComponent } from '../Modales/view-comments-m/view-comments-m.component';

@Component({
  selector: 'gk-parametros-fln',
  templateUrl: './parametros-fln.component.html',
  styleUrl: './parametros-fln.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('enter', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-in', style({ opacity: 1 }))
      ]),
    ]),
    trigger('fadeInOut', [
      transition(':enter', [
          style({ opacity: 0 }),
          animate('300ms ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [
          style({ opacity: 1 }),
          animate('300ms ease-out', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class ParametrosFlnComponent implements OnInit{

  @Input() parametrosFln: ResultDatosFLN[];
  @Output() comentarioAgregado = new EventEmitter<void>();

  //? Variables
  ocultarDetalles: boolean[] = [];
  ocultarHistorial: boolean[] = [];
  id: number;
  

  //? Servicios
  private dialog = inject(MatDialog);
  private route = inject(ActivatedRoute);

  ngOnInit(): void {
    this.route.params.subscribe( params => {
      this.id = params['pacienteId']
    })
    //? Inicializamos 'ocultarDetalles' con false para cada elemento de 'parametrosFln'
    this.ocultarDetalles = new Array(this.parametrosFln.length).fill(false);
    this.ocultarHistorial = new Array(this.parametrosFln.length).fill(false);
  }

  //? Cambia el valor de 'ocultar' basado en el índice
  toggleDetalle(index: number) {
    this.ocultarDetalles[index] = !this.ocultarDetalles[index];
  }

  toggleHistorial(index: number) {
    this.ocultarHistorial[index] = !this.ocultarHistorial[index];
  }

  agregarComentario(cuenta: ResultDatosFLN) {

    const dialogData = {
        Prueba:  `Medicos para ${cuenta.Prueba}`,
        Pacienteid: this.id,
        Tipocomentario: 2,
        Fecha: true,
        Filtroid: 0,
        Gral: 0,  //? op es 0 medicG es 1  MedicN es 0 
        CalifFinal: cuenta.Paciente_Calif_Final_Id
    };

    const dialogRef = this.dialog.open(AgregarComentMedicoComponent, {
        width: '1120px', //? Ancho deseado
        data: dialogData,
        position: {
          top: '50px' 
        },
        autoFocus: false
    })

    //? Escuchar el evento de emisión
    const instance = dialogRef.componentInstance;
    instance.actualizarDatosEmitter.subscribe(() => {
      this.comentarioAgregado.emit();
    });
  }

  verComentarios(comentarios: ComentariosMed[], cuenta: ResultDatosFLN, tipo:number){

    const dialogData = {
      Titulo:     `Medicos de ${cuenta.Prueba}`,
      SubTitulo: cuenta.prueba_desc,
      Comentarios: comentarios,
      TipoComentario: tipo,
    };

    console.log(comentarios);
    console.log(cuenta);

    const dialogRef = this.dialog.open(ViewCommentsMComponent, {
      width: '1120px', //? Ancho deseado
      data: dialogData,
      position: {
        top: '50px' 
      },
      autoFocus: false
    })
  }

}
