<mat-dialog-content class="mat-typography">

    <form [formGroup]="formContacto" (ngSubmit)="addUser()">
        <h2 mat-dialog-title>Contacto</h2>
        <div class="row">
            <label class="control-label col-md-4" >Usuario activo:</label>
            <div class="form-check col-md-1">
                <input class="form-check-input" [value]="true" type="radio" formControlName="Activo"  >
                <label class="form-check-label" >
                    Si
                </label>
            </div>
            <div class="form-check col-md-1">
                <input class="form-check-input" [value]="false" type="radio" formControlName="Activo"  >
                <label class="form-check-label" >
                    No
                </label>
            </div>
            <label class="control-label col-md-4" >Acceso a sistema GK:</label>
            <div class="form-check col-md-1">
                <input class="form-check-input" type="radio" [value]="true" formControlName="AccesoSistemaGk" [checked]="formContacto.get('AccesoSistemaGk')!.value === true">
                <label class="form-check-label" >
                    Si
                </label>
            </div>
            <div class="form-check col-md-1 mb-3">
                <input class="form-check-input" type="radio"  value="false" formControlName="AccesoSistemaGk" >
                <label class="form-check-label" >
                    No
                </label>
            </div>

            <label class="control-label col-md-6" >Apellido paterno:</label>
            <label class="control-label col-md-6" >Apellido materno:</label>
            <div class="col-md-6 mb-3">
                <span class="form-text text-danger my-1" *ngIf="isValidFieldContacto('ApellidoPaterno')">
                    <b>Este campo es requerido</b>
                </span>    
                <input type="text"  class="form-control" formControlName="ApellidoPaterno">
            </div>
            <div class="col-md-6">
                <span class="form-text text-danger my-1" *ngIf="isValidFieldContacto('ApellidoMaterno')">
                    <b>Este campo es requerido</b>
                </span>
                <input type="text"  class="form-control" formControlName="ApellidoMaterno">
            </div>
            <label class="control-label col-md-6" >Nombre(s):</label>
            <label class="control-label col-md-6" >Correo:</label>
            <div class="col-md-6 mb-3" >
                <span class="form-text text-danger my-1" *ngIf="isValidFieldContacto('Nombre')">
                    <b>Este campo es requerido</b>
                </span>    
                <input type="text"  class="form-control" formControlName="Nombre">
            </div>
            <div class="col-md-6">
                <span class="form-text text-danger my-1" *ngIf="isValidFieldContacto('Correo')">
                    <b>Este campo es requerido</b>
                </span>    
                <input type="text"  class="form-control" formControlName="Correo">
            </div>
            <label class="control-label col-md-6" >Telefono:</label>
            <label class="control-label col-md-6" >Fax:</label>
            <div class="col-md-6 mb-3">
                <span class="form-text text-danger my-1" *ngIf="isValidFieldContacto('Telefono')">
                    <b>Este campo es requerido</b>
                </span>    
                <input type="text"  class="form-control" formControlName="Telefono">
            </div>
            <div class="col-md-6">    
                <input type="text"  class="form-control" formControlName="Fax">
            </div>
            <label class="control-label col-md-6" >Celular:</label>
            <label class="control-label col-md-6" >Nextel:</label>
            <div class="col-md-6 mb-3">    
                <input type="text"  class="form-control" formControlName="Celular">
            </div>
            <div class="col-md-6">    
                <input type="text"  class="form-control" formControlName="Nextel">
            </div>
            <label class="control-label col-md-6" >Otro:</label>
            <label class="control-label col-md-6" >Puesto:</label>
            <div class="col-md-6 mb-3">    
                <input type="text"  class="form-control" formControlName="Otro">
            </div>
            <div class="col-md-6">    
                <input type="text"  class="form-control" formControlName="Puesto">
            </div>
            @if(formContacto.get('AccesoSistemaGk')!.value === true){
                <label class="control-label col-md-6" >Usuario:</label>
                <label class="control-label col-md-6" >Contraseña:</label>    
                <div class="col-md-6 mb-3">
                    <span class="form-text text-danger my-1" *ngIf="isValidFieldContacto('Usuario')">
                        <b>Este campo es requerido</b>
                    </span>    
                    <input type="text" formControlName="Usuario"  class="form-control">
                </div>
                <div class="col-md-6">
                    <span class="form-text text-danger my-1" *ngIf="isValidFieldContacto('Contrasena')">
                        <b>Este campo es requerido</b>
                    </span>    
                    <input type="password" formControlName="Contrasena" class="form-control">
                </div>
                <label class="control-label col-md-6" >Nivel:</label>
                <label class="control-label col-md-6" >Fecha de cumpleaños:</label> 
                <div class="col-md-6 mb-3">
                    <span class="form-text text-danger my-1" *ngIf="isValidFieldContacto('NivelId')">
                        <b>Este campo es requerido</b>
                    </span>
                    <select  class="form-control"  formControlName="NivelId" >
                        <option value="">Seleccione un tipo</option>
                        <option *ngFor="let nivel of combos.NivelUsuario"
                            [value]="nivel.value">
                            {{ nivel.display }}
                        </option>
                    </select>
                </div>
            }@else{
                <div class="col-md-6" ></div>
                <div class="col-md-6" ></div>
                <div class="col-md-6"></div>
                <label class="control-label col-md-6" >Fecha de cumpleaños:</label>
                <div class="col-md-6 mb-3"></div>
            }
            
            
            <div class="col-md-6">    
                <input type="date"  class="form-control" formControlName="RecordatorioCumpleanios">
            </div>
            <label class="control-label col-md-12" >Comentarios:</label>
            <div class="col-md-12 mb-3">    
                <textarea class="form-control" style="height: 100px" placeholder="favor de escribir algun comentario" formControlName="Comentarios">
                </textarea>
            </div>
            @if(usuario){
                <label class="control-label col-12 mb-3" >Acceso a soluciones Genomi-K</label>
                <div class="form-check col-2 ms-2">
                    <input class="form-check-input" type="checkbox" [value]="true" formControlName="AccesoTamik">
                    <label class="form-check-label " >
                        Támi-k 
                    </label>
                </div>
                <div class="form-check col-2">
                    <input class="form-check-input" type="checkbox" [value]="true" formControlName="AccesoAudik">
                    <label class="form-check-label" >
                        Áudi-k
                    </label>
                </div>
                <div class="form-check col-2 mb-3">
                    <input class="form-check-input" type="checkbox" [value]="true" formControlName="AccesoDx">
                    <label class="form-check-label" >
                        Dx
                    </label>
                </div>
                <div class="form-check col-2 mb-3">
                    <input class="form-check-input" type="checkbox" [value]="true" formControlName="AccesoCardik">
                    <label class="form-check-label" >
                        Cardi-k
                    </label>
                </div>
                @if(formContacto.get('AccesoTamik')!.value || formContacto.get('AccesoAudik')!.value || formContacto.get('AccesoDx')!.value || formContacto.get('AccesoCardik')!.value){
                    <label class="col-12 mb-3" >Alertas</label>
                }
                @if(tamik){
                    
                    <label class="control-label col-md-12 mb-3" >Támi-k</label> 
                    <nz-select nzMode="multiple" class="mb-3" formControlName="AlertasTamik" nzPlaceHolder="Seleccione una opcion" nzStyle="width: 100%;">
                        <nz-option  *ngFor="let alerta of combos.AlertasTamik"
                        [nzValue]="alerta.value"  [nzLabel]="alerta.display" >
                        </nz-option>
                    </nz-select>
                }
                @if(audik){
                    <label class="control-label col-md-12 mb-3" >Áudi-k</label>
                    <nz-select nzMode="multiple" class="mb-3" formControlName="AlertasAudik" nzPlaceHolder="Seleccione una opcion" nzStyle="width: 100%;">
                        <nz-option  *ngFor="let alerta of combos.AlertasAudik"
                        [nzValue]="alerta.value"  [nzLabel]="alerta.display" >
                        </nz-option>
                    </nz-select>
                }
                @if(dx){
                    <label class="control-label col-md-12 mb-3" >DX</label>
                    <nz-select nzMode="multiple" class="mb-3" formControlName="AlertasDx" nzPlaceHolder="Seleccione una opcion" nzStyle="width: 100%;">
                        <nz-option  *ngFor="let alerta of combos.AlertasDx"
                        [nzValue]="alerta.value"  [nzLabel]="alerta.display" >
                        </nz-option>
                    </nz-select>
                }
                @if(cardik){
                    <label class="control-label col-md-12 mb-3" >Cárdi-k</label>
                    <nz-select nzMode="multiple" class="mb-3" formControlName="AlertasCardik" nzPlaceHolder="Seleccione una opcion" nzStyle="width: 100%;">
                        <nz-option  *ngFor="let alerta of combos.AlertasCardik"
                        [nzValue]="alerta.value"  [nzLabel]="alerta.display" >
                        </nz-option>
                    </nz-select>
                }
            }
            
        </div>
    
        <div class="full-width-element full-height-element">
            <mat-divider class="mt-4"></mat-divider>
            <mat-dialog-actions align="end" class="gris py-4">
                @if(!usuario){
                    <button cdkFocusInitial class="btn btn-primary mx-2 mt-3" type="submit" *ngIf="!esVisor">Guardar</button>
                }@else{
                    <button cdkFocusInitial class="btn btn-primary mx-2 mt-3" type="submit" *ngIf="!esVisor">Guardar Cambios</button>
                }
                <button [mat-dialog-close] class="btn btn-secondary mx-2 mt-3">Cerrar</button>
            </mat-dialog-actions>
        </div>
    </form>
</mat-dialog-content>