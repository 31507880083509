import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AppCommonService } from '@common/services';
import { ConfirmDialogOptions } from '@common/services/dialog.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export type ConfirmationDialogResult = { Confirmed: boolean; Comentario: string } | false;

@Component({
    selector: 'gk-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationDialogComponent {
    @Input() type!: ConfirmDialogOptions['type'];
    @Input() title!: string;
    @Input() message!: string;
    @Input() btnOkText!: string;
    @Input() btnCancelText!: string;
    model = {
        Comentario: '',
    };

    constructor(private activeModal: NgbActiveModal, public appCommon: AppCommonService) {}

    private close(result: ConfirmationDialogResult) {
        this.activeModal.close(result);
    }

    decline() {
        this.close(false);
    }

    accept() {
        const response: ConfirmationDialogResult = {
            Confirmed: true,
            Comentario: '',
        };

        if (this.type === 'textreq' && this.model.Comentario === '') {
            this.appCommon.Notify('Favor de ingresar el comentario', 'warning');
            return;
        }

        if (this.type === 'text' || this.type === 'textreq') {
            response.Comentario = this.model.Comentario;
        }

        this.close(response);
    }
}
