import { ChangeDetectionStrategy, Component, EventEmitter, inject, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MuestraTransportacionSP, SpecialProof } from '../../../../medical-records/models/pruebasEspeciales.model';
import { AppCommonService } from '@common/services';
import { MedicalRecordsService } from '@modules/medical-records/services';
import { UserService } from '@modules/auth/services';

@Component({
  selector: 'gk-special-test-m',
  templateUrl: './special-test-m.component.html',
  styleUrl: './special-test-m.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpecialTestMComponent {

  user: string;
  pass: string;
  isCliente: any = 0;
  ActiveInstitucionID: any = 0;
  url: string;
  urlBase:any='https://sistema.genomi-k.com/';
  display: any = 'Exportar a PDF';
  clase: any = 'btn btn-outline-primary';
  file: string;

  async ngOnInit() {
    this.getIsCliente();
    this.ActiveInstitucionID = await this.getInstitucionID();

    if(this.data && this.data.SpecialProf){
      this.pruebaEspecial = this.data.SpecialProf;
    }
    const filename = this.data.SpecialProf.datosPrueba.CodigoPrueba
    this.user = btoa('ngUsrGK')
    this.pass = btoa('ngGenomi-k2023!.123')
    if(location.hostname.toLowerCase().indexOf("localhost") != -1) {
      this.urlBase = "https://demo.genomi-k.com/"
    }
    this.file = this.urlBase + "responsivegk/ng-files/"+filename+".pdf"    
    this.url=this.urlBase + "responsivegk/ng-pruebas_desc_pdf.cfm?exporta=1&pruebaid="+this.data.SpecialProf.datosPrueba.CodigoPrueba+"&user="+this.user+"&pass="+this.pass+"&nfile="+filename+"&iscliente="+this.isCliente+"&ins="+this.ActiveInstitucionID
  }

  //? variables
  pruebaEspecial: SpecialProof;
  pacienteId: string | number;
  selectedOptions: number[] = []; // Variable para almacenar la opción seleccionada

  //* Variable para indicar si se debe actualizar la información en el componente principal.
  @Output() actualizarDatosEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();


  onSelectChange(selected: number, index: number): void {
    this.selectedOptions[index] = selected; // Almacena la opción seleccionada en la posición correspondiente
}

  //?servicios
  private appCommon = inject(AppCommonService);
  private medicalRecordsService = inject(MedicalRecordsService);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SpecialTestMComponent>,
    public userService: UserService,

  ){
    if(this.data && this.data.SpecialProf && this.data.PacienteId){
      this.pruebaEspecial = this.data.SpecialProf;
      this.pacienteId = this.data.PacienteId;
    }
  }
  
  getInstitucionID(){
    return new Promise((resolve, reject) => {
        this.userService.vInstitucionID$.subscribe(data=>{
            console.info("Institucion ID: ",data);
            if(data==null){
                reject(0);
            }else{
                resolve(data);
                //this.ActiveInstitucionID = data
            }
        });
    });
}


  getIsCliente(){
    return new Promise((resolve, reject) => {
        this.userService.isCliente$.subscribe(data=>{
            //console.info("Is Cliente: ",data);
            if(data==null){
                reject(0);
            }else{
                this.isCliente = data
            }
        });
    });
  }

  solicitarPrueba(muestra: MuestraTransportacionSP, selectedOption: any) {
    if(!selectedOption){
      selectedOption = 0;
    }
    
    const prueba = {  
      kitid: muestra.Kit_Id,
      PruebaEspecialId: muestra.Pruebas_especiales_id,//Pruebas_especiales_id
      institucionid: this.ActiveInstitucionID,
      jqrychkConsulta: selectedOption,//combo costo conmsulta
      tipomuestra: muestra.TipoMuestraId,//Tipo_Muestra_Id
      px_dx_id: this.pacienteId,//Paciente ID
      ctrl_dx: 1//valor fijo 
    }
    
    this.appCommon.spinner(true);
    this.medicalRecordsService.postProofRequest(prueba).subscribe({
      next: (resp) => { 
        this.appCommon.spinner(false);
        this.appCommon.showMsg(`Operacion Exitosa`, `${resp.Mensaje}`, () => {
          this.actualizarDatosEmitter.emit(true);
          this.dialogRef.close();
        });
      },
      error: err => {
        this.appCommon.spinner(false);
        this.appCommon.showMsg(`Operacion fallida`, `A ocurrido un error al querer realizar la operacion`);
        console.log('err', err);
      }
    });
  }


}
