import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NavigationService } from '@modules/navigation/services';
import { UserService } from '@modules/auth/services';

@Component({
    selector: 'sb-top-nav',
    templateUrl: './top-nav.component.html',
    styleUrls: ['top-nav.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopNavComponent implements OnInit {
    topNavMenu$ = this.navigationService.topNavMenu$;
    sideMenuToggle = false;
    isCliente: boolean = false;

    constructor(private navigationService: NavigationService, public userService: UserService) {}

    ngOnInit() {
        this.toggleSideNav(this.sideMenuToggle);

        // get Menu Items from Profile
        this.userService.menuItems$.subscribe(data=>{
            //console.info("TOP Items From File Menu: ",data);
            //this.sideNavItems = data;
        });
    }

    toggleSideNav(sideMenuToggle) {
        //console.info("sideMenuToggle: ", !sideMenuToggle)
        this.sideMenuToggle = !sideMenuToggle;
/*
        this.userService.isCliente$.subscribe(data=>{
            console.info("Cliente: ",data);
            this.isCliente = data;
            // si es cliente oculta el sideMenu
            if(data){
                this.sideMenuToggle = sideMenuToggle;
            }
        });
*/
        this.navigationService.toggleSideNav(this.sideMenuToggle);

    }




}
