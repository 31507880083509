import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EnvioCorreoComponent } from '../Modales/envio-correo/envio-correo.component';
import { Preliminar } from '@modules/medical-records/models';

@Component({
  selector: 'gk-preliminary-result',
  templateUrl: './preliminary-result.component.html',
  styleUrl: './preliminary-result.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreliminaryResultComponent {

  //? Variables
  @Input() resultDatos: Preliminar[];
  @Input() filtroId: number;
  @Output() correoAgregado = new EventEmitter<void>();

  //? Servicios
  private dialog = inject(MatDialog);

  enviarCorreo(){

    const dialogData = {
      Id: this.filtroId
    };

    const dialogRef = this.dialog.open(EnvioCorreoComponent, {
      width: '1120px', //? Ancho deseado
      data: dialogData,
      position: {
        top: '50px' 
      },
      autoFocus: false
    })

    //? Escuchar el evento de emisión
    const instance = dialogRef.componentInstance;
    instance.actualizarDatosEmitter.subscribe(() => {
      this.correoAgregado.emit();
    });

  }

}
