import { ChangeDetectionStrategy, Component, EventEmitter, Injectable, Input, OnInit, Optional, Output, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Component({
    selector: 'toggle-button',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './toggle-button.component.html',
    styleUrls: ['toggle-button.component.scss'],
})
@Injectable()
export class ToggleButtonComponent implements OnInit {
    @Output() changed = new EventEmitter<boolean>();
    @Input()
    disabled = false;
    value!: number;
    @Input()
    status = false;
    @Input()
    name = 'toggle-button-checkbox';
    constructor(@Optional() @Self() public ngControl: NgControl) {
        if (this.ngControl != null) this.ngControl.valueAccessor = this;
    }
    ngOnInit() {}
    writeValue(obj: any): void {}
    onChange: (_: any) => void = (_: any) => {};

    onTouched: () => void = () => {};

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
}
