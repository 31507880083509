import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { UserService } from '@modules/auth/services';
import { Default } from '@modules/config/models';
import { Observable, of } from 'rxjs';
import { Administration } from '../models';

@Injectable()
export class AdministrationService {
    API_URL = environment.api.auth;
    API_URL_ADMINISTRATION = environment.api.administration;
    API_URL_CUSTOMERS = environment.api.customers;

    constructor(private http: HttpClient, private userService: UserService) {
        /*if(window.location.hostname.toLowerCase().indexOf("localhost") != -1) {
            this.API_URL_ADMINISTRATION = "http://localhost:3000/dev/api/v1";
        }*/
    }

    async getZones() {
        return new Promise((resolve, reject) => {
            this.http.get<Administration>(`${this.API_URL_ADMINISTRATION}/parametros/zonas`).subscribe(resp => {
                if (resp.status === 'fail') {
                    console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                resolve(resp.data);
            });
        });
    }

    async getUsersGK() {
        return new Promise((resolve, reject) => {
            this.http.get<Administration>(`${this.API_URL_ADMINISTRATION}/parametros/gkusers`).subscribe(resp => {
                if (resp.status === 'fail') {
                    console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                resolve(resp.data);
            });
        });
    }

    async LoadReportVolumeSales(requestBody) {
        return new Promise((resolve, reject) => {
            this.http.post<Administration>(`${this.API_URL_ADMINISTRATION}/reports/volumesales`, requestBody).subscribe(resp => {
                if (resp.status === 'fail') {
                    console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                resolve(resp.data);
            });
        });
    }

    async getBalanceOperAccountDetail(requestBody) {
        return new Promise((resolve, reject) => {
            this.http
                .post<Administration>(`${this.API_URL_ADMINISTRATION}/reports/balanceoperaccountdetail`, requestBody)
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                });
        });
    }

    async getBalanceOperAccountPolicyDetail(requestBody) {
        return new Promise((resolve, reject) => {
            this.http
                .post<Administration>(`${this.API_URL_ADMINISTRATION}/policy/policyoperaccountpolicydetail`, requestBody)
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                });
        });
    }

    async getOperPolicy(requestBody) {
        return new Promise((resolve, reject) => {
            this.http.post<Administration>(`${this.API_URL_ADMINISTRATION}/policy/read`, requestBody).subscribe(resp => {
                if (resp.status === 'fail') {
                    console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                resolve(resp.data);
            });
        });
    }

    async getSaldos(requestBody) {
        return new Promise((resolve, reject) => {
            this.http.post<Administration>(`${this.API_URL_ADMINISTRATION}/account/saldos`, requestBody).subscribe(resp => {
                if (resp.status === 'fail') {
                    console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                resolve(resp.data);
            });
        });
    }

    async getTransacciones(requestBody) {
        return new Promise((resolve, reject) => {
            this.http.post<Administration>(`${this.API_URL_ADMINISTRATION}/account/transacciones`, requestBody).subscribe(resp => {
                if (resp.status === 'fail') {
                    console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                resolve(resp.data);
            });
        });
    }

    async getPendingAutomaticRequisitions(requestBody) {
        return new Promise((resolve, reject) => {
            this.http
                .post<Default>(`${this.API_URL_ADMINISTRATION}/requisition/pending-auto-req`, {
                    ...requestBody,
                    UsuarioId: this.userService.vUsuarioID,
                })
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                });
        });
    }

    async getRemission(requestBody) {
        return new Promise((resolve, reject) => {
            this.http
                .post<Default>(`${this.API_URL_ADMINISTRATION}/remission`, { ...requestBody, UsuarioId: this.userService.vUsuarioID })
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                });
        });
    }

    async getRemissionCobranza(requestBody) {
        return new Promise((resolve, reject) => {
            this.http
                .post<Default>(`${this.API_URL_ADMINISTRATION}/remission/cobranza/audik`, {
                    ...requestBody,
                    UsuarioId: this.userService.vUsuarioID,
                })
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                });
        });
    }

    getControlRemissionInvoiceReport(requestBody): Observable<any> {
        return this.http.post(`${this.API_URL_ADMINISTRATION}/controlremissioninvoice/data`, {
            ...requestBody,
            UsuarioId: this.userService.vUsuarioID,
        });
    }

    getFiltersControlRemissionInvoiceReport(): Observable<any> {
        return this.http.get(`${this.API_URL_ADMINISTRATION}/controlremissioninvoice/params`);
    }

    async getRequisition(requestBody) {
        return new Promise((resolve, reject) => {
            this.http
                .post<Default>(`${this.API_URL_ADMINISTRATION}/requisition/detail`, {
                    ...requestBody,
                    UsuarioNivelId: this.userService.vNivelID,
                })
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                });
        });
    }

    async getRequisitionReportData(requestBody) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_ADMINISTRATION}/requisition/get-report-data`, requestBody).subscribe(resp => {
                if (resp.status === 'fail') {
                    console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                resolve(resp.data);
            });
        });
    }

    async getSaldoCuentasContables(requestBody) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_ADMINISTRATION}/cuentacontable/saldo`, requestBody).subscribe(resp => {
                if (resp.status === 'fail') {
                    console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                resolve(resp.data);
            });
        });
    }

    async getCompanies() {
        return new Promise((resolve, reject) => {
            this.http.get<Default>(`${this.API_URL_ADMINISTRATION}/cuentacontable/companies`).subscribe(resp => {
                if (resp.status === 'fail') {
                    console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                resolve(resp.data);
            });
        });
    }

    async getRequisitionsToPayData() {
        return new Promise((resolve, reject) => {
            this.http.get<Default>(`${this.API_URL_ADMINISTRATION}/requisitions/get`).subscribe(resp => {
                if (resp.status === 'fail') {
                    console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                resolve(resp.data);
            });
        });
    }

    async saveRequisition(requestBody) {
        return new Promise((resolve, reject) => {
            this.http
                .post<Administration>(`${this.API_URL_ADMINISTRATION}/requisition/save`, requestBody)
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                });
        });
    }

    async getRequisitionDetail(requestBody) {
        return new Promise((resolve, reject) => {
            this.http
                .post<Administration>(`${this.API_URL_ADMINISTRATION}/requisition/detail/get`, requestBody)
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                });
        });
    }

    async CancelFactClienteMoroso(requestBody) {
        return new Promise((resolve, reject) => {
            this.http
                .post<Administration>(`${this.API_URL_ADMINISTRATION}/clientes-morosos/cancel`, requestBody)
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                });
        });
    }

    async getClientesMorosos() {
        return new Promise((resolve, reject) => {
            this.http.get<Default>(`${this.API_URL_ADMINISTRATION}/clientes-morosos/get`).subscribe(resp => {
                if (resp.status === 'fail') {
                    console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                resolve(resp.data);
            });
        });
    }

    async getReporteSaldosFiltros() {
        return new Promise((resolve, reject) => {
            this.http.get<Default>(`${this.API_URL_ADMINISTRATION}/reportes-saldos/filtros`).subscribe(resp => {
                if (resp.status === 'fail') {
                    console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                resolve(resp.data);
            });
        });
    }

    async getReporteEcos(requestBody) {
        return new Promise((resolve, reject) => {
            this.http
                .post<Administration>(`${this.API_URL_ADMINISTRATION}/ecos-report/get`, requestBody)
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                });
        });
    }
    async getReporteSaldoClientes(requestBody) {
        return new Promise((resolve, reject) => {
            this.http
                .post<Administration>(`${this.API_URL_ADMINISTRATION}/reportes-saldos/clientes/get`, requestBody)
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                });
        });
    }

    async getReporteSaldoProveedores(requestBody) {
        return new Promise((resolve, reject) => {
            this.http
                .post<Administration>(`${this.API_URL_ADMINISTRATION}/reportes-saldos/proveedores/get`, requestBody)
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                });
        });
    }

    async GetServicios(requestBody) {
        return new Promise((resolve, reject) => {
            this.http
                .post<Administration>(`${this.API_URL_ADMINISTRATION}/ecos-report/detail/get `, requestBody)
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                });
        });
    }

    async getReportePolizas(requestBody) {
        return new Promise((resolve, reject) => {
            this.http
                .post<Administration>(`${this.API_URL_ADMINISTRATION}/policy-report/get`, requestBody)
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                });
        });
    }



    getAdministration$(): Observable<{}> {
        return of({});
    }
}
