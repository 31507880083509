<h3 mat-dialog-title class="text-center">Envio de Correo Preliminar</h3>
<mat-dialog-content class="mat-typography">
    <form
        [formGroup]="formEnviarCorreo"
        (ngSubmit)="enviarCorreo()"
        autocomplete="off">


        <div class="row">
            <div class="col-1"></div>
            <div class="col-2"> 
                <label class="control-label fw-bold">Parametro:</label>
                <span class="form-text text-danger validation-message ms-2" *ngIf="isValidFieldAsignarComentario('PruebaId')" @fadeInOut>
                    <b>*</b>
                </span>
            </div>
            <div class="col-3"> 
                <nz-select class="w-100"
                    nzAllowClear
                    nzMode="multiple"
                    formControlName="PruebaId"
                    (ngModelChange)="generarTexto()"
                    nzPlaceHolder="Seleccione Parametro(s)"
                    >
                    @for(prueba of pruebas; track $index){
                        <nz-option
                            [nzValue]="prueba.prueba_id"
                            [nzLabel]="prueba.prueba_desc">
                        </nz-option>
                    }
                </nz-select>
            </div>
            <div class="col-2"> 
                <label class="control-label fw-bold">Correos:</label>
                <span class="form-text text-danger validation-message ms-2" *ngIf="isValidFieldAsignarComentario('ContactosIdLista')" @fadeInOut>
                    <b>*</b>
                </span>
            </div>
            <div class="col-3"> 
                <nz-select 
                    nzMode="multiple"
                    formControlName="ContactosIdLista"
                    (ngModelChange)="generarTexto()"
                    nzPlaceHolder="Seleccione Correo(s)">
                    @for(correo of correos; track $index){
                        <nz-option
                            [nzValue]="correo.value"
                            [nzLabel]="correo.display">
                        </nz-option>
                    }
                </nz-select>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-1"></div>
            <div class="col-10">
                <span class="form-text text-danger validation-message ms-2" *ngIf="isValidFieldAsignarComentario('Texto')" @fadeInOut>
                    <b>Favor de agregar un comentario</b>
                </span>
                <quill-editor [modules]="editorModules" style="height: 400px; width: 100%;" formControlName="Texto"></quill-editor>
            </div>
        </div>


        <div class="full-width-element full-height-element">
            <mat-divider class="mt-4"></mat-divider>
            <mat-dialog-actions align="end" class="gris py-4">
                <button type="submit" class="btn btn-primary mx-2 mt-3">
                    Agregar Comentario
                </button>
                <button type="button" [mat-dialog-close] class="btn btn-secondary mx-2 mt-3">
                    Cerrar
                </button>
            </mat-dialog-actions>
        </div>
    
    </form>
</mat-dialog-content>