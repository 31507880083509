import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { NumeroALetraPipe } from '@common/pipes/numero-a-letras.pipe';
import { AppCommonService } from '@modules/app-common/services';

@Component({
    selector: 'gk-cf-pdf-viewer',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './cf-pdf-viewer.component.html',
    styleUrls: ['cf-pdf-viewer.component.scss'],
})
export class CfPdfViewerComponent {
    @Input() display!: string;
    @Input() file!: string;
    @Input() clase!: string;
    @Input() url!: string;
    @Input() noviewer!: number;
    @Output("CargarDatos") RecargarDatos: EventEmitter<any> = new EventEmitter();






    constructor(
            public appCommon: AppCommonService,
            private ref: ChangeDetectorRef,
        ) {}
    /*    COMPONENTE PARA GENERAR Y MOSTRAR ARCHIVOS PDF DESDE CF
            display =    es el texto que quieres que muestre
            file = es la direccion completa del archivo a mostrar
            clase = es la clase que le quieres poner. por ejemplo: como link "txtlinka" o como boton "btn btn-outline-primary"
            url = es el enlace en donde se genera el archivo en cf, ponerle usuario y password por seguridad en b64

            ejemplo de uso en:
                GeneralDataComponent     /reports/reports-operations-ak/general-data
    */
    GenerarPDF(){
        if (this.noviewer != 1)
        {
        //console.log(this.display)
            setTimeout(() => {
            this.appCommon.UrlViewer(this.file,"urlpdf");
            this.ref.detectChanges();
            }, 2000);
        }
        else
        {
            this.CargarDatos()
        }
    }

    async CargarDatos(){
        this.RecargarDatos.emit()
    }

}
