<div class="container-fluid">
    <form *ngIf="(formbuldierConfig | json) !== '{}'" [formGroup]="formFiltros" #f="ngForm" (ngSubmit)="generarFiltro()">
        <div *ngIf="TipoFormularioComponent === 2">
            <div id="TipoInstitucion" *ngIf="ConfigShow.TipoBusqueda.show ===true">
                <div class="row mt-3">
                    <div class="col-2">
                        <label for="soluciones"><fa-icon class="mr-1" [icon]='["fas", "filter"]'></fa-icon> &nbsp;<strong>Tipo de Institución:</strong></label>
                    </div>
                    <div class="col-10">
                        <div class="ng-star-inserted" [hidden]="ConfigShow.TipoBusquedaRadios">
                            <ng-container *ngFor="let type of searchType">
                                <input
                                    class="icheckbox_square-blue"
                                    type="radio"
                                    name="TipoBusqueda"
                                    formControlName="TipoBusqueda"
                                    (change)="onCheckChangeBusqueda()"
                                    value={{type.value}}
                                />
                                &nbsp;&nbsp;{{ type.text }}&nbsp;&nbsp;
                            </ng-container>
                            <ng-container *ngIf="ConfigShow.TipoBusqueda && ConfigShow.TipoBusqueda.especial === true">
                                <input
                                    class="icheckbox_square-blue"
                                    type="radio"
                                    name="TipoBusqueda"
                                    formControlName="TipoBusqueda"
                                    (change)="onCheckChangeBusqueda()"
                                    value = 4
                                />
                                &nbsp;&nbsp;Sin Cliente&nbsp;&nbsp;
                            </ng-container>
                        </div>

                        <div class="row mt-2">
                            <div class="col-md-12" *ngIf="formFiltros!.controls['TipoBusqueda'].value !== '3' && formFiltros!.controls['TipoBusqueda'].value !== '4' ">
                                <div >
                                    <!---<label class="control-label">{{txtTipoBusqueda}}</label>
                                    <br><br>--->
                                    <div class="">
                                        <nz-form-item>
                                            <nz-form-control>
                                                    <nz-select nzSize="large" nzShowSearch nzPlaceHolder="Selecciona Institucion" [nzMaxTagCount]="5" [nzAllowClear]="true" formControlName="Institucion_Id">
                                                    <nz-option nzLabel="Todas" [nzValue]= '0' ></nz-option>
                                                    <nz-option *ngFor="let dataInstituciones of arrayFiltradoInstituciones" [nzLabel]="dataInstituciones.NombreInst" [nzValue]="dataInstituciones.Institucion_Id"> </nz-option>
                                                </nz-select>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="divGrupoMedico" *ngIf="ConfigShow.cbxGrupoMedicoID && ConfigShow.cbxGrupoMedicoID.show === true">
                <div class="row mt-3">
                    <div class="col-2">
                        <label for="soluciones"><fa-icon class="mr-1" [icon]='["fas", "filter"]'></fa-icon> &nbsp;<strong>Grupo Medico</strong></label>
                    </div>
                    <div class="col-10">
                        <div class="row">
                            <div class="col-md-12">
                                <div >
                                    <div class="">
                                        <nz-form-item>
                                            <nz-form-control>
                                                    <nz-select nzSize="large" nzShowSearch nzPlaceHolder="Selecciona Grupo" [nzMaxTagCount]="5" [nzAllowClear]="true" formControlName="cbxGrupoMedicoID">
                                                    <nz-option nzLabel="Seleccione Grupo"  [nzValue] = '0'></nz-option>
                                                    <nz-option *ngFor="let dataGrupo of dataGrupoMedico" [nzLabel]="dataGrupo.Grupo_Medico" [nzValue]="dataGrupo.Grupo_Medico_Id"> </nz-option>
                                                </nz-select>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="divTipoInst" *ngIf="ConfigShow.cbxTipoInst && ConfigShow.cbxTipoInst.show === true">
                <div class="row mt-3">
                    <div class="col-2">
                        <label for="soluciones"><fa-icon class="mr-1" [icon]='["fas", "filter"]'></fa-icon> &nbsp;<strong>Tipo Institucion</strong></label>
                    </div>
                    <div class="col-10">
                        <div class="row">
                            <div class="col-md-12">
                                <div >
                                    <div class="">
                                        <nz-form-item>
                                            <nz-form-control>
                                                <nz-select nzSize="large" nzMode="multiple" nzPlaceHolder="Selecciona Tipo" [nzMaxTagCount]="5" [nzAllowClear]="true" formControlName="cbxTipoInst">
                                                    <nz-option *ngFor="let dataTipoInst of dataTipoInstituciones" [nzLabel]="dataTipoInst.Tipo_Institucion" [nzValue]="dataTipoInst.Tipo_Institucion_ID"> </nz-option>
                                                </nz-select>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="TipoBusquedaPeriodo" *ngIf="ConfigShow.TipoBusquedaPeriodo && ConfigShow.TipoBusquedaPeriodo.show === true">
                <div class="row mt-3">
                    <div class="col-2">
                        <label for="soluciones"><fa-icon class="mr-1" [icon]='["fas", "filter"]'></fa-icon> &nbsp;<strong>Periodo:</strong></label>
                    </div>
                    <div class="col-10">
                        <div class="ng-star-inserted" [hidden]="ConfigShow.TipoBusquedaPeriodoRadios">
                            <ng-container *ngFor="let periodo of searchPeriodo">
                                <input
                                    class="icheckbox_square-blue"
                                    type="radio"
                                    name="TipoBusquedaPeriodo"
                                    formControlName="TipoBusquedaPeriodo"
                                    value="{{periodo.value}}"
                                />
                                &nbsp;&nbsp;{{ periodo.text }}&nbsp;&nbsp;
                            </ng-container>
                        </div>
                        <div class="col-md-12 mt-2" *ngIf="formFiltros!.controls['TipoBusquedaPeriodo'].value !== '0'">
                            <div class="col-12 form-group" *ngIf="formFiltros!.controls['TipoBusquedaPeriodo'].value === '1' && !ConfigShow.TipoBusquedaPeriodo.especial">
                                <!---<div>
                                    <label class="control-label">Seleccione Fecha </label>
                                </div><br>--->
                                <div nz-row [nzGutter]="16">
                                    <div nz-col class="gutter-row" [nzSpan]="12">
                                        <nz-form-item>
                                            <nz-form-control>
                                                <nz-select nzSize="large" nzShowSearch nzPlaceHolder="Selecciona Mes" [nzMaxTagCount]="5" [nzAllowClear]="true" formControlName="mes">
                                                    <nz-option *ngFor="let FechaMes of dataMeses" [nzLabel]="FechaMes.mes" [nzValue]="FechaMes.id"> </nz-option>
                                                </nz-select>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                    <div nz-col class="gutter-row" [nzSpan]="12">
                                        <nz-form-item>
                                            <nz-form-control>
                                                <nz-select nzSize="large" nzShowSearch nzPlaceHolder="Selecciona Año" [nzMaxTagCount]="5" [nzAllowClear]="true" formControlName="anio">
                                                    <nz-option *ngFor="let anio of years" [nzLabel]="anio.year" [nzValue]="anio.year"> </nz-option>
                                                </nz-select>
                                            </nz-form-control>
                                            <!-- <div *ngIf="
                                                    formEnvioLab.controls['anio'].invalid &&
                                                    (formEnvioLab.controls['anio'].dirty || formEnvioLab.controls['anio'].touched)
                                                    " class="error">
                                                <div *ngIf="formEnvioLab.controls['anio']?.errors?.required">{{ t('MesageValidate') }}</div>
                                            </div> -->
                                        </nz-form-item>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 " *ngIf="formFiltros!.controls['TipoBusquedaPeriodo'].value === '2' && !ConfigShow.TipoBusquedaPeriodo.especial">
                                <!---<div>
                                    <label class="control-label"> Intervalo de Fechas </label>
                                </div><br>--->
                                <div class="col-12" nz-row [nzGutter] = "16">
                                    <div class="col-6" nz-col class="gutter-row" [nzSpan]="12">
                                        <nz-form-item>
                                            <nz-form-control>
                                                <input nz-input placeholder="Fecha inicial" nzSize="large" type="date" formControlName="fechaIni" />
                                            </nz-form-control>
                                            <!-- <div *ngIf="
                                                    formEnvioLab.controls['fechaIni'].invalid &&
                                                    (formEnvioLab.controls['fechaIni'].dirty || formEnvioLab.controls['fechaIni'].touched)
                                                    " class="error">
                                                <div *ngIf="formEnvioLab.controls['fechaIni']?.errors?.required">{{ t('MesageValidate') }}</div>
                                            </div> -->
                                        </nz-form-item>
                                    </div>
                                    <div class="col-6" nz-col class="gutter-row" [nzSpan]="12">
                                        <nz-form-item>
                                            <nz-form-control>
                                                <input nz-input placeholder="Fecha final" nzSize="large" type="date" formControlName="fechaFin" />
                                            </nz-form-control>
                                            <!-- <div *ngIf="
                                                    formEnvioLab.controls['fechaFin'].invalid &&
                                                    (formEnvioLab.controls['fechaFin'].dirty || formEnvioLab.controls['fechaFin'].touched)
                                                    " class="error">
                                                <div *ngIf="formEnvioLab.controls['fechaFin']?.errors?.required">{{ t('MesageValidate') }}</div>
                                            </div> -->
                                        </nz-form-item>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 form-group" *ngIf="formFiltros!.controls['TipoBusquedaPeriodo'].value === '1' && ConfigShow.TipoBusquedaPeriodo.especial">
                                <!---<div>
                                    <label class="control-label">Seleccione Fecha </label>
                                </div><br>--->
                                <div nz-row [nzGutter]="16">
                                    <div nz-col class="gutter-row" [nzSpan]="8">
                                        <nz-form-item>
                                            <nz-form-control>
                                                <nz-select nzSize="large" nzShowSearch nzPlaceHolder="Mes Inicial" [nzMaxTagCount]="5" [nzAllowClear]="true" formControlName="mes">
                                                    <nz-option *ngFor="let FechaMes of dataMeses" [nzLabel]="FechaMes.mes" [nzValue]="FechaMes.id"> </nz-option>
                                                </nz-select>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                    <div nz-col class="gutter-row" [nzSpan]="8">
                                        <nz-form-item>
                                            <nz-form-control>
                                                <nz-select nzSize="large" nzShowSearch nzPlaceHolder="Mes Final" [nzMaxTagCount]="5" [nzAllowClear]="true" formControlName="fechaMesFin">
                                                    <nz-option *ngFor="let FechaMesFin of dataMeses" [nzLabel]="FechaMesFin.mes" [nzValue]="FechaMesFin.id"> </nz-option>
                                                </nz-select>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                    <div nz-col class="gutter-row" [nzSpan]="8">
                                        <nz-form-item>
                                            <nz-form-control>
                                                <nz-select nzSize="large" nzShowSearch nzPlaceHolder="Año" [nzMaxTagCount]="5" [nzAllowClear]="true" formControlName="anio">
                                                    <nz-option *ngFor="let anio of years" [nzLabel]="anio.year" [nzValue]="anio.year"> </nz-option>
                                                </nz-select>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="divTipoBaja" *ngIf="ConfigShow.TipoBajaID && ConfigShow.TipoBajaID.show ===true">
                <div class="row mt-3">
                    <div class="col-2">
                        <label for="soluciones"><fa-icon class="mr-1" [icon]='["fas", "filter"]'></fa-icon> &nbsp;<strong>Baja realizada por:</strong></label>
                    </div>
                    <div class="col-9">
                        <div class="ng-star-inserted">
                            <ng-container *ngFor="let type of tipoBaja">
                                <input
                                    class="icheckbox_square-blue"
                                    type="radio"
                                    name="TipoBajaID"
                                    formControlName="TipoBajaID"
                                    value={{type.value}}
                                />
                                &nbsp;&nbsp;{{ type.text }}&nbsp;&nbsp;
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div id="divUsuarioID" *ngIf="ConfigShow.cbxUsuarioID && ConfigShow.cbxUsuarioID.show === true">
                <div class="row mt-3">
                    <div class="col-2">
                        <label for="soluciones"><fa-icon class="mr-1" [icon]='["fas", "filter"]'></fa-icon> &nbsp;<strong>Usuario</strong></label>
                    </div>
                    <div class="col-10">
                        <div class="row">
                            <div class="col-md-12">
                                <div >
                                    <div class="">
                                        <nz-form-item>
                                            <nz-form-control>
                                                    <nz-select nzSize="large" nzShowSearch nzPlaceHolder="Selecciona Usuario" [nzMaxTagCount]="5" [nzAllowClear]="true" formControlName="cbxUsuarioID">
                                                    <nz-option nzLabel="Seleccione Usuario"  [nzValue]= '0'></nz-option>
                                                    <nz-option *ngFor="let dataUsuario of dataUsuarios" [nzLabel]="dataUsuario.NombreUsuario" [nzValue]="dataUsuario.Usuario_id"> </nz-option>
                                                </nz-select>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="Tamiz" *ngIf="ConfigShow.inpIncTamiz && ConfigShow.inpIncTamiz.show ===true">
                <div class="row mt-3">
                    <div class="col-2">
                        <label for="soluciones"><fa-icon class="mr-1" [icon]='["fas", "filter"]'></fa-icon> &nbsp;<strong>Incluir NO Tamiz:</strong></label>
                    </div>
                    <div class="col-4">
                        <div class="ng-star-inserted">
                            <ng-container>
                                <input
                                    class="icheckbox_square-blue"
                                    type="radio"
                                    name="inpIncTamiz"
                                    formControlName="inpIncTamiz"
                                    value = "1"
                                />
                                &nbsp;&nbsp;Si&nbsp;&nbsp;
                            </ng-container>
                            <ng-container>
                                <input
                                    class="icheckbox_square-blue"
                                    type="radio"
                                    name="inpIncTamiz"
                                    formControlName="inpIncTamiz"
                                    value = "0"
                                />
                                &nbsp;&nbsp;No&nbsp;&nbsp;
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div id="divSexo" *ngIf="ConfigShow.inpSexo && ConfigShow.inpSexo.show ===true">
                <div class="row mt-3">
                    <div class="col-2">
                        <label for="soluciones"><fa-icon class="mr-1" [icon]='["fas", "filter"]'></fa-icon> &nbsp;<strong>Sexo:</strong></label>
                    </div>
                    <div class="col-10">
                        <div class="ng-star-inserted">
                            <ng-container>
                                <input
                                    class="icheckbox_square-blue"
                                    type="radio"
                                    name="inpSexo"
                                    formControlName="inpSexo"
                                    value = "0"
                                />
                                &nbsp;&nbsp;Todos&nbsp;&nbsp;
                            </ng-container>
                            <ng-container>
                                <input
                                    class="icheckbox_square-blue"
                                    type="radio"
                                    name="inpSexo"
                                    formControlName="inpSexo"
                                    value = "1"
                                />
                                &nbsp;&nbsp;Masculino&nbsp;&nbsp;
                            </ng-container>
                            <ng-container>
                                <input
                                    class="icheckbox_square-blue"
                                    type="radio"
                                    name="inpSexo"
                                    formControlName="inpSexo"
                                    value = "2"
                                />
                                &nbsp;&nbsp;Femenino&nbsp;&nbsp;
                            </ng-container>
                            <ng-container>
                                <input
                                    class="icheckbox_square-blue"
                                    type="radio"
                                    name="inpSexo"
                                    formControlName="inpSexo"
                                    value = "3"
                                />
                                &nbsp;&nbsp;Indefinido/Sin marcar&nbsp;&nbsp;
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div id="divDesglose" *ngIf="ConfigShow.inpDesglose && ConfigShow.inpDesglose.show ===true">
                <div class="row mt-3">
                    <div class="col-2">
                        <label for="soluciones"><fa-icon class="mr-1" [icon]='["fas", "filter"]'></fa-icon> &nbsp;<strong>Incluir Desgloce de Instituciones</strong></label>
                    </div>
                    <div class="col-10">
                        <toggle-button  class="mt-3" [status]="this.formFiltros!.controls.inpDesglose.value"  formControlName="inpDesglose" (changed)="this.formFiltros!.controls.inpDesglose.setValue($event)" name="inpDesglose" ></toggle-button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 mt-3 text-center">
                    <button (click)="OcultarFiltrosForm()" class="btn btn-primary  btn-lg " >Cancelar</button>
                    <button type="submit" class="btn btn-primary  btn-lg " >Generar</button>
                </div>
            </div>
        </div>
        <div *ngIf="TipoFormularioComponent === 1">
            <div id="TipoInstitucion" *ngIf="ConfigShow.TipoBusqueda.show ===true && ([1].includes(this.ActiveNivelID) || ([3,6].includes(this.ActiveNivelID) && ConfigShow.TipoBusqueda.vistaadmin))">
                <div class="row mt-3">
                    <div class="col-2">
                        <label for="soluciones"><fa-icon class="mr-1" [icon]='["fas", "filter"]'></fa-icon> &nbsp;<strong>{{Language('tipoInstitucion')}}:</strong></label>
                    </div>
                    <div class="col-9">
                        <div class="ng-star-inserted" [hidden]="ConfigShow.TipoBusquedaRadios || OcultarRadiosInstNivel">
                            <ng-container *ngFor="let type of searchType">
                                <input
                                    class="icheckbox_square-blue"
                                    type="radio"
                                    name="TipoBusqueda"
                                    formControlName="TipoBusqueda"
                                    (change)="onCheckChangeBusqueda()"
                                    value={{type.value}}
                                />
                                &nbsp;&nbsp;{{Language('tiposInstituciones')[(type.value-1)]}}&nbsp;&nbsp;
                            </ng-container>
                            <ng-container *ngIf="ConfigShow.TipoBusqueda && ConfigShow.TipoBusqueda.especial === true">
                                <input
                                    class="icheckbox_square-blue"
                                    type="radio"
                                    name="TipoBusqueda"
                                    formControlName="TipoBusqueda"
                                    (change)="onCheckChangeBusqueda()"
                                    value = 4
                                />
                                &nbsp;&nbsp;Sin Cliente&nbsp;&nbsp;
                            </ng-container>
                        </div>

                        <div class="row mt-2">
                            <div class="col-md-7" *ngIf="(formFiltros!.controls['TipoBusqueda'].value !== '3' && formFiltros!.controls['TipoBusqueda'].value !== '4') && (ActiveNivelID === 1 || ActiveNivelID === 3 || ActiveNivelID === 6)">
                                <div >
                                    <!---<label class="control-label">{{txtTipoBusqueda}}</label>
                                    <br><br>--->
                                    <div class="">
                                        <nz-form-item>
                                            <nz-form-control>
                                                    <nz-select nzSize="large" nzShowSearch nzPlaceHolder="Selecciona Institucion" [nzMaxTagCount]="5" [nzAllowClear]="true" formControlName="Institucion_Id">
                                                    <nz-option nzLabel= "{{Language('cbxTodas')}}" [nzValue]= '0' ></nz-option>
                                                    <nz-option *ngFor="let dataInstituciones of arrayFiltradoInstituciones" [nzLabel]="dataInstituciones.NombreInst" [nzValue]="dataInstituciones.Institucion_Id"> </nz-option>
                                                </nz-select>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="divGrupoMedico" *ngIf="ConfigShow.cbxGrupoMedicoID && ConfigShow.cbxGrupoMedicoID.show === true && !OcultarRadiosInstNivel">
                <div class="row mt-3">
                    <div class="col-2">
                        <label for="soluciones"><fa-icon class="mr-1" [icon]='["fas", "filter"]'></fa-icon> &nbsp;<strong>{{Language('grupomedico')}}</strong></label>
                    </div>
                    <div class="col-9">
                        <div class="row">
                            <div class="col-md-7">
                                <div >
                                    <div class="">
                                        <nz-form-item>
                                            <nz-form-control>
                                                    <nz-select nzSize="large" nzShowSearch nzPlaceHolder="{{Language('selectgrupomedico')}}" [nzMaxTagCount]="5" [nzAllowClear]="true" formControlName="cbxGrupoMedicoID">
                                                    <nz-option nzLabel="{{Language('selectgrupomedico')}}"  [nzValue] = '0'></nz-option>
                                                    <nz-option *ngFor="let dataGrupo of dataGrupoMedico" [nzLabel]="dataGrupo.Grupo_Medico" [nzValue]="dataGrupo.Grupo_Medico_Id"> </nz-option>
                                                </nz-select>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="divTipoInst" *ngIf="ConfigShow.cbxTipoInst && ConfigShow.cbxTipoInst.show === true && !OcultarRadiosInstNivel">
                <div class="row mt-3">
                    <div class="col-2">
                        <label for="soluciones"><fa-icon class="mr-1" [icon]='["fas", "filter"]'></fa-icon> &nbsp;<strong>{{Language('tipoInstitucion')}}</strong></label>
                    </div>
                    <div class="col-9">
                        <div class="row">
                            <div class="col-md-7">
                                <div >
                                    <div class="">
                                        <nz-form-item>
                                            <nz-form-control>
                                                <nz-select nzSize="large" nzMode="multiple" nzPlaceHolder="{{Language('selecttipoInstitucion')}}" [nzMaxTagCount]="5" [nzAllowClear]="true" formControlName="cbxTipoInst">
                                                    <nz-option *ngFor="let dataTipoInst of dataTipoInstituciones" [nzLabel]="dataTipoInst.Tipo_Institucion" [nzValue]="dataTipoInst.Tipo_Institucion_ID"> </nz-option>
                                                </nz-select>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="TipoBusquedaPeriodo" *ngIf="ConfigShow.TipoBusquedaPeriodo && ConfigShow.TipoBusquedaPeriodo.show === true">
                <div class="row mt-3">
                    <div class="col-2">
                        <label for="soluciones"><fa-icon class="mr-1" [icon]='["fas", "filter"]'></fa-icon> &nbsp;<strong>{{Language('periodo')}}:</strong></label>
                    </div>
                    <div class="col-9">
                        <div class="ng-star-inserted" [hidden]="ConfigShow.TipoBusquedaPeriodoRadios">
                            <ng-container *ngFor="let periodo of searchPeriodo">
                                <input
                                    class="icheckbox_square-blue"
                                    type="radio"
                                    name="TipoBusquedaPeriodo"
                                    formControlName="TipoBusquedaPeriodo"
                                    value="{{periodo.value}}"
                                />
                                &nbsp;&nbsp;{{Language('TiposPeriodos')[periodo.value]}}&nbsp;&nbsp;
                            </ng-container>
                        </div>
                        <div class="col-md-7 mt-2" *ngIf="formFiltros!.controls['TipoBusquedaPeriodo'].value !== '0'">
                            <div class="col-12 form-group" *ngIf="formFiltros!.controls['TipoBusquedaPeriodo'].value === '1' && !ConfigShow.TipoBusquedaPeriodo.especial">
                                <!---<div>
                                    <label class="control-label">Seleccione Fecha </label>
                                </div><br>--->
                                <div nz-row [nzGutter]="16">
                                    <div nz-col class="gutter-row" [nzSpan]="12">
                                        <nz-form-item>
                                            <nz-form-control>
                                                <nz-select nzSize="large" nzShowSearch nzPlaceHolder= "{{Language('Mes')}}" [nzMaxTagCount]="5" [nzAllowClear]="true" formControlName="mes">
                                                    <nz-option *ngFor="let FechaMes of dataMeses" [nzLabel]="FechaMes.mes" [nzValue]="FechaMes.id"> </nz-option>
                                                </nz-select>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                    <div nz-col class="gutter-row" [nzSpan]="12">
                                        <nz-form-item>
                                            <nz-form-control>
                                                <nz-select nzSize="large" nzShowSearch nzPlaceHolder="{{Language('Ano')}}" [nzMaxTagCount]="5" [nzAllowClear]="true" formControlName="anio">
                                                    <nz-option *ngFor="let anio of years" [nzLabel]="anio.year" [nzValue]="anio.year"> </nz-option>
                                                </nz-select>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 " *ngIf="formFiltros!.controls['TipoBusquedaPeriodo'].value === '2' && !ConfigShow.TipoBusquedaPeriodo.especial">
                                <!---<div>
                                    <label class="control-label"> Intervalo de Fechas </label>
                                </div><br>--->
                                <div class="col-12" nz-row [nzGutter] = "16">
                                    <div class="col-6" nz-col class="gutter-row" [nzSpan]="12">
                                        <nz-form-item>
                                            <nz-form-control>
                                                <input nz-input placeholder="Fecha inicial" nzSize="large" type="date" formControlName="fechaIni" />
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                    <div class="col-6" nz-col class="gutter-row" [nzSpan]="12">
                                        <nz-form-item>
                                            <nz-form-control>
                                                <input nz-input placeholder="Fecha final" nzSize="large" type="date" formControlName="fechaFin" />
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 form-group" *ngIf="formFiltros!.controls['TipoBusquedaPeriodo'].value === '1' && ConfigShow.TipoBusquedaPeriodo.especial">
                                <!---<div>
                                    <label class="control-label">Seleccione Fecha </label>
                                </div><br>--->
                                <div nz-row [nzGutter]="16">
                                    <div nz-col class="gutter-row" [nzSpan]="8">
                                        <nz-form-item>
                                            <nz-form-control>
                                                <nz-select nzSize="large" nzShowSearch nzPlaceHolder="{{Language('MesTo')}}" [nzMaxTagCount]="5" [nzAllowClear]="true" formControlName="mes">
                                                    <nz-option *ngFor="let FechaMes of dataMeses" [nzLabel]="FechaMes.mes" [nzValue]="FechaMes.id"> </nz-option>
                                                </nz-select>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                    <div nz-col class="gutter-row" [nzSpan]="8">
                                        <nz-form-item>
                                            <nz-form-control>
                                                <nz-select nzSize="large" nzShowSearch nzPlaceHolder= "{{Language('MesFrom')}}"  [nzMaxTagCount]="5" [nzAllowClear]="true" formControlName="fechaMesFin">
                                                    <nz-option *ngFor="let FechaMesFin of dataMeses" [nzLabel]="FechaMesFin.mes" [nzValue]="FechaMesFin.id"> </nz-option>
                                                </nz-select>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                    <div nz-col class="gutter-row" [nzSpan]="8">
                                        <nz-form-item>
                                            <nz-form-control>
                                                <nz-select nzSize="large" nzShowSearch nzPlaceHolder="{{Language('AnoEspecial')}}" [nzMaxTagCount]="5" [nzAllowClear]="true" formControlName="anio">
                                                    <nz-option *ngFor="let anio of years" [nzLabel]="anio.year" [nzValue]="anio.year"> </nz-option>
                                                </nz-select>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="divTipoBaja" *ngIf="ConfigShow.TipoBajaID && ConfigShow.TipoBajaID.show ===true">
                <div class="row mt-3">
                    <div class="col-2">
                        <label for="soluciones"><fa-icon class="mr-1" [icon]='["fas", "filter"]'></fa-icon> &nbsp;<strong>{{Language('bajaPor')}}:</strong></label>
                    </div>
                    <div class="col-9">
                        <div class="ng-star-inserted">
                            <ng-container *ngFor="let type of tipoBaja">
                                <input
                                    class="icheckbox_square-blue"
                                    type="radio"
                                    name="TipoBajaID"
                                    formControlName="TipoBajaID"
                                    value={{type.value}}
                                />
                                &nbsp;&nbsp;{{Language('TipoBajas')[type.value]}}&nbsp;&nbsp;
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div id="divUsuarioID" *ngIf="ConfigShow.cbxUsuarioID && ConfigShow.cbxUsuarioID.show === true">
                <div class="row mt-3">
                    <div class="col-2">
                        <label for="soluciones"><fa-icon class="mr-1" [icon]='["fas", "filter"]'></fa-icon> &nbsp;<strong>{{Language('user')}}</strong></label>
                    </div>
                    <div class="col-9">
                        <div class="row">
                            <div class="col-md-7">
                                <div >
                                    <div class="">
                                        <nz-form-item>
                                            <nz-form-control>
                                                    <nz-select nzSize="large" nzShowSearch nzPlaceHolder="Selecciona Institucion" [nzMaxTagCount]="5" [nzAllowClear]="true" formControlName="cbxUsuarioID">
                                                    <nz-option nzLabel="{{Language('selectuser')}}"  [nzValue]= '0'></nz-option>
                                                    <nz-option *ngFor="let dataUsuario of dataUsuarios" [nzLabel]="dataUsuario.NombreUsuario" [nzValue]="dataUsuario.Usuario_id"> </nz-option>
                                                </nz-select>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="Tamiz" *ngIf="(ConfigShow.inpIncTamiz && ConfigShow.inpIncTamiz.show ===true) && !OcultarRadiosInstNivel">
                <div class="row mt-3">
                    <div class="col-2">
                        <label for="soluciones"><fa-icon class="mr-1" [icon]='["fas", "filter"]'></fa-icon> &nbsp;<strong>{{Language('noIncluirTamiz')}}:</strong></label>
                    </div>
                    <div class="col-4">
                        <div class="ng-star-inserted">
                            <ng-container>
                                <input
                                    class="icheckbox_square-blue"
                                    type="radio"
                                    name="inpIncTamiz"
                                    formControlName="inpIncTamiz"
                                    value = "1"
                                />
                                &nbsp;&nbsp;{{Language('opSI')}}&nbsp;&nbsp;
                            </ng-container>
                            <ng-container>
                                <input
                                    class="icheckbox_square-blue"
                                    type="radio"
                                    name="inpIncTamiz"
                                    formControlName="inpIncTamiz"
                                    value = "0"
                                />
                                &nbsp;&nbsp;{{Language('opNo')}}&nbsp;&nbsp;
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div id="divSexo" *ngIf="ConfigShow.inpSexo && ConfigShow.inpSexo.show ===true && !OcultarRadiosInstNivel">
                <div class="row mt-3">
                    <div class="col-2">
                        <label for="soluciones"><fa-icon class="mr-1" [icon]='["fas", "filter"]'></fa-icon> &nbsp;<strong>{{Language('sexo')}}:</strong></label>
                    </div>
                    <div class="col-10">
                        <div class="ng-star-inserted">
                            <ng-container>
                                <input
                                    class="icheckbox_square-blue"
                                    type="radio"
                                    name="inpSexo"
                                    formControlName="inpSexo"
                                    value = "0"
                                />
                                &nbsp;&nbsp;{{Language('sexoOP0')}}&nbsp;&nbsp;
                            </ng-container>
                            <ng-container>
                                <input
                                    class="icheckbox_square-blue"
                                    type="radio"
                                    name="inpSexo"
                                    formControlName="inpSexo"
                                    value = "1"
                                />
                                &nbsp;&nbsp;{{Language('sexoOP1')}}&nbsp;&nbsp;
                            </ng-container>
                            <ng-container>
                                <input
                                    class="icheckbox_square-blue"
                                    type="radio"
                                    name="inpSexo"
                                    formControlName="inpSexo"
                                    value = "2"
                                />
                                &nbsp;&nbsp;{{Language('sexoOP2')}}&nbsp;&nbsp;
                            </ng-container>
                            <ng-container>
                                <input
                                    class="icheckbox_square-blue"
                                    type="radio"
                                    name="inpSexo"
                                    formControlName="inpSexo"
                                    value = "3"
                                />
                                &nbsp;&nbsp;{{Language('sexoOP3')}}&nbsp;&nbsp;
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div id="divTipoBusquedaServ" *ngIf="ConfigShow.TipoBusquedaServicio && ConfigShow.TipoBusquedaServicio.show ===true">
                <div class="row mt-3">
                    <div class="col-2">
                        <label for="TipoBusquedaServicio"><fa-icon class="mr-1" [icon]='["fas", "filter"]'></fa-icon> &nbsp;<strong>{{Language('BusqServ')}}:</strong></label>
                    </div>
                    <div class="col-9">
                        <div class="ng-star-inserted">
                            <ng-container *ngFor="let type of searchTipoServ" >
                                    <input *ngIf="ConfigShow.TipoBusquedaServicio.tiposol >= type.tiposol"
                                        class="icheckbox_square-blue"
                                        type="radio"
                                        name="TipoBusquedaServicio"
                                        formControlName="TipoBusquedaServicio"
                                        value={{type.value}}
                                    />
                                    &nbsp;&nbsp;<span *ngIf="ConfigShow.TipoBusquedaServicio.tiposol >= type.tiposol" >{{Language('TipoBusqServ')[type.value - 1]}}</span>&nbsp;&nbsp;
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div id="divDesglose" *ngIf="ConfigShow.inpDesglose && ConfigShow.inpDesglose.show ===true">
                <div class="row mt-3">
                    <div class="col-2 mt-4">
                        <label for="soluciones"><fa-icon class="mr-1" [icon]='["fas", "filter"]'></fa-icon> &nbsp;<strong>{{Language('IncluirDesglose')}}</strong></label>
                    </div>
                    <div class="col-10">
                        <toggle-button  class="mt-3" [status]="this.formFiltros!.controls.inpDesglose.value"  formControlName="inpDesglose" (changed)="this.formFiltros!.controls.inpDesglose.setValue($event)" name="inpDesglose" ></toggle-button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 mt-3 text-center">
                    <button (click)="OcultarFiltrosForm()" class="btn btn-primary  btn-lg m-1" >{{Language('btnCancelar')}}</button>
                    <button type="submit" class="btn btn-success  btn-lg m-1">{{Language('btnGenerar')}}</button>
                </div>
            </div>
        </div>
    </form>
</div>
