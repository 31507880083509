import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'gk-historial-va-m',
  templateUrl: './historial-va-m.component.html',
  styleUrl: './historial-va-m.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void => *', [
        animate(500)
      ]),
      transition('* => void', [
        animate(500)
      ])
    ])
  ]
})
export class HistorialVaMComponent implements OnInit{

  encabezado:any;
  validacionesCliente:any ;
  validacionCliente:any = false ;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,){
    if(this.data && this.data.validacionesCliente && this.data.encabezado ){
      this.validacionesCliente = this.data.validacionesCliente;
      this.encabezado = this.data.encabezado;
    }
  }
  ngOnInit(): void {
    console.log(this.validacionesCliente);
    
  }

  cerrarTabla() {
    this.validacionCliente = false;
  }

    cargarTabla(validacion:any) {
      this.validacionCliente = validacion;
    }

}
