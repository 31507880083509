import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'gk-file-viewer-dialog',
    templateUrl: './file-viewer-dialog.component.html',
    styleUrls: ['./file-viewer-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileViewerDialogComponent {
    @Input() file!: string;
    @Input() ext!: string;

    constructor(private activeModal: NgbActiveModal) {}

    dismiss() {
        this.activeModal.dismiss();
    }
}
