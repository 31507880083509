import { NgModule } from '@angular/core';

import {MatDialogModule} from '@angular/material/dialog';
import {MatStepperModule} from '@angular/material/stepper';
import {MatIconModule} from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDividerModule} from '@angular/material/divider';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatExpansionModule } from '@angular/material/expansion';

import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { CustomMatPaginatorIntl } from './CustomMatPaginator';


@NgModule({
  exports: [ 
    MatDialogModule,
    MatStepperModule,
    MatIconModule,
    MatDividerModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    MatExpansionModule
  ],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    },
    {
      provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl
    }
  ]
})
export class NgmaterialModule { }
