import { trigger, transition, style, animate } from '@angular/animations';
import { ChangeDetectionStrategy, Component, inject, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppCommonService } from '@common/services';
import { CorreoRequest } from '@modules/medical-records/models';
import { MedicalRecordsService } from '@modules/medical-records/services';

@Component({
  selector: 'gk-send-request-m',
  templateUrl: './send-request-m.component.html',
  styleUrl: './send-request-m.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeInOut', [
        transition(':enter', [
            style({ opacity: 0 }),
            animate('500ms ease-in', style({ opacity: 1 }))
        ]),
        transition(':leave', [
            style({ opacity: 1 }),
            animate('500ms ease-out', style({ opacity: 0 }))
        ])
    ]),
  ]
})
export class SendRequestMComponent implements OnInit{

  //? Variables
  correoRequest: CorreoRequest;
  idSolicitud:  number;

  //? Servicios
  private medicalRecordsService = inject(MedicalRecordsService);
  private fb = inject(FormBuilder);
  private appCommon = inject(AppCommonService);

  formEnviarSolicitud: FormGroup = this.fb.group({
    Cuerpo_email:         [ ,  Validators.required] ,
    SolicitudInformacionId: [],
    tipoasesorid:         [],
    Correosto:            [],
    Correoscc:            [''],
    Asunto:               []
  });


  isValidFieldEnviarSolicitud( field: string ): boolean | null {
    return this.formEnviarSolicitud.controls[field].errors 
    && this.formEnviarSolicitud.controls[field].touched;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SendRequestMComponent>
  ){
    if(this.data && this.data.CorreoRequest  && this.data.IdSolicitud){
      this.correoRequest = this.data.CorreoRequest;
      this.idSolicitud   = this.data.IdSolicitud;
    }
  }

  ngOnInit(): void {
    this.formEnviarSolicitud.patchValue({
      SolicitudInformacionId: this.idSolicitud,
      Cuerpo_email: this.correoRequest.Cuerpo_email,
      Asunto: this.correoRequest.Asunto,
      Correosto: this.correoRequest.Correosto.map( correo => correo.correo_medico ),
    })
    if(this.correoRequest.Correoscc[0].correo_medico_cc != ""){
      this.formEnviarSolicitud.patchValue({
        Correoscc: this.correoRequest.Correoscc.map( correo => correo.correo_medico_cc),
      })
    }
  }

  //? Configuracion del editor de texto
  editorModules = {
    toolbar: [
      [{ 'header': [] }],      // Tamaño de texto
      ['blockquote'],               
      ['bold', 'italic', 'underline', 'strike' ],
    ]
  };

  generarCorreos(formValue){
    formValue.Correosto = formValue.Correosto.map((correo: string) => {
      return { correo_medico: correo };
    });
    if(formValue.Correoscc != ''){
      formValue.Correoscc = formValue.Correoscc.map((correo: string) => {
        return { correo_medico_cc: correo };
      });
    }
  }

  enviarSolicitud(){
    if(this.formEnviarSolicitud.invalid){
      this.appCommon.showMsg('Asignacion invalida', `Favor de rellenar los campos faltantes`);
      this.formEnviarSolicitud.markAllAsTouched();
      return;
    }
    this.appCommon.spinner(true);
    const formValue =  { ...this.formEnviarSolicitud.value };
    this.generarCorreos(formValue);
    this.medicalRecordsService.postCorreoAsignados(formValue).subscribe({
      next: (resp) => {
        this.appCommon.spinner(false);
        this.appCommon.showMsg(`Operacion exitosa`, resp.Mensaje , () => {
          this.dialogRef.close();
        });
      },
      error: err => {
        this.appCommon.spinner(false);
        this.appCommon.showMsg(`Operacion fallida`, `A ocurrido un error al querer realizar la operacion`);
        console.log('err', err);
      }
    });
  }

}
