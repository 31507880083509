import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Injectable, OnInit, ViewChild } from '@angular/core';
import { AppCommonService } from '@common/services';
import { TamikService } from '@modules/operations-tk/services';
@Injectable({ providedIn: 'root' })

@Component({
    selector: 'app-pf-tamik-detail',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './pf-tamik-detail.component.html',
    styleUrls: ['pf-tamik-detail.component.scss'],
})
export class PfTamikDetailComponent implements OnInit {
    DatosFacturacionPF: any;
    constructor(private tamikService: TamikService, public appCommon: AppCommonService, private ref: ChangeDetectorRef) {}
    DatosGeneralesPF: any;
    DatosGeneralesAsesoria: any;
    DatosGeneralesEstudiosAsesoria: any;
    DatoEstatusPF: any;

    @ViewChild('fCLick')
    fCLick!: ElementRef<HTMLElement>;
    ngOnInit() {}

    triggerFalseClick() {
        const el: HTMLElement = this.fCLick.nativeElement;
        el.click();
    }

    async DetalleEstatusPF(codigopf) {
        this.appCommon.spinner(true);
        this.tamikService
            .getDatosGenerales(codigopf)
            .then(
                (Default: any) => {
                    this.DatosGeneralesPF = Default.data.DatosGenerales;
                    this.DatosGeneralesAsesoria = Default.data.DatosAsesoria;
                    this.DatosGeneralesEstudiosAsesoria = Default.data.DatosEstudiosAsesoria;
                    this.DatoEstatusPF = Default.data.DatosEstatus;
                    this.DatosFacturacionPF = Default.data.DatosFacturacion;
                    if (this.DatosGeneralesPF.Filtro_Inicial_Id !== 0) {
                        if (this.DatosGeneralesPF.Filtro_repeticion_Id === 0) {
                            this.DatosGeneralesPF.NecesitaRepeticion = 'El filtro necesita una repetición y no la tiene';
                        } else if (this.DatosGeneralesPF.Inicial === 0) {
                            this.DatosGeneralesPF.NecesitaRepeticion = 'Este filtro es una repetición';
                        } else {
                            this.DatosGeneralesPF.NecesitaRepeticion = '';
                        }
                    } else if (this.DatosGeneralesPF.Filtro_repeticion_Id !== 0 || this.DatosGeneralesPF.Inicial === 0) {
                        this.DatosGeneralesPF.NecesitaRepeticion = 'Este filtro es una repetición';
                    } else {
                        this.DatosGeneralesPF.NecesitaRepeticion = '';
                    }
                    this.Download(codigopf);
                    // console.info("THIS RESPONSE API Facturacion: ", this.DatosFacturacionPF);
                    /*  if(this.DatoEstatusPF.length == 0)
                    {
                        this.appCommon.Notify("No se encontraron datos para este PF", 'warning');
                    }
                    else
                    {
                        if (this.DatosGeneralesPF.Filtro_Inicial_Id != 0)
                        {
                            if (this.DatosGeneralesPF.Filtro_repeticion_Id == 0)
                            {
                                this.NecesitaRepeticion = "El filtro necesita una repetici&oacute;n y no la tiene"
                            }
                        }
                        this.CodigoPF = this.DatosGeneralesPF.codigo
                        this.FiltroId = this.DatosGeneralesPF.filtro_id
                        this.Institucion = this.DatosGeneralesPF.Nombre_Comercial
                        this.NombreBebe = this.DatosGeneralesPF.Nombre_bebe
                        this.NombreMama = this.DatosGeneralesPF.Nombre_mama
                        this.FechaTomaMuestra = this.DatosGeneralesPF.Fecha_hora_toma

                        this.totalpasos = this.DatoEstatusPF.length;
                        this.ImagenPF = this.DatoEstatusPF[0].imagen_pf;
                        this.ImagenPostPF = this.DatoEstatusPF[0].imagen_poste_pf;
                        if(this.ImagenPF != "")
                        {
                            this.mostrardivimg = true;
                        }
                        if(this.ImagenPostPF != "")
                        {
                            this.mostrardivimgpost = true;
                        }
                        if (this.DatosFacturacionPF != undefined)
                        {
                            if (this.DatosFacturacionPF.Factura != 0 )
                            {
                                this.Factura = this.DatosFacturacionPF.factura
                            }

                            if (this.DatosFacturacionPF.Filtro_repeticion_Id != 0)
                            {

                                this.RepeticionFacturacion = "Este filtro es una repetici&oacute;n"
                            }

                            this.Remision = this.DatosFacturacionPF.nota_id
                            this.Costo = this.DatosFacturacionPF.costo + this.DatosFacturacionPF.tipo_moneda
                            this.Contrato = this.DatosFacturacionPF.contrato
                        }
                        //console.info("THIS RESPONSE API Generales: ", this.DatosGeneralesPF);
                        this.mostrardivdatos = true;
                        this.mostrardivestatus = false;
                        this.mostrardivbusqueda = false
                    }*/
                    this.appCommon.spinner(false);
                    this.triggerFalseClick();
                    this.ref.detectChanges();
                },
                error => {
                    console.log('fail callback', error);
                    this.appCommon.spinner(false);
                    // alert(error.data.message);
                    this.appCommon.Notify(error.data.message, 'danger');
                    this.ref.detectChanges();
                }
            )
            .catch(e => {
                this.appCommon.spinner(false);
                console.log('catch', e);
                this.ref.detectChanges();
            });
    }

    async Download(codigopf) {
        const name = '30573401.jpg';
        const requestBody = {
            type: 'imagen-pf',
            name,
        };
        this.tamikService
            .downloadTestPdf(requestBody)
            .then(
                (Default: any) => {
                    this.DatosGeneralesPF.ImagenPF = Default.file;
                    this.ref.detectChanges();
                },
                error => {
                    console.log('fail callback', error);
                    this.appCommon.Notify(error.data.message, 'error');
                    this.ref.detectChanges();
                }
            )
            .catch(e => {
                console.log('catch', e);
                this.appCommon.spinner(false);
                this.ref.detectChanges();
            });
    }
}
