import { ChangeDetectionStrategy, Component, EventEmitter, Inject, OnInit, Output, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CustomersService } from '@modules/customers/services';
import { ConfirmacionMComponent } from '../confirmacion-m/confirmacion-m.component';
import { AppCommonService } from '@common/services';

@Component({
  selector: 'gk-tamizaje-m',
  templateUrl: './tamizaje-m.component.html',
  styleUrl: './tamizaje-m.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TamizajeMComponent implements OnInit{

  //? combos recibidos
  combos: any;
  institucionid: any;
  InstitucionTamizajeId = "";
  tamizaje: any;
  @Output() tamizajeAdded = new EventEmitter<any>();

  private fb = inject(FormBuilder);
  private customersService= inject(CustomersService);
  private dialogRef= inject(MatDialogRef<TamizajeMComponent>);
  private dialog = inject(MatDialog);
  public appCommon= inject(AppCommonService);

  public formTamizaje: FormGroup = this.fb.group({
    Tamiz: [,Validators.required],
    Costo_Aproximado: [,Validators.required],
    IncluidoPaquete: [],
    Comentarios: []
  })

  //ToDos campos invalidos
  isValidFieldTamiz( field: string ): boolean | null{
    return this.formTamizaje.controls[field].errors 
    && this.formTamizaje.controls[field].touched;
  }


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,){
    if(this.data && this.data.combos && this.data.institucionid && !this.data.tamizaje ){
      this.combos = this.data.combos;
      this.institucionid = this.data.institucionid;
    }else if(this.data && this.data.combos && this.data.institucionid && this.data.tamizaje ){
      this.combos = this.data.combos;
      this.institucionid = this.data.institucionid;
      this.tamizaje = this.data.tamizaje;
    }
  }
  ngOnInit(): void {
    if(this.tamizaje){
      console.log(this.tamizaje);
      this.formTamizaje.patchValue({
        Tamiz: this.tamizaje.Tamizaje_Id,
        Costo_Aproximado: this.tamizaje.Costo_Aproximado,
        Comentarios: this.tamizaje.Comentarios
      });
      if(this.tamizaje.Incluido_Paquete){
        this.formTamizaje.patchValue({
          IncluidoPaquete: 1
        })
      }else{
        this.formTamizaje.patchValue({
          IncluidoPaquete: 0
        })
      }
      this.InstitucionTamizajeId = this.tamizaje.Institucion_Tamizaje_Id.toString();
    }
  }

  saveTamizaje(){
    if(this.formTamizaje.invalid){
      const dialogRefM = this.dialog.open(ConfirmacionMComponent, {
        data: { titulo: `formulario invalido`, 
                mensaje: `Favor de terminar el formulario`,
                Noconfirm: true
            }
      })
      dialogRefM.afterOpened().subscribe(() => {
          setTimeout(() => {
              dialogRefM.close();
          }, 3000);
      });

      this.formTamizaje.markAllAsTouched();
      return
    }

    this.appCommon.spinner(true);

    //? Desestructuro el objeto 
    const formValue = { ...this.formTamizaje.value }

    formValue.institucionid = this.institucionid;
    formValue.InstitucionTamizajeId = this.InstitucionTamizajeId;
    formValue.Tamiz = parseInt(formValue.Tamiz);

    console.log(formValue);

    this.customersService.addTamizaje(formValue)
      .subscribe(
        {
          next: (resp: any) => {
            this.appCommon.spinner(false);
            const dialogRefM = this.dialog.open(ConfirmacionMComponent, {
              data: { titulo: `Paquete agregado`, 
                      mensaje: `Se ha guardado el paquete`,
                      Noconfirm: true
                  }
            })
            dialogRefM.afterOpened().subscribe(() => {
              setTimeout(() => {
                dialogRefM.close();
              }, 3000);
            });
            this.tamizajeAdded.emit(resp.data);
            this.dialogRef.close();
          },
          error: err => {
            this.appCommon.spinner(false);
            const dialogRefM = this.dialog.open(ConfirmacionMComponent, {
              data: { titulo: `No se pudo guardar la informacion`, 
                      mensaje: `A ocurrido un error al querer guardar el paquete de Maternidad`,
                      Noconfirm: true
                    }
              })

            dialogRefM.afterOpened().subscribe(() => {
              setTimeout(() => {
                dialogRefM.close();
              }, 3000);
            });
            console.log('error al enviar la informacion', err)
          }
        }
      )
    
  };



}
