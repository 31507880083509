import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from '@modules/auth/services';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import {map} from 'rxjs/operators'


import type { AddCustomer, AddDoctorEco, AddPaqueteM, AddPlantilla, AddTamizaje, Customers, Default, 
    DeleteAlertRes, DeleteConvenio, DeletePaqueteM, DeleteTamizaje, GetContratoConvenio, GetDoctorEco, GetTablaPrecio, SaveConvenio, SaveCustomer, changedEstatusCustomer, estatusChange, returnValidation } from '../models';
import { request } from 'http';

@Injectable()
export class CustomersService {
    API_URL = environment.api.auth;
    API_URL_CUSTOMERS = environment.api.customers;
    API_URL_CUSTOMERSADMON = environment.api.admonCustomers;
    API_URL_SYSTEMCUSTOMERS = environment.api.systemcustomers

    constructor(private http: HttpClient, private userService: UserService) {}

    getCustomers$(): Observable<{}> {
        return of({});
    }
    async getTypeCustomers() {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({
                Authorization: `Bearer ${this.userService.AccessToken}`,
            });

            this.http
                .get<Customers>(`${this.API_URL_CUSTOMERS}/TypeCustomers`)
                .subscribe(resp => {
                    if(resp.status == "fail"){
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                });
        });
    }
    async getTypeSolution() {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({
                Authorization: `Bearer ${this.userService.AccessToken}`,
            });

            this.http
                .get<Customers>(`${this.API_URL_CUSTOMERS}/TypeSolution`)
                .subscribe(resp => {
                    if(resp.status == "fail"){
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                });
        });
    }
    async getMediumGeneration() {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({
                Authorization: `Bearer ${this.userService.AccessToken}`,
            });

            this.http
                .get<Customers>(`${this.API_URL_CUSTOMERS}/MediumGeneration`)
                .subscribe(resp => {
                    if(resp.status == "fail"){
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                });
        });
    }
    async getCompanies() {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({
                Authorization: `Bearer ${this.userService.AccessToken}`,
            });

            this.http
                .get<Customers>(`${this.API_URL_CUSTOMERS}/Companies`)
                .subscribe(resp => {
                    if(resp.status == "fail"){
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                });
        });
    }
    async getTypeAgreement() {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({
                Authorization: `Bearer ${this.userService.AccessToken}`,
            });

            this.http
                .get<Customers>(`${this.API_URL_CUSTOMERS}/TypeAgreement`)
                .subscribe(resp => {
                    if(resp.status == "fail"){
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                });
        });
    }
    async insertCustomer(requestBody) {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({
                Authorization: `Bearer ${this.userService.AccessToken}`,
            });

            this.http
                .post<Customers>(`${this.API_URL_CUSTOMERSADMON}/customer/add`, requestBody, {
                    headers,
                })
                .subscribe(resp => {
                    if(resp.status == "fail"){
                        console.info('RESPONSE ERROR: ',  resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                });
        });
    }

    async searchCustomers(requestBody) {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({
                Authorization: `Bearer ${this.userService.AccessToken}`,
            });

            this.http
                .post<Customers>(`${this.API_URL_CUSTOMERS}/customers/search`, requestBody, {
                    headers,
                })
                .subscribe(resp => {
                    if(resp.status == "fail"){
                        console.info('RESPONSE ERROR: ',  resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                });
        });
    }


    getCustomer(requestBody) {

        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_CUSTOMERSADMON}/customer/get` ;

        return this.http.post<{ statusCode: boolean, message:string, data:any  }>(  url, requestBody, {
            headers}).pipe(
                map( (resp: { statusCode: boolean, message:string, data:any  } ) => resp.data   )
            )
    }

    getTablaPrecio(requestBody){
        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_CUSTOMERSADMON}/tablaprecio/get`;

        return this.http.post<GetTablaPrecio>(  url, requestBody, { headers})
                    .pipe(
                        map( ( resp: GetTablaPrecio ) => resp.data )
                    )

    }

    addCustomer(customer: AddCustomer){
        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_CUSTOMERSADMON}/customer/user/save`;

        return this.http.post<AddCustomer>(  url, customer, { headers } )
    }

    saveCustomer(customer: SaveCustomer){
        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_CUSTOMERSADMON}/customer/save`;

        return this.http.post<SaveCustomer>(  url, customer, { headers } )
    }

    changeStatusCustomer(status: changedEstatusCustomer){
        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_CUSTOMERSADMON}/customer/estatus`;

        return this.http.post<changedEstatusCustomer>(  url, status, { headers } )
    }

    regresaValidacion(validacion: returnValidation){

        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_CUSTOMERSADMON}/customer/validacion/cancel`;

        return this.http.post<returnValidation>(  url, validacion, { headers } )
    }

    inactivaUser(id: number){
        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_CUSTOMERSADMON}/customer/user/inactive/${id}`;

        return this.http.get(  url, { headers } )
    }

    deleteAlert(alerta: DeleteAlertRes){
        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_CUSTOMERSADMON}/customer/alertas/del`;

        return this.http.post<DeleteAlertRes>(  url, alerta , { headers } )
    }

    saveConvenio(convenio: SaveConvenio){

        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_CUSTOMERSADMON}/convenio/save`;

        return this.http.post<SaveConvenio>(  url, convenio , { headers } )

    }

    deleteConvenio(convenio: DeleteConvenio){
        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_CUSTOMERSADMON}/convenio/del`;

        return this.http.post<DeleteConvenio>(  url, convenio , { headers } )
    }

    getContratoConvenio(contrato: GetContratoConvenio){
        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_CUSTOMERSADMON}/customer/contratos/get`;

        return this.http.post<GetContratoConvenio>(  url, contrato , { headers } )
    }

    addTamizaje(tamizaje: AddTamizaje){
        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_CUSTOMERSADMON}/tamizaje/save`;

        return this.http.post<AddTamizaje>(  url, tamizaje , { headers } )
    }

    deleteTamizaje(tamizaje: DeleteTamizaje){
        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_CUSTOMERSADMON}/tamizaje/del`;

        return this.http.post<DeleteTamizaje>(  url, tamizaje , { headers } )
    }

    addPaqueteMater(paqueteM: AddPaqueteM){
        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_CUSTOMERSADMON}/paquete-maternidad/save`;

        return this.http.post<AddPaqueteM>(  url, paqueteM , { headers } )
    }

    deletePaqueteMater(paqueteM: DeletePaqueteM){
        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_CUSTOMERSADMON}/paquete-maternidad/del`;
        console.log(paqueteM);

        return this.http.post<DeletePaqueteM>(  url, paqueteM , { headers } )
    }

    addDoctorEco(doctor: AddDoctorEco){
        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_CUSTOMERSADMON}/doctoreco/save`;

        return this.http.post<AddDoctorEco>(  url, doctor , { headers } )
    }

    getDoctorEco(doctor: GetDoctorEco){
        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_CUSTOMERSADMON}/doctoreco/get`;

        return this.http.post<GetDoctorEco>(  url, doctor , { headers } )
    }

    savePlantillas(plantilla: AddPlantilla){
        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_CUSTOMERSADMON}/plantillas/save`;

        return this.http.post<AddPlantilla>(  url, plantilla , { headers } )
    }

    cambiaEstatus(estatus: estatusChange){
        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_CUSTOMERSADMON}/customer/estatus`;

        return this.http.post<estatusChange>(  url, estatus , { headers } )
    }

    async getValidationsData() {
        return new Promise((resolve, reject) => {
            this.http
            .get<Customers>(`${this.API_URL_CUSTOMERSADMON}/customer/validaciones/get`)
            .subscribe(resp => {
                if(resp.status == "fail"){
                    console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                resolve(resp.data);
            });
        });
    }

    async getResponsibles() {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({
                Authorization: `Bearer ${this.userService.AccessToken}`,
            });

            this.http
                .get<Customers>(`${this.API_URL_CUSTOMERS}/getResponsibles`)
                .subscribe(resp => {
                    if(resp.status == "fail"){
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                });
        });
    }

    async getCountrys() {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({
                Authorization: `Bearer ${this.userService.AccessToken}`,
            });

            this.http
                .get<Customers>(`${this.API_URL_CUSTOMERS}/getCountrys`)
                .subscribe(resp => {
                    if(resp.status == "fail"){
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                });
        });
    }

    async getStates(requestBody) {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({
                Authorization: `Bearer ${this.userService.AccessToken}`,
            });

            this.http
                .post<Customers>(`${this.API_URL_CUSTOMERS}/getStates`, requestBody)
                .subscribe(resp => {
                    if(resp.status == "fail"){
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                });
        });
    }

    async getCities(requestBody) {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({
                Authorization: `Bearer ${this.userService.AccessToken}`,
            });

            this.http
                .post<Customers>(`${this.API_URL_CUSTOMERS}/getCities`, requestBody)
                .subscribe(resp => {
                    if(resp.status == "fail"){
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                });
        });
    }

    async getExchangeRates() {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({
                Authorization: `Bearer ${this.userService.AccessToken}`,
            });

            this.http
                .get<Customers>(`${this.API_URL_CUSTOMERS}/getExchangeRates`)
                .subscribe(resp => {
                    if(resp.status == "fail"){
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                });
        });
    }
    async getZones() {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({
                Authorization: `Bearer ${this.userService.AccessToken}`,
            });

            this.http
                .get<Customers>(`${this.API_URL_CUSTOMERS}/getZones`)
                .subscribe(resp => {
                    if(resp.status == "fail"){
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                });
        });
    }

    async getFiles(requestBody) {
        return new Promise((resolve, reject) => {
            this.http
                .post<Default>(`${this.API_URL_CUSTOMERS}/customer/getFiles`, requestBody)
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    // console.info('RESPONSE: ', resp.data);
                    resolve(resp.data);
                });
        });
    }

    async getInfoUsuario(requestBody) {
        return new Promise((resolve, reject) => {
            this.http
                .post<Default>(`${this.API_URL_SYSTEMCUSTOMERS}/customersurvey/getInfoUsuario`, requestBody)
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    // console.info('RESPONSE: ', resp.data);
                    resolve(resp.data);
                });
        });
    }

        async generaEvaluacion(requestBody) {
        return new Promise((resolve, reject) => {
            this.http
                .put<Default>(`${this.API_URL_SYSTEMCUSTOMERS}/customersurvey/generaEvaluacion`, requestBody)
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    // console.info('RESPONSE: ', resp.data);
                    resolve(resp.data);
                });
        });
    }

    async guardarEncuesta(requestBody) {
        return new Promise((resolve, reject) => {
            this.http
                .post<Default>(`${this.API_URL_SYSTEMCUSTOMERS}/customersurvey/GuardarRespuesta`, requestBody)
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    // console.info('RESPONSE: ', resp.data);
                    resolve(resp.data);
                });
        });
    }

    async getTipoMensajeEncuesta(usuarioid) {
        return new Promise((resolve, reject) => {
            this.http
                .get<Default>(`${this.API_URL_SYSTEMCUSTOMERS}/customersurvey/checkuser/`+usuarioid)
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    // console.info('RESPONSE: ', resp.data);
                    resolve(resp.data);
                });
        });
    }

    async getControlEncuesta(requestBody) {
        return new Promise((resolve, reject) => {
            this.http
                .post<Default>(`${this.API_URL_SYSTEMCUSTOMERS}/customersurvey/ControlEncuesta`, requestBody)
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    // console.info('RESPONSE: ', resp.data);
                    resolve(resp.data);
                });
        });
    }

    



    getEvents$(): Observable<{}> {
        return of({});
    }
}
