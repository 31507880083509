import { AdministrationService } from './administration.service';
import { InvoiceService } from './invoice.service';
import { RequisitionService } from './requisition.service';
import { SuppliersService } from './suppliers.service';
import { AdministrationCierreDeMesService } from './administration.cierredemes.service';
import { AdministrationCoinciliacionBancariaService } from './administration.coinciliacion.bancaria.service';

export const services = [
    AdministrationService, 
    RequisitionService, 
    SuppliersService, 
    SuppliersService, 
    InvoiceService,
    AdministrationCierreDeMesService,
    AdministrationCoinciliacionBancariaService
];

export * from './administration.service';
export * from './requisition.service';
export * from './suppliers.service';
export * from './invoice.service';
export * from './administration.cierredemes.service';