import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChildActivationEnd, Router } from '@angular/router';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { SBRouteData, TopNavMenuItem, NavMenuItem } from '../models';
import { UserService } from '@modules/auth/services';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class NavigationService {
    private sideNavVisibleSubject = new BehaviorSubject<boolean>(true);
    private routeDataSubject = new BehaviorSubject<SBRouteData>({ title: '', activeTopNav: '', breadcrumbs: [] });
    private currentURLSubject = new BehaviorSubject<string>('');
    private topNavMenuSubject = new BehaviorSubject<TopNavMenuItem[]>([]);
    private NavMenuSubject = new BehaviorSubject<NavMenuItem[]>([]);
    pathMenu = environment.pathMenu;

    readonly sideNavVisible$ = this.sideNavVisibleSubject.asObservable();
    readonly routeData$ = this.routeDataSubject.asObservable();
    readonly currentURL$ = this.currentURLSubject.asObservable();
    topNavMenu$ = this.topNavMenuSubject.asObservable();
    NavMenu$ = this.NavMenuSubject.asObservable();
    userId;
    isCliente:boolean = false;
    readonly title$ = this.routeData$.pipe(map(routeData => routeData.title ?? ''));
    readonly breadcrumbs$ = this.routeData$.pipe(map(routeData => routeData.breadcrumbs));

    constructor(private router: Router, private http: HttpClient, private userService: UserService) {
        this.router.events.pipe(filter(event => event instanceof ChildActivationEnd)).subscribe(event => {
            let snapshot = (event as ChildActivationEnd).snapshot;
            while (snapshot.firstChild !== null) {
                snapshot = snapshot.firstChild;
            }
            this.routeDataSubject.next(snapshot.data as SBRouteData);
            this.currentURLSubject.next(router.url);
        });
        this.getIsCliente();
        this.getUsuarioID();
        this.getNivelID();
        //this.getMenu();

          /*
        await this.getUserID();
        this.getTopNavMenu().subscribe(menu => {
            console.info("TOP MENU: ", JSON.stringify(menu));
            console.info("TOP MENU: ", menu["topMenu"]);
            const topMenu = menu["topMenu"];
            this.topNavMenuSubject.next(menu["topMenu"] ?? null);
        });*/
        /*
        this.userService.topMenuItems$.subscribe(data=>{
            console.info("TOP Items From File Menu: ",data);
            this.topNavMenuSubject.next(data ?? null);
        });
        */
    }
/*
    async getMenu(usuario){
        this.getTopNavMenu(usuario).subscribe(menu => {
            console.info("TOP MENU: ", JSON.stringify(menu));
            console.info("TOP MENU: ", menu["topMenu"]);
            const topMenu = menu["topMenu"];
            this.topNavMenuSubject.next(menu["topMenu"] ?? null);
            //return topMenu;
        });

    }

    async getMenu(usuario){
        this.getTopNavMenu(usuario).subscribe(menu => {
            console.info("TOP MENU: ", JSON.stringify(menu));
            console.info("TOP MENU: ", menu["topMenu"]);
            const topMenu = menu["topMenu"];
            this.topNavMenuSubject.next(menu["topMenu"] ?? null);
            //return topMenu;
        });
    }*/

    async getMenuNivel(nivel){
        this.getTopNavMenu(nivel).subscribe(menu => {
            //console.info("TOP MENU: ", JSON.stringify(menu));
            //console.info("TOP MENU: ", menu["topMenu"]);
            this.topNavMenuSubject.next(menu["topMenu"] ?? null);
            sessionStorage.setItem('topMenuJson', JSON.stringify(menu["topMenu"]));

            //return topMenu;
        });
    }

    async setMenu(menuSesion){

        this.topNavMenuSubject.next(JSON.parse(menuSesion));
    }

    toggleSideNav(visibility?: boolean) {
        console.info("TOGGLE SIDE MENU]: ", visibility)
        if (typeof visibility !== 'undefined') {
            this.sideNavVisibleSubject.next(visibility);
        } else {
            // este hace que el menu lateral se abra solo en las pantallas al abrir en movil
            //this.sideNavVisibleSubject.next(!this.sideNavVisibleSubject.value);
            this.sideNavVisibleSubject.next(this.sideNavVisibleSubject.value);
        }
    }

    /**
     Antes se mandaba llamar desde un archivo local
     Ahora desde un archivo S3
     */
    private getTopNavMenu(nivel) {
        const headers = new HttpHeaders({
            //"Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Origin": "//localhost:4200",
            "Accept-Encoding": "gzip, deflate, br"
        });
        //const userId=2619;
        const S3Path = 'https://admin-genomik-ng-assets.s3.amazonaws.com';
        const fullMenu =  this.http.get<NavMenuItem>(`${this.pathMenu}/Menu_${nivel}.json`, { headers }).pipe(catchError(() => of([] as NavMenuItem[] )));
       // console.info("MENU FILE: ", fullMenu);
        return fullMenu;
    }

    async getUserID() {
        return new Promise((resolve, reject) => {
            this.userService.vUsuarioID$.subscribe(
                data => resolve(data),
                error => reject(error)
            );
        });
    }

    async getIsCliente() {
        return new Promise((resolve, reject) => {
            this.userService.isCliente$.subscribe(data => {
                this.isCliente = data;
                if (data){
                    this.toggleSideNav(false);
                }
                resolve(data)
            },
            error => reject(error)
            );
        });
    }

    async getUsuarioID() {
        return new Promise((resolve, reject) => {
            this.userService.vUsuarioID$.subscribe(data => {
                if (data){
                    //this.getMenu(data);
                }
                resolve(data)
            },
            error => reject(error)
            );
        });
    }

    async getNivelID(){
        return new Promise((resolve, reject) => {
            this.userService.vNivelID$.subscribe(data=>{
                if(data){
                    /*let menuCargado = 0
                    this.topNavMenu$.subscribe(data => {
                        menuCargado = data.length
                    });*/
                    const menuSesion =  sessionStorage.getItem('topMenuJson');

                    if(menuSesion == '' || menuSesion == undefined){
                        this.getMenuNivel(data);
                    }else{
                        this.setMenu(menuSesion);
                    }
                }
            });
        });
    }

}


