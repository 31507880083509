import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { UserService } from '@modules/auth/services';
import { Observable, of } from 'rxjs';
import { Administration } from '../models';

@Injectable()
export class SuppliersService {
    API_URL_ADMINISTRATION = environment.api.administration;
    API_URL_SYSTEM = environment.api.system;

    constructor(private http: HttpClient, private userService: UserService) {}

    getProviders$(): Observable<{}> {
        return of({});
    }

    async GetCatalogos() {
        return new Promise((resolve, reject) => {
            this.http.get<Administration>(`${this.API_URL_SYSTEM}/proveedor/catalogos`).subscribe(resp => {
                if (resp.status === 'fail') {
                    console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                // console.info('RESPONSE: ', resp.data);
                resolve(resp.data);
            });
        });
    }

    async getProveedor(requestBody: any) {
        return new Promise((resolve, reject) => {
            this.http
                .post<Administration>(`${this.API_URL_SYSTEM}/proveedor/get`, {
                    ...requestBody,
                    UsuarioSesionId: this.userService.vUsuarioID,
                })
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                });
        });
    }

    async GuardarSupplier(requestBody: any) {
        return new Promise((resolve, reject) => {
            this.http
                .post<Administration>(`${this.API_URL_SYSTEM}/proveedor/save`, {
                    ...requestBody,
                    UsuarioSesionId: this.userService.vUsuarioID,
                })
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                });
        });
    }

    async GuardarAccount(requestBody: any) {
        return new Promise((resolve, reject) => {
            this.http
                .post<Administration>(`${this.API_URL_SYSTEM}/proveedor/account/save`, {
                    ...requestBody,
                    UsuarioSesionId: this.userService.vUsuarioID,
                })
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                });
        });
    }

    async Autorizar(requestBody: any) {
        return new Promise((resolve, reject) => {
            this.http
                .post<Administration>(`${this.API_URL_SYSTEM}/proveedor/autoriza`, {
                    ...requestBody,
                    UsuarioSesionId: this.userService.vUsuarioID,
                })
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                });
        });
    }

    async delAccount(ID: number) {
        return new Promise((resolve, reject) => {
            this.http.get<Administration>(`${this.API_URL_SYSTEM}/proveedor/account/del/${ID}`).subscribe(resp => {
                if (resp.status === 'fail') {
                    console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                // console.info('RESPONSE: ', resp.data);
                resolve(resp.data);
            });
        });
    }

}
