import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Output, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppCommonService } from '@common/services';
import { CustomersService } from '@modules/customers/services';
import { ConfirmacionMComponent } from '../confirmacion-m/confirmacion-m.component';

@Component({
  selector: 'gk-comentario-form-m',
  templateUrl: './comentario-form-m.component.html',
  styleUrl: './comentario-form-m.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComentarioFormMComponent {


  //? recibe
  noInactivar: boolean = false;
  status!: any;
  @Output() statusChanged = new EventEmitter<any>();
  @Output() comentario = new EventEmitter<any>();

  private fb = inject(FormBuilder); 
  private customerService = inject(CustomersService);
  public appCommon= inject(AppCommonService);
  private dialog = inject(MatDialog);

  public form: FormGroup = this.fb.group({
    comentario: ['', Validators.required],
  })



  constructor(
    public dialogRef: MatDialogRef<ComentarioFormMComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,){
      if(this.data && !this.data.status && this.data.noInactivar){
        this.noInactivar = this.data.noInactivar;
      }else if (this.data && this.data.status && !this.data.noInactivar){
        this.status = this.data.status;
      }
    }

    changeStatus(){
      if(this.form.invalid){
        const dialogRefM = this.dialog.open(ConfirmacionMComponent, {
          data: { titulo: `formulario invalido`, 
                  mensaje: `Favor de escribir un comentario`,
                  Noconfirm: true
              }
        })
        dialogRefM.afterOpened().subscribe(() => {
          setTimeout(() => {
            dialogRefM.close();
          }, 3000);
        });
        return;
      }

      this.appCommon.spinner(true);

      const formConValue = { ...this.form.value }
      
      this.status.comentarios = formConValue.comentario;

      console.log(this.status);
      this.customerService.changeStatusCustomer(this.status).subscribe({
        next: (resp:any) => {
          this.appCommon.spinner(false);
          const dialogRefM = this.dialog.open(ConfirmacionMComponent, {
            data: { titulo: `Estatus cambiado`, 
                    mensaje: `Se ha cambiado el status`,
                    Noconfirm: true
                }
          })
          dialogRefM.afterOpened().subscribe(() => {
            setTimeout(() => {
              dialogRefM.close();
            }, 3000);
          });
          this.statusChanged.emit(resp.data);
          this.dialogRef.close();
          console.log('sucess', resp);
        },
        error: err => {
          this.appCommon.spinner(false);
          const dialogRefM = this.dialog.open(ConfirmacionMComponent, {
            data: { titulo: `No se pudo cambiar el status`, 
                    mensaje: `A ocurrido un error al querer cambiar el status`,
                    Noconfirm: true
                  }
            })
            dialogRefM.afterOpened().subscribe(() => {
              setTimeout(() => {
                dialogRefM.close();
              }, 3000);
            });
            console.log('ha ocurrido un error', err);
        }
      })


    }

    traerComentario() {
      if(this.form.invalid){
        const dialogRefM = this.dialog.open(ConfirmacionMComponent, {
          data: { titulo: `formulario invalido`, 
                  mensaje: `Favor de escribir un comentario`,
                  Noconfirm: true
              }
        })
        dialogRefM.afterOpened().subscribe(() => {
          setTimeout(() => {
            dialogRefM.close();
          }, 3000);
        });
        return;
      }
      const formConValue = { ...this.form.value }
      this.comentario.emit(formConValue.comentario);
      this.dialogRef.close();
    }


}
