<div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <object [data]="file | safeUrl" width="100%" height="592px" *ngIf="ext === 'pdf'"></object>
    <object [data]="file | safeUrl" width="100%" height="600px" *ngIf="ext === 'xml'"></object>
    <embed [src]="file | safeUrl" class="pdf-content" width="100%" height="750px" *ngIf="ext === 'urlpdf'" />
    <img [src]="file | safeUrl" class="pdf-content" width="100%" height="750px" *ngIf="ext === 'urlimg'" />
</div>