import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppCommonService } from '@common/services';
import { environment } from '@env';
import { Default } from '@modules/system/models';
import * as DataTables from 'datatables.net';


@Injectable({
    providedIn:'root'
  })

  export class AdministrationCierreDeMesService {
    API_URL_CIERRE_MES = `${environment.api.administracionCierreMes}/cierre-mes`;

    public filesBucket = "administration-cierre-mensual";

    constructor(
        private http: HttpClient,
        private appCommonSvc: AppCommonService,
        private datePipe: DatePipe) {}

    public dtOptions: DataTables.Config = {
        pageLength: 100,
        ordering: false
    };

    public Meses = ["","Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

    public EstatusEnum = {
        Generar: 0,
        En_Progreso: 1,
        Finalizado: 2
    };

    public EstatusDesc = {
        0: "Generar",
        1: "En progreso",
        2: "Finalizado"
    };

    public EstatusMovimientosEnum = {
        PorValidar: 0,
        EnProceso: 1,
        Validado: 2,
        ValidadoConCambios: 3,
        ValidaconConComentarios: 4
    };

    public EstatusMovimientosDesc = {
        0: "Por Validar",
        1: "En Proceso",
        2: "Validado",
        3: "Validado Con cambios",
        4: "Validado Con comentarios"
    }

    public TipoFaseEnum = {
        PreCierre: 1,
        RevisionContable: 2,
        Validacion: 3,
        CalculoImpuestos: 4,
        PagoImpuestos: 5
    };

    public TipoFaseDesc = {
        1: "Pre Cierre",
        2: "Revisión Contable",
        3: "Validación revisión contable",
        4: "Cálculo de impuestos",
        5: "Pago de impuestos"
    }

    public TipoArchivoEnum = {
        pdf: 1,
        xml: 2,
        xlsx: 3,
        txt: 4,
        xls: 5
    }

    public TipoArchivoMetadata = {
        1: {
            extension: "pdf",
            type: "application/pdf"
        },
        2: {
            extension: "xml",
            type: "text/xml"
        },
        3: {
            extension: "xlsx",
            type: "application/xlsx"
        },
        4: {
            extension: "txt",
            type: "application/txt"
        },
        5: {
            extension: "xls",
            type: "application/xls"
        },
    }

    public TipoTabEnum = {
        CoinciliacionBancos: 1,
        Facturacion: 2,
        Cobranza: 3,
        OtrosIngresos: 4,
        Egresos: 5,
        Intercompanias: 6,
        CruceIvas: 7,
        CambiosPropuestos: 8,
        ImpuestosNomina: 9,
        ImpuestosFederales: 10,
        FacturacionMensual: 11,
        FacturacionCancelada: 12,
        FacturacionAnual: 13,
        PolizasDiario: 14,
        Provisiones: 15,
        Balanzas: 16,
        Documentacion: 17,
        MovimientosCuentas: 18,
        PolizasPropuestas: 19,
        Generales: 20,
        Polizas: 21,
        PolizasCierreAnual: 22,
        CierreMensual: 23
    };

    public TipoTabDesc = {
        1: "Coinciliación",
        2: "Facturación",
        3: "Cobranza",
        4: "Otros ingresos",
        5: "Egresos",
        6: "Intercompañias",
        7: "Cruce de IVAs",
        8: "Cambios propuestos",
        9: "Impuestos de nómina",
        10: "Impestos federales",
        11: "Facturación mensual",
        12: "Facturas canceladas",
        13: "Facturación anual",
        14: "Pólizas de Diario",
        15: "Provisiones Operativas",
        16: "Previsualización de Balanzas",
        17: "Documentación",
        18: "Movimientos de Cuentas",
        19: "Pólizas Propuestas",
        20: "Generales",
        21: "Polizas",
        22: "Polizas de cierre anual",
        23: "Cierre mensual"
    }

    public TipoFacturacionEnum = {
        Facturacion_Mes: 1,
        Facturas_Canceladas: 2,
        Facturacion_Anual: 3,
        Cobranza: 4,
        Otros_Ingresos: 5,
        Total_Ingresos: 6,
        GP_Cambiaria: 7
    }

    public TipoIngresosEnum = {
        Facturacion: 1,
        Bancarios: 2
    }

    public IngresosBancariosEnum = {
        Cobranza: 0,
        Otros_Ingresos: 1,
        GP_Cambiaria: 2
    }

    public TipoFacturacionDesc = {
        1: "Total facturación en el mes",
        2: "Total de facturas canceladas en el mes",
        3: "Total de facturas en el año"
    }

    public TipoMovimientoEnum = {
        Ingresos: 1,
        Egresos: 2,
        Provision_Egresos: 3,
        Polizas_Diario: 4
    }

    public TipoValidacionEgresoEnum = {
        Egresos: 0,
        Provisiones: 1
    }

    public ValidacionPolizaEstatusEnum = {
        PorValidar: 0,
        EnProceso: 1,
        Validado: 2,
        ValidadoConCambios: 3,
        ValidadoConComentarios: 4
    }

    public ValidacionPolizaEstatusDescEnum = [
        "Por Validar","En Proceso","Validado","Validado Con cambios", "Validado Con comentarios"
    ]

    public TipoMovimientoPolizasEnum = {
        Cargos: 0,
        Abonos: 1
    }

    public EmpresasEnum = {
        GenomikSapi: 1,
        Genomi23: 2
    }

    public TipoSolucionEnum = {
        Tamik: 1,
        Audik: 2,
        Dx: 3,
        Cardik:9
    }


    public TipoProvisionEnum = {
        ProvisionPrecios: 1
    }

    public TipoProvisionMovimientoEnum = {
        Ingreso: 1,
        Egreso: 2
    }

    public TablaPrecioCriterioEnum = {
        Normal: 1,
        InicioFin: 2,
        PruebaEspecial: 3
    }

    public TipoBalanzaEnum = {
        Contable: 1,
        Operativa: 2
    }

    public EstatusCambiosPropuestosEnum = {
        Por_Revisar: 0,
        Validado: 1,
        Rechazado: 2
    }

    public EstatusCambiosPropuestosDesc = {
        0: "Por Revisar",
        1: "Aceptado",
        2: "Rechazado"
    }

    public TipoImpuestosArchivos = {
        IMMS: 1,
        SarInfonavit: 2,
        ResumenIMMSInfonavit: 3,
        SUA: 4,
        ISN: 5,
        AcuseSAT: 6,
        ImpuestosFederales: 7
    }

    public TipoImpuestosArchivosDesc = {
        1: "IMSS",
        2: "SAR e Infonavit",
        3: "Resumen IMSS e Infonavit",
        4: "Archivo *.SUA",
        5: "ISN",
        6: "Acuse SAT",
        7: "Previa Cálculo Impuestos Federales"
    }

    public TipoImpuestos = {
        Nomina: 1,
        Estatales: 2,
        Federales: 3
    }

    public DocumentacionTipoPolizas= {
		Todas: 0,
		Ingresos: 1,
		Egresos: 2,
		Diario: 3
	}

    public DocumentacionTipoPolizasDesc = {
		0: "Todas las Pólizas",
		1: "Pólizas de Ingresos",
		2: "Pólizas de Egresos",
		3: "Pólizas de Diario"
    }

    public DocumentacionTipoDocumentos = {
        PDF: 1,
		Excel: 2,
		TXTContpaq: 3
    }

    public DocumentacionTipoDocumentosDesc = {
        1: "PDF",
		2: "Excel",
		3: "TXT Contpaq"
    }

    public coinciliacionesPendientes = 0;
    public facturacionIngresosPendientes = 0;
    public egresosPendientes = 0;
    public intercompaniasPendientes = 0;
    public polizasDiarioPendientes = 0;
    public polizasCierrePendientes = 0;
    public cambiosPropuestosPendientes = 0;
    public polizasCierreAnualPendientes = 0;
    public impuestosFederalesPendientes = 0;
    public impuestosNominaPendientes = 0;
    public pagoImpuestosNominaPendientes = 0;

    getParams() {
        return new Promise((resolve, reject) => {
            /*for testing only
            resolve(JSON.parse("{\"Empresas\":[{\"Empresa_Id\":1,\"Empresa\":\"Genomi-k,S.A.P.I.deC.V.\"},{\"Empresa_Id\":2,\"Empresa\":\"Genomi-k23,S.C.\"},{\"Empresa_Id\":6,\"Empresa\":\"Genomi-k,TESTS.A.P.I.deC.V.\"},{\"Empresa_Id\":7,\"Empresa\":\"GENOMI-KHOLDING,S.A.P.I.DEC.V.\"}],\"Anios\":[{\"Anio\":2024},{\"Anio\":2023},{\"Anio\":2022},{\"Anio\":2021},{\"Anio\":2020},{\"Anio\":2019},{\"Anio\":2018},{\"Anio\":2017},{\"Anio\":2016}]}"));
            return;
              */
            this.http.get<Default>(`${this.API_URL_CIERRE_MES}/get-params`)
            .subscribe({
                next(resp) {
                    if (resp.status === 'fail') {
                        console.error('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error(err){
                    console.error('ERROR: ', err);
                    reject(err);
                }
            });
        });
    }

    /**
     * @param params un objeto con los parametros opcionales EmpresaId, Anio, CierreMesId
     */
    getCierre(params){
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_CIERRE_MES}/get-cierre`, params).subscribe({
                next(resp) {
                    if (resp.status === 'fail') {
                        console.error('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error(err){
                    console.error('ERROR: ', err);
                    reject(err);
                }
            });
        });
    }

    updateStatus(CierreMesId: number, Tipo: number, EstatusId: number, TipoAnterior?: number, EstatusAnteriorId?: number, Comentario?: string) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_CIERRE_MES}/update-status`,{
                CierreMesId,
                Tipo,
                EstatusId,
                TipoAnterior,
                EstatusAnteriorId,
                Comentario
            }).subscribe({
                next(resp) {
                    if (resp.status === 'fail') {
                        console.error('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error(err){
                    console.error('ERROR: ', err);
                    reject(err);
                }
            });
        });
    }

    //TODO: remove
    //precierre = null
    getPreCierre(cierreMesId){
        return new Promise((resolve, reject) => {
            //if(this.precierre == null){
            this.http.get<Default>(`${this.API_URL_CIERRE_MES}/get-pre-cierre`,{
                params: {
                    id: cierreMesId
                }
            })
            .subscribe({
                next: (resp: Default) => {
                    if (resp.status === 'fail') {
                        console.error('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    //this.precierre = resp.data
                    resolve(resp.data);
                },
                error: (err: any) => {
                    console.error('ERROR: ', err);
                    reject(err);
                }
            });
            /*}else{
                resolve(this.precierre);
            }*/

        });
    }

    updateFileCoinciliacion(coinciliacionId, archivo, tipoArchivo) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_CIERRE_MES}/update-archivo-coinciliacion`,{
                coinciliacionId,
                archivo,
                tipoArchivo
            }).subscribe({
                next(resp) {
                    if (resp.status === 'fail') {
                        console.error('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error(err){
                    console.error('ERROR: ', err);
                    reject(err);
                }
            });
        });
    }

    generarCoinciliacion(Id: number, CoinciliacionId: any) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_CIERRE_MES}/generate-coinciliacion`,{
                Id,
                CoinciliacionId
            }).subscribe({
                next(resp) {
                    if (resp.status === 'fail') {
                        console.error('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error(err){
                    console.error('ERROR: ', err);
                    reject(err);
                }
            });
        });
    }

    getCoinciliacionesPendientes(dataCoinciliacion, faseActualId){
        let accion = "";
        let coinciliacionesPendientesDesc = "";
        if(faseActualId == this.TipoFaseEnum.PreCierre){
            this.coinciliacionesPendientes = dataCoinciliacion
            .filter(x => parseInt(x.Estatus) != this.EstatusEnum.Finalizado)
            .length;
            accion = "realizar";
        } else if (faseActualId == this.TipoFaseEnum.RevisionContable){
            this.coinciliacionesPendientes = dataCoinciliacion
            .filter(x => parseInt(x.EstatusExterno) != this.EstatusEnum.Finalizado)
            .length;
            accion = "validar";
        }

        if (this.coinciliacionesPendientes > 0){
            coinciliacionesPendientesDesc = `(${this.coinciliacionesPendientes} conciliaciones por ${accion})`;
        }
        return {
            total: this.coinciliacionesPendientes,
            desc: coinciliacionesPendientesDesc
        }
    }

    getComentarios(CierreMesId: number, Tipo?: number, Id?: number , Todos?: boolean) {
        const params : any = {
            CierreMesId: CierreMesId
        };
        if(this.appCommonSvc.IsDefined(Tipo)){
            params.Tipo = Tipo;
        }
        if(this.appCommonSvc.IsDefined(Id) && Id !== undefined && Id > 0){
            params.Id = Id;
        }
        if(this.appCommonSvc.IsDefined(Todos)){
            params.Todos = 1;
        }
        return new Promise((resolve, reject) => {
            this.http.get<Default>(`${this.API_URL_CIERRE_MES}/get-comments`,{
                params: params
            })
            .subscribe({
                next(resp) {
                    if (resp.status === 'fail') {
                        console.error('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error(err){
                    console.error('ERROR: ', err);
                    reject(err);
                }
            });
        });
    }

    EliminarComentario(ComentarioId: number, CierreMesId: number, Tipo?: number, Id?: number) {
        const params : any = {
            ComentarioId: ComentarioId,
            CierreMesId: CierreMesId
        };
        if(Tipo !== undefined) params.Tipo = Tipo;
        if(Id !== undefined) params.Id = Id;

        return new Promise((resolve, reject) => {
            this.http.delete<Default>(`${this.API_URL_CIERRE_MES}/delete-comment`,{
                params: params
            })
            .subscribe({
                next(resp) {
                    if (resp.status === 'fail') {
                        console.error('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error(err){
                    console.error('ERROR: ', err);
                    reject(err);
                }
            });
        });
    }

    ComentarMovimiento(
        Id: number,
        Tipo: number,
        CierreMesId: number,
        Comentarios: string,
        ComentarioId = 0,
        FaseId = 1,
        Todos = false) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_CIERRE_MES}/save-comment`,{
                Id,
                Tipo,
                CierreMesId,
                Comentarios,
                ComentarioId,
                FaseId,
                Todos
            })
            .subscribe({
                next(resp) {
                    if (resp.status === 'fail') {
                        console.error('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error(err){
                    console.error('ERROR: ', err);
                    reject(err);
                }
            });
        });
    }

    updateValidacionIngreso(ValidacionId: any, EstatusId: number, Tipo: number = this.TipoIngresosEnum.Facturacion, Campo: string = "", Externo = false) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_CIERRE_MES}/update-validacion-ingresos`,{
                ValidacionId,
                EstatusId,
                Tipo,
                Campo,
                Externo
            })
            .subscribe({
                next(resp) {
                    if (resp.status === 'fail') {
                        console.error('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error(err){
                    console.error('ERROR: ', err);
                    reject(err);
                }
            });
        });
    }

    FormatearDescripcion(EstatusId,Usuario,Fecha){
        let descripcion = "";
        switch(parseInt(EstatusId)){
            case this.EstatusEnum.En_Progreso:{
                descripcion = `Generado el ${this.datePipe.transform(Fecha, "dd-MMM-yyyy hh:mm a", 'UTC')} por el usuario ${Usuario}`;
                break;
            }
            case this.EstatusEnum.Finalizado:{
                descripcion = `Finalizado por el usuario ${Usuario}`;
                break;
            }
        }
        return descripcion;
    }

    formatearEstatus(estatusId: number, fecha: any){
        let Estatus ="Por Validar";
        switch(estatusId){
            case this.EstatusEnum.En_Progreso:{
                Estatus = "En Progreso";
                break;
            }
            case this.EstatusEnum.Finalizado: {
                Estatus = this.datePipe.transform(fecha, "dd-MMM-yyyy hh:mm a", 'UTC' ) || "";
                break;
            }
        }
        return Estatus;
    }

    getIngresosPendientes(dataFacturacion: any, dataIngresosBancarios: any, faseActualId: number): any {
        this.facturacionIngresosPendientes = 0;
        let desc = "";
        if(faseActualId == this.TipoFaseEnum.PreCierre){
            this.facturacionIngresosPendientes = dataFacturacion
                .filter(x => parseInt(x.Estatus) != this.EstatusEnum.Finalizado)
                .length;
            this.facturacionIngresosPendientes += dataIngresosBancarios
                .filter(x => parseInt(x.EstatusCobranza) != this.EstatusEnum.Finalizado)
                .length;
            this.facturacionIngresosPendientes += dataIngresosBancarios
                .filter(x => parseInt(x.EstatusOtrosIngresos) != this.EstatusEnum.Finalizado)
                .length;
        } else if (faseActualId == this.TipoFaseEnum.RevisionContable){
            this.facturacionIngresosPendientes = dataFacturacion
                .filter(x => parseInt(x.EstatusExterno) != this.EstatusEnum.Finalizado)
                .length;
            this.facturacionIngresosPendientes += dataIngresosBancarios
                .filter(x => parseInt(x.EstatusCobranzaExterno) != this.EstatusEnum.Finalizado)
                .length;
            this.facturacionIngresosPendientes += dataIngresosBancarios
                .filter(x => parseInt(x.EstatusOtrosIngresosExterno) != this.EstatusEnum.Finalizado)
                .length;
        }

        if (this.facturacionIngresosPendientes > 0){
            desc = `(${this.facturacionIngresosPendientes} rubros por validar)`;
        }
        return {
            total: this.facturacionIngresosPendientes,
            desc: desc
        }
    }

    getEgresosPendientes(dataEgresos: any, faseActualId: number): any {
        this.egresosPendientes = 0;
        if(faseActualId == this.TipoFaseEnum.PreCierre){
            this.egresosPendientes = dataEgresos.filter(x => parseInt(x.Estatus) != this.EstatusEnum.Finalizado).length;
        }else if(faseActualId == this.TipoFaseEnum.RevisionContable){
            this.egresosPendientes = dataEgresos.filter(x => parseInt(x.EstatusExterno) != this.EstatusEnum.Finalizado).length;
        }
        let desc = "";
        if (this.egresosPendientes > 0){
            desc = `(${this.egresosPendientes} rubros por validar)`;
        }
        return {
            total: this.egresosPendientes,
            desc: desc
        }
    }

    updateValidacionEgreso(ValidacionId: any, EstatusId: number, Externo: boolean) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_CIERRE_MES}/update-validacion-egresos`,{
                ValidacionId,
                EstatusId,
                Externo
            })
            .subscribe({
                next(resp) {
                    if (resp.status === 'fail') {
                        console.error('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error(err){
                    console.error('ERROR: ', err);
                    reject(err);
                }
            });
        });
    }

    getIntercompaniasPendientes(dataIntercompanias: any, faseActualId: number): any {
        this.intercompaniasPendientes = 0;
        if(faseActualId == this.TipoFaseEnum.PreCierre){
            this.intercompaniasPendientes = dataIntercompanias.filter(x => parseInt(x.Estatus) != this.EstatusEnum.Finalizado).length;
        }else if(faseActualId == this.TipoFaseEnum.RevisionContable){
            this.intercompaniasPendientes = dataIntercompanias.filter(x => parseInt(x.EstatusExterno) != this.EstatusEnum.Finalizado).length;
        }
        return {
            total: this.intercompaniasPendientes,
            desc: this.intercompaniasPendientes > 0 ? "(Por Validar)" : ""
        }
    }

    validarMovimientosIntercompanias(Id: any, Externo: boolean) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_CIERRE_MES}/update-validacion-intercompanias`,{
                Id,
                Externo
            })
            .subscribe({
                next(resp) {
                    if (resp.status === 'fail') {
                        console.error('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error(err){
                    console.error('ERROR: ', err);
                    reject(err);
                }
            });
        });
    }

    obtenerPolizasIntercompania(EmpresaId: number, Ano: number, Mes: number, Tipo: number) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_CIERRE_MES}/get-polizas-intercompania`,{
                EmpresaId,
                Ano,
                Mes,
                Tipo
            })
            .subscribe({
                next(resp) {
                    if (resp.status === 'fail') {
                        console.error('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error(err){
                    console.error('ERROR: ', err);
                    reject(err);
                }
            });
        });
    }

    getPolizasDiarioPendientes(dataPolizas: any[]): any {
        this.polizasDiarioPendientes = dataPolizas.filter(x=> x.Pendientes > 0).length;
        return {
            total: this.polizasDiarioPendientes,
            desc: this.polizasDiarioPendientes > 0 ? `(${this.polizasDiarioPendientes} movimientos por validar)` : ""
        }
    }

    getDetallePolizaDiario(cuentaId: number, polizasIds: string) {
        return new Promise((resolve, reject) => {
            this.http.get<Default>(`${this.API_URL_CIERRE_MES}/get-detalle-polizas-diario`,{
                params: {
                    cuentaId,
                    polizasIds
                }
            })
            .subscribe({
                next(resp) {
                    if (resp.status === 'fail') {
                        console.error('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error(err){
                    console.error('ERROR: ', err);
                    reject(err);
                }
            });
        });
    }

    updateValidacionMovimiento(ValidacionId: number, PolizaOperativaId: number, EstatusId: number, Externo: boolean) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_CIERRE_MES}/update-validacion-movimiento`,{
                ValidacionId,
                PolizaOperativaId,
                EstatusId,
                Externo
            })
            .subscribe({
                next(resp) {
                    if (resp.status === 'fail') {
                        console.error('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error(err){
                    console.error('ERROR: ', err);
                    reject(err);
                }
            });
        });
    }

    getPolizasCierrePendientes(polizasCierre: any): any {
        this.polizasCierrePendientes = 0;
        if(polizasCierre.EstatusExterno !== this.EstatusEnum.Finalizado){
            this.polizasCierrePendientes = 1;
        }
        return {
            total: this.polizasCierrePendientes,
            desc: this.polizasCierrePendientes > 0 ? "(Pendiente)" : ""
        }
    }

    GenerarPolizaCruceIva(cierreMesId: number) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_CIERRE_MES}/generate-poliza-cruce-iva`,{
                cierreMesId
            })
            .subscribe({
                next(resp) {
                    if (resp.status === 'fail') {
                        console.error('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error(err){
                    console.error('ERROR: ', err);
                    reject(err);
                }
            });
        });
    }

    ValidarPolizaCruceIva(cierreMesId: number) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_CIERRE_MES}/validate-poliza-cruce-iva`,{
                cierreMesId
            })
            .subscribe({
                next(resp) {
                    if (resp.status === 'fail') {
                        console.error('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error(err){
                    console.error('ERROR: ', err);
                    reject(err);
                }
            });
        });
    }

    getDetalleAjusteProvision(Ids: string, SolucionId: number, ProvisionAjusteId: number, TipoProvision: number) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_CIERRE_MES}/provisiones/get-detalle-ajuste-provision`,{
                Ids,
                SolucionId,
                ProvisionAjusteId,
                TipoProvision
            })
            .subscribe({
                next(resp) {
                    if (resp.status === 'fail') {
                        console.error('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error(err){
                    console.error('ERROR: ', err);
                    reject(err);
                }
            });
        });
    }

    getDetalleProvisionFiltros(InstitucionId: number, SolucionId: number, Ano: number, Mes: number, OtrosMeses: boolean = false, ProvisionadoBit: number = 0) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_CIERRE_MES}/provisiones/get-detalle-filtros-provision`,{
                InstitucionId,
                SolucionId,
                Ano,
                Mes,
                OtrosMeses,
                ProvisionadoBit
            })
            .subscribe({
                next(resp) {
                    if (resp.status === 'fail') {
                        console.error('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error(err){
                    console.error('ERROR: ', err);
                    reject(err);
                }
            });
        });
    }

    generateAjusteProvisionesVolumen(Ano: number, Mes: number, DataInstituciones: any[]) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_CIERRE_MES}/provisiones/generate-ajuste-provisiones-volumen`,{
                Ano,
                Mes,
                DataInstituciones
            })
            .subscribe({
                next(resp) {
                    if (resp.status === 'fail') {
                        console.error('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error(err){
                    console.error('ERROR: ', err);
                    reject(err);
                }
            });
        });
    }

    GetParamsProvisionMensual() {
        return new Promise((resolve, reject) => {
            this.http.get<Default>(`${this.API_URL_CIERRE_MES}/provisiones/get-params`)
            .subscribe({
                next(resp) {
                    if (resp.status === 'fail') {
                        console.error('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error(err){
                    console.error('ERROR: ', err);
                    reject(err);
                }
            });
        });
    }

    provisionesGetClientes(SolucionId:number, Ano: number, Mes: number) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_CIERRE_MES}/provisiones/get-clientes`,{
                SolucionId,
                Ano,
                Mes
            })
            .subscribe({
                next(resp) {
                    if (resp.status === 'fail') {
                        console.error('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error(err){
                    console.error('ERROR: ', err);
                    reject(err);
                }
            });
        });
    }

    getProvisionTablaPrecio(tipoTablaPrecioId: any) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_CIERRE_MES}/provisiones/get-tabla-precio`,{
                tipoTablaPrecioId
            })
            .subscribe({
                next(resp) {
                    if (resp.status === 'fail') {
                        console.error('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error(err){
                    console.error('ERROR: ', err);
                    reject(err);
                }
            });
        });
    }

    provisionar(SolucionId: number,Ano: number, Mes: number, InstitucionProvision: any) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_CIERRE_MES}/provisiones/provisionar`,{
                SolucionId,
                Ano,
                Mes,
                InstitucionProvision
            })
            .subscribe({
                next(resp) {
                    if (resp.status === 'fail') {
                        console.error('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error(err){
                    console.error('ERROR: ', err);
                    reject(err);
                }
            });
        });
    }

    ObtenerPreviewBalanza(EmpresaId: any, Ano: any, Mes: any, TipoBalanzaId: number) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_CIERRE_MES}/balanzas/preview`,{
                EmpresaId,
                Ano,
                Mes,
                TipoBalanzaId
            })
            .subscribe({
                next(resp) {
                    if (resp.status === 'fail') {
                        console.error('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error(err){
                    console.error('ERROR: ', err);
                    reject(err);
                }
            });
        });
    }

    ObtenerPreviewBalanzaCuentaDetalle(CuentaId: number, Naturaleza: number, Ano: number, Mes: number, TipoBalanzaId: number) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_CIERRE_MES}/balanzas/get-detalle-cuenta`,{
                CuentaId,
                Ano,
                Mes,
                TipoBalanzaId,
                Naturaleza
            })
            .subscribe({
                next(resp) {
                    if (resp.status === 'fail') {
                        console.error('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error(err){
                    console.error('ERROR: ', err);
                    reject(err);
                }
            });
        });
    }

    validarMovimiento(Id: number, TipoTabId: number, CierreMesId: number) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_CIERRE_MES}/validar-movimiento`,{
                Id,
                TipoTabId,
                CierreMesId
            })
            .subscribe({
                next(resp) {
                    if (resp.status === 'fail') {
                        console.error('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error(err){
                    console.error('ERROR: ', err);
                    reject(err);
                }
            });
        });
    }

    obtenerPolizaDetalle(DetalleId: number, EmpresaId: number, CargarCuentas: boolean) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_CIERRE_MES}/get-poliza-detalle`,{
                DetalleId,
                EmpresaId,
                CargarCuentas
            })
            .subscribe({
                next(resp) {
                    if (resp.status === 'fail') {
                        console.error('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error(err){
                    console.error('ERROR: ', err);
                    reject(err);
                }
            });
        });
    }

    cambiarCuentaPolizaDetalle(
        PolizaOperativaId: number,
        PolizaOperativaDetalleId: number,
        CuentaOperativaId: number,
        CuentaContableId: number,
        Comentarios: string,
        Externo: boolean,
        CierreMesId: number) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_CIERRE_MES}/update-poliza-detalle`,{
                PolizaOperativaId,
                PolizaOperativaDetalleId,
                CuentaOperativaId,
                CuentaContableId,
                Comentarios,
                Externo,
                CierreMesId
            })
            .subscribe({
                next(resp) {
                    if (resp.status === 'fail') {
                        console.error('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error(err){
                    console.error('ERROR: ', err);
                    reject(err);
                }
            });
        });
    }

    getCambiosPropuestosPendientes(dataCambiosPropuestos: any, faseActualId: any) {
        this.cambiosPropuestosPendientes = 0;
        let desc = "";
        if(faseActualId >= this.TipoFaseEnum.Validacion){
            this.cambiosPropuestosPendientes = dataCambiosPropuestos.CambiosMovimientos.filter(x=> x.TieneCambio == true && x.Estatus == this.EstatusCambiosPropuestosEnum.Por_Revisar).length;
            this.cambiosPropuestosPendientes += dataCambiosPropuestos.Polizas.filter(x=> x.Estatus == this.EstatusCambiosPropuestosEnum.Por_Revisar).length;

        }
        if (this.cambiosPropuestosPendientes > 0){
            desc = `(${this.cambiosPropuestosPendientes}) movimientos por revisar`
        }

        return {
            total: this.cambiosPropuestosPendientes,
            desc: desc
        }
    }

    getImpuestosFederalesPendientes(dataImpuestosFederales: any, dataCierreMes: any): any {
        this.impuestosFederalesPendientes = dataImpuestosFederales.Archivos.filter(x=> !this.appCommonSvc.IsDefined(x.Archivo)).length;
        this.impuestosFederalesPendientes += dataImpuestosFederales.Archivos.filter(x=> this.appCommonSvc.IsDefined(x.Archivo) && x.Archivo.length == 0).length;

        const agruparImpuestos = dataCierreMes.Detalle.Ano >= 2022;
        if(agruparImpuestos){
            this.impuestosFederalesPendientes += dataImpuestosFederales.Impuestos
                .filter(x=> (x.Grupo_Id == 1 || x.Grupo_Id == 2) &&  x.Guardado == false).length;
        }else{
            this.impuestosFederalesPendientes += dataImpuestosFederales.Impuestos
                .filter(x=> x.Guardado == false).length;
        }
        return {
            total: this.impuestosFederalesPendientes,
            desc: ''
        }
    }

    getImpuestosNominaPendientes(dataImpuestosNomina: any,dataImpuestosEstatales: any): any {
        this.impuestosNominaPendientes = dataImpuestosNomina.Archivos.filter(x=> x.Archivo.length == 0).length;
        this.impuestosFederalesPendientes += dataImpuestosNomina.Impuestos.filter(x=> x.Guardado == false).length;
        this.impuestosFederalesPendientes += dataImpuestosEstatales.Impuestos.filter(x=> x.Guardado == false).length;
        return {
            total: this.impuestosNominaPendientes,
            desc: ''
        }
    }

    getPagoImpuestosNominaPendientes(dataImpuestosNomina: any,dataImpuestosEstatales: any): any {
        this.pagoImpuestosNominaPendientes = 0;
        this.pagoImpuestosNominaPendientes = dataImpuestosNomina.Impuestos.filter(x=> x.PagoDivididoGuardado == false).length;
        this.pagoImpuestosNominaPendientes += dataImpuestosEstatales.Impuestos.filter(x=> x.PagoDivididoGuardado == false).length;
        return {
            total: this.pagoImpuestosNominaPendientes,
            desc: ''
        }
    }


    polizaGeneralGetParams(polizaOperativaId = 0, cierreMesId = 0, cierreAnual = 0) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_CIERRE_MES}/poliza-general/get-params`,{
                polizaOperativaId,
                cierreMesId,
                cierreAnual
            })
            .subscribe({
                next(resp) {
                    if (resp.status === 'fail') {
                        console.error('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error(err){
                    console.error('ERROR: ', err);
                    reject(err);
                }
            });
        });
    }

    polizaGeneralSave(
        polizaOpId: number,
        polizaId: number,
        polizaGralDetalleId: number,
        movimientoId: number,
        empresaId: number,
        fecha: string,
        tipoPolizaId: number,
        conceptoPoliza: string,
        proveedorId: number,
        tipoProveedorId: number,
        vendedorId: number,
        zonaId: number,
        unidadesNegocio: { Solucion_Id: number; Porcentaje: number; }[],
        cargos: number,
        abonos: number,
        cierreMesId: number,
        cierreAnual: number) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_CIERRE_MES}/poliza-general/save`,{
                polizaOpId,
                polizaId,
                polizaGralDetalleId,
                movimientoId,
                empresaId,
                fecha,
                tipoPolizaId,
                conceptoPoliza,
                proveedorId,
                tipoProveedorId,
                vendedorId,
                zonaId,
                unidadesNegocio,
                cargos,
                abonos,
                cierreMesId,
                cierreAnual
            })
            .subscribe({
                next(resp) {
                    if (resp.status === 'fail') {
                        console.error('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error(err){
                    console.error('ERROR: ', err);
                    reject(err);
                }
            });
        });
    }

    polizaGeneralSaveDetalle(
        empresaId: number,
        polizaOperativaId: number,
        polizaOperativaDetalleId: number,
        tipoMovimientoId: number,
        importe: number,
        referencia: string,
        concepto: string,
        cuentaOperativaId: number,
        cuentaContableId: number,
        tipoProveedorId: number,
        proveedorId: number,
        externo: boolean) {
            return new Promise((resolve, reject) => {
                this.http.post<Default>(`${this.API_URL_CIERRE_MES}/poliza-general/save-det`,{
                    empresaId,
                    polizaOperativaId,
                    polizaOperativaDetalleId,
                    tipoMovimientoId,
                    importe,
                    referencia,
                    concepto,
                    cuentaOperativaId,
                    cuentaContableId,
                    tipoProveedorId,
                    proveedorId,
                    externo
                })
                .subscribe({
                    next(resp) {
                        if (resp.status === 'fail') {
                            console.error('RESPONSE ERROR: ', resp);
                            reject(resp);
                            return;
                        }
                        resolve(resp.data);
                    },
                    error(err){
                        console.error('ERROR: ', err);
                        reject(err);
                    }
                });
            });
    }

    polizaGeneralDeleteDetalle(polizaOpetativaId:number, polizaOperativaDetalleId: number) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_CIERRE_MES}/poliza-general/delete-det`,{
                polizaOpetativaId,
                polizaOperativaDetalleId
            })
            .subscribe({
                next(resp) {
                    if (resp.status === 'fail') {
                        console.error('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error(err){
                    console.error('ERROR: ', err);
                    reject(err);
                }
            });
        });
    }

    polizaGeneralAgregarArchivo(polizaOperativaId: number, archivo: any) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_CIERRE_MES}/poliza-general/add-file`,{
                polizaOperativaId,
                archivo
            })
            .subscribe({
                next(resp) {
                    if (resp.status === 'fail') {
                        console.error('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error(err){
                    console.error('ERROR: ', err);
                    reject(err);
                }
            });
        });
    }

    polizaGeneralDeleteDocumento(polizaOperativaId: number, documentoId: any) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_CIERRE_MES}/poliza-general/delete-file`,{
                polizaOperativaId,
                documentoId
            })
            .subscribe({
                next(resp) {
                    if (resp.status === 'fail') {
                        console.error('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error(err){
                    console.error('ERROR: ', err);
                    reject(err);
                }
            });
        });
    }

    updateCambioPropuesto(CierreMesId: number, FaseId: number, CambioPropuestoId: number, EstatusCambioPropuestoId: number, Comentarios: string) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_CIERRE_MES}/cambios-propuestos/update`,{
                CierreMesId,
                FaseId,
                CambioPropuestoId,
                EstatusCambioPropuestoId,
                Comentarios
            })
            .subscribe({
                next(resp) {
                    if (resp.status === 'fail') {
                        console.error('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error(err){
                    console.error('ERROR: ', err);
                    reject(err);
                }
            });
        });
    }

    updatePolizaPropuesta(CierreMesId: any, FaseId: any, CambioPropuestoId: any, EstatusCambioPropuestoId: number, Comentarios: string) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_CIERRE_MES}/cambios-propuestos/update-poliza`,{
                CierreMesId,
                FaseId,
                CambioPropuestoId,
                EstatusCambioPropuestoId,
                Comentarios
            })
            .subscribe({
                next(resp) {
                    if (resp.status === 'fail') {
                        console.error('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error(err){
                    console.error('ERROR: ', err);
                    reject(err);
                }
            });
        });
    }

    polizaGeneralProcesar(polizaOperativaId: number, cambioId: number) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_CIERRE_MES}/poliza-general/process`,{
                polizaOperativaId,
                cambioId
            })
            .subscribe({
                next(resp) {
                    if (resp.status === 'fail') {
                        console.error('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error(err){
                    console.error('ERROR: ', err);
                    reject(err);
                }
            });
        });
    }

    updateFileImpuesto(CierreMesId: number,TipoImpuestoId: number, ArchivoId: number, FileName: string) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_CIERRE_MES}/impuestos/update-file`,{
                CierreMesId,
                ArchivoId,
                FileName,
                TipoImpuestoId
            })
            .subscribe({
                next(resp) {
                    if (resp.status === 'fail') {
                        console.error('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error(err){
                    console.error('ERROR: ', err);
                    reject(err);
                }
            });
        });
    }

    guardarImpuestos(CierreMesId: number, Impuestos: any[], TipoImpuestoId: number,CompensacionMonto:number | null = null) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_CIERRE_MES}/impuestos/save`,{
                CierreMesId,
                Impuestos,
                TipoImpuestoId,
                CompensacionMonto
            })
            .subscribe({
                next(resp) {
                    if (resp.status === 'fail') {
                        console.error('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error(err){
                    console.error('ERROR: ', err);
                    reject(err);
                }
            });
        });
    }

    ObtenerDocumentacionPolizas(EmpresaId: number, Ano: number, Mes: number, TipoPolizaId: number, TipoDocumentoId: number) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_CIERRE_MES}/documentacion-polizas`,{
                EmpresaId,
                Ano,
                Mes,
                TipoPolizaId,
                TipoDocumentoId
            })
            .subscribe({
                next(resp) {
                    if (resp.status === 'fail') {
                        console.error('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error(err){
                    console.error('ERROR: ', err);
                    reject(err);
                }
            });
        });
    }

    guardarPagoImpuestosNomina(CierreMesId: number, Impuestos: any[]) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_CIERRE_MES}/impuestos/save-pago-impuestos-nomina`,{
                CierreMesId,
                Impuestos
            })
            .subscribe({
                next(resp) {
                    if (resp.status === 'fail') {
                        console.error('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error(err){
                    console.error('ERROR: ', err);
                    reject(err);
                }
            });
        });
    }

    eliminarPolizaCierreAnual(CierreMesId: number, PolizaOperativaId: number) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_CIERRE_MES}/polizas-cierre-anual/delete`,{
                CierreMesId,
                PolizaOperativaId
            })
            .subscribe({
                next(resp) {
                    if (resp.status === 'fail') {
                        console.error('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error(err){
                    console.error('ERROR: ', err);
                    reject(err);
                }
            });
        });
    }

    actualizarPolizaCierreAnual(CierreMesId: number, ValidacionPolizaCierraAnualId: number, EstatusId: number, Comentarios: string) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_CIERRE_MES}/polizas-cierre-anual/update`,{
                CierreMesId,
                ValidacionPolizaCierraAnualId,
                EstatusId,
                Comentarios
            })
            .subscribe({
                next(resp) {
                    if (resp.status === 'fail') {
                        console.error('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error(err){
                    console.error('ERROR: ', err);
                    reject(err);
                }
            });
        });
    }

    regenerarPolizaCierreAnual(CierreMesId: number, ValidacionPolizaCierraAnualId: number) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_CIERRE_MES}/polizas-cierre-anual/regenerate`,{
                CierreMesId,
                ValidacionPolizaCierraAnualId
            })
            .subscribe({
                next(resp) {
                    if (resp.status === 'fail') {
                        console.error('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error(err){
                    console.error('ERROR: ', err);
                    reject(err);
                }
            });
        });
    }    
}
