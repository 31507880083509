<label class="btn-primary" style="display:none;" ngbButtonLabel>
    <input #pickupCLick type="checkbox" ngbButton > ENE
</label> 
<form  [formGroup]="formRec">
    <div class="row mt-4 ms-2">
        <label class="col-sm-2 control-label" align="left">Recoleccion de la muestra:</label>
        <div class="col-md-1 mt-1" align="left">
            <input type="radio" [value]="true"  formControlName="RecoleccionMuestra" class="form-check-input"> {{  radios.SiNo[0].display }}
        </div>
        <div class="col-md-1 mt-1" align="left">
            <input type="radio" [value]="false"  formControlName="RecoleccionMuestra" class="form-check-input"> {{  radios.SiNo[1].display }}
        </div>
    </div>
    <div class="row mt-4 ms-2">
        <label class="col-sm-2 control-label" align="left">Forma de recoleccion:</label>
        <div class="col-md-3" align="left">
            <select  class="form-control"  formControlName="RecoleccionForma" >
                <option value="">Seleccione un tipo</option>
                <option *ngFor="let recoleccion of combos.FormaRecoleccion"
                        [value]="recoleccion.value">
                        {{ recoleccion.display }}
                </option>
            </select>
        </div>
        <div class="col-md-1"></div>
        <label class="col-sm-2 control-label" align="left">Programar recoleccion antes de:</label>
        <div class="col-md-3" align="left">
            <select  class="form-control" formControlName="RecoleccionProgramar" >
                <option value="">Seleccione un tipo</option>
                <option *ngFor="let horario of combos.ProgramarRecoleccion"
                        [value]="horario.value">{{ horario.display }}
                </option>
            </select>
        </div>
    </div>
    <div class="row mt-4 ms-2">
        <label class="col-sm-2 control-label" align="left">Nombre del lugar:</label>
        <div class="col-md-9" align="left">
            <div class="input-group mb-3">
                <input type="text"  class="form-control" formControlName="RecoleccionNombre" >
            </div>
        </div>
    </div>
    <div class="row mt-4 ms-2">
        <label class="col-sm-1 control-label" align="left">Entre calles:</label>
        <div class="col-md-10" align="left">
            <div class="input-group mb-3">
                <input type="text"  class="form-control" formControlName="RecoleccionReferencia">
            </div>
        </div>
    </div>
    <div class="row mt-4 ms-2">
        <label class="col-sm-1 control-label" align="left">Direccion:</label>
        <div class="col-md-10" align="left">
            <div class="input-group mb-3">
                <input type="text"  class="form-control" formControlName="RecoleccionDireccion" >
            </div>
        </div>
    </div>
    <div class="row mt-4 ms-2">
        <label class="col-sm-1 control-label" align="left">Colonia:</label>
        <div class="col-md-10" align="left">
            <div class="input-group mb-3">
                <input type="text"  class="form-control" formControlName="RecoleccionColonia" >
            </div>
        </div>
    </div>

    <div class="row mt-4 ms-2">
        <label class="col-sm-1 control-label" align="left">Ciudad:</label>
        <div class="col-md-3" align="left">
            <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Selecciona una ciudad" formControlName="RecoleccionCiudad">
                @for(ciudad of combos.CiudadEstado; track $index;){
                    @if($index === 0 || combos.CiudadEstado[$index].Estado !== combos.CiudadEstado[$index - 1].Estado){
                        <nz-option-group [nzLabel]="ciudad.Estado">
                        @for(c of combos.CiudadEstado.slice($index, combos.CiudadEstado.length); track $index;){
                            @if(c.Estado === ciudad.Estado){
                                <nz-option [nzValue]="c.Ciudad_id" [nzLabel]="c.Ciudad"></nz-option>
                            }
                        }
                        
                        </nz-option-group>
                    }
                }
            </nz-select>
            
        </div>
        <div class="col-md-1"></div>
        <label class="col-sm-1 control-label" align="left">C.P:</label>
        <div class="col-md-3" align="left">
            <input type="text"  class="form-control" formControlName="RecoleccionCodigoPostal" >
        </div>
    </div>

    <div class="row mt-4 ms-2">
        <label class="col-sm-2 control-label" align="left">Horario de recoleccion:</label>
        <div class="col-md-9" align="left">
            <div class="input-group mb-3">
                <input type="text"  class="form-control" formControlName="RecoleccionHorario" >
            </div>
        </div>
    </div>

    <div class="row mt-4 ms-2">
        <label class="col-sm-2 control-label" align="left">Lugar de recoleccion:</label>
        <div class="col-md-9" align="left">
            <div class="input-group mb-3">
                <input type="text"  class="form-control" formControlName="RecoleccionLugar" >
            </div>
        </div>
    </div>

    <div class="row mt-4 ms-2">
        <label class="col-sm-2 control-label" align="left">Comentarios de recoleccion:</label>
        <div class="col-md-9" align="left">
            <div class="input-group mb-3">
                <input type="text"  class="form-control" formControlName="RecoleccionComentarios">
            </div>
        </div>
    </div>


</form>