<div class="row mt-5 ms-3 me-3 mb-0">
    <div class="col-md-12">
        <div class="row">
            <div class="col-md-12 text-left">

                <mat-stepper orientation="vertical" [selectedIndex]="selectedIndex" #stepper
                    (click)="onStepChange(1)">
                    <mat-step>
                        <ng-template matStepLabel>Usuarios/Contactos</ng-template>
                        <ng-template matStepContent>

                            <button class="btn btn-primary mb-3 mt-4" type="button" (click)="newUser($event)">Usuario / Contacto nuevo</button>
                            <div class="row">
                                <div class="col-10">
                                    <div class="form-check mb-3">
                                        <input class="form-check-input" type="checkbox" id="mostrarInactivos" (click)="toggleMostrarInactivos($event)">
                                        <label class="form-check-label" for="mostrarInactivos" (click)="stopEvent($event)">
                                            Mostrar inactivos
                                        </label>
                                    </div>
                                </div>
                                <div class="col-2">
                                    <input type="text" class="form-control mb-3" placeholder="Buscar..."  (click)="stopEvent($event)" (input)="onSearch($event.target.value)"/>
                                </div>
                            </div>  
                            <table  class="table table-striped table-bordered row-border hover nowrap" style="width:100%">
                                <thead class="ttdblue">
                                    <tr>
                                        <th class="text-left">ID</th>
                                        <th class="text-left">NOMBRE</th>
                                        <th class="text-left">USUARIO SISTEMA</th>
                                        <th class="text-left">PUESTO</th>
                                        <th class="text-left">NIVEL</th>
                                        <th class="text-left">CORREO</th>
                                        <th class="text-left">TELEFONO</th>
                                        <th class="text-left">ACCION</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    @for(usuario of paginatedUsers; track $index) {
                                        <tr>
                                            <td>
                                                {{ usuario.UsuarioId }}
                                            </td>
                                            <td>
                                                {{ usuario.Nombre }}
                                            </td>
                                            <td>
                                                {{ usuario.Usuario }}
                                            </td>
                                            <td>
                                                {{ usuario.Puesto }}
                                            </td>
                                            <td>
                                                {{ usuario.Nivel }}
                                            </td>
                                            <td>
                                                {{ usuario.Correo }}
                                            </td>
                                            <td>
                                                {{ usuario.Telefono }}
                                            </td>
                                            <td>
                                                <mat-icon class="colorBtnIcon text-center ms-5" (click)="editUser(usuario, $event)">edit_square</mat-icon>
                                                <mat-icon class="colorBtnIcon text-center ms-3" (click)="inactivaUser(usuario, $event)">block</mat-icon>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>

                            <div class="d-flex justify-content-between align-items-center mt-3">
                                <div>
                                    Mostrando {{startIndex + 1}} a {{endIndex}} de {{filteredUsers.length}} registros
                                </div>
                                <mat-paginator [length]="filteredUsers.length"
                                            [pageSize]="selectedPageSize"
                                            [pageSizeOptions]="pageSizes"
                                            (page)="onPageChange($event)"
                                            (click)="stopEvent($event)">
                                </mat-paginator>
                            </div>

                        </ng-template>

                    </mat-step>
                </mat-stepper>
            </div>
        </div>
    </div>
</div>