import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AppCommonService } from '@common/services';
import { AddProof, CatalogoPruebasEsp, CatalogosSolicitudDx, PruebasAsignadaAP } from '@modules/medical-records/models';
import { MedicalRecordsService } from '@modules/medical-records/services';
import { SendRequestMComponent } from '../Modales/send-request-m/send-request-m.component';

@Component({
  selector: 'gk-information-request',
  templateUrl: './information-request.component.html',
  styleUrl: './information-request.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InformationRequestComponent implements OnInit{

  //? Variables
  @Input() catalogos: CatalogosSolicitudDx;
  @Output() catalogoAgregado = new EventEmitter<void>();
  pacienteId: string;

  mensaje: string;
  catalogosP: CatalogoPruebasEsp[];
  soliId: number;
  precioIngresado: number[] = [];
  selectedConsulta: any[] = [];
  pruebasAsignadas: PruebasAsignadaAP[] = [];
  ocultarPruebas: boolean; 

  //? Servicios
  private dialog = inject(MatDialog);
  private activatedRoute = inject(ActivatedRoute);
  private fb = inject(FormBuilder);
  private appCommon = inject(AppCommonService);
  private medicalRecordService = inject(MedicalRecordsService);
  private cdr = inject(ChangeDetectorRef);

  ngOnInit(): void {
    this.activatedRoute.params.subscribe( params =>{
      this.pacienteId = params['pacienteId']
    })
    this.formSolicitud.patchValue({
      PacienteId: this.pacienteId
    })
  }

  ingresarPrecios(){
    this.precioIngresado = this.catalogosP.map(cat => cat.Precio_GK);
    this.selectedConsulta = this.catalogosP.map(cat => {
      // Asigna 0 solo si existe una consulta cuyo valor es 0
      const firstConsulta = cat.CatConsulta.find(consulta => consulta.value === 0);
      return firstConsulta ? 0 : undefined;
    });
  }


  formSolicitud: FormGroup = this.fb.group({
    PruebaPre:         [     ],
    CorreoDirigido:    [  ,  ],
    CorreoCopia:       [ ''  ],
    PacienteId:        [    ],
    SolicitudInformacionId: [ ''     ]
  });

  cambioEnfermedad(){
    const formValue = { ...this.formSolicitud.value };
    if( !formValue.CorreoDirigido && formValue.PruebaPre ){
      this.appCommon.showMsg('Faltan correos', `Favor de rellenar el campo "Dirigido a:"`);
      return;
    }
    if( !formValue.PruebaPre){
      return;
    }
    this.appCommon.spinner(true);
    this.pruebasAsignadas = [];
    formValue.PacienteId = Number(formValue.PacienteId);
    this.medicalRecordService.postSolicitudInfo(formValue).subscribe({
      next: (resp) => {
        this.appCommon.spinner(false);
        this.mensaje = resp.Mensaje;
        this.catalogosP = resp.CatalogoPruebasEsp;
        this.soliId = resp.SolicitudInformacionId;
        this.ingresarPrecios();
        this.cdr.detectChanges();
      },
      error: err => {
        this.appCommon.spinner(false);
        this.appCommon.showMsg(`Operacion fallida`, `A ocurrido un error al querer realizar la operacion`);
        console.log('err', err);
      }
    });
  }

  agregarPrueba(catalogo: CatalogoPruebasEsp, index: number){

    this.appCommon.spinner(true);
    
    const enfermedad  = this.formSolicitud.get('PruebaPre')?.value;
    let   consulta    = this.selectedConsulta[index];
    const costoPro    = this.precioIngresado[index];

    if(!consulta){
      consulta = 0;
    }

    const prueba = {
      PruebaEspecialId: catalogo.Pruebas_Especiales_id ,
      preciogk: catalogo.Precio_GK ,
      preciopro: costoPro ,
      moneda_gk_id: catalogo.TIPO_MONEDA_COSTO_GK_ID ,
      jqryConsulta: consulta ,
      tiempo_entrega: catalogo.Tiempo_Entrega_GK ,
      soli_id: this.soliId,
      enfe_id: enfermedad,
      qry_Costobase: catalogo.Costo ,
      qry_txtmoneda: catalogo.Tipo_Moneda_Costo_GK ,
    }
    
    this.medicalRecordService.addProof(prueba).subscribe({
      next: (resp) => {
        this.appCommon.spinner(false);
        this.pruebasAsignadas = resp.PruebasAsignadas;
        this.appCommon.showMsg(`Operacion Exitosa`, resp.Mensaje);
        this.cdr.detectChanges();
      },
      error: err => {
        this.appCommon.spinner(false);
        this.appCommon.showMsg(`Operacion fallida`, `A ocurrido un error al querer realizar la operacion`);
        console.log('err', err);
      }
    })

  }


  enviarSolicitud(){
    this.appCommon.spinner(true);
    this.medicalRecordService.getCorreosRequest(this.soliId).subscribe({
      next: (resp) => {
        this.appCommon.spinner(false);
        const dialogData = {
          CorreoRequest: resp ,
          IdSolicitud: this.soliId
        }

        const dialogRef = this.dialog.open(SendRequestMComponent, {
          data: dialogData,
          autoFocus: false
        })
    
        // const instance = dialogRef.componentInstance;
        // instance.actualizarDatosEmitter.subscribe(() => {
        //   this.comentarioAgregado.emit();
        // });
      },
      error: err => {
        this.appCommon.spinner(false);
        this.appCommon.showMsg(`Operacion fallida`, `A ocurrido un error al querer realizar la operacion`);
        console.log('err', err);
      }
    });

  }

  eliminarPrueba(prueba: PruebasAsignadaAP ){
    this.appCommon.spinner(true);
    const enfermedad  = this.formSolicitud.get('PruebaPre')?.value;

    const pruebaD = {
      PruebaEspecialId: prueba.Pruebas_Especiales_id ,
      pesol_id: prueba.SOLUCION_PRUEBAS_ID ,
      soli_id: this.soliId,
      enfe_id: enfermedad,
    }

    this.medicalRecordService.deleteProof(pruebaD).subscribe({
      next: (resp) => {
        this.appCommon.spinner(false);
        this.pruebasAsignadas = resp.PruebasAsignadas;
        this.appCommon.showMsg(`Operacion Exitosa`, resp.Mensaje);
        this.cdr.detectChanges();
      },
      error: err => {
        this.appCommon.spinner(false);
        this.appCommon.showMsg(`Operacion fallida`, `A ocurrido un error al querer realizar la operacion`);
        console.log('err', err);
      }
    })
    
  }


}
