import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '@modules/auth/services';
import { SideNavItems, SideNavSection } from '@modules/navigation/models';
import { NavigationService } from '@modules/navigation/services';
import { Subscription } from 'rxjs';

@Component({
    selector: 'sb-side-nav',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './side-nav.component.html',
    styleUrls: ['side-nav.component.scss'],
})
export class SideNavComponent implements OnInit, OnDestroy {
    @Input() sidenavStyle!: string;
    @Input() sideNavItems!: SideNavItems;
    @Input() sideNavSections!: SideNavSection[];

    subscription: Subscription = new Subscription();
    routeDataSubscription!: Subscription;

    isCliente: boolean = false;

    constructor(public navigationService: NavigationService, public userService: UserService) {}

    ngOnInit() {
        // get Menu Items from Profile
        this.userService.menuItems$.subscribe(data=>{
            console.info("SIDE Items From File Menu: ",data);
            //this.sideNavItems = data;
        });

        this.userService.isCliente$.subscribe(data=>{
            console.info("Cliente: ",data);
            this.isCliente = data;
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
