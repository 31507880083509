import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AsesoriaDetail } from '@modules/medical-records/models';

@Component({
  selector: 'gk-detalle-solicitud-m',
  templateUrl: './detalle-solicitud-m.component.html',
  styleUrl: './detalle-solicitud-m.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetalleSolicitudMComponent {

  datosEstudio: AsesoriaDetail
  id: string | number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DetalleSolicitudMComponent>
  ){
    if(this.data && this.data.DatosEstudios && this.data.Id){
      this.datosEstudio = this.data.DatosEstudios;
      this.id           = this.data.Id;
    }
  }

}
