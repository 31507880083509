<mat-dialog-content class="mat-typography">

    <form [formGroup]="formConvenio" (ngSubmit)="saveConvenio()">
        <h2 mat-dialog-title>Archivo Convenio</h2>
        <div class="row">

            <label class="control-label col-2" >Documento de apertura cliente:</label>
            <div class="col-2 mb-3">    
                <input type="radio" [value]="1" formControlName="aperturacliente" class="form-check-input ml-5" > SI &nbsp;
                <input type="radio" [value]="0" formControlName="aperturacliente" class="form-check-input"  > NO
            </div>
            <div class="col-2"></div>
            <label class="control-label col-2" >Zona:</label>
            <div class="col-4">    
                <select  class="form-control" formControlName="zonaId"   >
                    <option value="">Seleccione una zona</option>
                    <option
                            *ngFor="let zona of combos.Zonas"
                            [value]="zona.value"
                            >
                            {{ zona.display }}
                    </option>
                </select>
            </div>
            @if(formConvenio.get('aperturacliente')!.value === 1){
                <label class="control-label col-2  mb-4" >Convenio:</label>
                <div class="col-3">
                    <input type="checkbox" formControlName="tamik"  class="form-check-input" > <label class="fw-bold ms-1">Támi-k</label>
                    <input type="checkbox"  formControlName="audik" class="form-check-input ms-1" > <label class="fw-bold mx-1">Áudi-k</label>
                    <input type="checkbox"  formControlName="dx" class="form-check-input ms-1" > <label class="fw-bold mx-1">Dx</label>
                    <input type="checkbox"  formControlName="cardik" class="form-check-input ms-1" > <label class="fw-bold mx-1">Cárdi-k</label>
                </div>
            }@else{
                <div class="col-5 mb-4"></div>
            }
            <div class="col-1"></div>
            <label class="control-label col-2" >Tipo Contrato:</label>
            <div class="col-4">
                <span class="form-text text-danger my-1" *ngIf="isValidFieldConvenio('tipoContratoId')">
                    <b>Este campo es requerido</b>
                </span>    
                <select  class="form-control"  formControlName="tipoContratoId" >
                    <option value="">Seleccionar</option>
                    <option
                            *ngFor="let contrato of combos.TipoContrato"
                            [value]="contrato.value"
                            >
                            {{ contrato.display }}
                    </option>
                </select>
            </div>
            <label class="control-label col-2  mb-4" >Expira Contrato:</label>
            <div class="col-2 mb-3">    
                <input type="radio" [value]="1" formControlName="expiraContrato"  class="form-check-input ml-5" > SI &nbsp;
                <input type="radio" [value]="0" formControlName="expiraContrato" class="form-check-input"  > NO
            </div>
            <div class="col-2"></div>
            @if(formConvenio.get('expiraContrato')!.value == 1){
                <label class="control-label col-2" >Fecha expiracion:</label>
                <div class="col-4">
                    <input type="date" class="form-control" formControlName="fechaExpira" name="fecha">
                </div>    
            }@else{
                <div class="col-6"></div>
            }
            <label class="control-label col-2  mb-3" >Adjuntar Archivo Manual:</label>
            <div class="col-5">
                <input type="file" class="form-control" (change)="archivoSeleccionado($event)">
            </div>
        </div>
    
        <div class="full-width-element full-height-element">
            <mat-divider class="mt-4"></mat-divider>
            <mat-dialog-actions align="end" class="gris py-4">
                <button cdkFocusInitial class="btn btn-success mx-2 mt-3" type="submit" *ngIf="!esVisor">
                    <mat-icon class="tamIconB">save</mat-icon>
                    Subir
                </button>
                <button [mat-dialog-close] class="btn btn-secondary mx-2 mt-3">
                    <mat-icon class="tamIconB">keyboard_arrow_left</mat-icon>
                    Cerrar
                </button>
            </mat-dialog-actions>
        </div>
    </form>
</mat-dialog-content>