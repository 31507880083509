<a class="nav-link" [routerLink]="sideNavItem.link" (click)="expanded = !expanded" [ngClass]="{ active: isActive, collapsed: !expanded }">
    <div *ngIf="sideNavItem.icon" class="sb-nav-link-icon">
        <fa-icon [icon]="['fas', sideNavItem.icon]"></fa-icon>
    </div>

    {{ sideNavItem.text }}

    <div class="sb-sidenav-collapse-arrow">
        <fa-icon *ngIf="sideNavItem.submenu" class="ml-auto" [icon]="['fas', 'angle-down']"></fa-icon>
    </div>
</a>

<nav class="sb-sidenav-menu-nested nav" *ngIf="sideNavItem.submenu && expanded">
    <sb-side-nav-item *ngFor="let submenuItem of sideNavItem.submenu" class="submenu" [sideNavItem]="submenuItem"></sb-side-nav-item>
</nav>
