import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { AppCommon, GkToastType } from '@modules/app-common/models';
import { UserService } from '@modules/auth/services';
import { clamp } from '@modules/utils';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfirmDialogOptions, DialogService } from './dialog.service';
import { ToastService } from './toast.service';

@Injectable({ providedIn: 'root' })
export class DetailPxAkCkService {
    private API_URL_MEDICAL_COMPONENTS = environment.api.medicalRecordsComponents;

    private activeRequestsSubject = new BehaviorSubject<number>(0);
    showLoader$ = this.activeRequestsSubject.asObservable().pipe(map(activeRequests => activeRequests > 0));

    constructor(
        private http: HttpClient,
        private userService: UserService,
        private toastService: ToastService,
        private dialogService: DialogService
    ) {}

    async DetalleAKCK(PacienteID) {
        return new Promise((resolve, reject) => {
            this.http
                .get<AppCommon>(`${this.API_URL_MEDICAL_COMPONENTS}/expediente/detalleakck/${PacienteID}`)
                .subscribe(resp => {
                    console.info('RESPONSE ERROR: ', resp);
                    if (resp.status == 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                });
        });
    }

}
