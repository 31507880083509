<h3 mat-dialog-title class="text-center">Comentarios {{titulo}}</h3>
<mat-dialog-content class="mat-typography">

    <table class="table-striped">
        <thead>
        <tr class="ttdblue">
            <td width="25%" class="text-left txtitgk">Usuario</td>
            <td width="45%" class="text-left txtitgk">Comentario</td>
            <td width="15%" class="text-right txtitgk">Fecha Comentario</td>
            <!-- @if (mostrarAcciones()) {
                <td  class="text-center txtitgk">
                    VISIBLE POR EL CLIENTE
                </td>
            } -->
        </tr>
        </thead>
        @if(subTitulo){
            <!-- @if(mostrarAcciones()) {
                <tbody>
                    <tr>
                        <td colspan="4" class="ttdgray">{{ subTitulo }}</td>
                    </tr>
                </tbody>
            }@else { -->
                <tbody>
                    <tr>
                        <td colspan="3" class="ttdgray">{{ subTitulo }}</td>
                    </tr>
                </tbody>
            <!-- } -->
        }
        <tbody>
            @for (comentario of comentarios; track $index) {
                <tr>
                    <td class="text-left">{{ comentario.Nombre }} {{ comentario.Apellido_Pat }}</td>
                    <td class="text-left" [innerHTML]="comentario.Comentario"></td>
                    <td class="text-right">{{ comentario.Fecha | date: 'dd-MMM-yyyy': "UTC" }}</td>
                    <!-- @if (comentario.btnActivar || comentario.btnInactivar) {
                        <td class="text-center">
                            @if(comentario.btnActivar){
                                <fa-icon class="txtlinka btnTam" 
                                        [icon]='["fas", "eye-slash"]'
                                        (click)="cambiarComentario(comentario)">
                                </fa-icon> 
                            }
                            @if(comentario.btnInactivar){
                                <fa-icon class="ms-3 txtlinka btnTam"
                                        [icon]='["fas", "times"]'
                                        (click)="cambiarComentario(comentario)">
                                </fa-icon>
                            }
                        </td>
                    } -->
                </tr>
            }@empty {
                <tr>
                    <td class="text-left" colspan="3">No existen comentarios registrados</td>
                </tr>
            }
        </tbody>        
    </table>

    <div class="full-width-element full-height-element">
        <mat-divider class="mt-4"></mat-divider>
        <mat-dialog-actions align="end" class="gris py-4">
            <button type="button" [mat-dialog-close] class="btn btn-secondary mx-2 mt-3">
                Cerrar
            </button>
        </mat-dialog-actions>
    </div>
</mat-dialog-content>