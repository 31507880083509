import { ChangeDetectionStrategy, Component,ChangeDetectorRef, OnInit,Output, EventEmitter, Input, AfterContentInit, AfterViewInit } from '@angular/core';
import { UserService } from '@modules/auth/services';
import { FormBuilder, FormGroup} from '@angular/forms';
import { AppCommonService } from '@modules/app-common/services';
import { ReportsService } from '@modules/reports/services';

@Component({
    selector: 'gk-search-result-filter-dinamic',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './search-result-filter-dinamic.component.html',
    styleUrls: ['search-result-filter-dinamic.component.scss'],
})
export class SearchResultFilterDinamicComponent implements  AfterViewInit {
    @Output("generarConsultaFiltros") GenerarDatosReporte: EventEmitter<any> = new EventEmitter();
    @Output("OcultarFiltros") MostrarFiltros: EventEmitter<boolean> = new EventEmitter();
    constructor(
        public appCommon: AppCommonService,
        private ref: ChangeDetectorRef,
        private reportsService:ReportsService,
        public formBuilder: FormBuilder,
        public userService: UserService
    ) {}
    @Input() Config
    @Input() CargarInit
    @Input() TipoFormulario
    @Input() RecargarDatos
    @Input() Language
    @Input() Solucion
    // readonly searchTypeData = [
    //     {"value": '1', "text":'No. de Orden de servicio (folio)'},
    //     {"value": '2', "text":'Nombre paciente'},
    //     {"value": '3', "text":'No. de Ficha'},
    //     {"value": '4', "text":'Nombre mamá'},
    //     {"value": '5', "text":'Paciente Id'},
    //     {"value": '6', "text":'Tiempo'}
    // ];
    readonly searchTypeDataCompleta = [
        {"value": '0', "text":'Codigo', "soluciones": ["tk"],"valueFilter":'0'},
        {"value": '1', "text":'No. de Orden de servicio (folio)', "soluciones": ["ak","ck"],"valueFilter":'0'},
        {"value": '2', "text":'Nombre paciente', "soluciones": ["tk","ak","ck"],"valueFilter":'0'},
        {"value": '3', "text":'No. de Ficha', "soluciones": ["tk","ak","ck"],"valueFilter":'0'},
        {"value": '4', "text":'Nombre mamá', "soluciones": ["tk","ak","ck"],"valueFilter":'0'},
        {"value": '5', "text":'Paciente Id', "soluciones": ["tk","ak","ck"],"valueFilter":'0'},
        {"value": '7', "text":'ID de Laboratorio', "soluciones": ["tk"],"valueFilter":'0'},
        {"value": '6', "text":'Tiempo', "soluciones": ["tk","ak","ck"],"valueFilter":'0'}
    ];




    searchTypeData:any = []
    ConfigShow: any
    years: any = [];
    readonly searchType = [{"value": '2', "text":'Privado'}, {"value": '1',"text":'Gobierno'}, {"value": '3',"text":'Gobierno y Privado'}];
    readonly searchPeriodo = [{"value": '0', "text":'Todas las fechas disponibles'}, {"value": '1',"text":'Por mes/año'}, {"value": '2',"text":'Por intervalo'}];
    dataMeses = [
        {
            id: '1',
            mes: 'Enero',
        },
        {
            id: '2',
            mes: 'Febrero',
        },
        {
            id: '3',
            mes: 'Marzo',
        },
        {
            id: '4',
            mes: 'Abril',
        },
        {
            id: '5',
            mes: 'Mayo',
        },
        {
            id: '6',
            mes: 'Junio',
        },
        {
            id: '7',
            mes: 'Julio',
        },
        {
            id: '8',
            mes: 'Agosto',
        },
        {
            id: '9',
            mes: 'Septiembre',
        },
        {
            id: '10',
            mes: 'Octubre',
        },
        {
            id: '11',
            mes: 'Noviembre',
        },
        {
            id: '12',
            mes: 'Diciembre',
        },
      ];

    formFiltros: FormGroup | undefined
    arrayFiltradoInstituciones:any;
    dataInstituciones: any;
    txtTipoBusqueda = "";
    tipobusqueda = 0;
    arrayFiltrado: any
    varaux: any;
    ActiveNivelID: any = 0;
    isCliente: any;
    ActiveInstitucionID: any = 0;
    OcultarRadiosInstNivel = false
    formbuldierConfig = {}
    TipoFormularioComponent: any
    tipoinstitucion= 0
    TipoBusquedaDatos = '1'
    mes_ini=""
    year_Fin =""
    requestBody: any
    usuarioId: any;

    async ngAfterViewInit(){
        this.searchTypeData = this.searchTypeDataCompleta.filter((filtros) => {
            return filtros.soluciones.find(x => x === this.Solucion) === this.Solucion;
        });
        // if(this.Solucion !== 'tk')
        // {
        //     this.searchTypeData.map(a=>a.valueFilter=`'${a.value - 1}'`);
        // }
        console.log("datos filtros", this.searchTypeData)
        this.ConfigShow = {}
        this.years = [];
        for (let i: any = 2008; i <= new Date().getFullYear(); i++) {
            this.years.push({ year: i });
        }
        //Aquie se manda llamar el service en el componente  para obtener las variables que requiero
        this.ActiveNivelID = await this.getNivelID();
        this.ActiveInstitucionID = await this.getInstitucionID();
        this.isCliente = await this.getIsCliente();
        this.usuarioId = await this.getUsuarioId();


        if(this.isCliente) {
            this.OcultarRadiosInstNivel = true
        }
        this.TipoFormularioComponent = this.TipoFormulario || 1
        this.Config.forEach(elementconfig => {
            this.ConfigShow[elementconfig.name] = {
                "show": elementconfig.show,
                "especial":elementconfig.especial,
                "vistaadmin":elementconfig.vistaadmin,
                "tiposol":elementconfig.tiposol}

            //Se empieza a armar el form para los datos a enviar
            if  (elementconfig.show == true || elementconfig.vistaadmin)
            {

                    let InitCampos = elementconfig.init
                    if  (elementconfig.name == 'TipoBusqueda')
                    {
                       if([3,4].includes(this.ActiveNivelID))
                       {
                        InitCampos = '1'
                       }
                       else if([5,6].includes(this.ActiveNivelID))
                       {
                        InitCampos = '2'
                       }

                    }
                    //console.log("datos para iniciar",InitCampos)

                    if  (elementconfig.type == 'radio')
                    {
                        this.ConfigShow[elementconfig.name+'Radios'] = elementconfig.hiddenRadios
                        this.formbuldierConfig[elementconfig.name] = InitCampos
                        if  (elementconfig.name == 'TipoBusqueda')
                        {
                            this.formbuldierConfig['Institucion_Id']=[0]
                        }
                        if  (elementconfig.name == 'TipoBusquedaDatos')
                        {
                            this.formbuldierConfig['txtBusqueda']=['']


                        }

                    }
                    this.formbuldierConfig['TipoBusquedaPeriodo']=['1']
                    this.formbuldierConfig['fechaIni']=['']
                    this.formbuldierConfig['fechaFin']=['']
                    this.formbuldierConfig['mes']=['']
                    this.formbuldierConfig['anio']=['']
                    this.formbuldierConfig['NivelActivo']=['']
                    this.formbuldierConfig['InstActiva']=['']
            }
        });
        console.log("config formulario", this.Config)
        this.formFiltros = this.formBuilder.group(this.formbuldierConfig);


        // this.formFiltros = this.formBuilder.group(
        //     {
        //         ['TipoBusqueda']:['1'],
        //         ['Institucion_Id']:['0'],
        //         ['TipoBusquedaDatos']:['1'],
        //         ['txtBusqueda']:['Hola'],
        //         ['TipoBusquedaPeriodo']:['1'],
        //         ['fechaIni']:[''],
        //         ['fechaFin']:[''],
        //         ['mes']:[''],
        //         ['anio']:['']
        //     }
        // );
        await this.verInstituciones()
        this.arrayFiltrado = [
            {
            TipoBusqueda :  [false]
            }
        ]
    }

    async onCheckChangeBusqueda() {
        let Gobierno_bit = 0
            this.txtTipoBusqueda="Hospital"
            const TipoInstitucionID = this.formFiltros?.get('TipoBusqueda')?.value
            this.formFiltros?.controls.Institucion_Id.setValue(0);

            if(TipoInstitucionID == 1)
            {
                this.txtTipoBusqueda="Unidad Medica"
                Gobierno_bit = 1
            }

            if(TipoInstitucionID == 3)
            {
                Gobierno_bit = 3
            }

        this.arrayFiltrado[0].TipoBusqueda = Gobierno_bit
        this.varaux = await this.FiltrarInstituciones()
        if(this.CargarInit)
        {
            await this.generarFiltro()
            this.CargarInit = false
        }
        //console.info("VAR AUX: ", this.varaux);

      }

    async FiltrarInstituciones(){
        return new Promise((resolve, reject) => {
            let varaux;
            if ( this.arrayFiltrado[0].TipoBusqueda == 3) {
              this.arrayFiltradoInstituciones = this.dataInstituciones
              varaux = ""
              //console.log("Data Gobierno y privado", this.arrayFiltradoInstituciones )
            }
            else if([3,6].includes(this.ActiveNivelID))
            {
                //console.log(`entro admin: ${this.arrayFiltrado[0].TipoBusqueda} uduario = ${this.ActiveInstitucionID}`)
                this.arrayFiltradoInstituciones =  this.dataInstituciones.filter(institucion => institucion.Gobierno_bit == this.arrayFiltrado[0].TipoBusqueda && institucion.isgroup == this.ActiveInstitucionID)
                varaux =  this.arrayFiltradoInstituciones.map(institucion => institucion.Institucion_Id).join(", ");
                if(this.isCliente && !this.Config.find(o => o.name === 'TipoBusqueda').vistaadmin)
                {
                    this.formFiltros?.controls.Institucion_Id.setValue(this.ActiveInstitucionID)
                }
            }
            else if([4,5].includes(this.ActiveNivelID))
            {
                //console.log("entro user")
                this.arrayFiltradoInstituciones =  this.dataInstituciones.filter(institucion => institucion.Gobierno_bit == this.arrayFiltrado[0].TipoBusqueda && institucion.Institucion_Id == this.ActiveInstitucionID)
                this.formFiltros?.controls.Institucion_Id.setValue(this.ActiveInstitucionID)
                varaux =  this.arrayFiltradoInstituciones.map(institucion => institucion.Institucion_Id).join(", ");

            }
            else
            {
                //console.log("entro todos")
                this.arrayFiltradoInstituciones =  this.dataInstituciones.filter(institucion => institucion.Gobierno_bit == this.arrayFiltrado[0].TipoBusqueda)
                varaux =  this.arrayFiltradoInstituciones.map(institucion => institucion.Institucion_Id).join(", ");
            }

            if (varaux == null) {
                reject(0);
            }else{
                resolve(varaux);
            }
        });

    }

    async verInstituciones(){
    this.appCommon.spinner(true);
    this.ref.detectChanges();
    this.reportsService
        .ListInstituciones(this.ActiveInstitucionID)
        .then(
            async(Default: any) => {
                this.dataInstituciones = Default.dataresult                 ;
                //console.log("data de instituciones:::", this.dataInstituciones);

                await this.onCheckChangeBusqueda()
                this.appCommon.spinner(false);
                this.ref.detectChanges();
                        },
            error => {
                //console.log('fail callback', error);
                this.appCommon.spinner(false);
                this.appCommon.Notify(error.data.message, 'error');
                this.ref.detectChanges();
            }
        )
        .catch(e => {
            //console.log('catch', e);
            this.appCommon.spinner(false);
        });
    }

    async OcultarFiltrosForm(){
        this.MostrarFiltros.emit(false)
    }

     /*
    Estos metodos los meti aqui para que se entienda mejor como hacer este tipo de llamadas pero lo correcto es q esten en un service para q se puedan consumir desde cualquier otro componente
    */
    getNivelID(){
        return new Promise((resolve, reject) => {
            this.userService.vNivelID$.subscribe(data=>{
                //console.info("Nivel ID: ",data);
                if(data==null){
                    reject(0);
                }else{
                    resolve(data);
                    //this.ActiveNivelID = data
                }
            });
        });
    }

    getInstitucionID(){
        return new Promise((resolve, reject) => {
            this.userService.vInstitucionID$.subscribe(data=>{
                //console.info("Institucion ID: ",data);
                if(data==null){
                    reject(0);
                }else{
                    resolve(data);
                    //this.ActiveInstitucionID = data
                }
            });
        });
    }

    getIsCliente(){
        return new Promise((resolve, reject) => {
            this.userService.isCliente$.subscribe(data=>{
                //console.info("Is Cliente: ",data);
                if(data==null){
                    reject(0);
                }else{
                    resolve(data);
                    //this.isCliente = data
                }
            });
        });
    }
    getUsuarioId(){
        return new Promise((resolve, reject) => {
            this.userService.vUsuarioID$.subscribe(data=>{
                //console.info("Is Cliente: ",data);
                if(data==null){
                    reject(0);
                }else{
                    resolve(data);
                }
            });
        });
    }
    async generarFiltro(){
        this.requestBody = {}
        if(this.ConfigShow.TipoBusqueda && this.ConfigShow.TipoBusqueda.show == true){
            this.tipoinstitucion = this.formFiltros?.controls.TipoBusqueda.value
            this.TipoBusquedaDatos = this.formFiltros?.controls.TipoBusquedaDatos.value
            this.requestBody['TipoBusquedaDatos'] = this.TipoBusquedaDatos
            this.requestBody['tipoinstitucion'] = this.tipoinstitucion
            this.requestBody['IntAux'] = this.varaux
            this.requestBody['BitCargaInicial'] = this.RecargarDatos
            if([3,6].includes(this.ActiveNivelID))
            {
                this.requestBody['institucionid'] = 0
            }
            else{
                this.requestBody['institucionid'] = this.formFiltros?.controls.Institucion_Id.value
            }
            this.requestBody['txtBusqueda'] = this.formFiltros?.controls.txtBusqueda.value.replace(/^\s+|\s+$/gm,'')

            if(this.requestBody['tipoinstitucion'] == 1 || this.requestBody['tipoinstitucion'] == 2){
                console.log("requestBody generasl fdiltro", this.requestBody)
                if( this.requestBody['institucionid'] === ''){
                    this.appCommon.Notify("Seleccione una institucion", 'warning');
                        this.ref.detectChanges();
                        return false;
                }
            }
        }

        // if(this.TipoBusquedaDatos == 1 || this.TipoBusquedaDatos == 2 || this.TipoBusquedaDatos == 3 || this.TipoBusquedaDatos == 4 || this.TipoBusquedaDatos == 5 ){
        //     if(this.formFiltros?.controls.txtBusqueda.value == ""){
        //         this.appCommon.Notify("Ingrese informacion solicitada", 'warning');
        //         this.ref.detectChanges();
        //         return false;
        //     }
        // }
        this.TipoBusquedaDatos = this.formFiltros?.controls['TipoBusquedaDatos'].value
        if([0,3,5,7].includes(Number(this.TipoBusquedaDatos)) && this.RecargarDatos == false){
            if(Number.isNaN(Number(this.formFiltros?.controls.txtBusqueda.value)) || this.formFiltros?.controls.txtBusqueda.value === '')
            {
                this.appCommon.Notify("Este dato tiene que ser un numero", 'warning');
                this.ref.detectChanges();
                return false;
            }
        }
        else
        {
            if((this.formFiltros?.controls.txtBusqueda.value === '' && this.TipoBusquedaDatos !== '6') && this.RecargarDatos == false)
            {
                this.appCommon.Notify("Agregue un dato de busqueda", 'warning');
                this.ref.detectChanges();
                return false;
            }

        }

        if(this.TipoBusquedaDatos === '6'){
            console.log("dato escogido", this.TipoBusquedaDatos)
            this.tipobusqueda = this.formFiltros?.controls.TipoBusquedaPeriodo.value
            this.requestBody['tipobusqueda'] = this.tipobusqueda

                if(this.tipobusqueda == 1 ){
                    this.mes_ini = this.formFiltros?.controls.mes.value
                    this.year_Fin = this.formFiltros?.controls.anio.value
                    if( this.mes_ini === '' || this.year_Fin === ''){
                        this.appCommon.Notify("Ingrese mes y año", 'warning');
                            this.ref.detectChanges();
                            return false;
                    }
                }
                else if (this.tipobusqueda == 2){
                    this.mes_ini = this.formFiltros?.controls.fechaIni.value
                    this.year_Fin = this.formFiltros?.controls.fechaFin.value

                    if( this.mes_ini === ''){
                        this.appCommon.Notify("Ingrese Fecha de inicio y fin", 'warning');
                            this.ref.detectChanges();
                            return false;
                    }
                }

            this.requestBody['f_mes_ini'] = this.mes_ini
            this.requestBody['f_year_fin'] = this.year_Fin
            //console.log("valores de formulario::", this.mes_ini, this.year_Fin )
        }
        this.requestBody['NivelActivo']= this.ActiveNivelID;
        this.requestBody['InstActiva'] = this.ActiveInstitucionID;
        this.requestBody['Cliente']= this.isCliente;
        this.requestBody['UsuarioID']= this.usuarioId;
        console.log("ver los datos que se van a enviar",this.requestBody)

       this.GenerarDatosReporte.emit(this.requestBody)
    }
}
