<div class="modal-header" *ngIf="title">
    <h4 class="modal-title">{{ title }}</h4>
</div>

<div class="modal-body">
    <div class="row">
        <div class="col-md-12 fs-15">{{ message }}</div>
    </div>

    <div class="row mt-2" *ngIf="type === 'text' || type === 'textreq'">
        <div class="col-md-12">
            <input type="text" class="form-control" [(ngModel)]="model.Comentario" placeholder="Ingrese un comentario..." />
        </div>
    </div>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="decline()">
        <fa-icon class="mr-1" [icon]="['fas', 'times']"></fa-icon> {{ btnCancelText }}
    </button>

    <button type="button" class="btn btn-primary" (click)="accept()">
        <fa-icon class="mr-1" [icon]="['fas', 'check']"></fa-icon> {{ btnOkText }}
    </button>
</div>
