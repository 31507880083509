import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AppCommonService } from '@modules/app-common/services';
import { UntypedFormBuilder, UntypedFormControl, FormGroup, Validators } from '@angular/forms';
import { GkValidators } from '@modules/shared/genomik-forms/genomik-validators';
import { ReportsService } from '@modules/reports/services';

@Component({
    selector: 'gk-comments-filter',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: 'comments-filter.component.html',
    styleUrls: ['comments-filter.component.scss'],
})
export class CommentsFilterComponent implements OnInit {
    @Input() DataComentarios: any=[];
    isVisibleModalComent: any;
    modalTitulo: string;
    tipocomentario: any;
    modalAccion: any;
    formComments: any;
    DataComentariosTabla: any;
    gral: number;


    constructor(
        public appCommon: AppCommonService,
        private formBuilder: UntypedFormBuilder,
        private reportsService:ReportsService,
        private ref: ChangeDetectorRef,

    ) {
        this.formComments = formBuilder.group({
            VerCliente: new UntypedFormControl('', GkValidators.required),
            Correo: new UntypedFormControl('', GkValidators.required),
            coment: new UntypedFormControl('', GkValidators.required),
            tipocomentario: new UntypedFormControl('', GkValidators.required),
            pacienteid: new UntypedFormControl('', GkValidators.required),
            filtroid: new UntypedFormControl('', GkValidators.required),
            accion: new UntypedFormControl('', GkValidators.required),
            califFinal: new UntypedFormControl('', GkValidators.required),
            gral: new UntypedFormControl('', GkValidators.required)
        });
    }
    ngOnInit() {
        console.log(this.DataComentarios)
    }

    showModal(tipocomentario,calif_id,accion = 'Ver',gral=0) {
        this.formComments.reset();
        this.formComments.patchValue({
            VerCliente: "0",
            Correo: "0",
            coment: "",
            tipocomentario: tipocomentario,
            pacienteid: this.DataComentarios.Paciente_id,
            filtroid: this.DataComentarios.Filtro_Id,
            accion: '',
            gral: gral,
            califFinal: calif_id
        });
        this.DataComentariosTabla = []
        this.tipocomentario = tipocomentario
        this.gral = gral
        this.modalAccion = accion
        
        if(accion == 'Ver'){
            if(this.tipocomentario == 1){
                this.DataComentariosTabla=this.DataComentarios.Operativos
            }else{
                this.DataComentariosTabla=this.DataComentarios.Medicos
            }
        }

        this.isVisibleModalComent = true;
    }

    handleCancel() {
        this.isVisibleModalComent = false;
    }

    handleOk(accion = 'Save') {
        this.formComments.controls.accion.setValue(accion);
        console.log(this.formComments.value)
        if (this.formComments.valid) {
            const valores = this.formComments.controls
            const requestBody = this.formComments.value

            this.appCommon.spinner(true);
            this.reportsService
            .AccionComentario(requestBody)
                .then(
                    (Default:any) => {
                        this.handleCancel();
                        this.appCommon.spinner(false);
                        this.DataComentarios.Medicos = Default.Comentarios.Medicos
                        this.DataComentarios.Operativos = Default.Comentarios.Operativos
                        this.DataComentarios.Calificacion = Default.Comentarios.Calificacion

                        this.appCommon.Notify(Default.Mensaje, 'success'); 
                        this.ref.detectChanges();
                        
                    },
                    error => {
                        console.log('fail callback', error);
                        this.appCommon.spinner(false);
                        this.appCommon.Notify(error.data.message, 'danger');
                    }
                )
                .catch(e => {
                    console.log('catch', e);
                    this.appCommon.spinner(false);
                    this.appCommon.Notify(e, 'danger');
                });
        } else {
            this.formComments.markAllAsTouched();
        }
    }


    ActivaComm(px, id, valor,tipo_coment)
	{
        let params:any


		if (tipo_coment == 1)
		{
            params= {
                filtroid: this.DataComentarios.Filtro_Id,
                pacienteid: px,
                id: id,
                valor: valor,
                tipo_coment: tipo_coment
            }
		}
		else if (tipo_coment == 2 || tipo_coment == 100)
		{
            params= {
                filtroid: this.DataComentarios.Filtro_Id,
                pacienteid: this.DataComentarios.Paciente_id,
                resultadoid: px,
                vercliente: id,
                valor: valor,
                tipo_coment: tipo_coment
            }
		}

        this.appCommon.spinner(true);
        this.reportsService
        .CambiaEstatus(params)
            .then(
                (Default:any) => {
                    //this.handleCancel();
                    this.appCommon.spinner(false);
                    this.DataComentarios.Medicos = Default.Comentarios.Medicos
                    this.DataComentarios.Operativos = Default.Comentarios.Operativos
                    this.DataComentarios.Calificacion = Default.Comentarios.Calificacion
                    
                    if(this.tipocomentario == 1){
                        this.DataComentariosTabla=this.DataComentarios.Operativos
                    }else{
                        this.DataComentariosTabla=this.DataComentarios.Medicos
                    }
                    this.ref.detectChanges();
                    this.appCommon.spinner(false);
                },
                error => {
                    console.log('fail callback', error);
                    this.appCommon.spinner(false);
                    this.appCommon.Notify(error.data.message, 'danger');
                }
            )
            .catch(e => {
                console.log('catch', e);
                this.appCommon.spinner(false);
                this.appCommon.Notify(e, 'danger');
            });
	}

    deleteComment(px, id, valor,tipo_coment){
        this.appCommon.openConfirmDialog({
            type: 'confirm',
            title: 'Eliminar comentario',
            message: `Seguro que deseas eliminar el comentario?`,
        }).then(response => {
            if (response.Confirmed) {
                this.appCommon.spinner(true);
                this.ActivaComm(px, id, valor,tipo_coment)
            }
        });
    }
    
}
