<label class="btn-primary" style="display:none;" ngbButtonLabel>
    <input #gralCLick type="checkbox" ngbButton > ENE
</label>
<div class="row">
    <div class="col-md-8" align="left">&nbsp;
        <div class="content no-padding">
            <div class="overflow-hidden">
                <h3 class="no-margin">
                    <fa-icon class="ml-1 me-3 fa-1x text-primary" [icon]='["fas", "info"]'></fa-icon>
                    <font color="#003366">Información General</font>
                </h3>
            </div>
        </div>
    </div>
</div>
<form [formGroup]="form">
    <div class="row mt-4 ms-2">
        <label class="col-sm-1 control-label">Institucion:</label>
        <div class="col-md-10">
            <span class="form-text text-danger my-1" *ngIf="isValidFieldGeneral('Institucion')">
                <b>Este campo es requerido</b>
            </span>
            <input type="text"  class="form-control" formControlName="Institucion">
        </div>
    </div>
    <div class="row mt-4 ms-2">
        <label class="col-1 control-label">Categoria:</label>
        <div class="col-2">
            <span class="form-text text-danger my-1" *ngIf="isValidFieldGeneral('CategoriaId')">
                <b>Este campo es requerido</b>
            </span>
            <select  class="form-control" formControlName="CategoriaId"  >
                <option value="">Seleccione un tipo</option>
                <option
                        *ngFor="let categoria of combos.Categorias"
                        [value]="categoria.value"
                        >
                        {{ categoria.display }}
                </option>
            </select>
        </div>
        <div class="col-1"></div>
        <label class="col-1 control-label" >Zona:</label>
        <div class="col-2" >
            <span class="form-text text-danger my-1" *ngIf="isValidFieldGeneral('ZonaId')">
                <b>Este campo es requerido</b>
            </span>
            <select  class="form-control" formControlName="ZonaId" >
                <option value="">Seleccione un tipo</option>
                <option
                        *ngFor="let zona of combos.Zonas"
                        [value]="zona.value"
                        >
                        {{ zona.display }}
                </option>
            </select>
        </div>
        <div class="col-1"></div>
        <label class="col-1 control-label">Tipo:</label>
        <div class="col-2">
            <span class="form-text text-danger my-1" *ngIf="isValidFieldGeneral('Tipo')">
                <b>Este campo es requerido</b>
            </span>
            <select  class="form-control" formControlName="Tipo" >
                <option value="">Seleccione un responsable</option>
                <option
                        *ngFor="let tipo of combos.TipoCliente"
                        [value]="tipo.value"
                        >
                        {{ tipo.display }}
                </option>
            </select>
        </div>
    </div>
    <div class="row mt-4 ms-2">
        <label class="col-1 control-label" >Grupo médico:</label>
        <div class="col-10">
            <div class="input-group mb-3" >
                <nz-select nzMode="multiple" formControlName="GrupoMedico" nzPlaceHolder="Seleccione una opcion" nzStyle="width: 100%;">
                    <nz-option  *ngFor="let grupo of combos.GrupoMedico"
                    [nzValue]="grupo.value"  [nzLabel]="grupo.display" >
                    </nz-option>
                </nz-select>
            </div>
        </div>
    </div>
    <div class="row mt-4 ms-2">
        <label class="col-1 control-label">Soluciones</label>
        <div class="col-8">
                <input type="checkbox" formControlName="TamikBit"  class="form-check-input ms-1"   > {{ checkboxes.Soluciones[0].display }}
                <input type="checkbox" formControlName="AudikBit"   class="form-check-input ms-4"   > {{ checkboxes.Soluciones[1].display }}
                <input type="checkbox" formControlName="DxBit" class="form-check-input ms-4"  > {{ checkboxes.Soluciones[2].display }}
                <input type="checkbox" formControlName="CardikBit"  class="form-check-input ms-4"  > {{ checkboxes.Soluciones[3].display }}
        </div>
    </div>
    <!-- <div class="row mt-4 ms-2">
        <label class="col-sm-1 control-label" align="left">Version sistema GK Clientes:</label>
        <div class="col-md-1">
            <input type="radio" [value]="1" formControlName="VersionSistema" class="form-check-input"> Version 1
            <img src="../../../../assets/img/gk_30x30.jpg" alt="Versión 1" class="img-fluid">
        </div>
        <div class="col-md-1">
            <input type="radio" [value]="2" formControlName="VersionSistema" class="form-check-input"> Version 2
            <img src="../../../../assets/img/gk_30x30.jpg" alt="Versión 2" class="img-fluid">
        </div>
        <div class="col-md-4"></div>
    </div> -->
    <div class="row mt-5">
        <div class="col-md-8" align="left">&nbsp;
            <div class="content no-padding">
                <div class="overflow-hidden">
                    <h3 class="no-margin">
                        <fa-icon class="ml-1 me-3 fa-1x text-primary" [icon]='["fas", "address-card"]'></fa-icon>
                        <font color="#003366">Perfil del cliente</font>
                    </h3>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-4 ms-2">
        <label class="col-sm-2 control-label" align="left">Nacimientos anuales:</label>
        <div class="col-md-3" align="left">
            <input type="number" formControlName="NacimientosAnuales"  class="form-control" min="0" >
        </div>
    </div>
    <div class="row mt-3 ms-2">
        <div class="col-md-5">
            @if(!ocultarTamizaje){
                <label class="control-label" align="left" (click)="ocultarTamizaje = !ocultarTamizaje" @fadeInOut>Tamizajes <label class="text-primary">(2-Cardiaco,Metabolico)</label></label>        
            }@else{
                <a class="txtlinka" *ngIf="!esVisor" (click)="agregarTamiz()" @fadeInOut>Agregar Tamiz</a>
                @if(tabs.Generales.Tablas.Tamizajes.length <= 0) {
                    <p>No hay ningun paquete agregado</p>
                }@else{
                    <table class="table table-striped table-bordered row-border hover nowrap" @fadeInOut>
                        <thead>
                            <tr class="ttdblue">
                                <th class="sorting_disabled text-center" tabindex="0" rowspan="1" colspan="1">TAMIZAJE</th>
                                <th class="sorting_disabled text-center" tabindex="0" rowspan="1" colspan="1">INCLUIDO EN PAQUETE</th>
                                <th class="sorting_disabled text-center" tabindex="0" rowspan="1" colspan="1">COSTO APROXIMADO</th>
                                <th class="sorting_disabled text-center" tabindex="0" rowspan="1" colspan="1">COMENTARIOS</th>
                                <th class="text-center text-center">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            @for(tamizaje of tabs.Generales.Tablas.Tamizajes; track $index){
                                <tr>
                                    <td class="text-center">{{ tamizaje.Tamiz }}</td>
                                    <td class="text-center">{{ tamizaje.IncluidoPaqueteTxt }}</td>
                                    <td class="text-center">${{ tamizaje.Costo_Aproximado}}</td>
                                    @if(tamizaje.Comentarios){
                                        <td class="text-center">{{ tamizaje.Comentarios }}</td>
                                    }@else{
                                        <td class="text-center">No hay ningun comentario.</td>
                                    }
                                    <td>
                                        <mat-icon class="colorBtnIcon text-center ms-1" (click)="agregarTamiz(tamizaje)">edit_square</mat-icon>
                                        <mat-icon class="colorBtnIcon text-center ms-1" (click)="eliminarTamizP(tamizaje.Institucion_Tamizaje_Id)">delete</mat-icon>
                                    </td>  
                                </tr>
                            }
                        </tbody>
                    </table>
                }
                <button class="btn btn-outline-secondary" (click)="ocultarTamizaje = !ocultarTamizaje" @fadeInOut><mat-icon class="tamIconB me-2">keyboard_return</mat-icon>Regresar</button>
            }
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-5">
            @if(!ocultarMaternidad){
                <label class="control-label" align="left" (click)="ocultarMaternidad = !ocultarMaternidad" @fadeInOut>Paquetes de maternidad <label class="text-primary">(2-Cardiaco,Metabolico)</label></label>
            }@else{
                <a class="txtlinka" (click)="agregarMaternidad()"  *ngIf="!esVisor" @fadeInOut>Agregar Paquete de maternidad</a>
                @if(tabs.Generales.Tablas.PaquetesMaternidad.length <= 0) {
                    <p>No hay ningun paquete agregado</p>
                }@else{
                    <table class="table table-striped table-bordered row-border hover nowrap" @fadeInOut>
                        <thead>
                            <tr class="ttdblue">
                                <th class="sorting_disabled text-center" tabindex="0" rowspan="1" colspan="1">Tipo</th>
                                <th class="sorting_disabled text-center" tabindex="0" rowspan="1" colspan="1">Dias</th>
                                <th class="sorting_disabled text-center" tabindex="0" rowspan="1" colspan="1">Costo</th>
                                <th class="sorting_disabled text-center" tabindex="0" rowspan="1" colspan="1">Comentarios</th>
                                <th class="text-center">Acciones</th>
                            </tr>
                        </thead>
                        <tbody >
                            @for(paquete of tabs.Generales.Tablas.PaquetesMaternidad; track $index){
                                <tr>
                                    <td class="text-center">{{ paquete.Tipo_Paquete }}</td>
                                    <td class="text-center">{{ paquete.Dias }}</td>
                                    <td class="text-center">{{ paquete.Costo }}</td>
                                    @if(paquete.Comentarios){
                                        <td class="text-center">{{ paquete.Comentarios }}</td>
                                    }@else{
                                        <td class="text-center">No hay comentarios</td>
                                    }
                                    <td>
                                        <mat-icon class="colorBtnIcon text-center ms-5" (click)="editarPMater(paquete)">edit_square</mat-icon>
                                        <mat-icon class="colorBtnIcon text-center ms-5" (click)="eliminarPMater(paquete.Institucion_Paquete_Id)">delete</mat-icon>
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                }
                <button class="btn btn-outline-secondary" (click)="ocultarMaternidad = !ocultarMaternidad" @fadeInOut><mat-icon class="tamIconB me-2">keyboard_return</mat-icon>Regresar</button>
            }
        </div>
    </div>
    <div class="row mt-5">
        <div class="col-md-8" align="left">&nbsp;
            <div class="content no-padding">
                <div class="overflow-hidden">
                    <h3 class="no-margin">
                        <fa-icon class="ml-1 me-3 fa-1x text-primary" [icon]='["fas", "cogs"]'></fa-icon>
                        <font color="#003366">Configuración del cliente</font>
                    </h3>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-4 ms-2">
        <label class="col-sm-1 control-label" align="left">Tipo Cliente:</label>
        <div class="col-md-1 mt-1">
            <input type="radio" [value]="true" class="form-check-input"  formControlName="BitProspecto" > {{ radios.ProspectoCliente[0].display }}
        </div>
        <div class="col-md-1 mt-1">
            <input type="radio" [value]="false"  class="form-check-input" formControlName="BitProspecto"> {{ radios.ProspectoCliente[1].display }}
        </div>
        <div class="col-md-4"></div>
        <label class="col-sm-1 control-label" align="left">Cliente dentro del tipo target:</label>
        <div class="col-md-1">
            <input type="radio" [value]="true" formControlName="DentroTarget" class="form-check-input" > {{  radios.SiNo[0].display }}
        </div>
        <div class="col-md-1">
            <input type="radio" [value]="false" formControlName="DentroTarget"  class="form-check-input" > {{  radios.SiNo[1].display }}
        </div>
    </div>
    <div class="row mt-4 ms-2">
        <label class="col-sm-1 control-label" align="left">Fecha de inicio:</label>
        <div class="col-md-3" align="left">
            <span class="form-text text-danger my-1" *ngIf="isValidFieldGeneral('FechaInicio')">
                <b>Este campo es requerido</b>
            </span>
            <input type="date" formControlName="FechaInicio" class="form-control">
        </div>
        <div class="col-md-3"></div>
        <label class="col-sm-1 control-label" align="left">Responsable:</label>
        <div class="col-md-3" align="left">
            <span class="form-text text-danger my-1" *ngIf="isValidFieldGeneral('Responsable')">
                <b>Este campo es requerido</b>
            </span>
            <select  class="form-control"  formControlName="Responsable" >
                <option value="">Seleccione un responsable</option>
                <option
                        *ngFor="let responsable of combos.Responsables"
                        [value]="responsable.value"
                        >
                        {{ responsable.display }}
                </option>
            </select>
        </div>
    </div>
    <div class="row mt-4 ms-2">
        <label class="col-sm-2 control-label" align="left">Responsable adjunto:</label>
        <div class="col-md-9" align="left">
            <div class="input-group mb-3">
                <nz-select nzMode="multiple" formControlName="ResponsableAdjunto" nzPlaceHolder="Seleccione una opcion" nzStyle="width: 100%;">
                    <nz-option *ngFor="let respon of combos.ResponsablesAdjuntos"
                    [nzValue]="respon.value"  [nzLabel]="respon.display" >
                    </nz-option>
                </nz-select>
            </div>
        </div>
    </div>
    <div class="row mt-4 ms-2">
        <label class="col-1 control-label">Maneja Tamiz:</label>
        <div class="col-1">
            <input type="radio" [value]="true" class="form-check-input"  formControlName="ManejaTamiz" > {{ radios.SiNo[0].display}}
        </div>
        <div class="col-1">
            <input type="radio" [value]="false"  class="form-check-input" formControlName="ManejaTamiz" > {{ radios.SiNo[1].display}}
        </div>
        <div class="col-4"></div>
        <label class="col-1 control-label">Tamiz:</label>
        <div class="col-3">
            <input type="radio" [value]="true" class="form-check-input"  formControlName="Tamiz"> {{ radios.Tamiz[0].display}}
            <input type="radio" [value]="false"  class="form-check-input ms-2" formControlName="Tamiz"> {{ radios.Tamiz[1].display}} 
        </div>
    </div>
    <div class="row mt-4 ms-2 mb-4">
        <div class="col-md-4">
            <label class="control-label" align="left">¿Dentro de la política de precios y volumen?:</label>
            <br>
            <input type="radio" [value]="true"  formControlName="DentroPoliticaPreciosVolumen"  class="form-check-input ml-5" > {{  radios.SiNo[0].display }} &nbsp;&nbsp;
            <input type="radio" [value]="false"  formControlName="DentroPoliticaPreciosVolumen" class="form-check-input"  > {{  radios.SiNo[1].display }}
        </div>
        <div class="col-md-3"></div>
        <div class="col-md-3">
            <label class="control-label" align="left">¿El servicio se incluirá en el paquete de maternidad?</label>
            <br>
            <input type="radio" [value]="true"  formControlName="IncluidoMaternidadBit" class="ml-5 form-check-input" > {{  radios.SiNo[0].display }} &nbsp;&nbsp;
            <input type="radio" [value]="false" formControlName="IncluidoMaternidadBit" class="form-check-input"  > {{  radios.SiNo[1].display }}
        </div>
    </div>
    <div class="row mt-4 ms-2 mb-4">
        <div class="col-md-9">
            <label class="control-label" align="left">Convenios:</label>
            <br>
            <button type="button" class="btn btn-outline-primary" (click)="subirConvenio()">Subir Archivo del convenio</button> 
        </div>
    </div>
    <div class="row mt-3 ms-2">
        <div class="col-2"></div>
        <div class="col-8">
            @if(tabs.Generales.Tablas.Contratos.length > 0){
                <table id="gridPaquetesMaternidad" class="table table-striped table-bordered row-border hover nowrap">
                    <thead>
                        <tr class="ttdblue">
                            <th class="sorting_disabled text-center" tabindex="0" rowspan="1" colspan="1">CONVENIO</th>
                            <th class="sorting_disabled text-center" tabindex="0" rowspan="1" colspan="1">TIPO CONTRATO</th>
                            <th class="sorting_disabled text-center" tabindex="0" rowspan="1" colspan="1">FECHA SUBIDA</th>
                            <th class="sorting_disabled text-center" tabindex="0" rowspan="1" colspan="1">FECHA DE EXPIRACIÓN</th>
                            <th class="sorting_disabled text-center" tabindex="0" rowspan="1" colspan="1">CONTRATO</th>
                            <th class="sorting_disabled text-center" tabindex="0" rowspan="1" colspan="1">Accion</th>
                        </tr>
                    </thead>
                    <tbody>
                        @for (contrato of tabs.Generales.Tablas.Contratos; track $index) {
                            <tr class="text-center">
                                <td class="text-center">{{ contrato.Solucion }}</td>
                                <td class="text-center">{{ contrato.Tipo }}</td>
                                <td class="text-center"> {{ contrato.FechaSubida | date:'yyyy-MM-dd' }} </td>
                                @if (contrato.FechaExpiracion) {
                                    <td class="text-center">{{ contrato.FechaExpiracion | date:'yyyy-MM-dd' }} </td>
                                }@else {
                                    <td class="text-center">No cuenta con fecha de expiracion</td>
                                }
                                @if (contrato.Contrato.endsWith('.pdf')) {
                                    <td class="txtlinka text-primary" (click)="downloadPdfConvenio(contrato.Id)"><strong style="cursor: pointer;">{{ contrato.Contrato }}</strong></td>
                                }@else {
                                    <td class="txtlinka text-primary" (click)="showImageConvenio(contrato.Id)"><strong style="cursor: pointer;">{{ contrato.Contrato }}</strong></td>
                                }
                                <td><mat-icon class="colorBtnIcon text-center" (click)="eliminarConvenio(contrato.Id)">delete</mat-icon></td>
                            </tr>
                        }
                    </tbody>
                </table>
            }
        </div>
        <div class="col-2"></div>
    </div>
</form>

    <div class="row mt-4 ms-2 mb-4">
        <div class="col-md-12">
            
            <mat-stepper orientation="vertical" #stepper>

                <ng-template matStepperIcon="abajo">
                    <mat-icon>expand_more</mat-icon>
                </ng-template>
                <form [formGroup]="formTamik">
                    @if(form.get('TamikBit')!.value && form.get('BitProspecto')!.value == false){
                        <mat-step  state="abajo" label="Támi-k">
                            <ng-template matStepContent>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="col-md-8" align="left">&nbsp;
                                            <div class="content no-padding">
                                                <div class="overflow-hidden">
                                                    <h3 class="no-margin">
                                                        <fa-icon class="ml-1 me-3 fa-1x text-primary" [icon]='["fas", "info"]'></fa-icon>
                                                        <font color="#003366">GENERALES</font>
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mt-4 ms-2">
                                            <label class="col-2 control-label">Programa de támi-k a seleccionar:</label>
                                            <div class="col-md-9">
                                                <span class="form-text text-danger my-1" *ngIf="isValidFieldTamik('ProgramasTamik')">
                                                    <b>Este campo es requerido</b>
                                                </span>
                                                <div class="input-group mb-3">
                                                    <nz-select nzMode="multiple" formControlName="ProgramasTamik" nzPlaceHolder="Seleccione una opcion" nzStyle="width: 100%;">
                                                        <nz-option  *ngFor="let programa of combos.ProgramaTamik"
                                                        [nzValue]="programa.value"  [nzLabel]="programa.display" >
                                                        </nz-option>
                                                    </nz-select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mt-4 ms-2">
                                            <label class="col-2 control-label">Tipo precio:</label>
                                            <div class="col-5">
                                                <div class="input-group mb-3">
                                                    <select  class="form-control"  formControlName="TipoCostoIdTamik" >
                                                        <option value="">Seleccione un tipo</option>
                                                        <option
                                                                *ngFor="let costo of combos.TipoCostoTamik"
                                                                [value]="costo.value"
                                                                >
                                                                {{ costo.display }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        @if(formTamik.get('ProgramasTamik')!.value){
                                            <div class="row mt-4 ms-2">
                                                <div class="col-2"></div>
                                                <div class="col-7">
                                                    <table class="table table-striped table-bordered row-border hover nowrap" @fadeInOut>
                                                        <thead>
                                                            <tr class="ttdblue">
                                                                <th class="sorting_disabled text-center" tabindex="0" rowspan="1" colspan="1" >SOLUCIÓN</th>
                                                                <th class="sorting_disabled text-center" tabindex="0" rowspan="1" colspan="1">PRECIO TOTAL</th>
                                                        </thead>
                                                        <tbody>
                                                            @if(isSelectedPTamik(1) ) {
                                                                <tr class="text-center">
                                                                    <td class="d-flex flex-column">
                                                                        <div class="d-flex align-items-center">Precio Támi-k 69
                                                                            @if(formTamik.get('TipoCostoIdTamik')!.value == 2){
                                                                                <nz-select  nzPlaceHolder="Seleccione una opcion" formControlName="TablaTamik69" style="width: 70%!important; margin-left: 10px!important;">
                                                                                    <nz-option  *ngFor="let tabla of combos.TamikTablaPrecio"
                                                                                    [nzValue]="tabla.value"  [nzLabel]="tabla.display" >
                                                                                    </nz-option>
                                                                                </nz-select>
                                                                            }
                                                                        </div>
                                                                        @if(formTamik.get('TablaTamik69')!.value){
                                                                            <a class="txtlinka mt-2" (click)="tablaPrecios(formTamik.get('TablaTamik69')!.value)">ver tabla</a>
                                                                        }
                                                                    </td>
                                                                    <td class="input-column">
                                                                        <div class="iconAlign"> 
                                                                            <mat-icon class="me-2 iconInpC">attach_money</mat-icon>
                                                                            <input type="number" class="form-control readonly-input" formControlName="PrecioTamik69"  placeholder="0.00" [readonly]="isReadOnlyTamikP()">
                                                                        </div>        
                                                                    </td>
                                                                </tr>
                                                            }
                                                            @if(isSelectedPTamik(29) ){
                                                                <tr class="text-center">
                                                                    <td class="d-flex flex-column">
                                                                        <div class="d-flex align-items-center">Precio Támi-k 61 
                                                                            @if(formTamik.get('TipoCostoIdTamik')!.value == 2){
                                                                                <nz-select  nzPlaceHolder="Seleccione una opcion" formControlName="TablaTamik61" style="width: 70%!important; margin-left: 10px!important;">
                                                                                    <nz-option  *ngFor="let tabla of combos.TamikTablaPrecio"
                                                                                    [nzValue]="tabla.value"  [nzLabel]="tabla.display" >
                                                                                    </nz-option>
                                                                                </nz-select>
                                                                            }
                                                                        </div>
                                                                        @if(formTamik.get('TablaTamik61')!.value){
                                                                            <a class="txtlinka mt-2" (click)="tablaPrecios(formTamik.get('TablaTamik61')!.value)">ver tabla</a>
                                                                        }
                                                                    </td>
                                                                    <td class="input-column">
                                                                        <div class="iconAlign">
                                                                            <mat-icon class="me-2 iconInpC">attach_money</mat-icon>
                                                                            <input type="number" class="form-control" formControlName="PrecioTamik61"  placeholder="0.00" [readonly]="isReadOnlyTamikP()">
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            }
                                                            @if(isSelectedPTamik(2)){
                                                                <tr class="text-center">
                                                                    <td class="d-flex flex-column">
                                                                        <div class="d-flex align-items-center">
                                                                            Precio Támi-k 70
                                                                            @if(formTamik.get('TipoCostoIdTamik')!.value == 2){
                                                                                <nz-select  nzPlaceHolder="Seleccione una opcion" formControlName="TablaTamik70" style="width: 70%!important; margin-left: 10px!important;">
                                                                                    <nz-option  *ngFor="let tabla of combos.TamikTablaPrecio"
                                                                                    [nzValue]="tabla.value"  [nzLabel]="tabla.display" >
                                                                                    </nz-option>
                                                                                </nz-select>
                                                                            }
                                                                        </div>
                                                                        @if(formTamik.get('TablaTamik70')!.value){
                                                                            <a class="txtlinka mt-2" (click)="tablaPrecios(formTamik.get('TablaTamik70')!.value)">ver tabla</a>
                                                                        }
                                                                    </td>
                                                                    <td class="input-column">
                                                                        <div class="iconAlign">
                                                                            <mat-icon class="me-2 iconInpC">attach_money</mat-icon>
                                                                            <input type="number" class="form-control" formControlName="PrecioTamik70"  placeholder="0.00" [readonly]="isReadOnlyTamikP()">
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            }
                                                            @if(isSelectedPTamik(15)){
                                                                <tr class="text-center">
                                                                    <td class="d-flex flex-column">
                                                                        <div class="d-flex align-items-center">
                                                                            Precio Támi-k 76
                                                                            @if(formTamik.get('TipoCostoIdTamik')!.value == 2){
                                                                                <nz-select  nzPlaceHolder="Seleccione una opcion" formControlName="TablaTamik76" style="width: 70%!important; margin-left: 10px!important;">
                                                                                    <nz-option  *ngFor="let tabla of combos.TamikTablaPrecio"
                                                                                    [nzValue]="tabla.value"  [nzLabel]="tabla.display" >
                                                                                    </nz-option>
                                                                                </nz-select>
                                                                            }
                                                                        </div>
                                                                        @if(formTamik.get('TablaTamik76')!.value){
                                                                            <a class="txtlinka mt-2" (click)="tablaPrecios(formTamik.get('TablaTamik76')!.value)">ver tabla</a>
                                                                        }
                                                                    </td>
                                                                    <td class="input-column">
                                                                        <div class="iconAlign">
                                                                            <mat-icon class="me-2 iconInpC">attach_money</mat-icon>
                                                                            <input type="number" class="form-control"  placeholder="0.00" formControlName="PrecioTamik76"  [readonly]="isReadOnlyTamikP()">
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            }
                                                            @if(isSelectedPTamik(32)){
                                                                <tr class="text-center">
                                                                    <td class="d-flex flex-column">
                                                                        <div class="d-flex align-items-center">
                                                                            Precio Támi-k 77
                                                                            @if(formTamik.get('TipoCostoIdTamik')!.value == 2){
                                                                                <nz-select  nzPlaceHolder="Seleccione una opcion" formControlName="TablaTamik77" style="width: 70%!important; margin-left: 10px!important;">
                                                                                    <nz-option  *ngFor="let tabla of combos.TamikTablaPrecio"
                                                                                    [nzValue]="tabla.value"  [nzLabel]="tabla.display" >
                                                                                    </nz-option>
                                                                                </nz-select>
                                                                            }
                                                                        </div>
                                                                        @if(formTamik.get('TablaTamik77')!.value){
                                                                            <a class="txtlinka mt-2" (click)="tablaPrecios(formTamik.get('TablaTamik77')!.value)">ver tabla</a>
                                                                        }
                                                                    </td>
                                                                    <td class="input-column">
                                                                        <div class="iconAlign">
                                                                            <mat-icon class="me-2 iconInpC">attach_money</mat-icon>
                                                                            <input type="number" class="form-control"  placeholder="0.00" formControlName="PrecioTamik77" [readonly]="isReadOnlyTamikP()">
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        }
        
                                        <div class="row mt-4 ms-2">
                                            <label class="col-2 control-label">Moneda:</label>
                                            <div class="col-3">
                                                <span class="form-text text-danger my-1" *ngIf="isValidFieldTamik('TipoMonedaIdTamik')">
                                                    <b>Este campo es requerido</b>
                                                </span>
                                                <select  class="form-control" formControlName="TipoMonedaIdTamik" >
                                                    <option value="">Seleccione un tipo</option>
                                                    <option
                                                            *ngFor="let moneda of combos.TipoMoneda"
                                                            [value]="moneda.value"
                                                            >
                                                            {{ moneda.display }}
                                                    </option>
                                                </select>
                                            </div>
                                            <label class="col-2 control-label">Comisión:</label>
                                            <div class="col-1 text-end" >
                                                <input type="radio" [value]="1" class="form-check-input" formControlName="ComisionId"   > {{  radios.SiNo[0].display }}
                                                <input type="radio" [value]="0" class="form-check-input ms-2" formControlName="ComisionId"  > {{  radios.SiNo[1].display }}
                                            </div>
                                        </div>
                                        @if(formTamik.get('ComisionId')!.value == 1){
                                            <div class="row mt-4 ms-2">
                                                <label class="col-2 control-label" @fadeInOut>Institucion:</label>
                                                <div class="col-3">
                                                    <span class="form-text text-danger my-1" *ngIf="isValidFieldTamik('InstitucionComisionId')">
                                                        <b>Este campo es requerido</b>
                                                    </span>
                                                    <select  class="form-control" formControlName="InstitucionComisionId" @fadeInOut>
                                                        <option value="">Seleccione un tipo</option>
                                                        <option
                                                                *ngFor="let moneda of combos.Institucion"
                                                                [value]="moneda.value"
                                                                >
                                                                {{ moneda.display }}
                                                        </option>
                                                    </select>
                                                </div>
            
                                                <label class="col-2 control-label" @fadeInOut>Tipo:</label>
                                                <div class="col-3">
                                                    <span class="form-text text-danger my-1" *ngIf="isValidFieldTamik('TipoComisionId')">
                                                        <b>Este campo es requerido</b>
                                                    </span>
                                                    <select  class="form-control" formControlName="TipoComisionId" @fadeInOut>
                                                        <option value="">Seleccione un tipo</option>
                                                        <option
                                                                *ngFor="let moneda of combos.TipoComision"
                                                                [value]="moneda.value"
                                                                >
                                                                {{ moneda.display }}
                                                        </option>
                                                    </select>
                                                </div>
            
                                            </div>
                                            <div class="row mt-4 ms-2">
                                                <label class="col-2 control-label" @fadeInOut>Cantidad:</label>
                                                <div class="col-3">
                                                    <span class="form-text text-danger my-1" *ngIf="isValidFieldTamik('CantidadComision')">
                                                        <b>Este campo es requerido</b>
                                                    </span>
                                                    <input class="form-control" type="number" formControlName="CantidadComision" @fadeInOut>
                                                </div>
                                            </div>
                                        }
                                        <div class="row mt-4 align-items-center">
                                                <div class="col-auto" @fadeInOut>
                                                    <mat-icon class="text-primary" >folder_open</mat-icon>
                                                </div>
                                                <div class="col">
                                                    <div class="d-flex flex-column">
                                                    <h3 class="mb-0">PROPUESTA</h3>
                                                    <small class="text-primary">Propuesta activa del cliente, descarga de archivo y visualización</small>
                                                    </div>
                                                </div>
                                        </div>
                                        
                                        <div class="row mt-4">
                                            @if(tabs.Generales.Valores.PropuestaTK){
                                                <div class="col-3"></div>
                                                    <div class="col-6">
                                                        <table class="table table-striped table-bordered row-border hover nowrap" @fadeInOut>
                                                            <thead>
                                                                <tr class="ttdblue">
                                                                    <th class="sorting_disabled text-start" tabindex="0" rowspan="1" colspan="1" >PROPUESTA ACTUAL</th>
                                                                    <th class="sorting_disabled text-end" tabindex="0" rowspan="1" colspan="1">FECHA ACTIVACIÓN</th>
                                                                    <th class="sorting_disabled text-center" tabindex="0" rowspan="1" colspan="1">DESCARGAR</th>
                                                            </thead>
                                                            <tbody>
                                                                <tr class="text-center">
                                                                    <td class="fw-bold text-start" style="width: 45%;">Propuesta Támi-k</td>
                                                                    <td class="text-end"  style="width: 25%;">{{ tabs.Generales.Valores.PropuestaTKFecha | date:'yyyy-MM-dd' }}</td>
                                                                    <td class="input-column" style="width: 30%;">
                                                                        <gk-cf-pdf-viewer url="{{url}}{{tabs.Generales.Valores.PropuestaTK}}" [file]="file" [display]=display [clase]=clase [noviewer]=1></gk-cf-pdf-viewer>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                            }@else{
                                                <div class="col-3"></div>
                                                <label class="col-6 control-label text-center">De momento no existe Propuesta creada desde el Sistema</label>
                                            }
                                        </div>
                                        
                                        <div class="row mt-4 align-items-center">
                                            <div class="col-auto">
                                                <mat-icon class="text-primary" >settings</mat-icon>
                                            </div>
                                            <div class="col">
                                                <div class="d-flex flex-column">
                                                <h3 class="mb-0">Configuración del Cliente</h3>
                                                <small class="text-primary">Envío de Resultados por Correo, Catálogo de Doctroes y Certificado de Resultado</small>
                                                </div>
                                            </div>
                                        </div>
        
                                        <div class="row mt-4 ms-2">
                                            <label class="col-3 control-label">Operaciones envia todos los resultados por correo?</label>
                                            <div class="col-1 text-end" >
                                                <input type="radio" [value]="true" class="form-check-input" formControlName="OperacionesCorreoTamik"   > {{  radios.SiNo[0].display }}
                                                <input type="radio" [value]="false" class="form-check-input ms-2" formControlName="OperacionesCorreoTamik"  > {{  radios.SiNo[1].display }}
                                            </div>
                                            <div class="col-3"></div>
                                            <label class="col-3 control-label">Se envia aviso de resultados nuevos?</label>
                                            <div class="col-1 text-end">
                                                <input type="radio" [value]="true" class="form-check-input" formControlName="AvisoResultadosNuevosTamik"   > {{  radios.SiNo[0].display }}
                                                <input type="radio" [value]="false" class="form-check-input ms-2" formControlName="AvisoResultadosNuevosTamik"  > {{  radios.SiNo[1].display }}
                                            </div>
                                                
                                        </div>
                                        <div class="row mt-4 ms-2">
                                            <label class="col-3 control-label">Usa catalogo de doctores para captura?</label>
                                            <div class="col-1 text-end" >
                                                <input type="radio" [value]="true" class="form-check-input" formControlName="CatalogoDoctoresTamik"   > {{  radios.SiNo[0].display }}
                                                <input type="radio" [value]="false" class="form-check-input ms-2" formControlName="CatalogoDoctoresTamik"  > {{  radios.SiNo[1].display }}
                                            </div>
                                            <div class="col-3"></div>
                                            <label class="col-3 control-label">Se incluye certificado en el Resultado?</label>
                                            <div class="col-1 text-end" >
                                                <input type="radio" [value]="true" class="form-check-input"  formControlName="IncluyeCertificadoResultadoTamik"  > {{  radios.SiNo[0].display }}
                                                <input type="radio" [value]="false" class="form-check-input ms-2" formControlName="IncluyeCertificadoResultadoTamik"  > {{  radios.SiNo[1].display }}
                                            </div>
                                                
                                        </div>
                                        <div class="row mt-4 ms-2">
                                            <label class="col-3 control-label">Cliente envía resultado por correo a papás y pediatra?</label>
                                            <div class="col-1 text-end" >
                                                <input type="radio" [value]="true" class="form-check-input" formControlName="ClienteCorreoPadresDrTamik"   > {{  radios.SiNo[0].display }}
                                                <input type="radio" [value]="false" class="form-check-input ms-2" formControlName="ClienteCorreoPadresDrTamik"  > {{  radios.SiNo[1].display }}
                                            </div>  
                                        </div>
                                        @if(formTamik.get('ClienteCorreoPadresDrTamik')!.value == true){
                                            <div class="row mt-4 ms-2">
                                                <label class="control-label col-2" >Correo de envio de resultados:</label>
                                                <div class="col-2" >
                                                    <span class="form-text text-danger my-1" *ngIf="isValidFieldTamik('CorreoEnvioResultadosTamik')">
                                                        <b>Este campo es requerido</b>
                                                    </span>
                                                    <input type="text" class="form-control" formControlName="CorreoEnvioResultadosTamik">
                                                </div>
                                                <label class="col-2">&#64;genomi-k.com</label>
                                                <div class="col-1"></div>
                                                <div class="col-2">
                                                    <label class="control-label" >Firma de Correo:</label>
                                                </div>
                                                <div class="col-2">
                                                    <span class="form-text text-danger my-1" *ngIf="isValidFieldTamik('FirmaCorreoEnvioResultadosTamik')">
                                                        <b>Este campo es requerido</b>
                                                    </span>
                                                    <input type="text" class="form-control"  formControlName="FirmaCorreoEnvioResultadosTamik"  > 
                                                </div>
                                                
                                            </div>
                                        }
                                        @if(tabs.Generales.Valores.LogoClienteTamik === 1){
                                            <div class="row mt-4 ms-2">
                                                <label class="control-label text-danger" >Para poder usar las opciones de logo del 
                                                    cliente en certificado y resultado es necesario entregar las imagenes a sistemas
                                                </label>
                                            </div>
                                        }                                 
                                        <div class="row mt-4 ms-2">
                                            <label class="control-label col-3" >Se incluye el logo del cliente en el resultado?</label>
                                            <div class="col-1 text-end" >
                                                @if(tabs.Generales.Valores.LogoClienteTamik === 1){
                                                    <input type="radio" [value]="true" class="form-check-input" formControlName="IncluyeLogoClienteResultadoTamik"> SI
                                                    <input type="radio" [value]="false" class="form-check-input ms-2" formControlName="IncluyeLogoClienteResultadoTamik"  > NO
                                                }@else{
                                                    <input type="radio" [value]="true" class="form-check-input" disabled formControlName="IncluyeLogoClienteResultadoTamik"> SI
                                                    <input type="radio" [value]="false" class="form-check-input ms-2" disabled formControlName="IncluyeLogoClienteResultadoTamik"  > NO
                                                }
                                            </div>
                                            <div class="col-3"></div>
                                            <label class="col-3 control-label" >Se incluye logo del cliente en el certificado?</label>
                                            <div class="col-1 text-end" >
                                                @if(tabs.Generales.Valores.LogoClienteTamik === 1){
                                                    <input type="radio" [value]="true" class="form-check-input"  formControlName="IncluyeLogoClienteCertificadoTamik"  > SI
                                                    <input type="radio" [value]="false" class="form-check-input ms-2" formControlName="IncluyeLogoClienteCertificadoTamik"  > NO
                                                }@else{
                                                    <input type="radio" [value]="true" class="form-check-input" disabled formControlName="IncluyeLogoClienteCertificadoTamik"  > SI
                                                    <input type="radio" [value]="false" class="form-check-input ms-2" disabled formControlName="IncluyeLogoClienteCertificadoTamik"  > NO
                                                }
                                            </div>
                                            
                                        </div>
        
                                    </div>
                                </div>
                            </ng-template>
                        </mat-step>
                    }    
                </form>

                <form [formGroup]="formAudik">
                    @if(form.get('AudikBit')!.value && form.get('BitProspecto')!.value === false){
                        <mat-step state="abajo" label="Áudi-k">
                            <ng-template matStepContent>
                                <div class="row">
                                    <div class="col-lg-12">
        

                                        <div class="row mt-4 align-items-center">
                                            <div class="col-auto">
                                                <mat-icon class="text-primary" >info</mat-icon>
                                            </div>
                                            <div class="col">
                                                <div class="d-flex flex-column">
                                                <h3 class="mb-0">GENERALES</h3>
                                                <small class="text-primary">Precio, Moneda y Certificado</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mt-4 ms-2">
                                            <label class="col-2 control-label">Cobranza Áudi-k / Cárdi-k</label>
                                            <div class="col-md-1 mt-1" align="left">
                                                <input type="radio" [value]="false"  formControlName="CobranzaAkCk" class="form-check-input"  > Juntos
                                            </div>
                                            <div class="col-md-1 mt-1" align="left">
                                                <input type="radio" [value]="true"  formControlName="CobranzaAkCk" class="form-check-input" > Separados
                                            </div>
                                        </div>
                                        <div class="row mt-4 ms-2">
                                            <label class="col-sm-1 control-label" align="left">Moneda:</label>
                                            <div class="col-md-3" align="left">
                                                <span class="form-text text-danger my-1" *ngIf="isValidFieldAudik('TipoMonedaIdAudik')">
                                                    <b>Este campo es requerido</b>
                                                </span>
                                                <select  class="form-control"    formControlName="TipoMonedaIdAudik" (change)="updateMonedaCardik()">
                                                    <option value="">Seleccione un tipo</option>
                                                    <option
                                                            *ngFor="let moneda of combos.TipoMoneda"
                                                            [value]="moneda.value"
                                                            >
                                                            {{ moneda.display }}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-md-3"></div>
                                            <label class="col-sm-2 control-label" >Tipo de precio:</label>
                                            <div class="col-md-3">
                                                <span class="form-text text-danger my-1" *ngIf="isValidFieldAudik('TipoCostoIdAudik')">
                                                    <b>Este campo es requerido</b>
                                                </span>
                                                <select  class="form-control" formControlName="TipoCostoIdAudik" (change)="changeTipoCostoIdA($event.target.value)" >
                                                    <option value="">Seleccione un tipo</option>
                                                    <option
                                                            *ngFor="let costo of combos.TipoCostoAudik"
                                                            [value]="costo.value"
                                                            >
                                                            {{ costo.display }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
        
                                        <div class="row mt-4 ms-2">
                                            <label class="col-3 control-label" >Se incluye certificado en el Resultado:</label>
                                            <div class="col-1" >
                                                <input type="radio" [value]="true" formControlName="IncluyeCertificadoResultadoAudik"  class="form-check-input" >  {{  radios.SiNo[0].display }}
                                                <input type="radio" [value]="false"  formControlName="IncluyeCertificadoResultadoAudik" class="form-check-input ms-2" >  {{  radios.SiNo[1].display }}
                                            </div>
                                            <div class="col-3"></div>
                                            <label class="col-3 control-label" >Se muestra encabezado del resultado:</label>
                                            <div class="col-1" align="left">
                                                <input type="radio" [value]="true" formControlName="MuestraEncabezadoResultadoAudik"  class="form-check-input" >  {{  radios.SiNo[0].display }}
                                                <input type="radio" [value]="false" formControlName="MuestraEncabezadoResultadoAudik"  class="form-check-input ms-2" >  {{  radios.SiNo[1].display }}
                                            </div>
                                        </div>
        
                                        <div class="row mt-4 ms-2">
                                            <label class="col-3 control-label">Se incluye el logo del cliente en el Resultado?:</label>
                                            <div class="col-1" >
                                                <input type="radio" [value]="true" formControlName="IncluyeLogoClienteResultadoAudik" class="form-check-input" >  {{  radios.SiNo[0].display }}
                                                <input type="radio" [value]="false"  formControlName="IncluyeLogoClienteResultadoAudik" class="form-check-input ms-2">  {{  radios.SiNo[1].display }}
                                            </div>
                                            <div class="col-3"></div>
                                            <label class="col-3 control-label" >Cliente envia resultados por correo a papás?:</label>
                                            <div class="col-1" >
                                                <input type="radio" [value]="true" formControlName="ClienteCorreoPadresDrAudik" class="form-check-input" >  {{  radios.SiNo[0].display }}
                                                <input type="radio" [value]="false" formControlName="ClienteCorreoPadresDrAudik" class="form-check-input ms-2" >  {{  radios.SiNo[1].display }}
                                            </div>
                                        </div>
                                        @if(!ocultarPrecioFijoEstu && !ocultarTablaPrecio && !ocultarMensualidadPrecio){
                                            <div class="row mt-4 align-items-center">
                                                <div class="col-auto" @fadeInOut>
                                                    <mat-icon class="text-primary" >folder_open</mat-icon>
                                                </div>
                                                <div class="col">
                                                    <div class="d-flex flex-column">
                                                        <h3 class="mb-0">PROPUESTA</h3>
                                                        <small class="text-primary">Propuesta activa del cliente, descarga de archivo y visualización</small>
                                                    </div>
                                                </div>
                                            </div>
                                        }@else if(ocultarPrecioFijoEstu || ocultarMensualidadPrecio){
                                            <label class="col-2 control-label" @fadeInOut>Precio 1er. Servicio</label>
                                            <div class="col-3" @fadeInOut>
                                                <input type="number" class="form-control" placeholder="0.00" formControlName="CostoAudik" (input)="updateValueCostAudik()" >    
                                            </div>
                                        }@else if(ocultarTablaPrecio){
                                            <label class="col-2 control-label" >Tipo de Tabla:</label>
                                            <div class="col-3" >
                                                <select  class="form-control" formControlName="TablaAudik">
                                                    <option value="">Seleccione un tipo</option>
                                                    <option
                                                            *ngFor="let tabla of combos.AudikTablaPrecio"
                                                            [value]="tabla.value"
                                                            >
                                                            {{ tabla.display }}
                                                    </option>
                                                </select>
                                                
                                                <button type="button" class="btn btn-outline-primary mt-2 ms-2"  (click)="tablaPrecios(formAudik.get('TablaAudik')!.value)" [disabled]="!formAudik.get('TablaAudik')!.value">Ver tabla</button>     
                                            </div>
                                        }
                                        <div class="row mt-4 ms-2">
                                            @if(formAudik.get('CobranzaAkCk')!.value == '0'){
                                                @if(tabs.Generales.Valores.PropuestaAK){
                                                    <div class="col-3"></div>
                                                    <div class="col-6">
                                                        <table class="table table-striped table-bordered row-border hover nowrap" @fadeInOut>
                                                            <thead>
                                                                <tr class="ttdblue">
                                                                    <th class="sorting_disabled text-start" tabindex="0" rowspan="1" colspan="1" >PROPUESTA ACTUAL</th>
                                                                    <th class="sorting_disabled text-end" tabindex="0" rowspan="1" colspan="1">FECHA ACTIVACIÓN</th>
                                                                    <th class="sorting_disabled text-center" tabindex="0" rowspan="1" colspan="1">DESCARGAR</th>
                                                            </thead>
                                                            <tbody>
                                                                <tr class="text-center">
                                                                    <td class="fw-bold text-start" style="width: 45%;">Propuesta Áudi-k</td>
                                                                    <td class="text-end"  style="width: 25%;">{{ tabs.Generales.Valores.PropuestaAKFecha | date:'yyyy-MM-dd' }}</td>
                                                                    <td class="input-column" style="width: 30%;">
                                                                        <gk-cf-pdf-viewer url="{{url}}{{tabs.Generales.Valores.PropuestaAK}}" [file]="file" [display]=display [clase]=clase [noviewer]=1></gk-cf-pdf-viewer>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                }@else{
                                                    <div class="col-12">
                                                        <p class="fw-bold text-center" @fadeInOut>De momento no existe Propuesta creada desde el sistema</p>
                                                    </div>
                                                    <div class="col-3"></div>
                                                    <div class="col-6"></div>
                                                }
                                                <div class="col-3"></div>
                                            }
                                            @if(formAudik.get('CobranzaAkCk')!.value == "1"){
                                                <div class="col-3"></div>
                                                <div class="col-6">
                                                    <table class="table table-striped table-bordered row-border hover nowrap" @fadeInOut>
                                                        <thead>
                                                            <tr class="ttdblue">
                                                                <th class="sorting_disabled text-center" tabindex="0" rowspan="1" colspan="1">SERVICIO</th>
                                                                @if(ocultarPrecioFijoEstu || !ocultarPrecioFijoEstu && !ocultarTablaPrecio && !ocultarMensualidadPrecio){
                                                                    <th class="sorting_disabled text-center" tabindex="0" rowspan="1" colspan="1" >PRECIO</th>
                                                                }@else if(ocultarTablaPrecio){
                                                                    <th class="sorting_disabled text-center" tabindex="0" rowspan="1" colspan="1" >TABLA</th>
                                                                }@else if(ocultarMensualidadPrecio){
                                                                    <th class="sorting_disabled text-center" tabindex="0" rowspan="1" colspan="1" >MENSUALIDAD ESTUDIOS ILIMITADOS</th>
                                                                }
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr class="text-center">
                                                                <td>Precio 1er servicio</td>
                                                                <td class="input-column">
                                                                    <div class="iconAlign"> 
                                                                        <mat-icon class="me-2 iconInpC">attach_money</mat-icon>
                                                                        <input type="number" class="form-control readonly-input" formControlName="CostoAudikTabla" placeholder="0.00" readonly>
                                                                    </div>        
                                                                </td>
                                                            </tr>
                                                            <tr class="text-center">
                                                                <td>Precio 2do servicio</td>
                                                                <td class="input-column">
                                                                    <div class="iconAlign">
                                                                        <mat-icon class="me-2 iconInpC">attach_money</mat-icon>
                                                                        <input type="number" class="form-control"  placeholder="0.00" formControlName="CostoCardik">
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="col-3"></div>
                                            }
                                        </div>
        
                                    </div>
                                </div>
                            </ng-template>
                        </mat-step>
                    }
                </form>

                <form [formGroup]="formCardik">
                    @if(form.get('CardikBit')!.value && form.get('BitProspecto')!.value == false){
                        <mat-step state="abajo" label="Cárdi-k">
                            <ng-template matStepContent>
                                <div class="row">
                                    <div class="col-lg-12">
        
                                        <div class="row mt-4 align-items-center">
                                            <div class="col-auto">
                                                <mat-icon class="text-primary" >info</mat-icon>
                                            </div>
                                            <div class="col">
                                                <div class="d-flex flex-column">
                                                    <h3 class="mb-0">GENERALES</h3>
                                                    <small class="text-primary">Altura, Precio</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mt-4 ms-2">
                                            <label class="col-2 control-label">Altura del equipo:</label>
                                            <div class="col-md-5">
                                                <span class="form-text text-danger my-1" *ngIf="isValidFieldCardik('AlturaEquipoCardik')">
                                                    <b>Este campo es requerido</b>
                                                </span>
                                                <div class="input-group mb-3">
                                                    <input type="number"  class="form-control" formControlName="AlturaEquipoCardik"  >
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mt-4 ms-2">
                                            <div class="col-3"></div>
                                            <div class="col-6">
                                                <table class="table table-striped table-bordered row-border hover nowrap" @fadeInOut>
                                                    <thead>
                                                        <tr class="ttdblue">
                                                            <th class="sorting_disabled text-center" tabindex="0" rowspan="1" colspan="1">SOLUCION</th>
                                                            <th class="sorting_disabled text-center" tabindex="0" rowspan="1" colspan="1">MENSUALIDAD ESTUDIOS ILIMITADOS</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr class="text-center">
                                                            <td>Áudi-k</td>
                                                            <td class="input-column">
                                                                <div class="iconAlign"> 
                                                                    <mat-icon class="me-2 iconInpC">attach_money</mat-icon>
                                                                    <input type="number" class="form-control readonly-input" formControlName="CostoAudik" readonly>
                                                                </div>        
                                                            </td>
                                                        </tr>
                                                        <tr class="text-center">
                                                            <td>Cárdi-k</td>
                                                            <td class="input-column">
                                                                <div class="iconAlign">
                                                                    <mat-icon class="me-2 iconInpC">attach_money</mat-icon>
                                                                    <input type="number" class="form-control" formControlName="CostoCardik" (input)="updateValueCostCardik()">
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr class="text-center">
                                                            <td>Total</td>
                                                            <td class="input-column">
                                                                <div class="iconAlign"> 
                                                                    <mat-icon class="me-2 iconInpC">attach_money</mat-icon>
                                                                    <input type="number" class="form-control readonly-input" formControlName="TotalCostos" readonly>
                                                                </div> 
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="col-3"></div>
                                        </div>
                                        <div class="row mt-4 ms-2">
                                            <label class="control-label col-3" >Se anexa certificado a Resultado</label>
                                            <div class="col-1" >
                                                <input type="radio" [value]="true" class="form-check-input" formControlName="IncluyeCertificadoCardik"    >  {{  radios.SiNo[0].display }}
                                                <input type="radio" [value]="false" class="form-check-input ms-2" formControlName="IncluyeCertificadoCardik"  >  {{  radios.SiNo[1].display }}
                                            </div>
                                            <div class="col-2"></div>
                                            <label class="col-4 control-label" >Mostrar encabezado</label>
                                            <div class="col-1 mt-1">
                                                <input type="radio" [value]="true" class="form-check-input"  formControlName="MuestraEncabezadoResultadoCardik"  >  {{  radios.SiNo[0].display }}
                                                <input type="radio" [value]="false" class="form-check-input ms-2" formControlName="MuestraEncabezadoResultadoCardik"  >  {{  radios.SiNo[1].display }}
                                            </div>
                                            
                                        </div>
                                        <div class="row mt-4 ms-2">
                                            <label class="control-label col-2" >Moneda:</label>
                                            <div class="col-4" >
                                                <select  class="form-control"  formControlName="TipoMonedaIdAudik">
                                                    <option value="">Seleccione un tipo</option>
                                                    <option
                                                            *ngFor="let moneda of combos.TipoMoneda"
                                                            [value]="moneda.value"
                                                            >
                                                            {{ moneda.display }}
                                                    </option>
                                                </select>
                                            </div>
                                            <label class="col-4 control-label">Logo del cliente en Resultado</label>
                                            <div class="col-1" >
                                                <input type="radio" [value]="true" class="form-check-input"  formControlName="LogoClienteResultado"  >  {{  radios.SiNo[0].display }}
                                                <input type="radio" [value]="false" class="form-check-input ms-2" formControlName="LogoClienteResultado"  >  {{  radios.SiNo[1].display }}
                                            </div>
                                            
                                        </div>
                                        <div class="row mt-4 align-items-center" @fadeInOut>
                                            <div class="col-auto" @fadeInOut>
                                                <mat-icon class="text-primary" >personal_injury</mat-icon>
                                            </div>
                                            <div class="col">
                                                <div class="d-flex flex-column">
                                                    <h3 class="mb-0">Prueba confirmatoria (ECO)</h3>
                                                    <small class="text-primary">Datos del Doctor y Precio</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mt-4 ms-2">
                                            <label class="col-3 control-label">Precio Ck incluye Prueba confirmatoria (ECO)</label>
                                            <div class="col-1">
                                                <input type="radio" [value]="true" class="form-check-input" formControlName="BitEcoCardik"  >  {{  radios.SiNo[0].display }}
                                                <input type="radio" [value]="false" class="form-check-input ms-2" formControlName="BitEcoCardik" >  {{  radios.SiNo[1].display }}
                                            </div>
                                        </div>
                                        @if(formCardik.get('BitEcoCardik')!.value == true){
                                            <div class="row mt-4 ms-2">
                                                <label class="col-3 control-label">Usa equipo de Doctor?</label>
                                                <div class="col-1" >
                                                    <input type="radio" [value]="true" class="form-check-input" formControlName="BitEquipoDrCardik"  >  {{  radios.SiNo[0].display }}
                                                    <input type="radio" [value]="false" class="form-check-input ms-2" formControlName="BitEquipoDrCardik" >  {{  radios.SiNo[1].display }}
                                                </div>
                                                <div class="col-2"></div>
                                                @if (formCardik.get('BitEquipoDrCardik')!.value == true || formCardik.get('BitEquipoDrCardik')!.value == false ) {
                                                    @if (formCardik.get('BitEquipoDrCardik')!.value == true) {
                                                        <label class="col-2 control-label">Costo total por ECO:</label>
                                                    }@else {
                                                        <label class="col-2 control-label">Costo del Dr por realizar ECO:</label>
                                                    }
                                                    <div class="col-3">
                                                        <div class="iconAlign">
                                                            <mat-icon class="iconInpC">attach_money</mat-icon>
                                                            <input type="number" class="form-control" formControlName="CostoEcoCardik">
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            
                                                <div class="row mt-4 ms-2">
                                                    <label class="col-3 control-label">Se paga uso de Equipo al Hospital</label>
                                                    <div class="col-1">
                                                        <input type="radio" [value]="true" class="form-check-input" formControlName="BitPagoHospitalEquipoCardik"  >  {{  radios.SiNo[0].display }}
                                                        <input type="radio" [value]="false" class="form-check-input ms-2"  formControlName="BitPagoHospitalEquipoCardik"  >  {{  radios.SiNo[1].display }}
                                                    </div>
                                                    <div class="col-2"></div>
                                                    @if(formCardik.get('BitPagoHospitalEquipoCardik')!.value == true){
                                                        <label class="col-2 control-label">Proveedor:</label>
                                                        <div class="col-3">
                                                            <span class="form-text text-danger my-1" *ngIf="isValidFieldCardik('ProveedorEquipoCardik')">
                                                                <b>Este campo es requerido</b>
                                                            </span>
                                                            <select  class="form-control" formControlName="ProveedorEquipoCardik">
                                                                <option value="">Seleccione un tipo</option>
                                                                <option
                                                                        *ngFor="let provedor of combos.Proveedores"
                                                                        [value]="provedor.value"
                                                                        >
                                                                        {{ provedor.display }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    }
                                                </div>
                                                @if(formCardik.get('BitPagoHospitalEquipoCardik')!.value == true){
                                                    <div class="row mt-4 ms-2">
                                                        <label class="col-3 control-label">Pago por el uso del Equipo:</label>
                                                        <div class="col-3">
                                                            <span class="form-text text-danger my-1" *ngIf="isValidFieldCardik('PagoHospitalEquipoCardik')">
                                                                <b>Este campo es requerido</b>
                                                            </span>
                                                            <div class="iconAlign">
                                                                <mat-icon class="iconInpC">attach_money</mat-icon>
                                                                <input type="number" class="form-control" formControlName="PagoHospitalEquipoCardik">
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div class="row mt-4 ms-2">
                                                    <label class="col-12 control-label">Doctores:</label>
                                                    <div class="col-1">
                                                        <button type="button" class="btn btn-success" (click)="agregarDoctor()"><mat-icon style="width: 23px; height: 19px;" >add</mat-icon>Agregar</button>
                                                    </div>
                                                </div>
                                                <div class="row mt-4 ms-2">
                                                    @if(tabs.Generales.Tablas.DoctoresECO > 0){
                                                        <table class="table table-striped table-bordered row-border hover nowrap" @fadeInOut>
                                                            <thead>
                                                                <tr class="ttdblue">
                                                                    <th class="sorting_disabled text-center" tabindex="0" rowspan="1" colspan="1">USUARIO</th>
                                                                    <th class="sorting_disabled text-start" tabindex="0" rowspan="1" colspan="1">NOMBRE DEL DOCTOR</th>
                                                                    <th class="sorting_disabled text-start" tabindex="0" rowspan="1" colspan="1">CORREO</th>
                                                                    <th class="sorting_disabled text-start" tabindex="0" rowspan="1" colspan="1">CELULAR</th>
                                                                    <th class="sorting_disabled text-start" tabindex="0" rowspan="1" colspan="1">ESPECIALIDADES</th>
                                                                    <th class="sorting_disabled text-center" tabindex="0" rowspan="1" colspan="1">FIRMA</th>
                                                                    <th class="sorting_disabled text-center" tabindex="0" rowspan="1" colspan="1">HERRAMIENTAS</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                @for(doctor of tabs.Generales.Tablas.DoctoresECO; track $index){
                                                                    <tr>
                                                                        <td class="text-center">{{ doctor.Usuario }}</td>
                                                                        <td class="text-center"> {{ doctor.Nombre  }}</td>
                                                                        <td class="text-center">{{ doctor.Correo}}  </td>
                                                                        <td class="text-center">{{ doctor.Celular }}</td>
                                                                        <td class="text-center">{{ doctor.Especialidad1 }} {{ doctor.Especialidad2 }} {{ doctor.Especialidad3 }}</td>
                                                                        @if(doctor.NombreFirma){
                                                                            <td class="text-center text-primary" (click)="showImageDoctor(doctor.Id)"><strong style="cursor: pointer;">{{ doctor.NombreFirma }}</strong></td>
                                                                        }@else{
                                                                            <td class="text-center">No hay firma disponible</td>
                                                                        }
                                                                        <td class="text-center">
                                                                            <mat-icon class="colorBtnIcon text-center" (click)="agregarDoctor(doctor)">edit_square</mat-icon>
                                                                        </td>  
                                                                    </tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    }
                                                </div>    
                                            
                                        }
                                        <div class="row mt-4 align-items-center">
                                            <div class="col-auto" @fadeInOut>
                                                <mat-icon class="text-primary" >folder_open</mat-icon>
                                            </div>
                                            <div class="col">
                                                <div class="d-flex flex-column">
                                                <h3 class="mb-0">PROPUESTA</h3>
                                                <small class="text-primary">Propuesta activa del cliente, descarga de archivo y visualización</small>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="row mt-4">
                                            @if(tabs.Generales.Valores.PropuestaCK){
                                                <div class="col-3"></div>
                                                    <div class="col-6">
                                                        <table class="table table-striped table-bordered row-border hover nowrap" @fadeInOut>
                                                            <thead>
                                                                <tr class="ttdblue">
                                                                    <th class="sorting_disabled text-start" tabindex="0" rowspan="1" colspan="1" >PROPUESTA ACTUAL</th>
                                                                    <th class="sorting_disabled text-end" tabindex="0" rowspan="1" colspan="1">FECHA ACTIVACIÓN</th>
                                                                    <th class="sorting_disabled text-center" tabindex="0" rowspan="1" colspan="1">DESCARGAR</th>
                                                            </thead>
                                                            <tbody>
                                                                <tr class="text-center">
                                                                    <td class="fw-bold text-start" style="width: 45%;">Propuesta Cárdi-k</td>
                                                                    <td class="text-end"  style="width: 25%;">{{ tabs.Generales.Valores.PropuestaCKFecha | date:'yyyy-MM-dd' }}</td>
                                                                    <td class="input-column" style="width: 30%;">
                                                                        <gk-cf-pdf-viewer url="{{url}}{{tabs.Generales.Valores.PropuestaCK}}" [file]="file" [display]=display [clase]=clase [noviewer]=1></gk-cf-pdf-viewer>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                            }@else{
                                                <div class="col-3"></div>
                                                <label class="col-6 control-label text-center">De momento no existe Propuesta creada desde el Sistema</label>
                                            }
                                        </div>
                                        
                                    </div>
                                </div>
                            </ng-template>
                        </mat-step>
                    }
                </form>

                <form [formGroup]="formDx">
                    @if(form.get('DxBit')!.value && form.get('BitProspecto')!.value == false){
                        <mat-step state="abajo" label="Dx">
                            <ng-template matStepContent>
                                <div class="row">
                                    <div class="col-lg-12">
        
                                        <div class="row mt-4 align-items-center">
                                            <div class="col-auto">
                                                <mat-icon class="text-primary" >info</mat-icon>
                                            </div>
                                            <div class="col">
                                                <div class="d-flex flex-column">
                                                    <h3 class="mb-0">GENERALES</h3>
                                                    <small class="text-primary">Resultado y tabla de precios</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mt-4 ms-2">
                                            <label class="col-sm-2 control-label" align="left">Se libera el resultado antes del pago?</label>
                                            <div class="col-md-1 mt-1" align="left">
                                                <input type="radio" [value]="true" formControlName="LiberaResultadoDx" class="form-check-input"  > {{  radios.SiNo[0].display }}
                                            </div>
                                            <div class="col-md-1 mt-1" align="left">
                                                <input type="radio" [value]="false" formControlName="LiberaResultadoDx" class="form-check-input" > {{  radios.SiNo[1].display }}
                                            </div>
                                            <div class="col-md-3"></div>
                                            <label class="col-sm-2 control-label" align="left">Tipo de precio:</label>
                                            <div class="col-md-3" align="left">
                                                <span class="form-text text-danger my-1" *ngIf="isValidFieldDx('TablaDx')">
                                                    <b>Este campo es requerido</b>
                                                </span>
                                                <select  class="form-control" formControlName="TablaDx" >
                                                    <option value="">Seleccione un tipo</option>
                                                    <option
                                                            *ngFor="let tabla of combos.DxTablaPrecio"
                                                            [value]="tabla.value"
                                                            >
                                                            {{ tabla.display }}
                                                    </option>
                                                </select>
                                                
                                                <button type="button" class="btn btn-outline-primary mt-2 ms-2" (click)="tablaPrecios(formDx.get('TablaDx')!.value)" [disabled]="!formDx.get('TablaDx')!.value">Ver tabla</button>     
                                            </div>
                                        </div>
        
        
                                    </div>
                                </div>
                            </ng-template>
                        </mat-step>
                    }
                </form>

                <form [formGroup]="formFact">
                    <mat-step state="abajo" label="Facturación">
                        <ng-template matStepContent>
                            <div class="row">
                                <div class="col-lg-12">
    
                                    <div class="row mt-4 ms-2">
                                        <label class="col-2 control-label" >Fecha de corte:</label>
                                        <div class="col-2 text-end" >
                                            <input type="radio" [value]="true"  formControlName="FechaCorte" class="form-check-input" > Fin de mes
                                            <input type="radio" [value]="false" formControlName="FechaCorte"  class="form-check-input ms-2"> Aleatorio
                                        </div>
                                        <div class="col-1"></div>
                                        <label class="col-4 control-label text-end" >Se requiere Resultado traducido para incluirlo en la factura?</label>
                                        <div class="col-2 text-center" >
                                            <input type="radio" [value]="true" formControlName="RemisionResultadoTraducido"  class="form-check-input" > {{  radios.SiNo[0].display }}
                                            <input type="radio" [value]="false" formControlName="RemisionResultadoTraducido" class="form-check-input ms-2"  > {{  radios.SiNo[1].display }}
                                        </div>
                                    </div>
                                    <div class="row mt-4 align-items-center">
                                        <div class="col-auto">
                                            <mat-icon class="text-primary" >settings</mat-icon>
                                        </div>
                                        <div class="col">
                                            <div class="d-flex flex-column">
                                                <h3 class="mb-0">PLANTILLAS</h3>
                                                <small class="text-primary">Formato de plantilla por solución</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-4 ms-2">
                                        <label class="col-2 control-label" >Plantillas:</label>
                                        <div class="col-3 text-center" >
                                            <input type="checkbox" formControlName="bitPlanTk"  class="form-check-input"   > Támi-k
                                            <input type="checkbox" formControlName="bitPlanAk" class="form-check-input ms-2"   > Áudi-k
                                            <input type="checkbox" formControlName="bitPlanDx"  class="form-check-input ms-2"  > Dx
                                            <input type="checkbox" formControlName="bitPlanCk" class="form-check-input ms-2"   > Cárdi-k
                                        </div>
                                        <div class="col-3">
                                            <button class="btn btn-outline-info" type="button" (click)="savePlantillas()" >Actualizar plantillas</button>
                                        </div>
                                    </div>
    
    
                                    
                                </div>
                            </div>
                        </ng-template>
                    </mat-step>
                </form>

            </mat-stepper>


        </div>
    </div>

