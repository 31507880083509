<mat-dialog-content class="mat-typography">
    <h2 mat-dialog-title>Tabla de precios</h2>

    <h2>{{ precios.display }}</h2>
    <table class="table table-striped table-bordered row-border hover nowrap">
        <thead>
            <tr class="ttdblue">
                <th class="sorting_disabled text-center" tabindex="0" rowspan="1" colspan="1">CANTIDAD</th>
                <th class="sorting_disabled text-end" tabindex="0" rowspan="1" colspan="1">PRECIO</th>
            </tr>
        </thead>
        <tbody>
            @for(registro of precios.registros; track $index){
                <tr >
                    <td class="text-center">{{ registro.Cantidad }}</td>
                    <td class="text-end">{{ registro.Costo }}</td>
                </tr>
            }
        </tbody>
    </table>

    <mat-divider class="mt-4"></mat-divider>
    <mat-dialog-actions align="end" class="gris py-4">
        <button [mat-dialog-close] class="btn btn-secondary mx-2">
            <mat-icon class="tamIconB">keyboard_arrow_left</mat-icon>
            Regresar
        </button>
    </mat-dialog-actions>
    
    
    
    
</mat-dialog-content>
