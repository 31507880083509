import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    { path: '', pathMatch: 'full', redirectTo: '/auth/login' },
    {
        path: 'home',
        loadChildren: () => import('modules/home/home-routing.module').then(m => m.HomeRoutingModule),
    },
    {
        path: 'dashboard',
        loadChildren: () => import('modules/dashboard/dashboard-routing.module').then(m => m.DashboardRoutingModule),
    },
    {
        path: 'customers',
        loadChildren: () => import('modules/customers/customers-routing.module').then(m => m.CustomersRoutingModule),
    },
    {
        path: 'auth',
        loadChildren: () => import('modules/auth/auth-routing.module').then(m => m.AuthRoutingModule),
    },
    {
        path: 'config',
        loadChildren: () => import('modules/config/config-routing.module').then(m => m.ConfigRoutingModule),
    },
    {
        path: 'operations/tami-k',
        loadChildren: () => import('modules/operations-tk/operations-tk-routing.module').then(m => m.OperationsTkRoutingModule),
    },
    {
        path: 'operations/audi-k',
        loadChildren: () => import('modules/operations-ak/operations-ak-routing.module').then(m => m.OperationsAkRoutingModule),
    },
    {
        path: 'operations/dx',
        loadChildren: () => import('modules/operations-dx/operations-dx-routing.module').then(m => m.OperationsDxRoutingModule),
    },
    {
        path: 'patient',
        loadChildren: () => import('modules/patient/patient-routing.module').then(m => m.PatientRoutingModule),
    },
    {
        path: 'marketing',
        loadChildren: () => import('modules/marketing/marketing-routing.module').then(m => m.MarketingRoutingModule),
    },
    {
        path: 'medical-area',
        loadChildren: () => import('modules/medical-area/medical-area-routing.module').then(m => m.MedicalAreaRoutingModule),
    },
    {
        path: 'itsystem',
        loadChildren: () => import('modules/itsystem/itsystem-routing.module').then(m => m.ItsystemRoutingModule),
    },
    {
        path: 'administration',
        loadChildren: () => import('modules/administration/administration-routing.module').then(m => m.AdministrationRoutingModule),
    },

    {
        path: 'error',
        loadChildren: () => import('modules/error/error-routing.module').then(m => m.ErrorRoutingModule),
    },
    {
        path: 'tables',
        loadChildren: () => import('modules/tables/tables-routing.module').then(m => m.TablesRoutingModule),
    },

    {
        path: 'version',
        loadChildren: () => import('modules/utility/utility-routing.module').then(m => m.UtilityRoutingModule),
    },
    {
        path: 'system',
        loadChildren: () => import('modules/system/system-routing.module').then(m => m.SystemRoutingModule),
    },
    {
        path: 'catalogs',
        loadChildren: () => import('modules/catalogs/catalogs-routing.module').then(m => m.CatalogsRoutingModule),
    },
    {
        path: 'reports',
        loadChildren: () => import('modules/reports/reports-routing.module').then(m => m.ReportsRoutingModule),
    },
    {
        path: 'employes',
        loadChildren: () => import('modules/employes/employes-routing.module').then(m => m.EmployesRoutingModule),
    },
    {
        path: 'medical-records',
        loadChildren: () => import('modules/medical-records/medical-records-routing.module').then(m => m.MedicalRecordsRoutingModule),
    },
    {
        path: 'operations-menu',
        loadChildren: () => import('modules/operations-menu/operations-menu-routing.module').then(m => m.OperationsMenuRoutingModule),
    },
    {
        path: '**',
        pathMatch: 'full',
        loadChildren: () => import('modules/error/error-routing.module').then(m => m.ErrorRoutingModule),
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule],
})
export class AppRoutingModule {}
