<ul nz-menu nzMode="horizontal" class="top-nav-menu divoculto">
    <ng-container *ngFor="let menu of menuItems">
        <li *ngIf="menu.Hijos.length === 0" class="menu-item" nz-menu-item nzMatchRouter>
            <a class="normal" [routerLink]="menu.Pantalla ? '/' + menu.Pantalla : ''">{{ menu.Menu }}</a>
        </li>

        <!-- Level 1 -->
        <li *ngIf="menu.Hijos.length > 0" class="menu-item " nz-submenu nzMenuClassName="top-nav-sub-menu" [nzTitle]="titleTpl">
            <ul class="subw1">
                <ng-container *ngFor="let menu of menu.Hijos">
                    <li *ngIf="menu.Hijos.length === 0" class="menu-item thin" nz-menu-item nzMatchRouter>
                        <a class="normal" [routerLink]="menu.Pantalla ? '/' + menu.Pantalla : ''">{{ menu.Menu }}</a>
                    </li>

                    <!-- Level 2 -->
                    <li *ngIf="menu.Hijos.length > 0" class="menu-item thin" nz-submenu nzMenuClassName="top-nav-sub-menu" [nzTitle]="menu.Menu">
                        <ul>
                            <ng-container *ngFor="let menu of menu.Hijos">
                                <li *ngIf="menu.Hijos.length === 0" class="menu-item thin" nz-menu-item nzMatchRouter>
                                    <a class="normal" [routerLink]="menu.Pantalla ? '/' + menu.Pantalla : ''">{{ menu.Menu }}</a>
                                </li>

                                <!-- Level 3 -->
                                <li *ngIf="menu.Hijos.length > 0" class="menu-item thin" nz-submenu nzMenuClassName="top-nav-sub-menu" [nzTitle]="menu.Menu">
                                    <ul>
                                        <ng-container *ngFor="let menu of menu.Hijos">
                                            <li *ngIf="menu.Hijos.length === 0" class="menu-item thin" nz-menu-item nzMatchRouter>
                                                <a class="normal" [routerLink]="menu.Pantalla ? '/' + menu.Pantalla : ''">{{ menu.Menu }}</a>
                                            </li>

                                            <!-- Level 4 -->
                                            <li *ngIf="menu.Hijos.length > 0" class="menu-item thin" nz-submenu nzMenuClassName="top-nav-sub-menu" [nzTitle]="menu.Menu">
                                                <ul>
                                                    <ng-container *ngFor="let menu of menu.Hijos">
                                                        <li *ngIf="menu.Hijos.length === 0" class="menu-item thin" nz-menu-item nzMatchRouter>
                                                            <a class="normal" [routerLink]="menu.Pantalla ? '/' + menu.Pantalla : ''">{{ menu.Menu }}</a>
                                                        </li>

                                                        <!-- Level 5 - Final -->
                                                    </ng-container>
                                                </ul>
                                            </li>
                                        </ng-container>
                                    </ul>
                                </li>
                            </ng-container>
                        </ul>
                    </li>

                    <ng-template #titleTpl>
                        <div *ngIf="!menu.Pantalla" class="menu-item__title-container">
                            <ng-container>
                                <span>{{ menu.Menu }}</span>
                                <i class="menu-item__title-icon" nz-icon nzType="down"></i>
                            </ng-container>
                        </div>

                        <div *ngIf="menu.Pantalla" class="menu-item__title-container">
                            <a [routerLink]="'/' + menu.Pantalla" routerLinkActive="active">
                                <span>{{ menu.Menu }}</span>
                                <i class="menu-item__title-icon" nz-icon nzType="down"></i>
                            </a>
                        </div>
                    </ng-template>
                </ng-container>
            </ul>
        </li>

        <ng-template #titleTpl>
            <div *ngIf="!menu.Pantalla" class="menu-item__title-container">
                <ng-container>
                    <span>{{ menu.Menu }}</span>
                    <i class="menu-item__title-icon" nz-icon nzType="down"></i>
                </ng-container>
            </div>

            <div *ngIf="menu.Pantalla" class="menu-item__title-container">
                <a [routerLink]="'/' + menu.Pantalla" routerLinkActive="active">
                    <span>{{ menu.Menu }}</span>
                    <i class="menu-item__title-icon" nz-icon nzType="down"></i>
                </a>
            </div>
        </ng-template>
    </ng-container>
</ul>