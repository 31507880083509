<h3 mat-dialog-title class="text-center">{{ pruebaEspecial.datosPrueba.NombrePrueba }}<br>{{ pruebaEspecial.datosPrueba.NombreLaboratorio }}</h3>
<mat-dialog-content class="mat-typography">

    <div class="full-width-element full-height-element">
        <div class="gris paddin10">
            <div class="container">

                <div class="mt-1">
                    <mat-accordion>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Datos de la Prueba
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <strong>Código de prueba:</strong> {{pruebaEspecial.datosPrueba.CodigoPrueba}}
                            <br>
                            @if (pruebaEspecial.datosPrueba.CodigoPruebaLab) {
                                <strong>Código del laboratorio:</strong> {{pruebaEspecial.datosPrueba.CodigoPruebaLab}}
                                <br>
                            }
                            <strong>Tiempo aproximado de entrega*:</strong> {{pruebaEspecial.datosPrueba.TiempoEntregaGk}}
                            <br>
                            *Nota: El tiempo puede extenderse dependiendo de diferentes circunstancias. El resultado será entregado tan pronto como los ensayos del laboratorio concluyan satisfactoriamente.
                            <br>
                            <strong>Enfermedad:</strong> {{pruebaEspecial.datosPrueba.Enfermedad}}
                            <br>
                            <strong>Gen:</strong> {{pruebaEspecial.datosPrueba.Gen}}
                            <br>
                            <strong>Locus:</strong> {{pruebaEspecial.datosPrueba.Locus}}
                            <br>
                            <strong>Cromosoma:</strong> {{pruebaEspecial.datosPrueba.Cromosoma}}
                            <br>
                            <strong>Tipo de Metodología:</strong> {{pruebaEspecial.datosPrueba.TipoMetodologia}}
                            <br>
                            <strong>Metodología:</strong> {{pruebaEspecial.datosPrueba.Metodologia}}
                            <br>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>

                <div class="custom-margin-top">
                    <gk-cf-pdf-viewer [url]="url" [file]="file" [display]=display [clase]=clase [noviewer]=1></gk-cf-pdf-viewer>
                </div>
            </div>
        </div>
    </div>

    @if(pruebaEspecial.MuestraTransportacion){
        <div class="row">
            <div class="col-12">
                <div class="mt-5">
                    <label>Muestra y transportación:</label>
                </div>
                <table class="table-striped" style="width: 100%;">
                    <thead>
                        <tr class="ttdblue">
                            <td   class="text-left txtitgk">Tipo de muestra</td>
                            <td   class="text-left txtitgk">Cant. recién nacidos</td>
                            <td   class="text-right txtitgk">Cant. niños</td>
                            <td   class="text-right txtitgk">Cant. adultos</td>
                            <td   class="text-right txtitgk">Almacenamiento</td>
                            <td   class="text-right txtitgk">Transportación</td>
                            <td   class="text-right txtitgk">Solicitar prueba</td>
                        </tr>
                    </thead>
                    <tbody>
                        @for (muestra of pruebaEspecial.MuestraTransportacion; track $index) {
                            <tr>
                                <td class="text-start">
                                    {{ muestra.Tipo_Muestra }}
                                </td>
                                <td class="text-center">
                                    {{ muestra.Cantidad_Bebes }}
                                </td>
                                <td class="text-center">
                                    {{ muestra.Cantidad_Ninios }}
                                </td>
                                <td class="text-start">
                                    {{ muestra.Cantidad_Adultos }}
                                </td>
                                <td class="text-start">
                                    {{ muestra.Tipo_Almacenamiento }}
                                </td>
                                <td class="text-center">
                                    {{ muestra.Tipo_Almacenamiento_Transportacion }}
                                </td>
                                <td class="text-center">
                                    <button class="btn btn-success" (click)="solicitarPrueba(muestra, selectedOptions[$index])">Solicitar prueba</button>
                                    <nz-select
                                        nzAllowClear
                                        nzPlaceHolder="Seleccionar"
                                        class="mt-3"
                                        [(ngModel)]="selectedOptions[$index]"
                                        (ngModelChange)="onSelectChange($event, $index)"
                                        >
                                        @for(prueba of muestra.CatConsulta; track $index){
                                            <nz-option
                                                [nzValue]="prueba.value"
                                                [nzLabel]="prueba.display" 
                                                >
                                            </nz-option>
                                        }
                                    </nz-select>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="7">
                                    <em>Recomendación especial:</em>
                                    {{ muestra.Recomendaciones_Especiales }}
                                </td>
                            </tr>
                        }@empty{
                            <tr>
                                <td colspan="7">
                                    Por el momento no hay muestras disponibles.
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
    
            </div>
    
        </div>
    }



    <div class="row">

        <div class="col-12">
            <strong>Precio: </strong> {{pruebaEspecial.Precio }}
            <br>
            <strong>Observaciones médicas:</strong>
            <br>
            <p [innerHTML]="pruebaEspecial.ObservacionesMedicas"></p>
            <br>
            <strong>Comentarios Internos:</strong>
            <br>
            <p [innerHTML]="pruebaEspecial.ComentariosInternos"></p>
        </div>

    </div>

    <div class="full-width-element full-height-element">
        <mat-divider></mat-divider>
        <mat-dialog-actions align="end" class="gris py-4">
            <button type="button" [mat-dialog-close] class="btn btn-secondary mx-2 mt-3">
                Cerrar
            </button>
        </mat-dialog-actions>
    </div>
</mat-dialog-content>