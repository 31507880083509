import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppCommonService } from '@common/services';
import { ReportsService } from '@modules/reports/services';

@Component({
  selector: 'gk-agregar-coment-medico',
  templateUrl: './agregar-coment-medico.component.html',
  styleUrl: './agregar-coment-medico.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeInOut', [
        transition(':enter', [
            style({ opacity: 0 }),
            animate('500ms ease-in', style({ opacity: 1 }))
        ]),
        transition(':leave', [
            style({ opacity: 1 }),
            animate('500ms ease-out', style({ opacity: 0 }))
        ])
    ]),
  ]
})
export class AgregarComentMedicoComponent implements OnInit{

  //? Variables
  nombreCuenta: string;
  hayFecha: boolean;
  pacienteId: number ;
  id: string;

  //* Variable para indicar si se debe actualizar la información en el componente principal.
  @Output() actualizarDatosEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  //? Servicios
  private dialog = inject(MatDialog);
  private fb = inject(FormBuilder);
  private appCommon= inject(AppCommonService);
  private reportsService = inject(ReportsService);

  //? Configuracion del editor de texto
  editorModules = {
    toolbar: [
      // ['bold', 'italic'],                               // Formatos de texto
      [{ 'header': [] }],      // Tamaño de texto
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      // [{ 'size': ['small', false, 'large', 'huge'] }],
      [{ 'align': [] }],                       // Alineación
      // ['image', 'link']                        // Insertar imágenes y links
    ]
  };

  formAsignarComentario: FormGroup = this.fb.group({
    coment:     [,  Validators.required] ,
    correo:     [ , Validators.required],
    VerCliente: [ , Validators.required],
    tipocomentario: [],
    pacienteid: [],
    filtroid:   [],
    accion:     ['Save'],
    gral: [],
    califFinal: [],
    fecharecordatorio: []
  });

  isValidFieldAsignarComentario( field: string ): boolean | null {
    return this.formAsignarComentario.controls[field].errors 
    && this.formAsignarComentario.controls[field].touched;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AgregarComentMedicoComponent>,
    private cdr: ChangeDetectorRef
  ){
    if(this.data && this.data.Prueba && this.data.Pacienteid ){
      this.nombreCuenta = this.data.Prueba;
      this.formAsignarComentario.patchValue({
        pacienteid: this.data.Pacienteid,
        tipocomentario: this.data.Tipocomentario,
        filtroid: this.data.Filtroid,
        gral: this.data.Gral,
        califFinal: this.data.CalifFinal
      });
      this.hayFecha = this.data.Fecha || false;
    }
  }

  ngOnInit(): void {
    if (this.hayFecha) {
      this.formAsignarComentario.get('fecharecordatorio')?.setValidators(Validators.required);
      this.formAsignarComentario.get('fecharecordatorio')?.updateValueAndValidity();
    }
  }

  //? Eliminar las etiquetas y dejar solo el texto plano
  stripTags(html: string): string {
    let text = html.replace(/<\/?p>/g, ' ');
    const doc = new DOMParser().parseFromString(text, 'text/html');
    return doc.body.textContent || '';
  }
  

  guardarComentario(){
    if(this.formAsignarComentario.invalid){
      this.appCommon.showMsg('Asignacion invalida', `Favor de rellenar los campos faltantes`);
      this.formAsignarComentario.markAllAsTouched();
      return;
    }
    this.appCommon.spinner(true);
    const formValue = { ...this.formAsignarComentario.value }
    if(!this.hayFecha){
      delete formValue.fecharecordatorio;
    }
    // if (formValue.coment) {
    //   formValue.coment = this.stripTags(formValue.coment);
    // }
    formValue.pacienteid =  formValue.pacienteid.toString();
    this.reportsService.saveCommentMedico(formValue).subscribe({
      next: ( resp ) =>{
        this.appCommon.spinner(false);
        this.appCommon.showMsg(`Operacion Exitosa`, `${resp.Mensaje}`, () => {
          this.actualizarDatosEmitter.emit(true);
          this.dialogRef.close();
        });
      },
      error: err => {
        this.appCommon.spinner(false);
        this.appCommon.showMsg(`Operacion fallida`, `A ocurrido un error al querer realizar la operacion`);
        console.log('err', err);
      }
    })
  }


}
