import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
//import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
//import { NumeroALetraPipe } from '@common/pipes/numero-a-letras.pipe';
import { NumeroALetraPipe } from '../app-common/pipes/numero-a-letras.pipe';
//import { IconsModule } from '@modules/icons/icons.module';
import { IconsModule } from '../../modules/icons/icons.module';
// import { GkFormsModule } from '@modules/shared/genomik-forms/genomik-forms.module';
import { GkFormsModule } from '../../modules/shared/genomik-forms/genomik-forms.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import * as appCommonComponents from './components';
import * as appCommonContainers from './containers';
import { safeUrlPipe } from './pipes/safeurl.pipe';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { FiltroPipe } from './pipes/filtro.pipe';
import { TranslocoModule } from '@ngneat/transloco';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NgmaterialModule } from '@modules/shared/ngmaterial/ngmaterial.module';
import { QuillModule } from 'ngx-quill';
import { DataTablesModule } from 'angular-datatables';

//const thirdParty = [IconsModule, NgbModule, CKEditorModule];
const thirdParty = [IconsModule, NgbModule];
const pipes = [safeUrlPipe, NumeroALetraPipe];

@NgModule({
    // imports: [TranslocoModule,CommonModule, RouterModule, GkFormsModule,NzDatePickerModule, ...thirdParty],
    imports: [CommonModule, RouterModule, DataTablesModule ,GkFormsModule,NzDatePickerModule, TranslocoModule, NzModalModule, ...thirdParty, NgmaterialModule,  QuillModule.forRoot()],
    declarations: [...appCommonContainers.containers, ...appCommonComponents.components, ...pipes, FiltroPipe],
    exports: [...appCommonContainers.containers, ...appCommonComponents.components, ...thirdParty, ...pipes],
})
export class AppCommonModule {}
