<ul class="top-nav-user" *transloco="let t; read: 'navigation.topnavuser'">
    <li *ngIf="user$ | async as user" class="nav-item dropdown dropdown-user no-caret" ngbDropdown display="dynamic" placement="bottom-right">
        <a id="userDropdown" class="nav-link dropdown-toggle white" ngbDropdownToggle data-cy="userMenu" role="button" aria-haspopup="true" aria-expanded="false">
            <img src="https://admin-genomik-ng-assets.s3.amazonaws.com/profile_images/photo_2021-04-25_02-59-26.jpg" alt="Avatar" style="width: 30px; border-radius: 50%" /> {{ user.name }}
        </a>

        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu aria-labelledby="dropdownUser">
            <h6 class="dropdown-header">
                <div class="dropdown-user-details">
                    <div class="dropdown-user-details-name">{{ user.name }}</div>
                    <div class="dropdown-user-details-email">{{ user.email }}</div>
                </div>
            </h6>

            <div class="dropdown-divider"></div>

            <a class="dropdown-item" routerLink="/home">{{ t('home') }}</a>

            <a class="dropdown-item" routerLink="/config/profile">{{ t('miperfil') }}</a>

            <div class="dropdown-divider"></div>

            <a class="dropdown-item" (click)="logout()">{{ t('logout') }}</a>
        </div>
    </li>
</ul>