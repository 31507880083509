import { Injectable, inject } from '@angular/core';
import { Observable, of, observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserService } from '@modules/auth/services';

import { environment } from 'environments/environment';
import type { CombosSolucionMultiple, Default, GetCasoOperativo, ChangeParams, GetChangeParamsResponse, GetCombosSolucionMultiple, GetCortesia, GetFiltrosResponse, GetRevisaRepeticion, GetTablaRevisaRepeticion, PfNoCobrar, PostAddPaso, PostCambioSolucion, PostCambioStatus, PostCasoOperativo, PostDelAutoPaso, PostrepeticionCobrar, PostrepeticionNoLlega, PostRevisaRepeticion, postSolucionMultiple, tablaCambiosSolucionMultiple, PostPfR2, GetR2SearchResponse, GetR2Search, PostPfR2Save, GetModifyPfSearch, GetModifyPfSearchResponse, DelQuitarAlertaTrad, GetResponseDelQuitarAlertaTrad, GetResponseMsg, Msg } from '../models';
import { map } from 'rxjs/operators'
import { TablaRevisaRepe } from '../models/getRevisaRepeticion.model';


@Injectable({providedIn:'root'})
export class TamikService {
    API_URL_COMMOMS = environment.api.commons;
    API_URL_COMMOMS2 = environment.api.commons2;
    API_URL_OPERATIONSTOOLS = environment.api.operationsTools;

    private http = inject(HttpClient);
    private userService = inject(UserService);

    async informacionFiltro(requestBody) {
        return new Promise((resolve, reject) => {
console.log("**llegue a back**",requestBody)
            this.http
                .post<Default>(`${this.API_URL_OPERATIONSTOOLS}/operationstools/informacionFiltro`, requestBody)
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    // console.info('RESPONSE: ', resp.data);
                    resolve(resp.data);
                });
        });
    }

    async pfNoCobro(requestBody) {
        return new Promise((resolve, reject) => {
            this.http
                .put<Default>(`${this.API_URL_OPERATIONSTOOLS}/operationstools/pf/noCobro`, requestBody )
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    // console.info('RESPONSE: ', resp.data);
                    resolve(resp.data);
                });
        });
    }


    async pteEnvGK() {
        return new Promise((resolve, reject) => {
            this.http
                .get<Default>(`${this.API_URL_OPERATIONSTOOLS}/operationstools/pteEnvGK`)
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    // console.info('RESPONSE: ', resp.data);
                    resolve(resp.data);
                });
        });
    }

    searchPf(id: number){

        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_OPERATIONSTOOLS}/operationstools/pfnocobrar/${id}`;
        return this.http.get(  url, { headers } )
    }

    pfNocobrar(pf: PfNoCobrar){

        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_OPERATIONSTOOLS}/operationstools/pfnocobrar/save`;

        return this.http.post<PfNoCobrar>(  url, pf, { headers } )
    }

    searchRepeticionCobrar(id: number){

        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_OPERATIONSTOOLS}/operationstools/repeticioncobrar/${id}`;
        return this.http.get(  url, { headers } )
    }


    repeticionCobrarSave(repeticion: PostrepeticionCobrar){

        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_OPERATIONSTOOLS}/operationstools/repeticioncobrar/save`;

        return this.http.post<PostrepeticionCobrar>(  url, repeticion, { headers } )
    }

    searchRepeticionNoLlega(id: number){

        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_OPERATIONSTOOLS}/operationstools/repeticion-no-llega/${id}`;
        return this.http.get(  url, { headers } )
    }


    repeticionNoLlegaSave(repeticion: PostrepeticionNoLlega){

        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_OPERATIONSTOOLS}/operationstools/repeticion-no-llega/save`;

        return this.http.post<PostrepeticionNoLlega>(  url, repeticion, { headers } )
    }

    searchCortesia(id: number){

        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_OPERATIONSTOOLS}/operationstools/cortesia/${id}`;
        return this.http.get<GetCortesia>(  url, { headers } )
    }

    cortesiaSave(cortesia: PfNoCobrar){

        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_OPERATIONSTOOLS}/operationstools/cortesia/save`;

        return this.http.post<PfNoCobrar>(  url, cortesia, { headers } )
    }

    searchConfirmatoria(id: number){

        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_OPERATIONSTOOLS}/operationstools/confirmatoria/${id}`;
        return this.http.get(  url, { headers } )
    }

    pruebaConfirmatoriaSave(cortesia: PfNoCobrar){

        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_OPERATIONSTOOLS}/operationstools/confirmatoria/save`;

        return this.http.post<PfNoCobrar>(  url, cortesia, { headers } )
    }

    searchComentarioGral(id: number){

        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_OPERATIONSTOOLS}/operationstools/comment-gral/${id}`;
        return this.http.get(  url, { headers } )
    }

    comentarioGralSave(comentario: PfNoCobrar){

        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_OPERATIONSTOOLS}/operationstools/comment-gral/save`;

        return this.http.post<PfNoCobrar>(  url, comentario, { headers } )
    }


    searchCambiaSolu(id: number){

        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_OPERATIONSTOOLS}/operationstools/cambio-solucion/${id}`;
        return this.http.get(  url, { headers } )
    }

    CambiaSoluSave(solucion: PostCambioSolucion){

        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_OPERATIONSTOOLS}/operationstools/cambio-solucion/save`;

        return this.http.post<PfNoCobrar>(  url, solucion, { headers } )
    }

    searchCambioStat(id: number){

        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_OPERATIONSTOOLS}/operationstools/cambio-estatus/${id}`;
        return this.http.get(  url, { headers } )
    }

    CambioStatSave(solucion: PostCambioStatus){

        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_OPERATIONSTOOLS}/operationstools/cambio-estatus/save`;

        return this.http.post<PfNoCobrar>(  url, solucion, { headers } )
    }

    searchCasoOperativo(id: number){

        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_OPERATIONSTOOLS}/operationstools/caso-operativo-medico/${id}`;
        return this.http.get<GetCasoOperativo>(  url, { headers } )
    }

    casoOperativoSave(caso: PostCasoOperativo){

        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_OPERATIONSTOOLS}/operationstools/caso-operativo-medico/save`;

        return this.http.post<PfNoCobrar>(  url, caso, { headers } )
    }

    searchRevisaRepeticion(id: number){

        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_OPERATIONSTOOLS}/operationstools/revisa-repeticion/${id}`;
        return this.http.get<GetRevisaRepeticion>(  url, { headers } )
    }

    revisaRepeticionSave(repeticion: PostRevisaRepeticion){

        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_OPERATIONSTOOLS}/operationstools/revisa-repeticion/save`;

        return this.http.post<PostRevisaRepeticion>(  url, repeticion, { headers } )
    }


    getTablaInicial(): Observable<TablaRevisaRepe[]>{

        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_OPERATIONSTOOLS}/operationstools/revisa-repeticion/get`;
        return this.http.get<GetTablaRevisaRepeticion>(  url, { headers } ).pipe(
            map(resp => resp.data)
        )
    }

    searchAddPaso(id: number){

        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_OPERATIONSTOOLS}/operationstools/add-paso/${id}`;
        return this.http.get(  url, { headers } )
    }

    agregarPasoSave(paso: PostRevisaRepeticion){

        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_OPERATIONSTOOLS}/operationstools/add-paso/save`;

        return this.http.post<PostAddPaso>(  url, paso, { headers } )
    }

    getCombosSoluMultiple(): Observable<CombosSolucionMultiple>{

        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_OPERATIONSTOOLS}/operationstools/cambio-solucion-multiple/get`;
        return this.http.get<GetCombosSolucionMultiple>(  url, { headers } ).pipe(
            map(resp => resp.data)
        )
    }

    tablaCambiosSolucionMultiple(cambio: tablaCambiosSolucionMultiple): Observable<GetFiltrosResponse>{

        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_OPERATIONSTOOLS}/operationstools/cambio-solucion-multiple`;

        return this.http.post<GetFiltrosResponse>(  url, cambio, { headers } )
    }

    solucionMultipleSave(cambio: postSolucionMultiple){

        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_OPERATIONSTOOLS}/operationstools/cambio-solucion-multiple/save`;

        return this.http.post<PostAddPaso>(  url, cambio, { headers } )
    }

    searchDelPasoAuto(id: number){

        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_OPERATIONSTOOLS}/operationstools/del-paso-auto/${id}`;
        return this.http.get(  url, { headers } )
    }

    delPasoAutoSave(paso: PostDelAutoPaso){

        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_OPERATIONSTOOLS}/operationstools/del-paso-auto/save`;

        return this.http.post(  url, paso, { headers } )
    }

    searchDelPaso(id: number){

        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_OPERATIONSTOOLS}/operationstools/del-paso/${id}`;
        return this.http.get(  url, { headers } )
    }

    delPasoSave(paso: PostDelAutoPaso){

        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_OPERATIONSTOOLS}/operationstools/del-paso/save`;

        return this.http.post(  url, paso, { headers } )
    }

    searchChangeParams(id: number): Observable<ChangeParams>{

        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_OPERATIONSTOOLS}/operationstools/change-param/${id}`;
        return this.http.get<GetChangeParamsResponse>(  url, { headers } ).pipe(
            map( resp => resp.data )
        )
    }

    changeParamsSave(parametros: any){

        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_OPERATIONSTOOLS}/operationstools/change-param/save`;

        return this.http.post(  url, parametros, { headers } )
    }

    searchBajaPf(baja: any){

        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_OPERATIONSTOOLS}/operationstools/baja-pf`;

        return this.http.post(  url, baja, { headers } )
    }

    validarPfDelUsuario(id: number){

        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_OPERATIONSTOOLS}/operationstools/baja-pf-user/${id}`;
        return this.http.get(  url, { headers } )
    }

    procesarBajaPf(baja: any){

        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_OPERATIONSTOOLS}/operationstools/baja-pf/del`;

        return this.http.post(  url, baja, { headers } )
    }

    procesarBajaTemporalPf(id: number){

        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_OPERATIONSTOOLS}/operationstools/baja-pf-temp/${id}`;

        return this.http.get(  url, { headers } )
    }

    searchCodigoR2(r2: PostPfR2): Observable<GetR2Search>{

        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_OPERATIONSTOOLS}/operationstools/pf-r2/search`;

        return this.http.post<GetR2SearchResponse>(  url, r2, { headers } ).pipe(
            map(resp => resp.data)
        )
    }

    filtroR2Save(codigos: PostPfR2Save){

        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_OPERATIONSTOOLS}/operationstools/pf-r2/save`;

        return this.http.post(url, codigos, { headers })

    }

    searchModifyPf(id: number): Observable<GetModifyPfSearch>{

        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_OPERATIONSTOOLS}/operationstools/modif-pf/${id}`;
        return this.http.get<GetModifyPfSearchResponse>(  url, { headers } ).pipe(
            map( resp => resp.data )
        )
    }

    async saveModifyPf(requestBody) {
        return new Promise((resolve, reject) => {
            this.http
                .post<Default>(`${this.API_URL_OPERATIONSTOOLS}/operationstools/modif-pf/save`, requestBody )
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    // console.info('RESPONSE: ', resp.data);
                    resolve(resp.data);
                });
        });
    }

    getBuscarAlertTrad(id: number | string): Observable<DelQuitarAlertaTrad>{

        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_OPERATIONSTOOLS}/operationstools/QuitaAlertaTraduccion/buscar/${id}`;
        return this.http.get<GetResponseDelQuitarAlertaTrad>(  url, { headers } ).pipe(
            map( resp => resp.data )
        )
    }

    getQuitarAlertTrad(id: number | string): Observable<Msg>{

        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.userService.AccessToken}`,
        });

        const url= `${this.API_URL_OPERATIONSTOOLS}/operationstools/QuitaAlertaTraduccion/eliminar/${id}`;
        return this.http.get<GetResponseMsg>(  url, { headers } ).pipe(
            map( resp => resp.data )
        )
    }


}

