<mat-dialog-content class="mat-typography">
    @if(!validacionesCliente){
        <h2 mat-dialog-title class="fw-normal">Historial de Validación</h2>
        <h4 class="my-4 text-center">Por el momento no existe ninguna validacion.</h4>
    }@else{
        <h2 mat-dialog-title class="fw-normal">Historial de Validación (Últimas {{this.validacionesCliente.length}}) <b>{{encabezado}}</b></h2>
    
        <div class="row">
            <div class="col-2"></div>
            <div class="col-8">
                <table class="table table-striped table-bordered row-border hover nowrap">
                    <thead>
                        <tr class="ttdblue">
                            <th class="sorting_disabled text-center" tabindex="0" rowspan="1" colspan="1">NO.</th>
                            <th class="sorting_disabled text-start" tabindex="0" rowspan="1" colspan="1">TIPO</th>
                            <th class="sorting_disabled text-start" tabindex="0" rowspan="1" colspan="1">CREACIÓN</th>
                            <th class="sorting_disabled text-start" tabindex="0" rowspan="1" colspan="1">VALIDACIÓN 1</th>
                            <th class="sorting_disabled text-start" tabindex="0" rowspan="1" colspan="1">VALIDACIÓN 2</th>
                            <th class="sorting_disabled text-center" tabindex="0" rowspan="1" colspan="1">ESTATUS</th>
                        </tr>
                    </thead>
                    <tbody>
                        @for(validacion of validacionesCliente; track $index){
                            <tr>
                                <td class="text-center txtlinka text-primary" (click)="cargarTabla(validacion)">  {{ validacion.id }}  </td>
                                <td class="text-start">{{ validacion.tipo }}</td>
                                <td class="text-start">{{ validacion.N0 }}</td>
                                <td class="text-start">{{ validacion.N1 }}</td>
                                <td class="text-start">{{ validacion.N2 }}</td>
                                <td class="text-start" [ngClass]="{'fw-bolder': validacion.estatus.includes('En proceso')}">{{ validacion.estatus }}</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    }

    @if(validacionCliente){
        <div class="row my-2 gris" [@fadeInOut]>
            <div class="position-relative">
                <div class="position-absolute top-0 end-0">
                    <mat-icon class="text-primary tamIcon" (click)="cerrarTabla()">close</mat-icon>
                </div>
            </div>
            <div class="col-12 mt-5 mb-1 text-center">
                <h3>Detalle de observaciones <b>{{ validacionCliente.tipo }} #{{validacionCliente.id}}</b></h3>
            </div>
            <div class="col-2">
    
            </div>
            <div class="col-8">
                <table class="table table-striped table-bordered row-border hover nowrap mb-4">
                    <thead>
                        <tr class="ttdblue">
                            <th class="sorting_disabled text-start" tabindex="0" rowspan="1" colspan="1">VALIDACIÓN CREACIÓN ({{ validacionCliente.N0 }})</th>
                            <th class="sorting_disabled text-start" tabindex="0" rowspan="1" colspan="1">VALIDACIÓN 1 ({{ validacionCliente.N1 }})</th>
                            <th class="sorting_disabled text-start" tabindex="0" rowspan="1" colspan="1">VALIDACIÓN 2 ({{ validacionCliente.N2 }})</th>
                        </tr>
                    </thead>
                    <tbody>
                            <tr >
                                @if(validacionCliente.Comentarios_Cambios_1){
                                    <td class="text-start">{{ validacionCliente.Comentarios_Cambios_1 }}</td>
                                }@else{
                                    <td class="text-start">No cuenta con ningun comentario</td>
                                }
                                @if(!validacionCliente.tipo.includes('Cambio Rápido')){
                                    @if(validacionCliente.Comentarios_Cambios_2){
                                        <td class="text-start">{{ validacionCliente.Comentarios_Cambios_2 }}</td>
                                    }@else{
                                        <td class="text-start">No cuenta con ningún comentario</td>
                                    }
                            
                                    @if(validacionCliente.Comentarios_Cambios_3){
                                        <td class="text-start">{{ validacionCliente.Comentarios_Cambios_3 }}</td>
                                    }@else{
                                        <td class="text-start">No cuenta con ningún comentario</td>
                                    }
                                }@else{
                                    <td class="text-start"></td>
                                    <td class="text-start"></td>
                                }
                            </tr>
                        
                    </tbody>
                </table>
            </div>
        </div>
    }
    
    <mat-divider class="mt-4"></mat-divider>
    <mat-dialog-actions align="end" class="gris py-4">
        <button [mat-dialog-close] class="btn btn-secondary mx-2">
            Cerrar
        </button>
    </mat-dialog-actions>

</mat-dialog-content>