import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AppCommonService } from '@common/services';

@Component({
    selector: 'sb-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['loader.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent {
    showLoader$ = this.appCommonService.showLoader$;

    constructor(private appCommonService: AppCommonService) {}
}
