<label class="btn-primary" style="display:none;" ngbButtonLabel>
    <input #gralCLick type="checkbox" ngbButton > ENE
</label>
<form [formGroup]="formFact">
    <div class="mt-4">
        <button type="button" class="btn btn-outline-primary" (click)="cargarDatos()">
            Cargar datos de recoleccion
            <mat-icon class="iconTam">autorenew</mat-icon>
        </button>
    </div>
    <div class="row mt-4 ms-2">
        <label class="col-sm-2 control-label" align="left">Facturado por:</label>
        <div class="col-md-3" align="left">
            <div class="input-group mb-3">
                <select class="form-control" formControlName="FacturacionEmpresa" >
                    <option value="">Seleccione una entidad</option>
                    <option *ngFor="let facturado of combos.Empresa"
                        [value]="facturado.value">
                        {{ facturado.display }}
                </option>
                </select>
            </div>
        </div>
        <div class="col-md-1"></div>
        <label class="col-sm-2 control-label" align="left">Tipo de cliente para T.C.:</label>
        <div class="col-md-3" align="left">
            <span class="form-text text-danger my-1" *ngIf="isValidFieldFacturacion('FacturacionTipoClienteTC')">
                <b>Este campo es requerido</b>
            </span>
            <div class="input-group mb-3">
                <select class="form-control" formControlName="FacturacionTipoClienteTC" >
                    <option value="">Seleccione una tipo</option>
                    <option *ngFor="let cliente of combos.TipoClienteTC"
                        [value]="cliente.value">
                        {{ cliente.display }}
                    </option>
                </select>
            </div>
        </div>
    </div>
    <div class="row mt-4 ms-2">
        <label class="col-sm-2 control-label" align="left">Nombre:</label>
        <div class="col-md-9" align="left">
            <span class="form-text text-danger my-1" *ngIf="isValidFieldFacturacion('FacturacionNombre')">
                <b>Este campo es requerido</b>
            </span>
            <div class="input-group mb-3">
                <input type="text"  class="form-control" formControlName="FacturacionNombre" >
            </div>
        </div>
    </div>
    <div class="row mt-4 ms-2">
        <label class="col-sm-2 control-label" align="left">Direccion:</label>
        <div class="col-md-9" align="left">
            <span class="form-text text-danger my-1" *ngIf="isValidFieldFacturacion('FacturacionDireccion')">
                <b>Este campo es requerido</b>
            </span>
            <div class="input-group mb-3">
                <input type="text"  class="form-control" formControlName="FacturacionDireccion">
            </div>
        </div>
    </div>
    <div class="row mt-4 ms-2">
        <label class="col-sm-2 control-label" align="left">Colonia:</label>
        <div class="col-md-9" align="left">
            <span class="form-text text-danger my-1" *ngIf="isValidFieldFacturacion('FacturacionColonia')">
                <b>Este campo es requerido</b>
            </span>
            <div class="input-group mb-3">
                <input type="text"  class="form-control" formControlName="FacturacionColonia" >
            </div>
        </div>
    </div>
    <div class="row mt-4 ms-2">
        <label class="col-sm-2 control-label" align="left">RFC:</label>
        <div class="col-md-9" align="left">
            <span class="form-text text-danger my-1" *ngIf="isValidFieldFacturacion('FacturacionRFC')">
                <b>Este campo es requerido</b>
            </span>
            <div class="input-group mb-3">
                <input type="text"  class="form-control" formControlName="FacturacionRFC" >
            </div>
        </div>
    </div>
    <div class="row mt-4 ms-2">
        <label class="col-sm-2 control-label" align="left">Ciudad:</label>
        <div class="col-md-3" align="left">
            <span class="form-text text-danger my-1" *ngIf="isValidFieldFacturacion('FacturacionCiudad')">
                <b>Este campo es requerido</b>
            </span>
            <div class="input-group mb-3">
                <select class="form-control" formControlName="FacturacionCiudad" >
                    <option value="">Seleccione una entidad</option>
                    <option *ngFor="let ciudad of combos.CiudadEstado"
                        [value]="ciudad.Ciudad_id">
                        {{ ciudad.Ciudad }}
                </option>
                </select>
            </div>
        </div>
        <div class="col-md-1"></div>
        <label class="col-sm-2 control-label" align="left">C.P:</label>
        <div class="col-md-3" align="left">
            <span class="form-text text-danger my-1" *ngIf="isValidFieldFacturacion('FacturacionCodigoPostal')">
                <b>Este campo es requerido</b>
            </span>
            <div class="input-group mb-3">
                <input type="text"  class="form-control" formControlName="FacturacionCodigoPostal" >
            </div>
        </div>
    </div>
    <div class="row mt-4 ms-2">
        <label class="col-sm-2 control-label" align="left">Días de Pago:</label>
        <div class="col-md-9" align="left">
            <span class="form-text text-danger my-1" *ngIf="isValidFieldFacturacion('FacturacionDiasPago')">
                <b>Este campo es requerido</b>
            </span>
            <div class="input-group mb-3">
                <input type="text"  class="form-control" formControlName="FacturacionDiasPago" >
            </div>
        </div>
    </div>
    <div class="row mt-4 ms-2">
        <label class="col-sm-2 control-label" align="left">Comentarios de Cobranza:</label>
        <div class="col-md-9" align="left">
            <span class="form-text text-danger my-1" *ngIf="isValidFieldFacturacion('FacturacionComentarios')">
                <b>Este campo es requerido</b>
            </span>
            <div class="input-group mb-3">
                <input type="text"  class="form-control" formControlName="FacturacionComentarios" >
            </div>
        </div>
    </div>
    <div class="row mt-4 ms-2">
        <label class="col-sm-3 control-label" align="left">Datos facturación electronica</label>
    </div>
    <div class="row mt-4 ms-2">
        <label class="col-sm-2 control-label" align="left">Version CFDI:</label>
        <div class="col-md-3" align="left">
            <span class="form-text text-danger my-1" *ngIf="isValidFieldFacturacion('FacturacionVersionCFDI')">
                <b>Este campo es requerido</b>
            </span>
            <div class="input-group mb-3">
                <select class="form-control" formControlName="FacturacionVersionCFDI" >
                    <option value="">Seleccione una entidad</option>
                    <option *ngFor="let version of combos.VersionCFDI"
                        [value]="version.value">
                        {{ version.display }}
                </option>
                </select>
            </div>
        </div>
        <div class="col-md-3" align="left"></div>
    </div>
    <div class="row mt-4 ms-2">
        <label class="col-sm-2 control-label" align="left">Regimen Fiscal:</label>
        <div class="col-md-3" align="left">
            <div class="input-group mb-3">
                <select class="form-control" formControlName="FacturacionRegimenFiscal" >
                    <option value="">Seleccione una entidad</option>
                    <option *ngFor="let regimen of combos.RegimenFiscal"
                        [value]="regimen.value">
                        {{ regimen.display }}
                </option>
                </select>
            </div>
        </div>
        <label class="col-sm-2 control-label" align="left">Uso CFDI:</label>
        <div class="col-md-3" align="left">
            <div class="input-group mb-3">
                <select class="form-control" formControlName="FacturacionUsoCFDI" >
                    <option value="">Seleccione una entidad</option>
                    <option *ngFor="let uso of combos.UsoCFDI"
                        [value]="uso.value">
                        {{ uso.display }}
                </option>
                </select>
            </div>
        </div>        
    </div>


    <div class="row mt-4 ms-2">
        <label class="col-sm-2 control-label" align="left">Forma de pago:</label>
        <div class="col-md-3" align="left">
            <div class="input-group mb-3">
                <select class="form-control" formControlName="FacturacionFormaPago" >
                    <option value="">Seleccione una entidad</option>
                    <option *ngFor="let pago of combos.FormaPago"
                        [value]="pago.value">
                        {{ pago.display }}
                </option>
                </select>
            </div>
        </div>

        <label class="col-sm-2 control-label" align="left">Concepto factura:</label>
        <div class="col-md-3" align="left">
            <div class="input-group mb-3">
                <select class="form-control" formControlName="FacturacionConceptoFactura" >
                    <option value="">Seleccione una entidad</option>
                    <option *ngFor="let concepto of combos.ConceptoFactura"
                        [value]="concepto.value">
                        {{ concepto.display }}
                </option>
                </select>
            </div>
        </div>

    </div>


    <div class="row mt-4 ms-2">
        <label class="col-sm-2 control-label" align="left">Metodo de Pago:</label>
        <div class="col-md-3" align="left">
            <div class="input-group mb-3">
                <select class="form-control" formControlName="FacturacionMetodoPago" >
                    <option value="">Seleccione una entidad</option>
                    <option *ngFor="let metodo of combos.MetodoPago"
                        [value]="metodo.value">
                        {{ metodo.display }}
                </option>
                </select>
            </div>
        </div>

        <label class="col-sm-2 control-label" align="left">Numero de cuenta:</label>
        <div class="col-md-3" align="left">
            <span class="form-text text-danger my-1" *ngIf="isValidFieldFacturacion('FacturacionNumeroCuenta')">
                <b>Este campo es requerido</b>
            </span>
            <div class="input-group mb-3">
                <input type="text"  class="form-control" formControlName="FacturacionNumeroCuenta" >
            </div>
        </div>

    </div>


    <div class="row mt-4 ms-2">
        <label class="col-sm-2 control-label" align="left">RFC Banco:</label>
        <div class="col-md-3" align="left">
            <div class="input-group mb-3">
                <input type="text"  class="form-control" formControlName="FacturacionRFCBanco" >
            </div>
        </div>
        <label class="col-sm-2 control-label" align="left">Número Cuenta Banco:</label>
        <div class="col-md-3" align="left">
            <div class="input-group mb-3">
                <input type="text"  class="form-control" formControlName="FacturacionNumeroCuentaBanco" >
            </div>
        </div>
    </div>



</form>