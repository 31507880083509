import { Injectable } from '@angular/core';
import { GkToastType } from '@common/models';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ToastService {
    private toastsSubject = new BehaviorSubject<any[]>([]);
    toasts$ = this.toastsSubject.asObservable();

    show(textOrTpl: string, type: GkToastType) {
        const opts = {
            type,
            classname: `bg-${type} text-light`,
            delay: 3000,
            positionClass: '',
        };

        const toasts = [...this.toastsSubject.value, { textOrTpl, ...opts }];
        this.toastsSubject.next(toasts);
    }

    remove(toast: any) {
        const toasts = this.toastsSubject.value.filter(t => t !== toast);
        this.toastsSubject.next(toasts);
    }

    clear() {
        this.toastsSubject.next([]);
    }
}
