import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Output, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CustomersService } from '@modules/customers/services';
import { ConfirmacionMComponent } from '../confirmacion-m/confirmacion-m.component';
import { AppCommonService } from '@common/services';

@Component({
  selector: 'gk-paquete-mater-m',
  templateUrl: './paquete-mater-m.component.html',
  styleUrl: './paquete-mater-m.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaqueteMaterMComponent {

  //? combos recibidos
  combos: any;
  paquete: any;
  institucionid: any;
  InstitucionPaqueteId = "";
  @Output() paqueteAdded = new EventEmitter<any>();

  private fb = inject(FormBuilder);
  private customersService= inject(CustomersService);
  private dialogRef= inject(MatDialogRef<PaqueteMaterMComponent>);
  private dialog = inject(MatDialog);
  public appCommon= inject(AppCommonService);

  public formMaternidad: FormGroup = this.fb.group({
    TipoPaqueteId: [,Validators.required],
    Dias: [,Validators.required],
    Costo: [,Validators.required],
    Comentarios: [],
  })

  //ToDos campos invalidos
  isValidFieldMaternidad( field: string ): boolean | null{
    return this.formMaternidad.controls[field].errors 
    && this.formMaternidad.controls[field].touched;
  }


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,){
    if(this.data && this.data.combos  && this.data.paquete && this.data.institucionid ){
      this.combos = this.data.combos;
      this.paquete = this.data.paquete;
      this.institucionid = this.data.institucionid;
    }
    if(this.data && this.data.combos && this.data.institucionid  && !this.data.paquete ){
      this.combos = this.data.combos;
      this.institucionid = this.data.institucionid;
    }
  }
  ngOnInit(): void {
    if(this.paquete){
      this.formMaternidad.patchValue({
        TipoPaqueteId: this.paquete.Tipo_Paquete_Id,
        Dias: this.paquete.Dias,
        Costo: this.paquete.Costo,
        Comentarios: this.paquete.Comentarios
      })
      this.InstitucionPaqueteId = this.paquete.Institucion_Paquete_Id;
    }
    
  }

  saveMaternidad(){
    if(this.formMaternidad.invalid){
      const dialogRefM = this.dialog.open(ConfirmacionMComponent, {
        data: { titulo: `formulario invalido`, 
                mensaje: `Favor de terminar el formulario`,
                Noconfirm: true
            }
      })
      dialogRefM.afterOpened().subscribe(() => {
          setTimeout(() => {
              dialogRefM.close();
          }, 3000);
      });
      this.formMaternidad.markAllAsTouched();
      console.log(this.formMaternidad.value);
      return
    }

    this.appCommon.spinner(true);

    //? Desestructuro el objeto 
    const formValue = { ...this.formMaternidad.value }

    formValue.institucionid = this.institucionid;
    formValue.InstitucionPaqueteId = this.InstitucionPaqueteId;
    formValue.TipoPaqueteId = parseInt(formValue.TipoPaqueteId);

    this.customersService.addPaqueteMater(formValue)
      .subscribe(
        {
          next: (resp: any) => {
            this.appCommon.spinner(false);
            const dialogRefM = this.dialog.open(ConfirmacionMComponent, {
              data: { titulo: `Paquete agregado`, 
                      mensaje: `Se ha guardado el paquete`,
                      Noconfirm: true
                  }
            })
            dialogRefM.afterOpened().subscribe(() => {
              setTimeout(() => {
                dialogRefM.close();
              }, 3000);
            });
            console.log(resp);
            this.paqueteAdded.emit(resp.data);
            this.dialogRef.close();
          },
          error: err => {
            this.appCommon.spinner(false);
            const dialogRefM = this.dialog.open(ConfirmacionMComponent, {
              data: { titulo: `No se pudo guardar la informacion`, 
                      mensaje: `A ocurrido un error al querer guardar el paquete de Maternidad`,
                      Noconfirm: true
                  }
            })

            dialogRefM.afterOpened().subscribe(() => {
              setTimeout(() => {
                dialogRefM.close();
              }, 3000);
            });
            console.log('error', err);
          }
        }
      )
    
  };

}
