import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppCommonService } from '@common/services';
import { ComentariosMed } from '@modules/medical-records/models';
import { ReportsService } from '@modules/reports/services';

@Component({
  selector: 'gk-view-comments-m',
  templateUrl: './view-comments-m.component.html',
  styleUrl: './view-comments-m.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewCommentsMComponent {

  //? Varibles
  titulo: string;
  subTitulo: string;
  tipoComentario: number;
  comentarios: ComentariosMed[]; 

  //* Variable para indicar si se debe actualizar la información en el componente principal.
  @Output() actualizarDatosEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  //? Servicios
  reportsService = inject(ReportsService);
  appCommonService = inject(AppCommonService);

  mostrarAcciones(): boolean {
    return this.comentarios.some(comentario => 
      comentario.btnActivar === 1 || comentario.btnInactivar === 1
    );
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ViewCommentsMComponent>,
    private cdr: ChangeDetectorRef
  ){
    if(this.data && this.data.Titulo && this.data.Comentarios && this.data.TipoComentario ){
      this.titulo         = this.data.Titulo;
      this.tipoComentario = this.data.TipoComentario;
      this.subTitulo      = this.data.SubTitulo   || null;
      this.comentarios    = this.data.Comentarios || [];
    }
  }

  // cambiarComentario(comentario: ComentariosMed ){
  //   this.appCommonService.spinner(true);

  //   let verCliente: boolean | number = comentario.ver_cliente

  //   //* Si verCliente es un booleano, lo convierto a número (true => 1, false => 2)
  //   if (typeof verCliente === 'boolean') {
  //     verCliente = verCliente ? 1 : 2;
  //   }

  //   //* Si es 1, lo cambio a 2, y si es 2, lo cambio a 1
  //   verCliente = verCliente === 1 ? 2 : 1;

  //   const comentarioSend: any = {
  //     vercliente: verCliente,
  //     CommId: this.pacienteId,
  //     tipo_coment: this.tipoComentario,
  //   }

  //   // this.reportsService.activaODesactivaComments(comentario).subscribe({
  //   //   next: (resp) => {
  //   //     this.appCommonService.spinner(false);
  //   //     console.log(resp);
  //   //   },
  //   //   error: err => {
  //   //     this.appCommonService.spinner(false);
  //   //     console.log('err', err);
  //   //   }
  //   // })

  //   console.log(comentario);
  //   this.appCommonService.spinner(false);
    
  // }

}
