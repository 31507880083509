<div id="comentariosOp" class="well" *ngIf="DataComentarios.Paciente_id!=''">
    <label class="label-control">Comentarios:</label>
    <br>
    <table class="no-border black" width="100%">
        <tbody class="no-border-y">
            <tr>
                <td align="left" width="60%"><label>&nbsp;&nbsp;Operativos</label></td>
                <td align="center" width="20%">
                    <a (click)="showModal(1,DataComentarios.Calificacion)" class='txtlinka cursor-default'>
                        {{DataComentarios.Operativos.length}}
                    </a>
                </td>
                <td align="center" width="20%">
                    <a (click)="showModal(1,0,'Add',0)" class='txtlinka cursor-default' placement="top" ngbTooltip="Agregar comentario operativo">
                        <fa-icon [icon]="['fas', 'comments']" size="2x"></fa-icon>
                    </a>
                </td>
            </tr>
            <tr>
                <td align="left" class="btop"><label>&nbsp;&nbsp;Médicos</label></td>
                <td align="center" class="btop">
                    <a (click)="showModal(2,0,'Ver',1)" class='txtlinka cursor-default'>
                        {{DataComentarios.Medicos.length}}
                    </a>
                </td>
                <td align="center" class="btop">
                    <a (click)="showModal(1,DataComentarios.Calificacion,'Add',1)" class='txtlinka' placement="top" ngbTooltip="Agregar comentario médico">
                        <fa-icon [icon]="['fas', 'comments']" size="2x"></fa-icon>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<!---Modificacion para agregar comentario al PF--->
<div id="comentariosOpAux" class="well" *ngIf="DataComentarios.Paciente_id==''">
    <label class="label-control">Comentarios: <br>**Sin captura, se guardara hasta validar**</label>
    <br>
    <table class="no-border">
        <tbody class="no-border-y">
            <tr>
                <td align="left" width="60%"><label>&nbsp;&nbsp;Operativos</label></td>
                <td align="center" class="tdComentariosOpAux" width="20%">
                    <a (click)="showModal(1,DataComentarios.Calificacion)" class='txtlinka cursor-default'>
                        {{DataComentarios.Operativos.length}}
                    </a>
                </td>
                <td align="center" width="20%">
                    <a (click)="showModal(1,DataComentarios.califComentarios,'Add',0)" class='txtlinka' placement="top" ngbTooltip="Agregar comentario operativo">
                        <fa-icon [icon]="['fas', 'comments']" size="2x"></fa-icon>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
</div>        

<nz-modal [(nzVisible)]="isVisibleModalComent" [nzTitle]="modalTitle" [nzContent]="modalContent" [nzFooter]="modalFooter" (nzOnCancel)="handleCancel()" [nzStyle]="{ width: '60%' }">
    <ng-template #modalTitle>
        <span *ngIf="modalAccion=='Ver' && tipocomentario == 2">Comentarios Médicos</span>
        <span *ngIf="modalAccion=='Ver' && tipocomentario == 1">Comentarios Operativos</span>
        <span *ngIf="modalAccion=='Add' && tipocomentario == 2 && gral != 1">Agregar Comentario Médico</span>
        <span *ngIf="modalAccion=='Add' && tipocomentario == 1 &&  gral == 1">Agregar Comentario Médico General</span>
        <span *ngIf="modalAccion=='Add' && tipocomentario == 1 &&  gral == 0">Agregar Comentario Operativo</span>
    </ng-template>
    <form nz-form gkForm novalidate  nzLayout="vertical" #f="ngForm" [formGroup]="formComments">
        <ng-template #modalContent>
            <ng-container *ngIf="modalAccion=='Ver'">
                <table width="100%" class="table-striped" align="center">
                    <thead>
                    <tr class="ttdblue">
                        <td width="15%" class="text-left txtitgk">Usuario</td>
                        <td width="45%" class="text-left txtitgk">Comentario</td>
                        <td width="20%" class="text-right txtitgk">Fecha Comentario</td>
                        <td width="20%" class="text-center txtitgk" *ngIf="tipocomentario == 2 || DataComentarios.Paciente_id!=''">Visible por el cliente</td>
                    </tr>
                    </thead>
                    <tbody *ngIf="DataComentariosTabla.length > 0">
                        <ng-container  *ngFor="let data of DataComentariosTabla">
                            <tr>
                                <td class="text-left">{{data.Nombre}} {{data.Apellido_Pat}}</td>
                                <td class="text-left">{{data.Comentario}}</td>
                                <td class="text-right">{{data.Fecha | date: 'dd-MMM-yyyy': "UTC"}}</td>
                                <td class="text-center" *ngIf="tipocomentario == 2 || DataComentarios.Paciente_id!=''">
                                    <div *ngIf="tipocomentario == 1">
                                        <ng-container *ngIf="data.tipo == 1 && DataComentarios.usuario == data.User_id">
                                            <a *ngIf="data.Ver_Cliente == 0" (click)="ActivaComm(DataComentarios.Paciente_id,data.Operacion_comentario_id,'1','1');" class='txtlinka'>Activar</a>
                                            <a *ngIf="data.Ver_Cliente == 1" (click)="ActivaComm(DataComentarios.Paciente_id,data.Operacion_comentario_id,'0','1');" class='txtlinka'>Desactivar</a>
                                        </ng-container>
                                        
                                        <ng-container *ngIf="data.tipo == 1 && DataComentarios.usuario != data.User_id">
                                            <div *ngIf="data.Ver_Cliente == 0">
                                                <fa-icon [icon]="['fas', 'eye-slash']"></fa-icon>
                                                &nbsp;
                                                &nbsp;
                                                <fa-icon [icon]="['fas', 'times']"></fa-icon>
                                            </div>
                                            <div *ngIf="data.Ver_Cliente == 1">
                                                <fa-icon [icon]="['fas', 'eye']"></fa-icon>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div *ngIf="tipocomentario == 2">
                                        <ng-container *ngIf="DataComentarios.usuario == data.User_id">
                                            <a *ngIf="data.Ver_Cliente == 0" (click)="ActivaComm(data.tbl,1,data.ID,'2');" class='txtlinka'>Activar</a>
                                            <a *ngIf="data.Ver_Cliente == 1" (click)="ActivaComm(data.tbl,0,data.ID,'2');" class='txtlinka'>Desactivar</a>
                                        </ng-container>
                                        <ng-container *ngIf="data.tipo == 1 && DataComentarios.usuario != data.User_id">
                                            <fa-icon *ngIf="data.Ver_Cliente == 0" [icon]="['fas', 'eye-slash']"></fa-icon>
                                            <fa-icon *ngIf="data.Ver_Cliente == 1" [icon]="['fas', 'eye']"></fa-icon>
                                        </ng-container>
                                        &nbsp;&nbsp;&nbsp;<a (click)="deleteComment(data.tbl,'1',data.ID,'100');" class='txtlinka' title="Eliminar"><fa-icon [icon]="['fas', 'times']"></fa-icon></a>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>        
                </table>
            </ng-container>

            <ng-container *ngIf="modalAccion=='Add'">
                <input type="hidden" name="tipocomentario" formControlName="tipocomentario"/>
                <div class="row mt-2">
                    <div class="col-md-6">
                        <label>Activado para cliente:</label>
                        <select name="VerCliente" id="VerCliente" class="form-control" style="width:100%" formControlName="VerCliente">
                            <option value="0">No</option>
                            <option value="1">Si</option>		
                        </select>
                        <div *ngIf="formComments.controls['VerCliente'].invalid && (formComments.controls['VerCliente'].dirty || formComments.controls['VerCliente'].touched)" class="error">
                            <div *ngIf="formComments.controls['VerCliente']?.errors?.required">obligatorio</div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label>Enviar por correo:</label>
                        <select name="Correo" id="Correo" class="form-control" style="width:100%" formControlName="Correo">
                            <option value="0">No</option>
                            <option value="1">Si</option>		
                        </select>
                        <div *ngIf="formComments.controls['Correo'].invalid && (formComments.controls['Correo'].dirty || formComments.controls['Correo'].touched)" class="error">
                            <div *ngIf="formComments.controls['Correo']?.errors?.required">obligatorio</div>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-md-12">
                        <label >Comentario:</label>
                        <textarea class="form-control" #txtComentario name="coment" id="coment" cols="55" rows="5" formControlName="coment"></textarea>
                        <div *ngIf="formComments.controls['coment'].invalid && (formComments.controls['coment'].dirty || formComments.controls['coment'].touched)" class="error">
                            <div *ngIf="formComments.controls['coment']?.errors?.required">obligatorio</div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-template>
    </form>
    <ng-template #modalFooter>
        <button nz-button class="btn btn-default" (click)="handleCancel()">Cerrar</button>
        <button nz-button class="btn btn-primary ml-1" (click)="handleOk()" *ngIf="modalAccion=='Add'">Guardar</button>
    </ng-template>
</nz-modal>



