import { AfterViewInit, ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { MatPaginator,PageEvent } from '@angular/material/paginator';

@Component({
    selector: 'sb-events-tab',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './events-tab.component.html',
    styleUrls: ['events-tab.component.scss']
})
export class EventsTabComponent implements  ViewChild, AfterViewInit {

    static?: boolean | undefined;
    @Input() tabs:any;
    selectedIndex: number = 0;

    selectedPageSize: number = 10;
    pageSizes: number[] = [10, 20, 30];
    filteredEvents: any[] = [];
    paginatedEvents: any[] = [];
    startIndex: number = 0;
    endIndex: number = 0;
    searchTerm: string = '';

    stopEvent(event: Event) {
        event.stopPropagation();
    }

    @ViewChild(MatPaginator) paginator: MatPaginator;

    descendants: boolean;
    emitDistinctChangesOnly: boolean;
    first: boolean;
    read: any;
    isViewQuery: boolean;
    selector: any;
    response: any;
    id: any;

    ngAfterViewInit(): void {
        this.updateFilteredEvents();
        this.updatePaginatedEvents();
    }

    onStepChange(stepper: number) {
        
        if(this.selectedIndex == 1){
            stepper = 0;   
        }
        this.selectedIndex = stepper;
    }

    onPageSizeChange() {
        this.paginator.pageSize = this.selectedPageSize;
        this.paginator.firstPage();
        this.updatePaginatedEvents();
    }

    onPageChange(event: PageEvent) {
        this.startIndex = event.pageIndex * event.pageSize;
        this.endIndex = Math.min(this.startIndex + event.pageSize, this.filteredEvents.length);
        this.updatePaginatedEvents();
    }

    updateFilteredEvents() {
        this.filteredEvents = this.tabs.BitacoraEventos.Tablas.Eventos.filter(evento =>
            evento.Descripcion.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
            evento.Usuario.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
            evento.Tipo_Relacion.toLowerCase().includes(this.searchTerm.toLowerCase())
        );
        this.paginator.pageIndex = 0;
        this.updatePaginatedEvents();
    }

    updatePaginatedEvents() {
        this.paginatedEvents = this.filteredEvents.slice(this.startIndex, this.endIndex);
        this.endIndex = Math.min(this.startIndex + this.selectedPageSize, this.filteredEvents.length);
    }

    onSearch(searchTerm: string) {
        this.searchTerm = searchTerm;
        if (this.searchTerm.trim() == '' || this.searchTerm == '') {
            this.updatePaginatedEvents();
            this.updateFilteredEvents();
        } else {
            this.updateFilteredEvents();
        }
        this.startIndex = 0;
        this.updatePaginatedEvents();
    }

}
