import { GkEnvironment } from './environment.model';

export const environment: GkEnvironment = {
    production: false,
    api: {
        auth: 'https://dev.demogenomi-k.com/authentication/api/v1',
        customers: 'https://dev.demogenomi-k.com/customers/api/v1',
        dashboard: 'https://dev.demogenomi-k.com/dashboard/api/v1',
        employes: 'https://dev.demogenomi-k.com/employes/api/v1',
        operations: 'https://dev.demogenomi-k.com/operations/api/v1',
        marketing: 'https://dev.demogenomi-k.com/marketing/api/v1',
        patients: 'https://dev.demogenomi-k.com/patients/api/v1',
        medicalArea: 'https://dev.demogenomi-k.com/medicalarea/api/v1',
        administration: 'https://dev.demogenomi-k.com/administration/api/v1',
        commons: 'https://dev.demogenomi-k.com/commons/api/v1',
        system: 'https://dev.demogenomi-k.com/system/api/v1',
        operationsAk: 'https://dev.demogenomi-k.com/operations-ak/api/v1',
        operationsCatalogs: 'https://dev.demogenomi-k.com/operations-catalogs/api/v1',
        administracionCatalogs: 'https://dev.demogenomi-k.com/admin-catalogs/api/v1',
        areaMedicaCatalogs: 'https://dev.demogenomi-k.com/medicalarea-catalogs/api/v1',
        akReport: 'https://dev.demogenomi-k.com/operations-ak-reports/api/v1',
        ckReport: 'https://dev.demogenomi-k.com/operations-ck-reports/api/v1',
        dxReports: 'https://dev.demogenomi-k.com/medical-dx-reports/api/v1',
        tkReport: 'https://dev.demogenomi-k.com/operations-tk-reports/api/v1',
        medicalReportsAk: 'https://dev.demogenomi-k.com/medical-ak-reports/api/v1',
        medicalReportsCK:   'https://dev.demogenomi-k.com/medical-ck-reports/api/v1',
        medicalReportsTK: 'https://dev.demogenomi-k.com/medical-tk-reports/api/v1',
        alertas: 'https://dev.demogenomi-k.com/alertas/api/v1',
        region: 'https://dev.demogenomi-k.com/region/api/v1',
        medicalRecords: 'https://dev.demogenomi-k.com/medical-records/api/v1',
        medicalRecordsComponents: 'https://dev.demogenomi-k.com/common-medical-records/api/v1',
        commons2: 'https://dev.demogenomi-k.com/commons2/api/v1',
        operinvdis: 'https://dev.demogenomi-k.com/operations-inventorydistribution/api/v1',
        operationsTools: 'https://dev.demogenomi-k.com/operations-tools/api/v1',
        company: 'https://dev.demogenomi-k.com/company/api/v1',
        systemcustomers: 'https://dev.demogenomi-k.com/system-customer/api/v1',
        resultoperationsck: 'https://dev.demogenomi-k.com/result-operations-ck/api/v1',
        resultoperationsak: 'https://dev.demogenomi-k.com/result-operations-ak/api/v1',
        operationsmenu: 'https://dev.demogenomi-k.com/operations-menu-process/api/v1',
        menuoperations: 'https://dev.demogenomi-k.com/operations-menu/api/v1',
        administrationReports: 'https://dev.demogenomi-k.com/administration-reports/api/v1',
        administracionCierreMes: 'https://dev.demogenomi-k.com/admin-month-close/api/v1',
        admonCustomers: 'https://dev.demogenomi-k.com/admin-custumer/api/v1',
        admonReportCierre: 'https://dev.demogenomi-k.com/admon-report-billings/api/v1',
        dhl: 'https://dev.demogenomi-k.com/dhl/api/v1',
        dxprocess: 'https://dev.demogenomi-k.com/dx-process/api/v1',
        adminconfigtools: 'https://dev.demogenomi-k.com/admin-tools-config/api/v1',
        adminFacturacion: 'https://dev.demogenomi-k.com/admin-facturacion/api/v1',
        mktOperation: 'https://dev.demogenomi-k.com/mkt-operations/api/v1',
    },
    s3: 'https://admin-genomik-ng-assets.s3.amazonaws.com',
    pathMenu: 'https://admin-genomik-ng-assets.s3.amazonaws.com/menu/dev',
};
