import { SideNavItems, SideNavSection } from '@modules/navigation/models';

export const sideNavSections: SideNavSection[] = [
    {
        text: 'CORE',
        items: ['home', 'dashboard'],
    },
    {
        text: 'OPERACIONES',
        items: ['operacionestk', 'operacionesak', 'operacionesdx'],
    },
    {
        text: 'MEDICAL AREA',
        items: ['MedicalArea'],
    },
    {
        text: 'IT SYSTEM',
        items: ['Tickets'],
    },
    {
        text: 'MARKETING',
        items: ['comercial'],
    },
    {
        text: 'CUSTOMERS',
        items: ['clientes'],
    },
    {
        text: 'SYSTEM',
        items: ['config'],
    },
];
export const sideNavItems: SideNavItems = {
    home: {
        icon: 'house-user',
        text: 'Home',
        link: '/home',
    },
    dashboard: {
        icon: 'tachometer-alt',
        text: 'Dashboard',
        link: '/dashboard',
    },
    clientes: {
        icon: 'address-book',
        text: 'Difusion',
        submenu: [
            {
                text: 'List',
                link: '/customers/difusion',
            },
        ],
    },
    operacionestk: {
        icon: 'dna',
        text: 'Támi-k',
        submenu: [
            {
                text: 'Agregar Comentario GRAL',
                link: '/operations/tami-k/add-gral-comment',
            },
            {
                text: 'Search',
                link: '/operations/tami-k/search',
            },
            {
                text: 'Medical Record',
                link: '/operations/tami-k/medical-record',
            },
            {
                text: 'Traslate Results',
                link: '/operations/tami-k/tami-k-translate-result',
            },
            {
                text: 'recolecciones ',
                link: '/operations/tami-k/recolecciones',
            },
            {
                text: 'pickup confirmation ',
                link: '/operations/tami-k/pickup-confirmation',
            },
            {
                text: 'reporte mensual ',
                link: '/operations/tami-k/reporte-mensual',
            },
            {
                text: 'reception ',
                link: '/operations/tami-k/reception',
            },
        ],
    },
    operacionesdx: {
        icon: 'dna',
        text: 'DX',
        submenu: [
            {
                text: 'DX pending',
                link: '/operations/dx/dx-pending',
            },
        ],
    },
    operacionesak: {
        icon: 'dna',
        text: 'Audi-k',
        submenu: [
            {
                text: 'convenios hp',
                link: '/operations/audi-k/convenios-hp',
            },
            {
                text: 'Delete Study',
                link: '/operations/audi-k/delete-study',
            },
            {
                text: 'Edit Patient',
                link: '/patient/edit',
            },
            {
                text: 'Process studies',
                link: '/patient/modify-data',
            },
            {
                text: 'Equipment calibration ',
                link: '/operations/audi-k/calibration-equipment-audi-k',
            },
            {
                text: 'test-pdf ',
                link: '/operations/audi-k/test-pdf',
            },
        ],
    },
    comercial: {
        icon: 'dna',
        text: 'Comercial',
        submenu: [
            {
                text: 'Contribucion Vol',
                link: '/marketing/vol-contribution',
            },
        ],
    },
    config: {
        icon: 'tools',
        text: 'Config',
        submenu: [
            {
                text: 'Users',
                submenu: [
                    {
                        text: 'List',
                        link: '/config/user',
                    },
                ],
            },
        ],
    },

    MedicalArea: {
        icon: 'tools',
        text: 'Medical Area',
        submenu: [
            {
                text: 'Validate results',
                link: '/medical-area/validate-results',
            },
        ],
    },

    Tickets: {
        icon: 'tools',
        text: 'Herramientas',
        submenu: [
            {
                text: 'Tickets',
                link: '/itsystem/tickets',
            },
            {
                text: 'Download Files Test',
                link: '/itsystem/test/download-files',
            },
        ],
    },
};
