 <div class="container-fluid">
    <form [formGroup]="formFiltros" #f="ngForm" (ngSubmit)="generarFiltro(f)">
        <div *ngIf="TipoFiltros == 0">
            <div class="row mt-3">
                <div class="col-2">
                    <label for="soluciones"><fa-icon class="mr-1" [icon]='["fas", "filter"]'></fa-icon> &nbsp;<strong>Tipo de Institución:</strong></label>
                </div>
                <div class="col-9">
                    <div class="ng-star-inserted">
                        <ng-container *ngFor="let type of searchType">
                            <input
                                class="icheckbox_square-blue"
                                type="radio"
                                name="TipoBusqueda"
                                formControlName="TipoBusqueda"
                                (change)="onCheckChangeBusqueda()"
                                value="{{type.value}}"
                            />
                            &nbsp;&nbsp;{{ type.text }}&nbsp;&nbsp;
                        </ng-container>
                    </div>

                    <div class="row mt-5">
                        <div class="col-md-7" *ngIf="formFiltros.controls['TipoBusqueda'].value !='Gobierno y Privado'">
                            <div >
                                <label class="control-label">{{txtTipoBusqueda}}</label>
                                <br><br>
                                <div class="">
                                    <nz-form-item>
                                        <nz-form-control>
                                                <nz-select nzSize="large" nzShowSearch nzPlaceHolder="Selecciona Institucion" [nzMaxTagCount]="5" [nzAllowClear]="true" formControlName="Institucion_Id">
                                                <nz-option nzLabel="Seleccione Institucion"  [nzValue]=""></nz-option>
                                                <nz-option nzLabel="Todas" [nzValue]= 0></nz-option>
                                                <nz-option *ngFor="let dataInstituciones of arrayFiltradoInstituciones" [nzLabel]="dataInstituciones.NombreInst" [nzValue]="dataInstituciones.Institucion_Id"> </nz-option>
                                            </nz-select>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-2">
                    <label for="soluciones"><fa-icon class="mr-1" [icon]='["fas", "filter"]'></fa-icon> &nbsp;<strong>Periodo:</strong></label>
                </div>
                <div class="col-9">
                    <div class="ng-star-inserted">
                        <ng-container *ngFor="let periodo of searchPeriodo">
                            <input
                                class="icheckbox_square-blue"
                                type="radio"
                                name="TipoBusquedaPeriodo"
                                formControlName="TipoBusquedaPeriodo"
                                value="{{periodo}}"
                            />
                            &nbsp;&nbsp;{{ periodo }}&nbsp;&nbsp;
                        </ng-container>
                    </div>
                    <div class="col-md-7 mt-5" *ngIf="formFiltros.controls['TipoBusquedaPeriodo'].value != 'Todas las fechas disponibles'">
                        <div class="col-12 form-group" *ngIf="formFiltros.controls['TipoBusquedaPeriodo'].value == 'Por mes/año'">
                            <div>
                                <label class="control-label">Seleccione Fecha </label>
                            </div><br>
                            <div nz-row [nzGutter]="16">
                                <div nz-col class="gutter-row" [nzSpan]="12">
                                    <nz-form-item>
                                        <nz-form-control>
                                            <nz-select nzSize="large" nzShowSearch nzPlaceHolder="Selecciona Mes" [nzMaxTagCount]="5" [nzAllowClear]="true" formControlName="mes">
                                                <nz-option *ngFor="let FechaMes of dataMeses" [nzLabel]="FechaMes.mes" [nzValue]="FechaMes.id"> </nz-option>
                                            </nz-select>
                                        </nz-form-control>
                                        <!-- <div *ngIf="
                                                formEnvioLab.controls['mes'].invalid &&
                                                (formEnvioLab.controls['mes'].dirty || formEnvioLab.controls['mes'].touched)
                                                " class="error">
                                            <div *ngIf="formEnvioLab.controls['mes']?.errors?.required">{{ t('MesageValidate') }}</div>
                                        </div> -->
                                    </nz-form-item>
                                </div>
                                <div nz-col class="gutter-row" [nzSpan]="12">
                                    <nz-form-item>
                                        <nz-form-control>
                                            <nz-select nzSize="large" nzShowSearch nzPlaceHolder="Selecciona Año" [nzMaxTagCount]="5" [nzAllowClear]="true" formControlName="anio">
                                                <nz-option *ngFor="let anio of years" [nzLabel]="anio.year" [nzValue]="anio.year"> </nz-option>
                                            </nz-select>
                                        </nz-form-control>
                                        <!-- <div *ngIf="
                                                formEnvioLab.controls['anio'].invalid &&
                                                (formEnvioLab.controls['anio'].dirty || formEnvioLab.controls['anio'].touched)
                                                " class="error">
                                            <div *ngIf="formEnvioLab.controls['anio']?.errors?.required">{{ t('MesageValidate') }}</div>
                                        </div> -->
                                    </nz-form-item>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 " *ngIf="formFiltros.controls['TipoBusquedaPeriodo'].value == 'Por intervalo'">
                            <div>
                                <label class="control-label"> Intervalo de Fechas </label>
                            </div><br>
                            <div class="col-12" nz-row [nzGutter]="16">
                                <div class="col-6" nz-col class="gutter-row" [nzSpan]="12">
                                    <nz-form-item>
                                        <nz-form-control>
                                            <input nz-input placeholder="Fecha inicial" nzSize="large" type="date" formControlName="fechaIni" />
                                        </nz-form-control>
                                        <!-- <div *ngIf="
                                                formEnvioLab.controls['fechaIni'].invalid &&
                                                (formEnvioLab.controls['fechaIni'].dirty || formEnvioLab.controls['fechaIni'].touched)
                                                " class="error">
                                            <div *ngIf="formEnvioLab.controls['fechaIni']?.errors?.required">{{ t('MesageValidate') }}</div>
                                        </div> -->
                                    </nz-form-item>
                                </div>
                                <div class="col-6" nz-col class="gutter-row" [nzSpan]="12">
                                    <nz-form-item>
                                        <nz-form-control>
                                            <input nz-input placeholder="Fecha final" nzSize="large" type="date" formControlName="fechaFin" />
                                        </nz-form-control>
                                        <!-- <div *ngIf="
                                                formEnvioLab.controls['fechaFin'].invalid &&
                                                (formEnvioLab.controls['fechaFin'].dirty || formEnvioLab.controls['fechaFin'].touched)
                                                " class="error">
                                            <div *ngIf="formEnvioLab.controls['fechaFin']?.errors?.required">{{ t('MesageValidate') }}</div>
                                        </div> -->
                                    </nz-form-item>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <br>
            <br>
            <div class="row mb-5">
                <div class="col-12 mt-5 text-center">
                    <button type="submit" class="btn btn-primary  btn-lg" > Generar</button>
                </div>

            </div>
        </div>
        <div *ngIf="TipoFiltros == 1">
            <div class="row mt-3">
                <div class="col-2">
                    <label for="soluciones"><fa-icon class="mr-1" [icon]='["fas", "filter"]'></fa-icon> &nbsp;<strong>Tipo de Institución:</strong></label>
                </div>
                <div class="col-9">
                    <div class="ng-star-inserted">
                        <ng-container *ngFor="let type of searchType">
                            <input
                                class="icheckbox_square-blue"
                                type="radio"
                                name="TipoBusqueda"
                                formControlName="TipoBusqueda"
                                (change)="onCheckChangeBusqueda()"
                                value="{{type}}"
                            />
                            &nbsp;&nbsp;{{ type }}&nbsp;&nbsp;
                        </ng-container>
                    </div>

                    <div class="row mt-5">
                        <div class="col-md-7" *ngIf="formFiltros.controls['TipoBusqueda'].value !='Gobierno y Privado'">
                            <div >
                                <label class="control-label">{{txtTipoBusqueda}}</label>
                                <br><br>
                                <div class="">
                                    <nz-form-item>
                                        <nz-form-control>
                                                <nz-select nzSize="large" nzShowSearch nzPlaceHolder="Selecciona Institucion" [nzMaxTagCount]="5" [nzAllowClear]="true" formControlName="Institucion_Id">
                                                <nz-option nzLabel="Seleccione Institucion"  [nzValue]=""></nz-option>
                                                <nz-option nzLabel="Todas" [nzValue]= 0></nz-option>
                                                <nz-option *ngFor="let dataInstituciones of arrayFiltradoInstituciones" [nzLabel]="dataInstituciones.NombreInst" [nzValue]="dataInstituciones.Institucion_Id"> </nz-option>
                                            </nz-select>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-2">
                    <label for="soluciones"><fa-icon class="mr-1" [icon]='["fas", "filter"]'></fa-icon> &nbsp;<strong>Periodo:</strong></label>
                </div>
                <div class="col-4">
                    <div class="ng-star-inserted">
                        <ng-container *ngFor="let periodo of searchPeriodo">
                            <input
                                class="icheckbox_square-blue"
                                type="radio"
                                name="TipoBusquedaPeriodo"
                                formControlName="TipoBusquedaPeriodo"
                                value="{{periodo}}"
                            />
                            &nbsp;&nbsp;{{ periodo }}&nbsp;&nbsp;
                        </ng-container>
                    </div>
                    <div class="col-md-7 mt-5" *ngIf="formFiltros.controls['TipoBusquedaPeriodo'].value != 'Todas las fechas disponibles'">
                        <div class="col-12 form-group" *ngIf="formFiltros.controls['TipoBusquedaPeriodo'].value == 'Por mes/año'">
                            <div>
                                <label class="control-label">Seleccione Fecha </label>
                            </div><br>
                            <div nz-row [nzGutter]="16">
                                <div nz-col class="gutter-row" [nzSpan]="12">
                                    <nz-form-item>
                                        <nz-form-control>
                                            <nz-select nzSize="large" nzShowSearch nzPlaceHolder="Selecciona Mes" [nzMaxTagCount]="5" [nzAllowClear]="true" formControlName="mes">
                                                <nz-option *ngFor="let FechaMes of dataMeses" [nzLabel]="FechaMes.mes" [nzValue]="FechaMes.id"> </nz-option>
                                            </nz-select>
                                        </nz-form-control>
                                        <!-- <div *ngIf="
                                                formEnvioLab.controls['mes'].invalid &&
                                                (formEnvioLab.controls['mes'].dirty || formEnvioLab.controls['mes'].touched)
                                                " class="error">
                                            <div *ngIf="formEnvioLab.controls['mes']?.errors?.required">{{ t('MesageValidate') }}</div>
                                        </div> -->
                                    </nz-form-item>
                                </div>
                                <div nz-col class="gutter-row" [nzSpan]="12">
                                    <nz-form-item>
                                        <nz-form-control>
                                            <nz-select nzSize="large" nzShowSearch nzPlaceHolder="Selecciona Año" [nzMaxTagCount]="5" [nzAllowClear]="true" formControlName="anio">
                                                <nz-option *ngFor="let anio of years" [nzLabel]="anio.year" [nzValue]="anio.year"> </nz-option>
                                            </nz-select>
                                        </nz-form-control>
                                        <!-- <div *ngIf="
                                                formEnvioLab.controls['anio'].invalid &&
                                                (formEnvioLab.controls['anio'].dirty || formEnvioLab.controls['anio'].touched)
                                                " class="error">
                                            <div *ngIf="formEnvioLab.controls['anio']?.errors?.required">{{ t('MesageValidate') }}</div>
                                        </div> -->
                                    </nz-form-item>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 " *ngIf="formFiltros.controls['TipoBusquedaPeriodo'].value == 'Por intervalo'">
                            <div>
                                <label class="control-label"> Intervalo de Fechas </label>
                            </div><br>
                            <div class="col-12" nz-row [nzGutter]="16">
                                <div class="col-6" nz-col class="gutter-row" [nzSpan]="12">
                                    <nz-form-item>
                                        <nz-form-control>
                                            <input nz-input placeholder="Fecha inicial" nzSize="large" type="date" formControlName="fechaIni" />
                                        </nz-form-control>
                                        <!-- <div *ngIf="
                                                formEnvioLab.controls['fechaIni'].invalid &&
                                                (formEnvioLab.controls['fechaIni'].dirty || formEnvioLab.controls['fechaIni'].touched)
                                                " class="error">
                                            <div *ngIf="formEnvioLab.controls['fechaIni']?.errors?.required">{{ t('MesageValidate') }}</div>
                                        </div> -->
                                    </nz-form-item>
                                </div>
                                <div class="col-6" nz-col class="gutter-row" [nzSpan]="12">
                                    <nz-form-item>
                                        <nz-form-control>
                                            <input nz-input placeholder="Fecha final" nzSize="large" type="date" formControlName="fechaFin" />
                                        </nz-form-control>
                                        <!-- <div *ngIf="
                                                formEnvioLab.controls['fechaFin'].invalid &&
                                                (formEnvioLab.controls['fechaFin'].dirty || formEnvioLab.controls['fechaFin'].touched)
                                                " class="error">
                                            <div *ngIf="formEnvioLab.controls['fechaFin']?.errors?.required">{{ t('MesageValidate') }}</div>
                                        </div> -->
                                    </nz-form-item>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-2">
                    <label for="soluciones"><fa-icon class="mr-1" [icon]='["fas", "filter"]'></fa-icon> &nbsp;<strong>Incluir NO Tamiz:</strong></label>
                </div>
                <div class="col-4">
                    <div class="ng-star-inserted">
                        <ng-container>
                            <input
                                class="icheckbox_square-blue"
                                type="radio"
                                name="inpIncTamiz"
                                formControlName="inpIncTamiz"
                                value = "1"
                            />
                            &nbsp;&nbsp;Si&nbsp;&nbsp;
                        </ng-container>
                        <ng-container>
                            <input
                                class="icheckbox_square-blue"
                                type="radio"
                                name="inpIncTamiz"
                                formControlName="inpIncTamiz"
                                value = "0"
                            />
                            &nbsp;&nbsp;No&nbsp;&nbsp;
                        </ng-container>
                    </div>
                </div>
            </div>
            <br>
            <br>
            <div class="row mb-5">
                <div class="col-12 mt-5 text-center">
                    <button type="submit" class="btn btn-primary  btn-lg" > Generar</button>
                </div>

            </div>
        </div>
    </form>
</div>