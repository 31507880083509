import { ChangeDetectionStrategy, Component, OnDestroy, TemplateRef, ViewEncapsulation } from '@angular/core';
import { ToastService } from '@common/services/toast.service';

@Component({
    selector: 'sb-toast',
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        '[class.ngb-toasts]': 'true',
    },
})
export class ToastComponent implements OnDestroy {
    toasts$ = this.toastService.toasts$;

    constructor(private toastService: ToastService) {}

    ngOnDestroy() {
        /**
         * Toasts are removed automatically after some time has passed (usually between 3-5 seconds).
         *
         * When we navigate to a different page while some toasts are still active they are shown again in the new page.
         * This will clear the toasts from our service when the component is destroyed.
         */
        this.toastService.clear();
    }

    isTemplate(toast: any) {
        return toast.textOrTpl instanceof TemplateRef;
    }

    removeToast(toast: any) {
        this.toastService.remove(toast);
    }
}
