<h3 mat-dialog-title>Comentario</h3>
<mat-dialog-content class="mat-typography">
    <form [formGroup]="form">
        <div class="row">
            <div class="col-12 mb-3 mt-4">
                <input type="text" class="form-control" formControlName="comentario" style="height: 75px;" >
            </div>
        </div>
    
        <div class="full-width-element full-height-element">
            <mat-divider class="mt-4"></mat-divider>
            <mat-dialog-actions align="end" class="gris py-4">
                @if(!noInactivar){
                    <button cdkFocusInitial class="btn btn-success mx-2 mt-3" type="submit" (click)="changeStatus()">
                        Guardar
                    </button>
                }@else{
                    <button cdkFocusInitial class="btn btn-success mx-2 mt-3" type="submit" (click)="traerComentario()">
                        Guardar
                    </button>
                }
                <button [mat-dialog-close] class="btn btn-secondary mx-2 mt-3">
                    Cerrar
                </button>
            </mat-dialog-actions>
        </div>
    </form>

</mat-dialog-content>