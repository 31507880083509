import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from '@modules/auth/services';
import { checkResponseFailStatus } from '@modules/utils/operators';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Default } from '../models';

@Injectable({ providedIn: 'root' })
export class TamikService {
    // API_URL = environment.api.auth;
    API_URL_OPERATIONS = environment.api.operations;
    API_URL_COMMOMS = environment.api.commons;
    API_URL_CUSTOMERS = environment.api.customers;
    API_URL_OPERATIONSAK = environment.api.operationsAk;
    API_URL_DHL = environment.api.dhl

    constructor(private http: HttpClient, private userService: UserService) {}


    async getDatosPF(CodigoPF) {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({
                Authorization: `Bearer ${this.userService.AccessToken}`,
            });

            this.http.get<Default>(`${this.API_URL_OPERATIONS}/estatuspf/${CodigoPF}`, { headers }).subscribe(
                resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp);
                },
                error => {
                    reject(error);
                }
            );
        });
    }

    async getDatosTraslate() {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({
                Authorization: `Bearer ${this.userService.AccessToken}`,
            });

            this.http.get<Default>(`${this.API_URL_OPERATIONS}/DatosTraslateResult`).subscribe(
                resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp);
                },
                error => {
                    const resp = {
                        status: 'fail',
                        data: {
                            message: error.message,
                        },
                    };
                    reject(resp);
                }
            );
        });
    }

    async getDatosGenerales(CodigoPF) {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({
                Authorization: `Bearer ${this.userService.AccessToken}`,
            });

            this.http.get<Default>(`${this.API_URL_OPERATIONS}/datosgeneralespf/${CodigoPF}`, { headers }).subscribe(
                resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp);
                },
                error => {
                    const resp = {
                        status: 'fail',
                        data: {
                            message: error.message,
                        },
                    };
                    reject(resp);
                }
            );
        });
    }
    async getDatosFacturacion(CodigoPF) {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({
                Authorization: `Bearer ${this.userService.AccessToken}`,
            });

            this.http.get<Default>(`${this.API_URL_OPERATIONS}/datosfacturacionpf/${CodigoPF}`, { headers }).subscribe(resp => {
                if (resp.status === 'fail') {
                    console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                resolve(resp);
            });
        });
    }
    async InsertResultadoPF(requestBody) {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({
                Authorization: `Bearer ${this.userService.AccessToken}`,
            });

            this.http.post<Default>(`${this.API_URL_OPERATIONS}/InsertResultadoPF`, requestBody, { headers }).subscribe(resp => {
                if (resp.status === 'fail') {
                    console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                resolve(resp.data);
            });
        });
    }
    async InsertCommentPF(requestBody) {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({
                Authorization: `Bearer ${this.userService.AccessToken}`,
            });

            this.http.post<Default>(`${this.API_URL_OPERATIONS}/InsertCommentPF`, requestBody, { headers }).subscribe(resp => {
                if (resp.status === 'fail') {
                    console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                resolve(resp.data);
            });
        });
    }

    async getDatosBusqueda(requestBody) {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({
                Authorization: `Bearer ${this.userService.AccessToken}`,
            });

            this.http.post<Default>(`${this.API_URL_OPERATIONS}/searchpf`, requestBody, { headers }).subscribe(
                resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp);
                },
                error => {
                    const resp = {
                        status: 'fail',
                        data: {
                            message: error.message,
                        },
                    };
                    reject(resp);
                }
            );
        });
    }
    async getDatosComentarios(pacienteid) {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({
                Authorization: `Bearer ${this.userService.AccessToken}`,
            });

            this.http.get<Default>(`${this.API_URL_OPERATIONS}/getDatosComentariosPX/${pacienteid}`).subscribe(
                resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp);
                },
                error => {
                    const resp = {
                        status: 'fail',
                        data: {
                            message: error.message,
                        },
                    };
                    reject(resp);
                }
            );
        });
    }

    async getDetalleComentarios(requestBody) {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({
                Authorization: `Bearer ${this.userService.AccessToken}`,
            });

            this.http.post<Default>(`${this.API_URL_OPERATIONS}/getDetalleComentarios`, requestBody, { headers }).subscribe(
                resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp);
                },
                error => {
                    const resp = {
                        status: 'fail',
                        data: {
                            message: error.message,
                        },
                    };
                    reject(resp);
                }
            );
        });
    }

    async getDatosFijos(requestBody) {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({
                Authorization: `Bearer ${this.userService.AccessToken}`,
            });

            this.http.post<Default>(`${this.API_URL_OPERATIONS}/getDatosFijos`, requestBody, { headers }).subscribe(
                resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp);
                },
                error => {
                    const resp = {
                        status: 'fail',
                        data: {
                            message: error.message,
                        },
                    };
                    reject(resp);
                }
            );
        });
    }

    async getModificarDatosGenerales(requestBody) {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({
                Authorization: `Bearer ${this.userService.AccessToken}`,
            });

            this.http.post<Default>(`${this.API_URL_OPERATIONS}/getModificarDatosGenerales`, requestBody, { headers }).subscribe(
                resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp);
                },
                error => {
                    const resp = {
                        status: 'fail',
                        data: {
                            message: error.message,
                        },
                    };
                    reject(resp);
                }
            );
        });
    }

    async InsertComentComponent(requestBody) {
        return new Promise((resolve, reject) => {
            this.http
                .post<Default>(`${this.API_URL_OPERATIONS}/InsertCommentComponent`, {
                    ...requestBody,
                    UsuarioId: this.userService.vUsuarioID,
                })
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                });
        });
    }

    async getRecolecciones() {
        return new Promise((resolve, reject) => {
            this.http.get<Default>(`${this.API_URL_OPERATIONS}/recolecciones/get`).subscribe(resp => {
                if (resp.status === 'fail') {
                    console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                resolve(resp);
            });
        });
    }

    async getRecoleccionesConfirmacion() {
        return new Promise((resolve, reject) => {
            this.http.get<Default>(`${this.API_URL_OPERATIONS}/recolecciones/getConfirmaciones`).subscribe(resp => {
                if (resp.status === 'fail') {
                    console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                resolve(resp);
            });
        });
    }

    async getDetallePFConfirmar(pasoid) {
        return new Promise((resolve, reject) => {
            this.http.get<Default>(`${this.API_URL_OPERATIONS}/recolecciones/getDetallePFConfirmar/${pasoid}`).subscribe(resp => {
                if (resp.status === 'fail') {
                    console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                resolve(resp);
            });
        });
    }

    async AddPfRecoleccion(requestBody) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_OPERATIONS}/recolecciones/AgregarFiltrosRecoleccion`, requestBody).subscribe(resp => {
                if (resp.status === 'fail') {
                    console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                resolve(resp);
            });
        });
    }

    async getDatosGuiaModificar(pasoid) {
        return new Promise((resolve, reject) => {
            this.http.get<Default>(`${this.API_URL_OPERATIONS}/recolecciones/getDatosGuiaModificar/${pasoid}`).subscribe(resp => {
                if (resp.status === 'fail') {
                    console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                resolve(resp);
            });
        });
    }

    async DelPfRecoleccion(requestBody) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_OPERATIONS}/recolecciones/BorrarFiltrosRecoleccion`, requestBody).subscribe(resp => {
                if (resp.status === 'fail') {
                    console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                resolve(resp);
            });
        });
    }

    async GuiaModificar(requestBody) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_OPERATIONS}/getGuiaModificar`, requestBody).subscribe(resp => {
                if (resp.status === 'fail') {
                    console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                resolve(resp);
            });
        });
    }

    async ProcesarPFs(requestBody) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_OPERATIONS}/recolecciones/files/CopyFiles`, requestBody).subscribe(resp => {
                if (resp.status === 'fail') {
                    console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                resolve(resp);
            });
        });
    }

    async RecepcionarGuias(requestBody) {
        return new Promise((resolve, reject) => {
            this.http
                .post<Default>(`${this.API_URL_OPERATIONS}/recolecciones/getRecepcionarGuias`, {
                    ...requestBody,
                    UsuarioSesionId: this.userService.vUsuarioID,
                })
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp);
                });
        });
    }
    async getPreConfirmarFiltros(pasoid) {
        return new Promise((resolve, reject) => {
            this.http
                .get<Default>(`${this.API_URL_OPERATIONS}/recolecciones/getPreConfirmarFiltros/${pasoid}/${this.userService.vUsuarioID}`)
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp);
                });
        });
    }

    async getConfirmarFiltros(requestBody) {
        return new Promise((resolve, reject) => {
            this.http
                .post<Default>(`${this.API_URL_OPERATIONS}/recolecciones/getConfirmarFiltros`, {
                    ...requestBody,
                    UsuarioSesionId: this.userService.vUsuarioID,
                })
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp);
                });
        });
    }

    async getCancelarRecoleccion(requestBody) {
        return new Promise((resolve, reject) => {
            this.http
                .post<Default>(`${this.API_URL_OPERATIONS}/recolecciones/getCancelarRecol`, {
                    ...requestBody,
                    UsuarioSesionId: this.userService.vUsuarioID,
                })
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp);
                });
        });
    }

    async getInactivarRecoleccion(requestBody) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_OPERATIONS}/recolecciones/getInactivarRecol`,  requestBody).subscribe(resp => {
                if (resp.status === 'fail') {
                    console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                resolve(resp);
            });
        });
    }

    async getRecepcionMuestras() {
        return new Promise((resolve, reject) => {
            this.http.get<Default>(`${this.API_URL_OPERATIONS}/recolecciones/getRecepcionMuestras`).subscribe(resp => {
                if (resp.status === 'fail') {
                    console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                resolve(resp);
            });
        });
    }

    async getModalRecepcion(CodigoPF,TipoBusqueda) {
        return new Promise((resolve, reject) => {
            this.http.get<Default>(`${this.API_URL_OPERATIONS}/recolecciones/getModalRecepcion/${CodigoPF}/${TipoBusqueda}/${this.userService.vUsuarioID}`).subscribe(resp => {
                if (resp.status === 'fail') {
                    console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                resolve(resp);
            });
        });
    }

    async getRecepcionFiltros(requestBody) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_OPERATIONS}/recolecciones/getRecepcionFiltro`, {
                ...requestBody,
                UsuarioSesionId: this.userService.vUsuarioID,
            }).subscribe(resp => {
                if (resp.status === 'fail') {
                    console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                resolve(resp);
            });
        });
    }

    async getListadoPXRecept(requestBody) {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({
                Authorization: `Bearer ${this.userService.AccessToken}`,
            });
            this.http.post<Default>(`${this.API_URL_OPERATIONS}/recolecciones/getListaRecepRepe`, requestBody).subscribe(resp => {
                if (resp.status === 'fail') {
                    console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                resolve(resp);
            });
        });
    }

    async getDatosFiltros(requestBody) {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({
                Authorization: `Bearer ${this.userService.AccessToken}`,
            });
            this.http.post<Default>(`${this.API_URL_OPERATIONS}/recolecciones/getDatosFiltrosUnificar`, requestBody).subscribe(resp => {
                if (resp.status === 'fail') {
                    console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                resolve(resp);
            });
        });
    }

    async getUnificarPx(requestBody) {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({
                Authorization: `Bearer ${this.userService.AccessToken}`,
            });
            this.http.post<Default>(`${this.API_URL_OPERATIONS}/recolecciones/UnificarDatos`,{
                ...requestBody,
                UsuarioSesionId: this.userService.vUsuarioID,
            }).subscribe(resp => {
                if (resp.status === 'fail') {
                    console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                resolve(resp);
            });
        });
    }




    async generateReportPDF(requestBody) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_OPERATIONS}/tamik/reporte`, requestBody).subscribe(
                resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error => {
                    const resp = {
                        status: 'fail',
                        data: {
                            message: error.message,
                        },
                    };
                    reject(resp);
                }
            );
        });
    }

    async downloadTestPdf(requestBody) {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({
                Authorization: `Bearer ${this.userService.AccessToken}`,
            });
            this.http.post<Default>(`${this.API_URL_COMMOMS}/getfile`, requestBody, { headers }).subscribe(
                resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error => {
                    const resp = {
                        status: 'fail',
                        data: {
                            message: error.message,
                        },
                    };
                    reject(resp);
                }
            );
        });
    }

    async getDetailListPF(requestBody) {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({
                Authorization: `Bearer ${this.userService.AccessToken}`,
            });
            this.http.post<Default>(`${this.API_URL_OPERATIONS}/detailpflist/data`, requestBody, { headers }).subscribe(
                resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp.data);
                },
                error => {
                    const resp = {
                        status: 'fail',
                        data: {
                            message: error.message,
                        },
                    };
                    reject(resp);
                }
            );
        });
    }

    async getCatalogos() {
        return new Promise((resolve, reject) => {
            this.http.get<Default>(`${this.API_URL_CUSTOMERS}/customer/catalogos`).subscribe(resp => {
                if (resp.status === 'fail') {
                    console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                resolve(resp);
            });
        });
    }

    getCustomer(requestBody) {
        return this.http.post<Default>(`${this.API_URL_CUSTOMERS}/getCustomer`, requestBody).pipe(
            checkResponseFailStatus(),
            map(response => response.data)
        );
    }

    LoadGuia(requestBody) {
        return this.http
            .post<Default>(`${this.API_URL_OPERATIONS}/recolecciones/guia/load`, {
                ...requestBody,
                UsuarioSesionId: this.userService.vUsuarioID,
            })
            .pipe(
                checkResponseFailStatus(),
                map(response => response.data)
            );
    }

    setUsuarioRecoleccion(requestBody) {
        return new Promise((resolve, reject) => {
            this.http
                .post<Default>(`${this.API_URL_OPERATIONS}/recolecciones/cleanuser`, {
                    ...requestBody,
                    UsuarioSesionId: this.userService.vUsuarioID,
                })
                .subscribe(
                    resp => {
                        if (resp.status === 'fail') {
                            console.info('RESPONSE ERROR: ', resp);
                            reject(resp);
                            return;
                        }
                        resolve(resp.data);
                    },
                    error => {
                        const resp = {
                            status: 'fail',
                            data: {
                                message: error.message,
                            },
                        };
                        reject(resp);
                    }
                );
        });
    }

    getTamik$(): Observable<{}> {
        return of({});
    }

    async getEquipoTA() {
        return new Promise((resolve, reject) => {
            // const headers = new HttpHeaders({
            //     Authorization: `Bearer ${this.userService.AccessToken}`,
            // });


            this.http.get<Default>(`${this.API_URL_OPERATIONS}/operations/incidencias/equipota`).subscribe(
                resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp);
                },
                error => {
                    reject(error);
                }
            );
        });
    }
    async getIncidenciasTA() {
        return new Promise((resolve, reject) => {
            // const headers = new HttpHeaders({
            //     Authorization: `Bearer ${this.userService.AccessToken}`,
            // });

            this.http.get<Default>(`${this.API_URL_OPERATIONS}/operations/incidencias/incidenciasta`).subscribe(
                resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp);
                },
                error => {
                    reject(error);
                }
            );
        });
    }

    async AddIncidencia(requesBody: any) {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({
                Authorization: `Bearer ${this.userService.AccessToken}`,
            });

            this.http.post<Default>(`${this.API_URL_OPERATIONS}/operations/incidencias/addincidencia`, requesBody).subscribe(resp => {
                if (resp.status === 'fail') {
                    console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                console.info('RESPONSE: ', resp.data);
                resolve(resp.data);
            });
        });
    }

    async UpdateIncidencia(requesBody: any) {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({
                Authorization: `Bearer ${this.userService.AccessToken}`,
            });

            this.http.post<Default>(`${this.API_URL_OPERATIONS}/operations/incidencias/updateincidencia`,requesBody).subscribe(resp => {
                if (resp.status === 'fail') {
                    console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                console.info('RESPONSE: ', resp.data);
                resolve(resp.data);
            });
        });
    }

    async getMatGenomik() {
        return new Promise((resolve, reject) => {
            // const headers = new HttpHeaders({
            //     Authorization: `Bearer ${this.userService.AccessToken}`,
            // });
            this.http.get<Default>(`${this.API_URL_OPERATIONS}/operations/report-general`).subscribe(
                resp => {
                    if (resp.status == 'fail') {
                        console.info('RESPONSE: ', resp.data);
                        reject(resp);
                        return;
                    }
                    console.info('RESPONSE: ', resp.data);
                    resolve(resp.data);
            });
        });
    }

    async detalleMatGK(requestBody: any) {
        return new Promise((resolve, reject) => {
            // const headers = new HttpHeaders({
            //     Authorization: `Bearer ${this.userService.AccessToken}`,
            // });
            this.http.post<Default>(`${this.API_URL_OPERATIONS}/operations/detallematgk`, requestBody, ).subscribe(resp => {
                if (resp.status === 'fail') {
                    console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                console.info('RESPONSE: ', resp.data);
                resolve(resp.data);
            });
        });
    }
    async detalleInstitucion(requestBody: any) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_OPERATIONS}/operations/detalleinstitucion`, requestBody, ).subscribe(resp => {
                if (resp.status === 'fail') {
                    console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                console.info('RESPONSE: ', resp.data);
                resolve(resp.data);
            });
        });
    }

    async GenerarRecoleccion(requestBody: any) {
        return new Promise((resolve, reject) => {
            this.http
                .post<Default>(`${this.API_URL_DHL}/guias`, {
                    ...requestBody,
                    UsuarioSesionId: this.userService.vUsuarioID,
                })

                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    resolve(resp);
                },
                error => {
                    reject(error);
                }
            );
        });
    }
    async GetFile(requestBody: any) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_COMMOMS}/s3/getfroms3`, requestBody).subscribe(resp => {
                if (resp.status === 'fail') {
                    //console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                //console.info('RESPONSE: ', resp);
                resolve(resp);
            });
        });
    }

    async UpdateDatosInstitucion(requestBody: any) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_OPERATIONS}/recolecciones/institucion/update`, requestBody).subscribe(resp => {
                if (resp.status === 'fail') {
                    console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                console.info('RESPONSE: ', resp.data);
                resolve(resp.data);
            });
        });
    }

    async GuardarRecoleccion(requestBody: any) {
        return new Promise((resolve, reject) => {
            this.http
                .post<Default>(`${this.API_URL_OPERATIONS}/recolecciones/set`, {
                    ...requestBody,
                    UsuarioSesionId: this.userService.vUsuarioID,
                })
                .subscribe(resp => {
                    if (resp.status === 'fail') {
                        console.info('RESPONSE ERROR: ', resp);
                        reject(resp);
                        return;
                    }
                    console.info('RESPONSE: ', resp.data);
                    resolve(resp.data);
                });
        });
    }
    async dataInstituciones() {
        return new Promise((resolve, reject) => {
            this.http.get<Default>(`${this.API_URL_OPERATIONS}/operations/datainstitucion`).subscribe(resp => {
                if (resp.status === 'fail') {
                    console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                resolve(resp);
            });
        });
    }

    async dataPF(requestBody: any) {
        return new Promise((resolve, reject) => {
            this.http.post<Default>(`${this.API_URL_OPERATIONS}/operations/datatipopf`, requestBody).subscribe(resp => {
                if (resp.status === 'fail') {
                    console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                console.info('RESPONSE: ', resp.data);
                resolve(resp.data);
            });
        });
    }
    async getDataEnvioLab(requestBody: any) {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({
                Authorization: `Bearer ${this.userService.AccessToken}`,
            });
            this.http.post<Default>(`${this.API_URL_OPERATIONSAK}/report/envioslab`, requestBody, { headers }).subscribe(resp => {
                if (resp.status === 'fail') {
                    console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                console.info('RESPONSE: ', resp.data);
                resolve(resp.data);
            });
        });
    }
    async getDetalleEnvioLab(requestBody: any) {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({
                Authorization: `Bearer ${this.userService.AccessToken}`,
            });
            this.http.post<Default>(`${this.API_URL_OPERATIONSAK}/report/detalleenviolab`, requestBody, { headers }).subscribe(resp => {
                if (resp.status === 'fail') {
                    console.info('RESPONSE ERROR: ', resp);
                    reject(resp);
                    return;
                }
                console.info('RESPONSE: ', resp.data);
                resolve(resp.data);
            });
        });
    }
    // async ProcesarImagenes(requestBody: any) {
    //     return new Promise((resolve, reject) => {
    //         const headers = new HttpHeaders({
    //             Authorization: `Bearer ${this.userService.AccessToken}`,
    //         });
    //         this.http.post<Default>(`${this.API_URL_OPERATIONS}/recolecciones/files/CopyFiles`, requestBody, { headers,observe: 'events' })
    //         .subscribe((event:any)=> {
    //             console.log("respuesta", event)
    //             if (event instanceof HttpResponse) {
    //             if (event.body.data.estatus == "200"){
    //                 resolve(event);
    //             }

    //             }

    //         }, error => {
    //                 console.log("❌ Error copy file: ", error);
    //             })
    //     });
    // }
}
