<div class="tab-content">
    <div class="container-fluid">
        <div class="row mt-4">
            <div class="col-md-4">
                <table class="table table-striped table-bordered" width="100%">
                    <tbody><tr>
                        <td width="100%" class="ttdgray" style="text-align:center; font-size:16px; vertical-align:middle"><strong>INFORMACIÓN DEL PACIENTE</strong></td>
                    </tr>
                    <tr>
                        <td class="Normal" valign="top">
                            <table border="0" cellspacing="0" cellpadding="0" width="100%">
                                <tbody><tr>
                                    <td width="41%" style="border:none"><strong>ID Paciente:</strong></td>
                                    <td width="59%" style="border:none"><strong>{{ datosPx.paciente_id }}</strong></td>
                                </tr>
                                <tr>
                                    <td width="41%" style="border:none"><strong>Nombre:</strong></td>
                                    <td width="59%" style="border:none"><strong>{{ datosPx.nombre }}</strong></td>
                                </tr>
                                <tr>
                                    <td width="41%" style="border:none"><strong>Fecha de Nacimiento:</strong></td>
                                    <td width="59%" style="border:none"><strong>{{ datosPx.Fecha_hora_nac | date: 'dd-MMM-yyyy': "UTC" }}</strong></td>
                                </tr>
                                <tr>
                                    <td width="41%" style="border:none"><strong>Sexo:</strong></td>
                                    <td width="59%" style="border:none"><strong>{{ datosPx.Sexo }}</strong></td>
                                </tr>
                                <tr>
                                    <td width="41%" style="border:none">Raza:</td>
                                    <td width="59%" style="border:none">{{ datosPx.Raza }}</td>
                                </tr>
                                <tr>
                                    <td width="41%" style="border:none">Peso:</td>
                                    <td width="59%" style="border:none">{{ datosPx.Peso }}</td>
                                </tr>
                                <tr>
                                    <td width="41%" style="border:none">Semas Gestación:</td>
                                    <td width="59%" style="border:none"> {{ datosPx.Semanas_g }}</td>
                                </tr>
                                <tr>
                                    <td width="41%" style="border:none">Nutrición parenteral:</td>
                                    <td width="59%" style="border:none">{{ datosPx.Nutricion_Parenteral }}</td>
                                </tr>
                                <tr>
                                    <td width="41%" style="border:none">Nombre Mamá:</td>
                                    <td width="59%" style="border:none">{{ datosPx.nombremama }}</td>
                                </tr>
                                <tr>
                                    <td width="21%" style="border:none">&nbsp;</td>
                                    <td width="79%" style="border:none">&nbsp;</td>
                                </tr>
                            </tbody></table>
                        </td>
                    </tr>
                </tbody></table>
            </div>
    
            <div class="col-md-4">
                        <table class="table table-striped table-bordered" width="100%">
                            <tbody><tr>
                                <td width="100%" class="ttdgray" style="text-align:center; font-size:16px; vertical-align:middle"><strong>DATOS GENERALES</strong></td>
                            </tr>
                            <tr>
                                <td class="Normal" valign="top">
                                    <table border="0" cellspacing="0" cellpadding="0" width="100%">
                                        <tbody><tr>
                                            <td width="41%" style="border:none"><strong>Institución</strong></td>
                                            <td width="59%" style="border:none"><strong>
                                                <p class="mt-2 txtlinka" style="cursor: pointer;" (click)="customerDetail(datosPx.institucion_id)" >{{ datosPx.Nombre_Comercial }}</p>
                                                </strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="41%" style="border:none"><strong>Tamiz:</strong></td>
                                            <td width="59%" style="border:none">
                                                    {{ datosPx.Tamiz }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="41%" style="border:none"><strong>Finado:</strong></td>
                                            <td width="59%" style="border:none">
                                                {{ datosPx.tipo_finado }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="41%" style="border:none"><strong>Urgente:</strong></td>
                                            <td width="59%" style="border:none">
                                                {{ datosPx.px_urgente }}
                                            </td>
                                        </tr>
                                    </tbody></table>
                                </td>
                            </tr>
                        </tbody></table>
            </div>
    
            <div class="col-md-4">
    
                <table class="table table-striped table-bordered" width="100%">
                    <tbody>
                        <tr>
                            <td width="100%" class="ttdgray" style="text-align:center; font-size:16px; vertical-align:middle">
                                <strong>Pruebas FLN</strong></td>
                        </tr>
                        <tr>
                            <td class="Normal" valign="top">
                                <table border="0" cellspacing="0" cellpadding="0" width="100%">
                
                                    <tbody>
                                        @for (fln of datosFln; track $index) {
                                            <tr>
                                                <td width="50%" style="border:none"><strong>{{ fln.prueba_desc }}</strong></td>
                                                <td width="50%" style="border:none">
                                                    <label class="alert alert-danger custom-alert">{{ fln.CalificacionTNA }}</label>
                                                </td>
                                            </tr>
                                        }
                
                                        <tr>
                                            <td width="50%" style="border:none; font-style: italic;">
                                                <strong><i class="fa fa-phone-alt me-2"></i>Notificado a Cliente</strong></td>
                                            <td width="50%" style="border:none">
                                                <div class="row" style="margin-top: 0px;">
                                                    <div class="col-sm-6" style="margin-top: 0px;">
                
                                                        <div class="form-check form-switch custom-switch">
                                                            <input class="form-check-input switchTam" type="checkbox" role="switch"
                                                            [checked]="datosPx.bitNotificadoCLiente" 
                                                            [disabled]="datosPx.bitNotificadoCLiente"
                                                            (change)="onSwitchChange($event)">
                                                            <label class="form-check-label" for="flexSwitchCheckDefault">
                                                            <span class="switch-label-on">SI</span>
                                                            <span class="switch-label-off">NO</span>
                                                            </label>
                                                        </div>
                
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
    
            </div>
        </div>
    </div>
</div>