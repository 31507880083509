<div id="comentariosOp" class="well">
    <label class="label-control">Comentarios:</label>
    <br>
    <table class="no-border black" width="100%">
        <tbody class="no-border-y">
            <tr>
                <td align="left" width="60%"><label>&nbsp;&nbsp;Operativos</label></td>
                <td align="center" width="20%">
                    @if(comentarios.Operativos.length > 0){
                        <label  class='txtlinka' (click)="verComentarios(comentarios.Operativos, 2)">
                            {{ comentarios.Operativos.length }}
                        </label>
                    }@else{
                        <label class='txtlinka sinCursor'>
                            {{ comentarios.Operativos.length }}
                        </label>
                    }
                </td>
                @if (!esVisor) {
                    <td align="center" width="20%">
                        <a  placement="top" (click)="agregarComentario(2)" ngbTooltip="Agregar comentario operativo">
                            <fa-icon [icon]="['fas', 'comments']" size="2x"></fa-icon>
                        </a>
                    </td>
                }
            </tr>
            <tr>
                <td align="left" class="btop"><label>&nbsp;&nbsp;Médicos</label></td>
                <td align="center" class="btop">
                    @if(comentarios.Medicos.length > 0){
                        <label  class='txtlinka' (click)="verComentarios(comentarios.Medicos, 1)">
                            {{ comentarios.Medicos.length }}
                        </label>
                    }@else{
                        <label class='txtlinka sinCursor'>{{ comentarios.Medicos.length }}</label>
                    }
                </td>
                @if (!esVisor) {
                    <td align="center" class="btop">
                        <a  class='txtlinka' placement="top" (click)="agregarComentario(1)" ngbTooltip="Agregar comentario médico">
                            <fa-icon [icon]="['fas', 'comments']" size="2x"></fa-icon>
                        </a>
                    </td>
                }
            </tr>
        </tbody>
    </table>
</div>