<span [ngClass]="{ 'sb-nav-fixed': !static }">
    <sb-top-nav></sb-top-nav>

    <div id="layoutSidenav">
        <div id="layoutSidenav_nav" style="border-right: solid; border-right-color: #737277;">
            <sb-side-nav [sidenavStyle]="sidenavStyle" [sideNavItems]="sideNavItems" [sideNavSections]="sideNavSections"></sb-side-nav>
        </div>

        <div id="layoutSidenav_content">
            <main>
                <div class="container-fluid">
                    <ng-content></ng-content>
                </div>
            </main>

            <!-- <sb-footer></sb-footer> -->
        </div>
    </div>
</span>
