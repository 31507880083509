import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';


@Injectable()
export class RequisitionService {
    private baseUrl = 'http://localhost:8080';

    constructor(private http: HttpClient) { }

    getRequisition$(): Observable<{}> {
        return of({});
    }




    upload(file: File): Observable<HttpEvent<any>> {
        const formData: FormData = new FormData();

        formData.append('file', file);

        const req = new HttpRequest('POST', `${this.baseUrl}/upload`, formData, {
            reportProgress: true,
            responseType: 'json'
        });

        return this.http.request(req);
    }




    getFiles(): Observable<any> {
        return this.http.get(`${this.baseUrl}/files`);
    }





}
