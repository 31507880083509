<div class="tab-content">
    @if (soliId) {
        <h2 class="text-center">Solicitud DX: {{soliId}}</h2>
    }@else {
        <h2 class="text-center">Solicitud DX</h2>
    }

    @if(!ocultarPruebas){
        <div class="container">
    
            <form
            [formGroup]="formSolicitud"
            autocomplete="off">
    
                <div class="row">
                    <div class="col-1"></div>
                    <div class="col">
                        <div class="card mb-5 mt-1">
                            <div class="card-body container-gray">
    
                                <div class="row">
                                    <div class="col-2"></div>
                                    <div class="col-3">
                                        <div class="text-center">
                                            <label class="text-center">Dirigido a:</label>
                                        </div>
                                    </div>
                                    <div class="col-1"></div>
                                    <div class="col-3">
                                        <div class="text-center">
                                            <label class="text-center">Copia a:</label>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="row">
                                    <div class="col-2"></div>
                                    <div class="col-3">
                                        <nz-select class="w-100"
                                            nzAllowClear
                                            nzPlaceHolder="Seleccione un Correo"
                                            formControlName="CorreoDirigido"
                                            (ngModelChange)="cambioEnfermedad()">
                                            @for(correo of catalogos.Correos; track $index){
                                                <nz-option
                                                    [nzValue]="correo.Usuario_sistema_id"
                                                    [nzLabel]="correo.c_nombre+' ('+correo.Tipo_Contacto_Correo+')'" >
                                                </nz-option>
                                            }
                                        </nz-select>
                                    </div>
                                    <div class="col-1"></div>
                                    <div class="col-3">
                                        <nz-select class="w-100"
                                            nzAllowClear
                                            nzPlaceHolder="Seleccione un Correo"
                                            formControlName="CorreoCopia"
                                            (ngModelChange)="cambioEnfermedad()">
                                            @for(correo of catalogos.Correos; track $index){
                                                <nz-option
                                                    [nzValue]="correo.Usuario_sistema_id"
                                                    [nzLabel]="correo.c_nombre+' ('+correo.Tipo_Contacto_Correo+')'" >
                                                </nz-option>
                                            }
                                        </nz-select>
                                    </div>
                                </div>
    
                                <div class="row">
                                    <div class="col-2">
                                        <div class="text-center">
                                            <label>Enfermedad:</label>
                                        </div>
                                    </div>
                                    <div class="col-7">
                                        <nz-select class="w-100 text-center"
                                            nzAllowClear
                                            nzPlaceHolder="Seleccione una Enfermedad"
                                            formControlName="PruebaPre"
                                            (ngModelChange)="cambioEnfermedad()">
                                            @for(enfermedad of catalogos.Enfermedades.recordset; track $index){
                                                <nz-option
                                                    [nzValue]="enfermedad.Enfermedad_id"
                                                    [nzLabel]="enfermedad.Enfermedad" >
                                                </nz-option>
                                            }
                                        </nz-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-1"></div>
                </div>
    
            </form>
        
        </div>
    }


    @if(catalogosP){

        @if (!ocultarPruebas) {
            @if (catalogosP.length > 0) {
                <table class="table-striped">
                    <thead>
                        <tr class="ttdgray">
                            <td colspan="8" class="text-center">CATALOGO DE PRUEBAS ESPECIALES</td>
                        </tr>
                        <tr class="ttdblue">
                            <td width="4%" class="text-center">ID</td>
                            <td width="30%" class="text-start">NOMBRE PRUEBA</td>
                            <td width="15%" class="text-center">LABORATORIO</td>
                            <td width="8%" class="text-center">COSTO DE LA PRUEBA</td>
                            <td width="14%" class="text-center">PRECIO BASE</td>
                            <td width="10%" class="text-center">PRECIO PROPUESTA</td>
                            <td width="15%" class="text-center">CONSULTA</td>
                            <td width="4%" class="text-center">ACCIONES</td>
                        </tr>
                    </thead>
                    <tbody>
                        @for (cat of catalogosP; track $index) {
                            <tr>
                                <td class="text-center">{{ cat.Pruebas_Especiales_id }} </td>
                                <td class="text-start">{{ cat.Pruebas_Especiales }}</td>
                                <td class="text-start">{{ cat.Laboratorio  }}</td>
                                <td class="text-center">{{ cat.Costo  }}</td>
                                <td class="text-center">{{ cat.Precio_GK }}  {{cat.Tipo_Moneda_Costo_GK}}</td>
                                <td class="text-center">
                                    <input type="number"
                                    disabled
                                    [(ngModel)]="precioIngresado[$index]">  
                                        {{cat.Tipo_Moneda_Costo_GK}}
                                </td>
                                <td> 
                                    <nz-select
                                    nzAllowClear
                                    [(ngModel)]="selectedConsulta[$index]"
                                    >
                                        @for (consulta of cat.CatConsulta; track $index) {
                                            <nz-option [nzLabel]="consulta.display" [nzValue]="consulta.value"></nz-option>
                                        }
                                    </nz-select>  
                                </td>
                                <td class="text-center">
                                    <fa-icon [icon]="['fas', 'plus-square']" class="text-success btnTam"
                                    (click)="agregarPrueba(cat, $index)" title="Agregar prueba"></fa-icon>
                                </td>
                            </tr>
                        }
                    </tbody>        
                </table>
            }@else{
                <table class="all">
                    <thead>
                        <tr class="ttdblue trS">
                            <td class="text-center" colspan="8">POR EL MOMENTO NO EXISTEN PRUEBAS ESPECIALES PARA ESTA ENFERMEDAD</td>
                        </tr>
                    </thead>
                </table>
            }
        }
        

        @if(pruebasAsignadas.length > 0){
            <table class="table-striped">
                <thead>
                    <tr class="ttdgray">
                        <td colspan="8" class="text-center fw-bold">PRUEBAS ESPECIALES</td>
                    </tr>
                    <tr class="ttdblue">
                        <td width="4%" class="text-center">ID</td>
                        <td width="30%" class="text-start">NOMBRE PRUEBA</td>
                        <td width="15%" class="text-center">LABORATORIO</td>
                        <td width="8%" class="text-center">COSTO DE LA PRUEBA</td>
                        <td width="14%" class="text-center">PRECIO BASE</td>
                        <td width="10%" class="text-center">PRECIO PROPUESTA</td>
                        <td width="15%" class="text-center">CONSULTA</td>
                        @if(!ocultarPruebas){
                            <td width="4%" class="text-center">ACCIONES</td>
                        }
                    </tr>
                </thead>
                <tbody>
                    @for (prueba of pruebasAsignadas; track $index) {
                        <tr>
                            <td class="text-center">{{ prueba.Pruebas_Especiales_id }} </td>
                            <td class="text-start">{{ prueba.Pruebas_Especiales }}</td>
                            <td class="text-start">{{ prueba.Laboratorio  }}</td>
                            <td class="text-center">{{ prueba.costo  }}</td>
                            <td class="text-center">{{ prueba.Precio_GK }}  {{prueba.moneda}}</td>
                            <td class="text-center">
                                {{prueba.PRECIO_PROPUESTA}} {{prueba.moneda}}
                            </td>
                            <td class="text-center"> 
                                {{ prueba.tcon }}
                            </td>
                            @if(!ocultarPruebas){
                                <td class="text-center">
                                    <fa-icon [icon]="['fas', 'minus-square']" class="text-danger btnTam"
                                    (click)="eliminarPrueba(prueba)" title="Agregar prueba"></fa-icon>
                                </td>
                            }
                        </tr>
                    }
                </tbody>        
            </table>
        }@else{
            <table class="all mt-4">
                <thead>
                    <tr class="ttdblue trS">
                        <td class="text-center" colspan="8">POR EL MOMENTO LA ASESORIA NO TIENE PRUEBAS ASIGNADAS</td>
                    </tr>
                </thead>
            </table>
        }


        <div class="text-center mt-4">
            @if(!ocultarPruebas){
                <button class="btn btn-primary" [disabled]="pruebasAsignadas.length === 0"  (click)="ocultarPruebas = true">Validar</button>
            }@else{
                <button class="btn btn-primary"  (click)="enviarSolicitud()"><fa-icon [icon]="['fas', 'envelope']" class="iconTam"></fa-icon> Correo</button>
                <button class="btn btn-success ms-4"  (click)="ocultarPruebas = false">Modificar</button>
            }
        </div>



    }




</div>