import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppCommonService } from '@common/services';
import { ComentariosMed, MedicoTK, OperativoTK } from '@modules/medical-records/models';
import { ReportsService } from '@modules/reports/services';

@Component({
  selector: 'gk-view-comments-g',
  templateUrl: './view-comments-g.component.html',
  styleUrl: './view-comments-g.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewCommentsGComponent{

  //? Varibles
  titulo: string;
  subTitulo: string;
  tipoComentario: number;
  canDelete: boolean;
  comentarios: MedicoTK[] | OperativoTK[];


  //* Variable para indicar si se debe ac<tualizar la información en el componente principal.
  @Output() actualizarDatosEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  //? Servicios
  reportsService = inject(ReportsService);
  appCommonService = inject(AppCommonService);

  mostrarAcciones(): boolean {
    return this.comentarios.some(comentario => 
      comentario.btnActivar === 1 || comentario.btnInactivar === 1
    );
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ViewCommentsGComponent>,
    private cdr: ChangeDetectorRef
  ){
    if(this.data && this.data.Titulo && this.data.Comentarios && this.data.TipoComentario){
      this.titulo         = this.data.Titulo;
      this.tipoComentario = this.data.TipoComentario;
      this.subTitulo      = this.data.SubTitulo   || null;
      this.comentarios    = this.data.Comentarios || [];
      this.canDelete      = this.data.CanDelete   || false;
    }
  }


  cambiarComentarioTK(comentario: MedicoTK | OperativoTK){
    this.appCommonService.spinner(true);
    
    let verCliente = comentario.Ver_Cliente

    //* Si verCliente es un booleano, lo convierto a número (true => 1, false => 2)
    if (typeof verCliente === 'boolean') {
      verCliente = verCliente ? 1 : 0;
    }

    //* Si es 1, lo cambio a 2, y si es 2, lo cambio a 1
    verCliente = verCliente === 1 ? 0 : 1;

    const comentarioSend: any = {
      vercliente: verCliente,
      CommId: comentario.ID,
      tipo_coment: this.tipoComentario,
    }

    if(comentario.tbl !== ''){
      comentarioSend.resultadoid = comentario.tbl;
    }

    this.reportsService.activaODesactivaComments(comentarioSend).subscribe({
      next: (resp) => {
        this.appCommonService.spinner(false);
        this.appCommonService.showMsg(`Operacion Exitosa`, `${resp.Mensaje}`, () => {
          this.actualizarDatosEmitter.emit(true);
          if(this.canDelete){
            this.comentarios = resp.Comentarios.Medicos
          }else{
            this.comentarios = resp.Comentarios.Operativos
          }
          this.cdr.detectChanges();
        });
      },
      error: err => {
        this.appCommonService.spinner(false);
        this.appCommonService.showMsg(`Operacion fallida`, `A ocurrido un error al querer realizar la operacion`);
        console.log('err', err);
      }
    })
    
  }

  eliminarComentarioTk(comentario: MedicoTK | OperativoTK){

    this.appCommonService.spinner(true);

    const comentarioSend: any = {
      CommId: comentario.ID,
      resultadoid: comentario.tbl
    }

    this.reportsService.eliminaComments(comentarioSend).subscribe({
      next: (resp) => {
        this.appCommonService.spinner(false);
        this.appCommonService.showMsg(`Operacion Exitosa`, `${resp.Mensaje}`, () => {
          this.actualizarDatosEmitter.emit(true);
          this.comentarios = resp.Comentarios.Medicos
          this.cdr.detectChanges();
        });
      },
      error: err => {
        this.appCommonService.spinner(false);
        this.appCommonService.showMsg(`Operacion fallida`, `A ocurrido un error al querer realizar la operacion`);
        console.log('err', err);
      }
    })

  }

}
