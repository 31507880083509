import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogComponent, ConfirmationDialogResult, FileViewerDialogComponent, ModalViewFilterComponent } from '../components';

export interface DialogRef<T = unknown> extends NgbModalRef {
    result: Promise<T>;
}

export interface ConfirmDialogOptions {
    type: 'confirm' | 'text' | 'textreq';
    message: string;
    title?: string;
    btnOkText?: string;
    btnCancelText?: string;
}

@Injectable({
    providedIn: 'root',
})
export class DialogService {
    constructor(private ngbModal: NgbModal) {}

    open<TResult = unknown, TData = unknown, TComponent = unknown>(
        component: TComponent,
        options: NgbModalOptions & { data?: TData } = {}
    ): DialogRef<TResult> {
        const { data, ...dialogOptions } = options;
        const dialogRef = this.ngbModal.open(component, dialogOptions);

        if (data) {
            // Assigns each data values to their corresponding component input
            for (const key of Object.keys(data)) {
                dialogRef.componentInstance[key] = data[key];
            }
        }

        return dialogRef;
    }

    confirm(options: ConfirmDialogOptions) {
        const dialogRef = this.open<ConfirmationDialogResult>(ConfirmationDialogComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false,
            data: {
                title: '',
                btnOkText: 'Aceptar',
                btnCancelText: 'Cancelar',
                ...options,
            },
        });

        return dialogRef.result;
    }

    viewFile(base64File: string, ext: string) {
        const file = ext === 'pdf' ? 'data:application/pdf;base64,' + base64File : 'data:application/json;base64,' + base64File;
        const dialogRef = this.open<unknown>(FileViewerDialogComponent, {
            size: 'xl',
            backdropClass: 'modalcustom',
            data: {
                ext,
                file,
            },
        });

        return dialogRef.result;
    }

    viewUrl(file: string, ext: string) {
        const dialogRef = this.open<unknown>(FileViewerDialogComponent, {
            size: 'xl',
            backdropClass: 'modalcustom',
            data: {
                ext,
                file,
            },
        });

        return dialogRef.result;
    }

    viewModalFilter(datoRecollecion:any,visor:any){
        const dialogRef = this.open<unknown>(ModalViewFilterComponent, {
            size: 'xl',
            backdropClass: 'modalcustom',
            data: {
                datoRecollecion,
                visor
            },
        });

        return dialogRef.result;
    }
}
