import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { UserService } from '@modules/auth/services';
import { pipe } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private readonly endpoints = [
        environment.api.auth,
        environment.api.customers,
        environment.api.dashboard,
        environment.api.employes,
        environment.api.operations,
        environment.api.operationsAk,
        environment.api.marketing,
        environment.api.patients,
        environment.api.medicalArea,
        environment.api.administration,
        environment.api.commons,
        environment.api.system,
        environment.api.operationsCatalogs,
        environment.api.administracionCatalogs,
        environment.api.areaMedicaCatalogs,
        environment.api.ckReport,
        environment.api.dxReports,
        environment.api.tkReport,
        environment.api.akReport,
        environment.api.medicalReportsAk,
        environment.api.medicalReportsCK,
        environment.api.medicalReportsTK,
        environment.api.administrationReports,
        environment.api.region,
        environment.api.medicalRecords,
        environment.api.medicalRecordsComponents,
        environment.api.commons2,
        environment.api.operinvdis,
        environment.api.alertas,
        environment.api.operationsTools,
        environment.api.company,
        environment.api.systemcustomers,
        environment.api.resultoperationsck,
        environment.api.resultoperationsak,
        environment.api.operationsmenu,
        environment.api.menuoperations,
        environment.api.administracionCierreMes,
        environment.api.admonCustomers,
        environment.api.admonReportCierre,
        environment.api.dhl,
        environment.api.dxprocess,
        environment.api.adminFacturacion,
        environment.api.mktOperation
    ];

    constructor(private userService: UserService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler) {
        /**
         * Only includes our authentication header for our API endpoints
         */
        if (!this.endpoints.some(url => request.url.includes(url))) {
            return next.handle(request);
        }

        console.groupCollapsed(`${request.method} ${request.url}`);
        console.log(`Headers => ${JSON.stringify(request.headers.keys())}`);
        console.log('hasAuthorizationHeader:', !!request.headers.get('Authorization'));
        console.groupEnd();

        if (request.headers.get('Authorization')) {
            return next.handle(request).pipe(this.logResponseBody());
        }

        return this.userService.accessToken$.pipe(
            filter(accessToken => !!accessToken),
            take(1),
            switchMap(accessToken => {
                const headers = request.headers.set('Authorization', `Bearer ${accessToken}`);
                const clonedRequest = request.clone({ headers });

                return next.handle(clonedRequest).pipe(this.logResponseBody());
            })
        );
    }

    private logResponseBody() {
        return pipe(
            tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    console.log(`Results from ${event.url} =>`, event.body);
                }
            })
        );
    }
}
