import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidatorService {

  public fechaPattern: string = "^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$"; // Formato esperado: dd/mm/yyyy

  public curpPattern: string = "^[A-Z][A,E,I,O,U,X][A-Z]{2}[0-9]{2}[0-1][0-9][0-3][0-9][M,H][A-Z]{2}[B,C,D,F,G,H,J,K,L,M,N,Ñ,P,Q,R,S,T,V,W,X,Y,Z]{3}[0-9,A-Z][0-9]$"

  constructor() { }

  //? Convertir archivos en base64
  convertToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result?.toString() || '';
        const base64Content = base64String.split(';')[1].split(',')[1];
        resolve(base64Content);
      };
      reader.onerror = (error) => reject(error);
    });
  }


  //? Descargar archivos pdf
  downloadPDF(base64: string, fileName: string) {
    const linkSource = `data:application/pdf;base64,${base64}`;
    const downloadLink = document.createElement("a");

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  //? Niega el input si solo tiene espacios en blanco
  noWhitespaceValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;

    // Si el valor es null o undefined, o si no es un string, no aplicar la validación
    if (value === null || value === undefined || typeof value !== 'string') {
      return null;
    }

    // Aplicar validación de espacios en blanco solo si el valor es un string
    const isWhitespace = value.trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }


}
