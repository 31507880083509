<div class="tab-content">
    <h2 class="text-center fw-light">Agregar prueba especial al paciente</h2>

    <div class="container">

        <div class="row">
            <div class="card">
                <div class="row">
                    <div class="col-1"></div>
                    <div class="col-9">
                        <div class="card-body container-gray">
                            <div class="row">
                                <div class="col-3">
                                    <label>Palabra de Busqueda:</label>
                                </div>
                                <div class="col-6">
                                    <input type="text" class="form-control" (input)="updateDataTableSearch($event.target.value)">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                @if (pruebasEspeciales) {
                    <table class="table-striped" datatable style="width: 100%;">
                        <thead>
                            <tr class="ttdblue">
                                <td width="15%" class="text-center">ID</td>
                                <td width="85%" class="text-start">NOMBRE DE LA PRUEBA</td>
                            </tr>
                        </thead>
                        <tbody>
                            @for (prueba of pruebasEspeciales; track $index) {
                                <tr>
                                    <td class="text-center">{{ prueba.codigo_prueba }} </td>
                                    <td class="text-start"><label class="txtlinka" (click)="verPrueba(prueba.pruebas_especiales_id)">{{ prueba.Pruebas_especiales }}</label></td>
                                </tr>
                            }@empty {
                                <tr>
                                    <td colspan="2">
                                        Por el momento no hay pruebas disponibles.
                                    </td>
                                </tr>
                            }
                        </tbody>        
                    </table>
                }
            </div>
            <div class="col-1"></div>
        </div>



    </div>

</div>