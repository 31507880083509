<nav class="sb-sidenav accordion" id="sidenavAccordion" [ngClass]="sidenavStyle">
    <div class="sb-sidenav-menu" *ngIf="!isCliente" >



        <div class="nav">
            <ng-container>
                <div class="sb-sidenav-menu-heading min-ocultar">Menu Principal <i class="fa fa-chevron-right" aria-hidden="true"></i></div>
                <ng-container>
                    <div class="min-ocultar">
                        <a class="nav-link" href="#">Empresa </a>
                        <a class="nav-link" href="#">Operaciones </a>
                        <a class="nav-link" href="#">Area Medica </a>
                    </div>
                </ng-container>
            </ng-container>
            <ng-container *ngFor="let section of sideNavSections">
                <div class="sb-sidenav-menu-heading" *ngIf="section.text">{{ section.text }}</div>
                <ng-container *ngFor="let item of section.items">
                    <sb-side-nav-item [sideNavItem]="sideNavItems[item]"></sb-side-nav-item>
                </ng-container>
            </ng-container>
        </div>

    </div>
    <!--
    <div class="sb-sidenav-footer" *ngIf="!isCliente" >
        <div class="small">Logged in as:</div>
        <span *ngIf="userService.user$ | async as user">{{user.name}} {{user.lastName}} </span>
    </div>
    -->
</nav>
