import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { NzSafeAny } from 'ng-zorro-antd/core/types';

/**
 * Locale keys do not match with our locales (e.g. "es-MX"), they use NZ locales instead.
 */
export type GkErrorsOptions = Record<'es' | 'en', string> & Record<string, NzSafeAny>;
export type GkValidationErrors = Record<string, GkErrorsOptions>;

export class GkValidators extends Validators {
    static required(control: AbstractControl): GkValidationErrors | null {
        if (Validators.required(control) === null) {
            return null;
        }

        return {
            required: {
                es: `Este campo es obligatorio`,
                en: `This field is required`,
            },
        };
    }

    static minLength(minLength: number): ValidatorFn {
        return (control: AbstractControl): GkValidationErrors | null => {
            if (Validators.minLength(minLength)(control) === null) {
                return null;
            }

            return {
                minlength: {
                    es: `El tamaño mínimo es ${minLength}`,
                    en: `Min length is ${minLength}`,
                },
            };
        };
    }

    static maxLength(maxLength: number): ValidatorFn {
        return (control: AbstractControl): GkValidationErrors | null => {
            if (Validators.maxLength(maxLength)(control) === null) {
                return null;
            }

            return {
                maxlength: {
                    es: `El tamaño máximo es ${maxLength}`,
                    en: `Max length is ${maxLength}`,
                },
            };
        };
    }

    static onlyNumbers(control: AbstractControl): GkValidationErrors | null {
        if (Validators.pattern(/^-?(0|[1-9]\d*)?$/)(control) === null) {
            return null;
        }

        return {
            onlynumbers: {
                es: `El PF tiene que ser un numero`,
                en: `only allow numbers`,
            },
        };
    }

    static onlynumbers2(onlynumbers2: any): ValidatorFn {
        return (control: AbstractControl): GkValidationErrors | null => {
            if (Validators.pattern(onlynumbers2)(control) === null) {
                return null;
            }

            return {
                onlynumbers2: {
                    es: `solonumero`,
                    en: `ingles`,
                },
            };
        };
    }
}

function isEmptyInputValue(value: NzSafeAny): boolean {
    return value == null || value.length === 0;
}
