<h2 mat-dialog-title class="text-center text-primary mb-1">
    {{ titulo }}
</h2>
<mat-dialog-content class="mat-typography">

    <img [src]="imagenBase64" class="img-fluid img-size" [ngStyle]="{ 'width': width || '300px!important', 'height': height || '300px!important' }" >

    
    <mat-divider class="mt-4"></mat-divider>
    <mat-dialog-actions align="end" class="py-2">
        <button [mat-dialog-close] class="btn btn-secondary btn-sm mt-3">
            Regresar
        </button>
    </mat-dialog-actions>

</mat-dialog-content>
