<h2 mat-dialog-title>
    {{ titulo }}
</h2>
<mat-dialog-content class="mat-typography">
    {{ mensaje }}
</mat-dialog-content>
    
<mat-divider class="mt-4"></mat-divider>
<mat-dialog-actions align="end" class="gris py-2">
    @if(confirm){
        <button [mat-dialog-close] class="btn btn-danger mx-2">Cancelar</button>
        <button cdkFocusInitial class="btn btn-success mx-2" (click)="confirmacion()" >Confirmar</button>
    }@else{
        <button [mat-dialog-close] class="btn btn-secondary mx-2">Cerrar</button>
    }
</mat-dialog-actions>