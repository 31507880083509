<h3 mat-dialog-title class="text-start">{{correoRequest.Titulo}}</h3>
<mat-dialog-content class="mat-typography">
    <form
        [formGroup]="formEnviarSolicitud"
        (ngSubmit)="enviarSolicitud()"
        autocomplete="off">
        
        <div class="row card">
            <div class="card-body container-gray">

                <div class="row">
                    <div class="col-2 text-end">
                        <label>Tipo Asesoria:</label>
                    </div>
                    
                    <div class="col-5">
                        <nz-select 
                        nzPlaceHolder="Seleccione Asesoria" 
                        formControlName="tipoasesorid"
                        >
                        @for(tipo of correoRequest.Asesoria; track $index){
                            <nz-option
                                [nzValue]="tipo.Tipo_Asesoria_id"
                                [nzLabel]="tipo.Tipo_Asesoria">
                            </nz-option>
                        }
                        </nz-select>
                    </div>
                
                </div>
    
                <div class="row">
                    <div class="col-2 text-end" >
                        <label>Para:</label>
                    </div>
    
                    <div class="col-10">
                        <nz-select 
                        nzMode="multiple"
                        nzPlaceHolder="Seleccione Correo(s)"
                        formControlName="Correosto">
                            @for(correo of correoRequest.Correosto; track $index){
                                <nz-option
                                    [nzValue]="correo.correo_medico"
                                    [nzLabel]="correo.correo_medico">
                                </nz-option>
                            }
                        </nz-select>
                    </div>

                </div>

                <div class="row">

                    <div class="col-2 text-end">
                        <label>Copia:</label>
                    </div>

                    <div class="col-10">
                        <nz-select 
                        nzMode="multiple"
                        nzPlaceHolder="Seleccione Correo(s)"
                        formControlName="Correoscc">
                            @for(correo of correoRequest.Correoscc; track $index) {
                                <nz-option
                                    [nzValue]="correo.correo_medico_cc"
                                    [nzLabel]="correo.correo_medico_cc">
                                </nz-option>
                            }
                        </nz-select>
                    </div>

                </div>

                <div class="row">
    
                    <div class="col-2 text-end">
                        <label>Asunto:</label>
                    </div>

                    <div class="col-10">
                        <input class="form-control" type="text" formControlName="Asunto" />
                    </div>
                </div>



            </div>
        </div>

        <div class="row mt-3 card">
            <div class="card-body container-gray">
                <span class="form-text text-danger validation-message ms-2" *ngIf="isValidFieldEnviarSolicitud('Cuerpo_email')" @fadeInOut>
                    <b>Favor de agregar el cuerpo del correo</b>
                </span>
                <quill-editor formControlName="Cuerpo_email" [modules]="editorModules" style="height: 200px; width: 100%;"></quill-editor>
            </div>
        </div>


        <div class="full-width-element full-height-element">
            <mat-divider class="mt-2"></mat-divider>
            <mat-dialog-actions align="end" class="gris py-4">
                <button type="submit" class="btn btn-primary mx-2 mt-3">
                    Enviar
                </button>
                <button type="button" [mat-dialog-close] class="btn btn-secondary mx-2 mt-3">
                    Cerrar
                </button>
            </mat-dialog-actions>
        </div>
    
    </form>
</mat-dialog-content>