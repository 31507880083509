import { ChangeDetectionStrategy, Component, Inject, OnInit, inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TablaPrecio } from '@modules/customers/models/tablaPrecio.model';
import { CustomersService } from '@modules/customers/services';

@Component({
  selector: 'gk-tabla-precios-m',
  templateUrl: './tabla-precios-m.component.html',
  styleUrl: './tabla-precios-m.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TablaPreciosMComponent {

  //? combos recibidos
  public precios!:TablaPrecio ;
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,){
    if(this.data && this.data.tipotablaprecio ){
      this.precios = this.data.tipotablaprecio;
    }
  }

}
