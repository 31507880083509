<label class="btn-primary" style="display:none;" ngbButtonLabel>
    <input #gralCLick type="checkbox" ngbButton > ENE
</label>
<form [formGroup]="formMat">
    <div class="mt-4">
        <button type="button" class="btn btn-outline-primary" (click)="cargarDatos()">
            Cargar datos de recoleccion
            <mat-icon class="iconTam">autorenew</mat-icon>
        </button>
    </div>
    <div class="row mt-4 ms-2">
        <label class="col-2 control-label" >Nombre del lugar:</label>
        <div class="col-9" >
            <span class="form-text text-danger my-1" *ngIf="isValidFieldMateriales('EnvioNombre')">
                <b>Este campo es requerido</b>
            </span>
            <div class="input-group mb-3">
                <input type="text"  class="form-control" formControlName="EnvioNombre" >
            </div>
        </div>
    </div>
    <div class="row mt-4 ms-2">
        <label class="col-2 control-label">Referencia:</label>
        <div class="col-9">
            <span class="form-text text-danger my-1" *ngIf="isValidFieldMateriales('EnvioReferencia')">
                <b>Este campo es requerido</b>
            </span>
            <div class="input-group mb-3">
                <input type="text"  class="form-control" formControlName="EnvioReferencia" >
            </div>
        </div>
    </div>
    <div class="row mt-4 ms-2">
        <label class="col-2 control-label" >Direccion:</label>
        <div class="col-9">
            <span class="form-text text-danger my-1" *ngIf="isValidFieldMateriales('EnvioDireccion')">
                <b>Este campo es requerido</b>
            </span>
            <div class="input-group mb-3">
                <input type="text"  class="form-control" formControlName="EnvioDireccion" >
            </div>
        </div>
    </div>
    <div class="row mt-4 ms-2">
        <label class="col-2 control-label" >Colonia:</label>
        <div class="col-9" align="left">
            <span class="form-text text-danger my-1" *ngIf="isValidFieldMateriales('EnvioColonia')">
                <b>Este campo es requerido</b>
            </span>
            <div class="input-group mb-3">
                <input type="text"  class="form-control" formControlName="EnvioColonia" >
            </div>
        </div>
    </div>
    <div class="row mt-4 ms-2">
        <label class="col-2 control-label">Ciudad:</label>
        <div class="col-3" >
            <span class="form-text text-danger my-1" *ngIf="isValidFieldMateriales('EnvioCiudad')">
                <b>Este campo es requerido</b>
            </span>
            <div class="input-group mb-3">
                <select class="form-control" formControlName="EnvioCiudad" >
                    <option value="">Seleccione una ciudad</option>
                    <option *ngFor="let ciudad of combos.CiudadEstado"
                        [value]="ciudad.Ciudad_id">
                        {{ ciudad.Ciudad }}
                    </option>
                </select>
            </div>
        </div>
        <div class="col-md-1"></div>
        <label class="col-sm-2 control-label" >C.P:</label>
        <div class="col-3" >
            <span class="form-text text-danger my-1" *ngIf="isValidFieldMateriales('EnvioCodigoPostal')">
                <b>Este campo es requerido</b>
            </span>
            <div class="input-group mb-3">
                <input type="text"  class="form-control" formControlName="EnvioCodigoPostal" >
            </div>
        </div>
    </div>
</form>