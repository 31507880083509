<h3 mat-dialog-title class="text-center">Detalle solicitud</h3>
<mat-dialog-content class="mat-typography">

    <div class="full-width-element full-height-element">
        <div class="gris paddin10">
            <div class="container">
                <div class="mt-1 mb-4">
                    @for (solicitud of datosEstudio.DetalleSolicitud; track $index) {
                        <mat-accordion class="mb-2">
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Detalle de Solicitud No. {{id}}
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <strong>Nombre:</strong> {{ solicitud.NombreBebe }}
                                <br>
                                <strong>Fecha Nacimiento:</strong> {{ solicitud.fecha_nacimiento | date: 'dd-MMM-yyyy': "UTC" }}
                                <br>
                                <strong>Sexo:</strong>  {{ solicitud.sexo }}
                                <br>
                                <strong>Nombre Medico:</strong> {{ solicitud.NombreMedico }}
                                <br>
                                <strong>Institucion:</strong> {{ solicitud.Institucion }}
                                <br>
                                <strong>Descripcion de padecimiento:</strong> {{ solicitud.Desc_Padecimiento }}
                                <br>
                                <strong>Antecedentes del padecimiento:</strong> {{ solicitud.Antecedente_Padecimiento }}
                                <br>
                                <strong>Estudios realizados:</strong>  {{ solicitud.Estudios_Realizados }}
                                <br>
                                <strong>Impresion Diagnostica:</strong> {{ solicitud.Impresion_Diagnostica }}
                                <br>
                                <strong>Comentario Solucion:</strong>  {{ solicitud.Comentario_Solucion }}
                                <br>
                                <strong>Cuerpo Correo:</strong> {{ solicitud.Cuerpo_correo_solucion }}
                            </mat-expansion-panel>
                        </mat-accordion>
                    }
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <table class="table-striped mt-5" style="width: 100%;">
                <thead>
                    <tr class="ttdblue">
                        <td   class="text-start txtitgk">Folios por capturar</td>
                        <td   class="text-start txtitgk">Prueba</td>
                        <td   class="text-start txtitgk">Estatus</td>
                        <td   class="text-start txtitgk">Precio (Mas IVA)</td>
                    </tr>
                </thead>
                <tbody>
                    @for(datos of datosEstudio.Grid; track $index){
                        <tr>
                            <td class="text-start">
                                {{ datos.Codigo }}
                            </td>
                            <td class="text-start">
                                {{ datos.Pruebas_Especiales }}
                            </td>
                            <td class="text-start">
                                {{ datos.Estatus }}
                            </td>
                            <td class="text-start">
                                {{ datos.precio_propuesta }} {{ datos.Abreviatura_Moneda }}
                            </td>   
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    </div>

    <div class="full-width-element full-height-element">
        <mat-divider></mat-divider>
        <mat-dialog-actions align="end" class="gris mb-4">
            <button type="button" [mat-dialog-close] class="btn btn-secondary mx-2 mt-3">
                Cerrar
            </button>
        </mat-dialog-actions>
    </div>
</mat-dialog-content>