import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { GkFormErrorsDirective } from './directives/gk-form-errors.directive';

const nzModules = [NzFormModule, NzInputModule, NzSelectModule];

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule, ...nzModules],
    declarations: [GkFormErrorsDirective],
    exports: [FormsModule, ReactiveFormsModule, ...nzModules, GkFormErrorsDirective],
})
export class GkFormsModule {}
