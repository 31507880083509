import { trigger, transition, style, animate } from '@angular/animations';
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppCommonService } from '@common/services';
import { CorreoPre, CorreosPre, PruebaPre } from '@modules/medical-records/models';
import { MedicalRecordsService } from '@modules/medical-records/services';

@Component({
  selector: 'gk-envio-correo',
  templateUrl: './envio-correo.component.html',
  styleUrl: './envio-correo.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeInOut', [
        transition(':enter', [
            style({ opacity: 0 }),
            animate('500ms ease-in', style({ opacity: 1 }))
        ]),
        transition(':leave', [
            style({ opacity: 1 }),
            animate('500ms ease-out', style({ opacity: 0 }))
        ])
    ]),
  ]
})
export class EnvioCorreoComponent implements OnInit{

  //? Variables
  id: number;
  correos:  CorreoPre[];
  pruebas:  PruebaPre[];

  //* Variable para indicar si se debe actualizar la información en el componente principal.
  @Output() actualizarDatosEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  //? Servicios
  private medicalRecordsService = inject(MedicalRecordsService);
  private fb = inject(FormBuilder);
  private appCommon = inject(AppCommonService);

  formEnviarCorreo: FormGroup = this.fb.group({
    PruebaId:         [ ,  Validators.required] ,
    Texto:            [ ,  Validators.required],
    ContactosIdLista: [ ,  Validators.required],
  });

  isValidFieldAsignarComentario( field: string ): boolean | null {
    return this.formEnviarCorreo.controls[field].errors 
    && this.formEnviarCorreo.controls[field].touched;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EnvioCorreoComponent>
  ){
    if(this.data && this.data.Id){
      this.id = this.data.Id;
    }
  }

  ngOnInit(): void {
    this.obtenerCE(this.id);
  }

  obtenerCE(id:number){
    this.appCommon.spinner(true);
    this.medicalRecordsService.getCorreosPreliminar(id).subscribe({
      next: (resp: CorreosPre) => {
        this.appCommon.spinner(false);
        this.correos = resp.Correos;
        this.pruebas = resp.Pruebas;
      },
      error: err => {
        this.appCommon.spinner(false);
        this.appCommon.showMsg(`Operacion fallida`, `A ocurrido un error al querer realizar la operacion`);
        console.log('err', err);
      }
    })
  }

  transformarString(formValue){
    if(formValue.PruebaId){
      formValue.PruebaId = formValue.PruebaId.join(', ');
    }
    if(formValue.ContactosIdLista){
      formValue.ContactosIdLista = formValue.ContactosIdLista.join(', ');
    }
    return formValue
  }

  generarTexto(){
    this.appCommon.spinner(true);
    const formValue =  { ...this.formEnviarCorreo.value };
    delete formValue.Texto; 
    formValue.PacienteID = this.id;
    this.transformarString(formValue);
    this.medicalRecordsService.postTextoCorreo(formValue).subscribe({
      next: (resp) => {
        this.formEnviarCorreo.patchValue({
          Texto: resp
        })
        this.appCommon.spinner(false);
      },
      error: err => {
        this.appCommon.spinner(false);
        this.appCommon.showMsg(`Operacion fallida`, `A ocurrido un error al querer realizar la operacion`);
        console.log('err', err);
      }
    })
  }


  //? Configuracion del editor de texto
  editorModules = {
    toolbar: [
      [{ 'header': [] }],      // Tamaño de texto
      ['bold', 'italic', 'underline', 'strike' ],                        
    ]
  };


  enviarCorreo() {
    if(this.formEnviarCorreo.invalid){
      this.appCommon.showMsg('Asignacion invalida', `Favor de rellenar los campos faltantes`);
      this.formEnviarCorreo.markAllAsTouched();
      return;
    }
    this.appCommon.spinner(true);
    const formValue =  { ...this.formEnviarCorreo.value };
    this.transformarString(formValue);
    formValue.FiltroId = Number(this.id);
    this.medicalRecordsService.postPreliminarSave(formValue).subscribe({
      next: (resp) => {
        this.appCommon.spinner(false);
        this.appCommon.showMsg(`Operacion Exitosa`, `${resp.Mensaje}`, () => {
          this.actualizarDatosEmitter.emit(true);
          this.dialogRef.close();
        });
      },
      error: err => {
        this.appCommon.spinner(false);
        this.appCommon.showMsg(`Operacion fallida`, `A ocurrido un error al querer realizar la operacion`);
        console.log('err', err);
      } 
    });
    
  }


}
