import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import type { DatosPX, ResultDatosFLN } from '@modules/medical-records/models';
import { ActivatedRoute, Router } from '@angular/router';
import { AppCommonService } from '@common/services';
import { MedicalRecordsService } from '@modules/medical-records/services';

@Component({
    selector: 'gk-file-general-data',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './file-general-data.component.html',
    styleUrls: ['file-general-data.component.scss'],
})
export class FileGeneralDataComponent implements OnInit{

  //? Variables
  @Input() datosPx: DatosPX;
  @Input() datosFln: ResultDatosFLN[];
  id: string;
  
  //* Variable para indicar si se debe actualizar la información en el componente principal.
  @Output() notificacionAgregada = new EventEmitter<void>();

  //? Servicios
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private appCommon= inject(AppCommonService);
  private medicalRecordsService = inject(MedicalRecordsService);

  ngOnInit(): void {
    this.route.params.subscribe( params => {
      this.id = params['pacienteId']
    })
  }

  customerDetail(id: number) {

    const urlTree = this.router.createUrlTree([`/customers/detail/${id}`], {
      queryParams: { esVisor: true }
    });
    
    const url = window.location.origin + this.router.serializeUrl(urlTree);
    window.open(url, '_blank', 'width=1000,height=650');
  }

  onSwitchChange(event: Event): void {
    const isChecked = (event.target as HTMLInputElement).checked;
    
    //* Si el switch está en "SI" (activado)
    if (isChecked) {
      this.notificaCliente();
    }
  }

  notificaCliente(){
    this.appCommon.spinner(true);
    this.medicalRecordsService.clientNotification(this.id).subscribe({
      next: ( resp ) =>{
        this.appCommon.spinner(false);
        this.appCommon.showMsg(`Operacion Exitosa`, `${resp.Mensaje}`, () => {
          this.notificacionAgregada.emit();
        });
      },
      error: err => {
        this.appCommon.spinner(false);
        this.appCommon.showMsg(`Operacion fallida`, `A ocurrido un error al querer realizar la operacion`);
        console.log('err', err);
      }
    })
  }


}
