<nav class="sb-topnav navbar navbar-expand navbar-dark">
    <a class="navbar-brand divoculto" routerLink="/home"> <img src="assets/img/logo.png" alt="Logo Genomi-k" /> Genomi-k </a>
    <button class="btn btn-link btn-sm order-1 order-lg-0" id="sidebarToggle" data-cy="topNavToggleSideNav" (click)="toggleSideNav(sideMenuToggle)">
        <fa-icon [icon]="['fas', 'bars']"></fa-icon>
    </button>

    <gk-top-nav-menu class="flex-grow-1" [menuItems]="topNavMenu$ | async"></gk-top-nav-menu>

    <!-- Navbar Search
    <form class="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
        <div class="input-group">
            <input class="form-control" type="text" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2" />
            <div class="input-group-append">
                <button class="btn btn-primary" type="button"><fa-icon [icon]='["fas", "search"]'></fa-icon></button>
            </div>
        </div>
    </form>
    -->

    <!----iconos superiores-->

    <ul class="navbar-nav ms-auto nav-flex-icons">
        <li class="nav-item dropdown dropdown-user no-caret divoculto" ngbDropdown placement="bottom-right" display="dynamic" style="margin-right: 5%">
            <a class="nav-link dropdown-toggle dropdown-toggle-ico dropdown-toggle-no-ico" id="userDropdown" ngbDropdownToggle data-cy="userMenu" role="button" aria-haspopup="true" aria-expanded="false" title="Asistencia" style="width: 90%">
                <fa-icon [icon]="['fas', 'clock']"></fa-icon>
            </a>
            <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu aria-labelledby="dropdownUser">
                <h6 class="dropdown-header">
                    <div class="dropdown-user-details">
                        <div>
                            <fa-icon [icon]="['fas', 'info']"></fa-icon>&nbsp;20 Feb Hr. 08:43</div>
                    </div>
                </h6>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" routerLink="/dashboard">Ver asistencia</a>
            </div>
        </li>
        <li class="nav-item dropdown dropdown-user no-caret divoculto" ngbDropdown placement="bottom-right" display="dynamic" style="margin-right: 5%">
            <a class="nav-link dropdown-toggle dropdown-toggle-ico dropdown-toggle-no-ico" id="userDropdown" ngbDropdownToggle data-cy="userMenu" role="button" aria-haspopup="true" aria-expanded="false" title="Acuerdos" style="width: 90%">
                <fa-icon [icon]="['fas', 'tasks']"></fa-icon>
            </a>
            <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu aria-labelledby="dropdownUser">
                <h6 class="dropdown-header">
                    <div class="dropdown-user-details">
                        <div>
                            <fa-icon [icon]="['fas', 'info']"></fa-icon>&nbsp;20 Feb Hr. 08:43</div>
                    </div>
                </h6>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" routerLink="/dashboard">Ver Acuerdos</a>
            </div>
        </li>
        <li class="nav-item dropdown dropdown-user no-caret divoculto" ngbDropdown placement="bottom-right" display="dynamic" style="margin-right: 5%">
            <a class="nav-link dropdown-toggle dropdown-toggle-ico dropdown-toggle-no-ico" id="userDropdown" ngbDropdownToggle data-cy="userMenu" role="button" aria-haspopup="true" aria-expanded="false" title="Alertas del Sistema" style="width: 90%">
                <fa-icon [icon]="['fas', 'bell']"></fa-icon>
            </a>
            <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu aria-labelledby="dropdownUser">
                <h6 class="dropdown-header">
                    <div class="dropdown-user-details">
                        <div>
                            <fa-icon [icon]="['fas', 'info']"></fa-icon>&nbsp;Solicitud de Materiales Alicia...</div>
                    </div>
                </h6>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" routerLink="/dashboard">Ver todas las alertas</a>
            </div>
        </li>
        <li class="nav-item dropdown dropdown-user no-caret divoculto" ngbDropdown placement="bottom-right" display="dynamic" style="margin-right: 5%">
            <a class="nav-link dropdown-toggle dropdown-toggle-ico dropdown-toggle-no-ico" id="userDropdown" ngbDropdownToggle data-cy="userMenu" role="button" aria-haspopup="true" aria-expanded="false" title="Tickets y Validar Vacaciones" style="width: 90%">
                <fa-icon [icon]="['fas', 'ticket-alt']"></fa-icon>
            </a>
            <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu aria-labelledby="dropdownUser">
                <h6 class="dropdown-header">
                    <div class="dropdown-user-details">
                        <div>
                            <fa-icon [icon]="['fas', 'edit']"></fa-icon>&nbsp;Hola!Pedir su ayuda para revi......</div>
                    </div>
                </h6>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" routerLink="/dashboard">Ver todos mis tickets</a>
            </div>
        </li>
    </ul>

    <div class="sb-topnav__right navbar-nav ms-auto ms-md-0" style="margin-left: 7px !important">
        <sb-search></sb-search>
        <!---<sb-change-active-language class="mr-3"></sb-change-active-language>--->
        <sb-top-nav-user></sb-top-nav-user>
    </div>

    <!----Fin-->

    <!-- Navbar-->
</nav>