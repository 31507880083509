import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Injectable, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AppCommonService } from '@common/services';
import { PfTamikDetailComponent } from '@modules/app-common/components/pf-tamik-detail/pf-tamik-detail.component';
import { SearchService } from '@modules/navigation/services';
import { GkValidators } from '@modules/shared/genomik-forms/genomik-validators';
@Component({
    selector: 'sb-search',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './search.component.html',
    styleUrls: ['search.component.scss'],
})
export class SearchComponent implements OnInit {

    readonly searchPeriodo = [{"value": '1', "text":'Año-Mes'}, {"value": '2',"text":'Rango de fechas'}];
    readonly searchTypeBusqueda = [{"value": '1', "text":'Tipo de búsqueda'}];
    readonly searchTypePeriodo = [{"value": '2',"text":'Periodo'}];

    dataMeses = [
        {Value: '1', Display: 'Enero'},{Value: '2', Display: 'Febrero'},{Value: '3', Display: 'Marzo'},{Value: '4', Display: 'Abril'},{Value: '5',Display: 'Mayo'},{Value: '6', Display: 'Junio'},
        {Value: '7', Display: 'Julio'},{Value: '8', Display: 'Agosto'},{Value: '9', Display: 'Septiembre'},{Value: '10',Display: 'Octubre'},{Value: '11', Display: 'Noviembre' }, { Value: '12', Display: 'Diciembre'}
    ];

    dataYears = [{Id: 1, anio: '2010'},{Id: 2, anio: '2011'},{Id: 3, anio: '2012'},{Id: 4, anio: '2013'},{Id: 5, anio: '2014'},{Id: 6, anio: '2015'},
                {Id: 7, anio: '2016'},{Id: 8, anio: '2017'},{Id: 9, anio: '2018'},{Id: 10, anio: '2019'},{Id: 11, anio: '2020'},{Id: 12, anio: '2021'},
                {Id: 13, anio: '2022'},{Id: 14, anio: '2023'},{Id: 15, anio: '2024'}
    ];

    periodoSeleccionado = 1
    dateFormat = 'yyyy/MM/dd'
    isVisible = false;
    formSearchNavigation: any;
    DataBusqueda: any;
    LabelTipoBusqueda: any;
    showBusquedaAvanzada = false;
    showBusqueda = false;
    BusquedaRealizada = false;
    nuevaBusqueda = false;
    showResultadosBusqueda: boolean;
    @ViewChild('BtnModalSearchNavigation')
    BtnModalSearchNavigation!: ElementRef<HTMLElement>;
    @ViewChild('btnModalSearchNavigation')
    btnModalSearchNavigation!: ElementRef<HTMLElement>;
    @ViewChild('BtnModalSearchNavigationPFDetail')
    BtnModalSearchNavigationPFDetail!: ElementRef<HTMLElement>;
    @ViewChild('fCLick')
    fCLick!: ElementRef<HTMLElement>;
    BusquedaEnProceso: boolean | undefined;
    @ViewChild(PfTamikDetailComponent) pfdetail!: PfTamikDetailComponent;
    searchTSeleccionado = 1
    tBusqueda = ""

    constructor(
        private formBuilder: UntypedFormBuilder,
        public appCommon: AppCommonService,
        private searchService: SearchService,
        private router: Router,
        private ref: ChangeDetectorRef
    ) {
        this.formSearchNavigation = formBuilder.group({
            BusquedaAvanzada: new UntypedFormControl(''),
            Solucion: new UntypedFormControl('', GkValidators.required),
            searchTypeBusqueda: new UntypedFormControl(''),
            searchTypePeriodo: new UntypedFormControl(''),
            TipoBusqueda: new UntypedFormControl('1'),
            Busqueda: new UntypedFormControl('', GkValidators.required),
            bAvanzada: new UntypedFormControl('1'),
            f_mes_ini: new UntypedFormControl(''),
            f_year_fin: new UntypedFormControl(''),
        });
    }
    soluciones = [
        {
            Display: 'Támi-k',
            Value: 1,
        },
        {
            Display: 'Áudi-k / Cárdi-k',
            Value: 2,
        },
    ];

    

    ngOnInit() {
        // this.showBusqueda = false;
        // this.showResultadosBusqueda = false;
    }

    CleanModalSearchNavigation() {
        this.formSearchNavigation.reset();
        this.formSearchNavigation.setErrors(null);
        this.formSearchNavigation.updateValueAndValidity();
        this.formSearchNavigation.patchValue({
            BusquedaAvanzada: false,
            Solucion: '',
            tipobusqueda: '',
            Busqueda: '',
            bAvanzada: '1',
            f_mes_ini: '',
            f_year_fin: '',
        });
        this.showBusqueda = false;
        this.showBusquedaAvanzada = false;
        this.showResultadosBusqueda = false;
        this.formSearchNavigation.controls.BusquedaAvanzada.setValue(false);
        this.formSearchNavigation.controls.Busqueda.setValue('');
        this.nuevaBusqueda = false;
    }

    OpenModal() {
        const el: HTMLElement = this.BtnModalSearchNavigation.nativeElement;
        el.click();
        $('.modal').insertAfter($('body'));
    }

    CloseModal() {
        const el: HTMLElement = this.btnModalSearchNavigation.nativeElement;
        el.click();
    }

    OpenModalDetailPF(codigo) {
        this.CloseModal();
        const el: HTMLElement = this.BtnModalSearchNavigationPFDetail.nativeElement;
        el.click();
        this.pfdetail.DetalleEstatusPF(codigo);
    }

    validateFormSearchNavigation() {
        if (this.formSearchNavigation.valid) {
            this.RealizaBusqueda(this.formSearchNavigation.value);
        } else {
            this.validateAllFormFields(this.formSearchNavigation);
        }
    }

    // *******************************************************

    async RealizaBusqueda(formGroup: UntypedFormGroup) {
        console.log("valores del formGroup", formGroup)
        this.showResultadosBusqueda = false
        this.DataBusqueda = [];
        const requestBody = formGroup;
        this.appCommon.spinner(true);
            this.ref.detectChanges();
            this.searchService
                .RealizaBusqueda(requestBody)
                .then(
                    async(Default: any) => {
                    console.log("datos default::", Default)  
                    this.DataBusqueda = Default;
                    this.showResultadosBusqueda = true;
                    this.appCommon.spinner(false);
                    this.ref.detectChanges();
                    },
                    error => {
                        console.log('fail callback', error);
                        this.appCommon.spinner(false);
                        this.appCommon.Notify(error.data.message, 'error');
                        this.ref.detectChanges();
                    }
                )
                .catch(e => {
                    console.log('catch', e);
                    this.appCommon.spinner(false);
                });
    }


    // async RealizaBusqueda(formGroup: UntypedFormGroup) {
    //     console.log("valores del formGroup", formGroup)
        // if (this.BusquedaEnProceso === true) {
        //     return;
        // }
        // this.DataBusqueda = [];
        // this.showResultadosBusqueda = false;
        // this.BusquedaEnProceso = true;
        // const requestBody = formGroup;
        // this.ref.detectChanges();
        // this.appCommon.spinner(true);
        // this.searchService
        //     .RealizaBusqueda(requestBody)
        //     .then(
        //         (Default: any) => {
        //             this.DataBusqueda = Default;

        //             this.appCommon.spinner(false);
        //             this.showResultadosBusqueda = true;
        //             this.triggerFalseClick();
                    // this.BusquedaEnProceso = false;
            //     },
            //     error => {
            //         console.log('fail callback', error);
            //         this.appCommon.spinner(false);
            //         this.appCommon.Notify(error.data.message, 'error');
            //     }
            // )
            // .catch(e => {
            //     console.log('catch', e);
            //     this.appCommon.spinner(false);
            // });
        // console.info('SEND TO ALL: ', this);
    // }
// ***************************************************************************************


    validateAllFormFields(formGroup: UntypedFormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field); //
            if (control instanceof UntypedFormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof UntypedFormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }



    OnChangeSolucion(event) {
        if (event.target.options.selectedIndex === 0) {
            this.formSearchNavigation.controls.TipoBusqueda.setValue('');
        }
        this.formSearchNavigation.setErrors(null);
        this.formSearchNavigation.updateValueAndValidity();
    }

    OnChangeTipoBusqueda(event) {
        this.LabelTipoBusqueda = event.target.options[event.target.options.selectedIndex].text;
        this.LabelTipoBusqueda = event.target.options[event.target.options.selectedIndex].text;
        if (event.target.options.selectedIndex === 0) {
            this.formSearchNavigation.controls.TipoBusqueda.setValue('');
            this.LabelTipoBusqueda = '';
        }
        this.formSearchNavigation.controls.Busqueda.setValue('');
        this.formSearchNavigation.setErrors(null);
        this.formSearchNavigation.updateValueAndValidity();
    }

    closeModalSearchNavigation() {
        const el: HTMLElement = this.btnModalSearchNavigation.nativeElement;
        el.click();
    }

    triggerFalseClick() {
        const el: HTMLElement = this.fCLick.nativeElement;
        el.click();
    }

    GoExpediente(id) {
        const link = 'operations/tami-k/medical-record/' + id;
        this.router.navigate([link]);
        this.closeModalSearchNavigation();
    }

    Download(Codigo) {
        let name = 'reporte' + Codigo + '.pdf';
        name = 'Reporte305836992.pdf';
        const requestBody = {
            type: 'tk-results',
            name,
        };
        this.appCommon.spinner(true);
        this.ref.detectChanges();
        this.searchService
            .downloadTestPdf(requestBody)
            .then(
                (Default: any) => {
                    this.appCommon.PDFViewer(Default.file);
                    $('#ModalSearchNavigation').css('z-index', 1049);
                    this.appCommon.spinner(false);
                    this.ref.detectChanges();
                },
                error => {
                    console.log('fail callback', error);
                    this.appCommon.Notify(error.data.message, 'error');
                    this.ref.detectChanges();
                }
            )
            .catch(e => {
                console.log('catch', e);
                this.appCommon.spinner(false);
                this.ref.detectChanges();
            });
    }



    showModal(): void {
      this.isVisible = true;
    }
  
    handleOk(): void {
      console.log('Button ok clicked!');
      this.isVisible = false;
    }
  
    handleCancel(): void {
        this.formSearchNavigation.reset();
        this.formSearchNavigation.setErrors(null);
        this.formSearchNavigation.updateValueAndValidity();
        this.formSearchNavigation.patchValue({
            BusquedaAvanzada: false,
            Solucion: '',
            TipoBusqueda: '',
            Busqueda: '',
        });
        this.showBusqueda = false;
        this.showBusquedaAvanzada = false;
        this.showResultadosBusqueda = false;
        this.formSearchNavigation.controls.BusquedaAvanzada.setValue(false);
        this.nuevaBusqueda = false;
      this.isVisible = false;
    }

    modelChanged(tperiodo) {
        // do something with new value
    
        console.log("newObj",tperiodo)
        if(tperiodo == 1){
            this.periodoSeleccionado = 1
        }
        if(tperiodo == 2){
            this.periodoSeleccionado = 2
        }
    }

    bAvanzadaChanged(bAvanzada) {
        // do something with new value
    
        console.log("newObj",bAvanzada)
        if(bAvanzada == 1){
            this.searchTSeleccionado = 1
        }
        if(bAvanzada == 2){
            this.searchTSeleccionado = 2
        }
    }

    TypeBusquedaChanged(tBusqueda){
        if(tBusqueda == 1){
            
        }
    }

    PeriodoChanged(tPeriodo){
        if(tPeriodo == 1){
            
        }
    }

    
  
}
